@import './variables.less';

#planit-left-menu {
  z-index: 1000;

  -webkit-transition: width 0.5s ease;
  transition: width 0.5s ease;

  a.disabled.item {
    color: rgba(255, 255, 255, 0.9) !important;
    font-weight: bold;
    font-size: 14px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .planit__user-name {
    word-break: break-word;
  }

  a {
    font-weight: bold;
    font-size: 14px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;

    i {
      font-size: 1.3em;
      margin-right: 20px;
    }

    .users.icon {
      margin-right: 23px;
    }

    .home.icon,
    .fa-file-contract {
      margin-right: 22px;
    }

    svg {
      font-size: 1.4em;
      margin-right: 22px;
      margin-left: 4px;
    }
  }

  .item::before,
  .item::after {
    //background: @inverted-color !important;
    background: #eeeeee !important;
  }

  a.active.item,
  .item:hover {
    //background: @inverted-color !important;
    background: #eeeeee !important;
    color: @hover-green !important;
  }

  //background-color: @inverted-color;
  background-color: #eeeeee;
  width: 16.8rem;

  &.shrunk {
    width: 4.5rem;

    .menu.visible,
    .menu.transition {
      width: 300%;
    }

    #expand-menu-icon {
      text-align: left !important;
    }

    a,
    span {
      i,
      #assessments-composite-icon,
      svg {
        margin-right: 0px !important;
      }
    }
  }

  #expand-menu-icon {
    text-align: right;
    margin: 5px;
    //color: white;

    i {
      font-size: 1.4rem;
      float: right;
    }
  }

  #expand-menu-icon-expanded {
    text-align: right;
    margin-left: 85px;
    //color: white;

    i {
      font-size: 1.4rem;
      float: left;
    }
  }

  #jti-white-container {
    #jti-white {
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      min-width: 47px;
      max-width: 47px;
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }

    &.shrunk {
      padding: 12px 3px;
    }
  }

  .left-menu-item {
    font-weight: bold;
    font-size: 14px;
    //color: rgba(255, 255, 255, 0.9) !important;
    color: rgba(25, 25, 25, 0.9) !important;

    &.active,
    &:hover {
      //background: @inverted-color !important;
      background: #eeeeee !important;
      color: @hover-green !important;
    }

    i.dropdown.icon {
      visibility: hidden;
    }

    &.secondary-menu {
      display: flex;
      justify-content: space-between;

      i.icon {
        visibility: visible;
        margin-right: 0px !important;
      }
    }

    position: relative;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    padding: 12px 1.14285714em;
    text-transform: none;

    span {
      i {
        font-size: 1.3em;
        margin-right: 20px;
      }

      .users.icon {
        margin-right: 23px;
      }

      .home.icon {
        margin-right: 22px;
      }
    }
  }

  #avatar-container {
    font-weight: bold;
    font-size: 14px;
    //color: rgba(255, 255, 255, 0.9) !important;
    color: rgba(25, 25, 25, 0.9) !important;
    padding-top: 12px;
    padding-bottom: 12px;

    &.active,
    &:hover {
      //background: @inverted-color !important;
      background: #eeeeee !important;
      color: @hover-green !important;
    }

    i.dropdown.icon {
      visibility: hidden;
    }

    #user-avatar {
      max-width: 30px;
      max-height: 30px;
      min-width: 30px;
      min-height: 30px;
      width: 100%;
      height: 100%;
      margin: 0px 11px 3px 7px;
    }

    padding-left: 9px;

    &.shrunk {
      padding-left: 1em;

      #user-avatar {
        margin-left: 0px;
      }
    }
  }

  .menu.visible,
  .menu.transition {
    color: rgba(255, 255, 255, 0.9) !important;
    //background: @inverted-color;
    background: #eeeeee !important;
    border-radius: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 250px;
    top: 0 !important;
    z-index: 0 !important;
    letter-spacing: 1px;

    .circular {
      font-weight: lighter;
      width: 32px;
      height: 32px;
      padding-top: 10px !important;
    }

    .header {
      //color: rgba(255, 255, 255, 0.9) !important;
      color: rgba(25, 25, 25, 0.9) !important;
      text-transform: capitalize;

      .role-location-list {
        font-weight: lighter;
      }
    }

    .divider {
      //background-color: #474b4f;
      background-color: #eeeeee;
    }

    p {
      width: 100% !important;
    }

    .item[role='option'] {
      //color: rgba(255, 255, 255, 0.9) !important;
      color: rgba(25, 25, 25, 0.9) !important;

      &:hover,
      &.active {
        background: #00b2a933 !important;
        //color: white !important;
        color: rgba(25, 25, 25, 0.9) !important;
      }
    }

    .user-item__active {
      background-color: #00b2a933 !important;
    }

    .update-photo__box {
      font-weight: lighter;
      font-size: 12px;
      display: flex;
      justify-content: flex-end;

      .icon {
        margin-left: 5px;
      }
    }

    .role-switch__main-text {
      color: #00b2a9;
      font-weight: medium;
      width: 100%;
      right: 0px;
      padding-left: 200px;
    }
  }
}

#content-container
  > div.ui.grid.event-types-list-grid
  > div.row.event-types-list-items-row
  > div
  > div.ui.active.transition.visible.dimmer {
  z-index: 0 !important;
  padding: 0px 15px !important;
}

#assessments-composite-icon {
  font-size: 1.4em;
  margin-right: 21px;

  .fa-file {
    margin-left: 4px;
  }

  .fa-tasks {
    -webkit-transform-origin: 0.5em 0.5em;
    transform-origin: 0.5em 0.5em;
    font-size: 0.8em;
    //color: #474b4f;
    color: #eeeeee;
    margin-top: 6px;
    margin-left: 4px;
  }
}

.role-location-list {
  margin-bottom: 5px;
}

.menu__collapsable-dropdown {
  width: 165px;
  min-height: fit-content !important;
  height: fit-content !important;
  z-index: 4 !important;
}

.role-switch__menu {
  min-width: 245px !important;
  width: fit-content !important;
  font-weight: 200;
  max-height: 90vh;
  overflow-y: auto;

  #role-switch__menu__logout-divider {
    margin-bottom: 0 !important;
  }
}

//MEDIA QUERIES
@media only screen and (max-height: 670px) {
  #planit-left-menu * {
    transform: scale(0.95) !important;
  }

  #planit-left-menu .item {
    padding-top: 7.8px !important;
    padding-bottom: 7.8px !important;
  }

  #planit-left-menu i,
  #user-avatar {
    transform: scale(0.8) !important;
  }

  #planit-left-menu #assessments-composite-icon {
    font-size: 1.3em;
    margin-left: 2px;

    .fa-tasks {
      font-size: 0.6em;
      margin-left: 6px;
    }
  }

  #planit-left-menu {
    .fa-file-contract {
      font-size: 1.2em;
      margin-left: 5px;
      margin-right: 23px;
    }

    .menu.visible,
    .menu.transition {
      left: 99% !important;

      .item {
        transform: unset !important;
        font-size: 13px !important;
      }
    }

    &.shrunk {
      .menu.visible,
      .menu.transition {
        left: 4rem !important;
      }
    }
  }
}

@media only screen and (max-height: 580px) {
  #planit-left-menu * {
    // zoom: 0.81 !important;
    transform: scale(0.85) !important;
  }

  #planit-left-menu #assessments-composite-icon {
    font-size: 1.8em;
    margin-right: 13px;

    .fa-file {
      margin-left: 1px !important;
    }

    .fa-tasks {
      margin-left: 4px !important;
    }
  }

  #planit-left-menu .fa-file-contract {
    font-size: 1.8em;
    margin-left: 1px;
  }

  #planit-left-menu.shrunk {
    .menu.visible,
    .menu.transition {
      left: 3.3rem !important;
    }
  }
}
