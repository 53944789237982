.employee-search__modal {
  transform: translateY(10vh);
  .ui.custom-table thead tr th:first-of-type {
    min-width: unset !important;
  }

  .full-width.table__flex-container {
    height: fit-content;
  }

  .table__flex-container {
    overflow-x: hidden;
  }

  .employee-search-filter {
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .ui.inline.dropdown.employee-search-filter .dropdown.icon {
    margin: -1em;
  }

  .search__modal__header.header {
    border-bottom: 1px solid rgba(181, 181, 181, 0.342) !important;
  }

  .custom-table-main-actions {
    display: none !important;
  }
}

.ui.container.student-list-table {
  min-height: 40vh;
}

.student-list-table {
  .table__flex-container {
    max-height: 50vh !important;
  }
}

.choose-instructor-form__table-wrapper {
  .full-width.table__flex-container {
    height: 40vh;
    overflow-x: hidden;
  }

  .ui.right.floated.menu {
    display: none;
  }
}

.ui.header.modal-header-title {
  padding-top: 1rem;
  padding-left: 2rem;
  letter-spacing: 0.28px;
  color: #222629;
  opacity: 1;
}

.ui.modal > .content.modal-content {
  padding-left: 3rem;
  padding-right: 3rem;
}

.add-participants-button {
  background: #00b2a9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  min-width: 125px;
}

.selected-icon-cell {
  width: 50px;
}
