@import '../../../../../assets/less/variables.less';

.scheduler-poc-config__tabs {
  .ui.secondary.pointing.menu {
    border-bottom: 2px solid transparent;
  }

  .item {
    padding: 24px 0 !important;
    font-size: 14px;
    margin-right: 32px !important;
  }
}

.scheduler-poc-config {
  min-width: 200px;
  min-height: 200px;

  .scheduler-poc-config__header {
    border: 1px solid #dedfdf !important;
    padding: 24px 32px !important;
  }
}

.poc-config-modal__container {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 14px;
  max-height: 50vh;

  .poc-config-modal__scrollable-content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .poc-config-modal__range-content {
    button {
      width: 32px !important;
      height: 32px !important;
      padding: 0;
      margin-right: 16px;
      background-color: transparent;
      border: 1px solid transparent;

      &:active {
        background-color: transparent;
      }
    }

    .selected {
      border-color: @hover-green;
      color: @hover-green;
    }
  }

  .row {
    display: flex;
    margin: 16px 0;
    font-weight: bold;

    .filter-form__item-input {
      border: 1px solid @inverted-color;
    }

    .planit-eventform-dates-period div {
      min-width: 120px;
      max-height: 100% !important;

      i {
        max-width: 30px !important;
        text-align: right;
      }
    }

    .column {
      width: 120px;
      margin-right: 8px;
      font-weight: bold;

      .disabled.input {
        min-width: 38px;
        max-width: 50px;
        height: 32px;
        user-select: none;

        input:disabled {
          color: @inverted-color !important;
          text-align: center;
          padding: 4px;
        }
      }

      input {
        font-weight: bold;
        padding: 8px !important;
      }

      &:nth-child(3) {
        width: 50px !important;
      }
    }

    .remove-row__times {
      align-items: center;
      cursor: pointer;
    }
  }
}

.row.poc-config-modal__action-buttons {
  display: flex;
  justify-content: space-between;

  .ui.button {
    min-width: 100px !important;
    margin: 0;

    &.cancel {
      align-self: flex-end;
    }

    &.save {
      align-self: flex-end;
      margin-left: 16px;
    }
  }
}
