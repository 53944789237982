.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles {
  width: 100%;
  flex-direction: column;
  align-items: flex-start !important;

  .flex-start {
    align-items: center !important;
    margin-bottom: 39px;
  }

  p {
    margin-right: 16px;
    margin-bottom: 0px;
    width: fit-content;
  }

  h4 {
    font-size: 14px;
  }

  .form__header__dropdown-style {
    min-width: 138px;
  }

  .events-form__btn__positive {
    background-color: #474b4f;
    color: white;
    font-weight: 500;
    text-align: center;

    &:hover {
      background-color: #474b4f;
      color: white;
    }
  }

  .events-form__btn__negative {
    text-align: center;
    color: #b0b0b0;
    background-color: white;
    font-weight: 500;

    &:hover {
      color: #b0b0b0;
      background-color: white;
    }
  }

  .form__event-details-tab__element {
    display: flex;
    align-items: center !important;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 24px;
  }

  .scroll {
    scroll-padding: 5px !important;
  }

  .scroll::-webkit-scrollbar {
    height: 4px;
  }

  .scroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #dfdfdf;
  }

  .event-details-tab__mr-table {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(6, 1fr) 20px;
    grid-template-rows: repeat(auto, 1fr);
    margin-bottom: 20px;
    font-size: 11px;
    overflow: visible;
    padding-bottom: 10px;

    div.field > label {
      font-weight: normal;
      font-size: 11px;
    }

    .ui.selection.dropdown {
      min-width: 100px;
      width: 95%;
      margin-right: 5px !important;
      line-height: 12px;
      min-height: 100%;
    }

    .border-bottom-gray {
      padding-top: 9.5px;
      padding-bottom: 9.5px;
    }

    .mr-table__dropdown {
      margin-top: 8px;
      padding: 8px;
      display: flex !important;
      align-items: center !important;
      margin-right: 5px;

      i {
        height: 100% !important;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0px;
      }

      > div.text {
        display: flex !important;
        align-items: center !important;
        width: 70px !important;
        text-overflow: ellipsis !important;

        div {
          display: flex !important;
          align-items: center !important;
          width: 70px !important;
          text-overflow: ellipsis !important;
        }
      }
    }

    .mr-table__dropdown__multi {
      margin-top: 8px;
      padding: 4px;
      margin-right: 5px;

      > div.default.text {
        font-weight: 11px;
      }

      .ui.label {
        width: 66px;
      }
    }

    .event-details-tab__mr-table__remove {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 20px;
    }

    .mr-table__column-title {
      background-color: #eeeeee;
      width: 100%;
      padding: 8px;
      margin-right: 0px;
    }

    .text__transparent {
      color: transparent;
    }

    .mr-table__dropdown.disabled {
      border: none !important;
      background-color: white !important;

      i {
        display: none;
      }
    }

    .ui.multiple.search.dropdown > input.search {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 100%;
      min-height: 100% !important;
      margin: 0px !important;
      padding: 0px !important;
      flex-direction: row !important;
    }

    .ui.multiple.search.dropdown > .text {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 80%;
      height: 100%;
      margin: 0px !important;
      padding: 5px !important;
      font-weight: 500;
    }

    .ui.search.dropdown > .text {
      width: 80%;
      height: 100%;
      margin: 0px !important;
    }

    .ui.search.dropdown > .text > div {
      width: 100%;
      height: 100%;
    }
  }
}
