.template-search__modal {
  .ui.table td {
    padding: 10px 0.75em;
    text-align: inherit;
  }

  .borderless-header {
    .arrow {
      display: none;
    }
  }

  .ui.header.modal-header-title {
    padding-top: 1rem;
    padding-left: 2rem;
    letter-spacing: 0.28px;
    color: #222629;
    opacity: 1;
    font-size: 16px !important;
    margin-top: 0px;
  }

  .full-width.table__flex-container {
    overflow-x: hidden;
    margin-bottom: 16.5px;
  }

  .template-search__first-row__column-filters {
    margin-right: 16px;

    .input {
      min-width: 250px;
      margin-right: 12px;
    }

    .ui.input.template-search-filter > input {
      border: 1px solid #474b4f;
      border-radius: 4px;
    }

    .keep-right {
      float: right;
    }

    .skill-name {
      font-weight: bold;
      color: #474b4f;
      transform: translateY(50%);
    }
  }

  .choose-template-search__first-row__column-filters {
    margin-right: 12px !important;

    .input {
      min-width: 230px;
      //margin-right: 12px;
    }

    .ui.input.template-search-filter > input {
      border: 1px solid #474b4f;
      border-radius: 4px;
    }
  }

  .ui.icon.input.custom-editor-search {
    margin-right: 12px !important;
  }

  .custom-editor-search__names {
    min-width: 250px;
  }

  .ui.container.template-list-table {
    min-height: 40vh;
    margin-top: 16px;
    max-height: 70vh;

    label:before {
      border: 1px solid #474b4f !important;
    }

    > div > div.custom-table-main-actions {
      display: none;
    }

    > div.full-width.table__flex-container > table > thead > tr > th:nth-child(1) {
      min-width: 40px !important;
    }

    > div.full-width.table__flex-container > table > thead > tr > th:nth-child(2) {
      min-width: 40px !important;

      i {
        font-size: 16px;
      }
    }

    > div.full-width.table__flex-container > table > tbody > tr:nth-child(1) > td:nth-child(1) {
      width: 40px;
    }

    > div.full-width.table__flex-container > table > tbody > tr:nth-child(1) > td:nth-child(2) {
      width: 130px;
    }
  }

  .template-list-table {
    .full-width.table__flex-container {
      height: 52vh;
      overflow-x: hidden;
    }

    .ui.right.floated.menu {
      display: none;
    }
  }

  .ui.search.dropdown {
    &.template-search-filter {
      border: 1px solid #474b4f !important;
      border-radius: 4px;
    }
  }

  .modal-back-icon {
    padding-top: 10px;
  }

  .ui.modal > .content.modal-content {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .actions {
    padding-left: 4rem;
    padding-right: 33px !important;
  }

  .add-template-button {
    background: #00b2a9 0% 0% no-repeat padding-box;
    border-radius: 4px;
    min-width: 135px;
  }

  .selected-icon-cell {
    width: 50px;
  }
}

//For assessors & employee modal

.ui.container.employee-list-table {
  min-height: 52vh;
  margin-top: 16px;

  label:before {
    border: 1px solid #474b4f !important;
  }

  > div > div.custom-table-main-actions {
    display: none;
  }

  > div.full-width.table__flex-container > table > thead > tr > th:nth-child(1) {
    min-width: 40px !important;
  }

  > div.full-width.table__flex-container > table > thead > tr > th:nth-child(2) {
    min-width: 150px !important;

    i {
      font-size: 16px;
    }
  }

  > div.full-width.table__flex-container > table > thead > tr > th:nth-child(3) {
    min-width: 150px !important;
  }

  > div.full-width.table__flex-container > table > thead > tr > th:nth-child(4) {
    min-width: 150px !important;
  }

  > div.full-width.table__flex-container > table > thead > tr > th:nth-child(5) {
    min-width: 150px !important;
  }
}

@media all and (min-width: 1400px) {
  .modal-content.content {
    padding: 26.5px 55px 50px 48px !important;
  }

  .actions {
    padding-right: 0px 55px 0px 48px !important;
  }
}
