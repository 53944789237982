.configuration-event-n-request__modal {
  .ui.form {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    flex-direction: row !important;

    .search.selection.dropdown.custom-editor {
      max-width: 100px;
    }

    .search.multiple.selection.dropdown {
      max-width: 250px;
    }
  }

  .configuration-event-n-request__modal__element {
    margin-bottom: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;

    label {
      min-width: fit-content;
      margin-left: 5px;
    }

    .field {
      display: flex !important;
      align-items: center !important;

      .input {
        min-width: 150px;
        margin-left: 10px !important;
      }
    }
  }
}
