.b-sch-event-wrap {
  overflow: visible;
  &.b-sch-travel-event {
    z-index: 4;
    &:hover {
      z-index: 6;
    }
  }

  &.b-milestone-wrap {
    margin-left: 0;
    font-size: 1rem;
    .b-milestone.b-sch-event:not(.b-sch-event-withicon):before,
    .b-milestone.b-task-baseline:before {
      content: none;
    }
  }
}

.b-sch-event.travel-event {
  position: relative;
  overflow: visible;
  i.travel-icon {
    border-radius: 4px;
    border: 1px solid #b0b0b0;
    position: absolute;
    background-color: #b0b0b0;
    color: #dedfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: default;
    height: 100% + 6px;
    width: 50px;
    &.b-fa-plane-departure {
      left: -60px;
    }
    &.b-fa-plane-arrival {
      right: -58px;
    }

    &:hover {
      background-color: #474b4f;
      color: white !important;
    }
  }
}

.row-height-slider {
  background-color: #ffffff;
  padding: 4px;
}

input[type='range'] {
  -webkit-appearance: none;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.poc-config-modal__ranges-container {
  .fields .field .planit-eventform-dates-period .ui.icon.input {
    .icon {
      display: none;
    }
    input {
      text-align: center;
      padding: 8px;
      padding-right: 8px !important;
    }
    max-width: 100px;
    margin-right: 24px;
    min-width: 100px;
    width: 100px;
    height: 32px;
  }
}

/* Edit css for slider  with recomendations here: https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */

//TOOLTIP STYLES
.scheduler-tooltip {
  border-radius: 10px;
  // min-height: 200px;

  &.b-panel:not(.b-outer) {
    border-radius: 4px;

    .b-tooltip-content {
      min-height: 100%;
      padding: 0.5em;
    }

    .b-panel-content {
      border-radius: 4px;
      background-color: #474b4f;
      padding: 1em;
      color: white;
    }
  }
}

.b-sch-foreground-canvas {
  .today {
    display: none;
  }

  .weekend {
    background: #f2f3f3 !important;
  }

  .holiday {
    background: #f0aea9 !important;
    border: none !important;
    z-index: 3 !important;
  }
}

.b-grid-header .b-sch-timerange {
  background: transparent;

  &.today {
    cursor: auto;
    background: #3be0d84d;
  }
}

.b-popup.b-text-content {
  max-width: unset !important;
}

.scheduler-tooltip__wrapper {
  min-width: 460px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  i.b-fa {
    margin-right: 0.5rem;
  }
  .b-fa-circle,
  .fa-circle {
    font-size: 1.5rem;
  }

  .tooltip__section-item {
    display: flex;
    justify-content: initial;
  }

  .tooltip__header {
    margin: 0 4px 8px 4px;
    display: flex;
    justify-content: space-between;
    .tooltip__header-event-type {
      display: flex;
      margin: 0 1em;
    }
    p {
      white-space: nowrap;
      margin-bottom: 0;
    }
    margin-bottom: 0.5em;

    .tooltip__status {
      align-content: flex-end;
    }
  }

  .tooltip__section {
    margin: 0 4px 8px 4px;
    display: flex;
    justify-content: space-between;
    &.machines {
      flex-wrap: wrap;
      justify-content: flex-start;
      span {
        padding: 0 0.5rem;
      }
    }
  }
  i {
    margin-right: 8px;
  }
}
