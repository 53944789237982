.feedbacks-wizard__steps-container.theoretical-form-wizard-container .template-wizard__machinery__training {
  margin-bottom: 32px;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .template-wizard__machinery__training label {
  padding-bottom: 8px;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .template-wizard__machinery__training .ui.search.selection.dropdown.custom-editor {
  min-width: 175px;
  width: 175px !important;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .template-wizard__machinery__wrapper .flex-column {
  margin-right: 16px;
  margin-bottom: 50px;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .template-wizard__machinery__wrapper .flex-column label {
  padding-bottom: 8px;
}
