.edit-form__title {
  font-size: 16px;
}

.form__all-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  font-size: 12px;
  max-height: 88vh;
  padding: 0px 15px !important;
  //Scroll styles

  .scroll {
    scroll-padding: 5px !important;
  }

  .scroll::-webkit-scrollbar {
    width: 6px;
  }

  .scroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #dfdfdf;
  }
}

//MEDIA QUERIES

@media all and (max-height: 800px) {
  .form__all-wrapper {
    margin-top: 10px;
    padding: 0px 15px !important;

    .form__all-wrapper__header {
      margin-bottom: 20px;
    }

    .form__all-wrapper__main-content {
      height: 50vh;
    }

    .form__all-wrapper__footer {
      .ui.divider {
        margin: 10px 0px 10px 0px;
      }

      p {
        margin: 0px;
      }
    }

    .tna-edit-form__header__evaluation-criteria {
      margin-bottom: 5px;
    }
  }
}

@media all and (max-width: 1300px) {
  .main-tab__skill-section__score {
    margin-right: 40px !important;
  }
}
