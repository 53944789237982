.events-form__check-list-container {
  .field {
    margin-left: 74px;
  }

  .checkbox {
    margin-left: 74px;

    > label {
      padding-left: 30px;
    }
  }

  .button {
    margin-left: 74px;
  }
}
