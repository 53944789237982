.template-search__modal.choose-assessors {
  .full-width.table__flex-container {
    overflow-x: scroll;
  }

  .template-search__first-row__column-filters.choose-assessors {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .ui.input {
      min-width: 200px;
      width: 200px;
      height: 36px !important;
      margin-bottom: 8px;

      &.long-input {
        width: 250px;
        min-width: 250px;
      }
    }

    .custom-editor.ui.inline.dropdown {
      min-width: 200px;
      width: 200px !important;
      margin-right: 12px;
      margin-top: 8px;

      .default.text {
        font-weight: 500 !important;
      }

      .icon {
        color: #474b4f !important;
      }

      .dropdown.icon {
        margin: -0.91666667em;
      }
    }

    .search {
      font-weight: 500 !important;
    }

    .ui.inline.dropdown .dropdown.icon {
      margin: -10px 0px !important;
    }
  }
}
