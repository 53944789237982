.wizard__step-three {
  .step-three__skills-questions-wrapper {
    margin-left: 62px;
    margin-bottom: 27px;

    .skill-label {
      background-color: #f9f9f9;
      color: #474b4f;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      margin-bottom: 18px;

      p {
        margin-bottom: 0px;
        margin-left: 12px;
      }

      .wizard__sort-icons-wrapper {
        margin-right: 12px;
        display: flex;
        align-items: center;

        i {
          font-size: 18px;
          cursor: pointer;
        }

        .tna-template-wizard__add-questions-btn {
          min-width: 100px;
          max-width: 100px;
          height: 38px;
          border-radius: 4px;
          padding: 0px !important;
          margin: 0px 120px 0px 0px;
          background-color: #f9f9f9 !important;
          border: 1px solid transparent !important;

          &:hover {
            background-color: #dedfdf !important;
            border: 1px solid #dedfdf !important;
            transition: ease-in 0.2s;
          }
        }
      }
    }
  }

  .tna-arrow-left-margin {
    margin-right: 3px !important;
  }

  .remove {
    cursor: pointer;
  }

  .tna-wizard__assign-question {
    display: flex;
    align-items: center;

    .tna-wizard__assign-question__items {
      display: flex;
      align-items: center;
      border-top: 1px solid #f9f9f9;
      padding: 14px 0px;
      width: 100%;
      margin-right: 32.5px;

      p {
        margin-bottom: 0px;
      }

      .wizard__sort-icons-wrapper {
        margin-right: 16.5px;

        .caret.square.up.outline.icon {
          cursor: pointer;
        }

        i {
          font-size: 16px;
          cursor: pointer;
        }
      }

      .wizard__question-item {
        &:first-of-type {
          margin-right: 24.5px;
        }
      }

      .wizard__customize-question-item {
        &:first-of-type {
          margin-right: 20px;
        }
        &:nth-of-type(2) {
          margin-right: 20px;
        }
      }
    }
  }

  .tna-wizard__assign-question:last-child {
    .tna-wizard__assign-question__items {
      border-bottom: 1px solid #f9f9f9;
    }
  }
}

.template-wizard__machine-related {
  display: flex;
  flex-direction: column;

  .editor-32px {
    .ui.dropdown {
      max-height: 32px !important;
      min-height: 32px !important;
      min-width: 250px !important;
      max-width: 30% !important;

      input {
        padding: 6.3px !important;
      }

      i.dropdown.icon {
        padding: 0 !important;
        margin: 0;
        top: -3px;
      }

      &.loading {
        i.dropdown.icon {
          padding: 14.8px !important;
        }
      }

      div.text {
        top: -3px !important;
        font-size: 12px !important;
        width: 95% !important;
      }
    }
  }

  .template-wizard__machine-unit {
    min-width: 175px;
    max-width: 175px;
    padding: 9px 8px 9px 8px;
    font-size: 12px;
    border: 1px solid #b0b0b0;
    border-radius: 4px;

    div {
      width: 138px;
    }

    .text {
      font-size: 12px;
      color: black;
      font-weight: 500 !important;
    }

    .visible.menu.transition {
      .item {
        width: 100%;
      }
    }
  }

  .button {
    width: 120px;
    height: 32px;
    margin-bottom: 24px;
  }

  .filter-form__item-input {
    width: 175px;
    height: 32px;
    margin-bottom: 16px;
  }
}

.template-wizard__machineUnit-button-container {
  display: flex;
  justify-content: flex-start;

  .template-wizard__machine-unit {
    .icon {
      transform: translateY(10px);
    }
  }

  .tna-template-wizard__remove-group-btn {
    border: 1px solid #e03c31;
    color: #e03c31;
    width: 68px;
    margin-left: 24px;
    background-color: white;
    padding: 0px;
    height: 39.66px;

    &:hover {
      background-color: white;
      color: red;
      font-weight: bold;
    }

    &::after {
      background-color: white;
    }
  }
}

.template-wizard-customize__machineUnit-button-container {
  display: flex;
  justify-content: flex-start;
  background-color: #00b2a933;
  padding: 8px;

  h2 {
    font-size: 12px;
  }

  .template-wizard__machine-unit {
    .icon {
      transform: translateY(10px);
    }
  }

  .tna-template-wizard__remove-group-btn {
    border: 1px solid #e03c31;
    color: #e03c31;
    width: 68px;
    margin-left: 24px;
    background-color: white;
    padding: 0px;
    height: 39.66px;

    &:hover {
      background-color: white;
      color: red;
      font-weight: bold;
    }

    &::after {
      background-color: white;
    }
  }
}
