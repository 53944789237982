.request-wizard__step1__locations {
  display: flex;
  justify-content: center;
  align-items: center;
}

.machine-related-request-reason__row {
  .label-flex-start {
    padding-top: 10px;
  }
}
