.user-profile-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr) 60px;
  grid-template-rows: repeat(auto, 1fr);
  width: 100%;
  margin-bottom: 30px;

  .user-profile-list__column-title {
    background-color: #eeeeee;
    width: 100%;
    padding: 8px;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .border-bottom-gray {
    border-bottom: 1px solid #dedfdf;
    padding: 11.5px;
  }

  span {
    display: flex;
    align-items: center;
  }

  .text__transparent {
    color: transparent;
  }

  .icons {
    display: flex;
    align-items: center;
  }
}
