.useful-link-form-group .field {
  z-index: 11;
  //RichTextEditor component .BtnWrap has z-index:10
}
.custom-rte {
  &.rte-30vh {
    .public-DraftEditor-content {
      max-height: 30vh;
      height: 30vh !important;
    }
  }
}

.custom-check-box {
  &.custom-position {
    margin-top: 29px !important;
  }
}
