@import '../../../../assets/less/variables.less';
@import '../shared-scheduler-components/request-drag.style.less';

.employee-page__container {
  margin-top: 30px !important;
}

.user-card-box {
  background-color: #474b4f;
  color: white;

  img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    margin-top: 19px;
    margin-left: 10px;
  }
}

.rows-user-card-box {
  margin-top: 3px;
  margin-bottom: 3px;
  min-height: 2em;
}

.min-height-box {
  min-height: 20em;
}

.bold-text {
  font-weight: bold;
  text-align: left;
}

.dark-grey-box {
  background-color: #b5b5b5;
}

.planit-main-container {
  background: white;
}

.instructor-card__expertise-title {
  color: #00b2a9;
  font-size: 14px;
}

//Tabs styles
.instructor-expertise-card__tab {
  height: 100%;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 15px !important;

  .ui.segment.active.tab {
    margin-top: 0px !important;
    padding-top: 0px !important;
    height: 95%;
    border: none;
    background-color: transparent;
    border-style: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
    display: none;
  }

  .ui.pointing.secondary.menu {
    margin-bottom: 0px !important;
    border-bottom: 1px solid #f9f9f9;

    .item {
      margin-bottom: 0.1px;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
      background-color: #f9f9f9;
      width: 128px;
      text-align: center !important;
      justify-content: center;

      &:hover:not(.active) {
        background-color: #eeeeee !important;
      }
    }

    .active.item,
    .active.item:hover {
      color: #ffffff;
      background-color: #474b4f;
      border-bottom: none;
    }
  }
}

@media all and (max-width: 1280px) {
  .user-card-box {
    img {
      height: 60px;
      width: 60px;
      margin-top: 12px;
      margin-left: 12px;
    }
  }
}
