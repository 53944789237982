.modal-header-title {
  font-size: 16px !important;
}
.search__modal .search__modal__header.header {
  border-bottom: 1px solid rgba(181, 181, 181, 0.342) !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}
.fit-content {
  width: fit-content;
}
.text__center {
  text-align: center;
}
.add-row-btn {
  background-color: #eeeeee !important;
  border: 1px solid #eeeeee !important;
  padding: 2px !important;
}
.question-bank__cell__tag {
  background-color: #eeeeee;
  margin: 5px;
  color: #474b4f;
  font-weight: 500;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  border-radius: 10px;
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.text__regular {
  font-weight: 500;
}
.text__bold {
  font-weight: 700 !important;
}
.flex-baseline {
  display: flex;
  align-items: baseline;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.padding-margin-right-none {
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.text__grey {
  color: grey;
}
.margin-zero {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.padding-zero {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.form__box-content {
  border: 1px solid #c1c5c5 !important;
  background-color: white !important;
}
.form__header__row {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 100% !important;
  margin-bottom: 24px !important;
}
.form__header__row-icon {
  margin-right: 24px !important;
  font-size: 18px !important;
  margin-bottom: 5px !important;
  color: #474b4f !important;
  height: 18px !important;
  width: 18px !important;
}
.form__field-numeric {
  width: 70px !important;
  height: 32px !important;
  margin-right: 50px;
}
.form__planned-duration__numeric__wd {
  width: 90px !important;
}
.form__planned-duration__numeric__wd input {
  min-width: 90px;
}
.text__transparent {
  color: transparent !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #dedfdf !important;
}
.students-table__column-title {
  background-color: #eeeeee !important;
  width: 100% !important;
  padding: 8px !important;
  margin-right: 0px !important;
}
.form__tab__icon-element {
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}
.error-message__style {
  width: 95%;
  border: 1px solid #e03c31;
  background-color: white !important;
  color: #222629 !important;
  font-size: 11px;
  margin-bottom: 32px;
  box-shadow: none !important;
}
.error-message__style .list {
  margin: 0px;
}
.error-message__style li.content::before {
  display: none;
}
.error-message__style .icon {
  font-size: 16px !important;
  color: #e03c31;
  align-self: flex-start;
}
.error-message__style .header {
  padding-left: 14px !important;
  color: #e03c31 !important;
}
.ui.modal .actions.form__action-buttons-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #eeeeee !important;
  padding: 16px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__actions__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-right: 16px;
  font-weight: 500;
}
.ui.modal .actions.form__action-buttons-wrapper .form__align-right-btns {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
}
.ui.modal .actions.form__action-buttons-wrapper .form__white-btn {
  background-color: white;
  color: #474b4f;
  border: 1px solid #474b4f;
}
.ui.modal .actions.form__action-buttons-wrapper .form__white-btn .icon {
  color: #474b4f;
  background-color: transparent;
  padding: 0px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__request-changes-btn {
  padding: 10px;
  min-width: 120px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__next-status-accept-btn {
  background-color: #00b2a9;
  color: white;
  font-weight: 600;
}
.ui.modal .actions.form__action-buttons-wrapper .request-form__reject-button {
  background-color: white;
  color: #e03c31;
  border: 1px solid #e03c31;
}
.form__cancel-btn {
  background-color: #ec8a83 !important;
  color: white !important;
  min-width: 110px;
  padding: 2px;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    width: 40px;
  }
  100% {
    opacity: 1;
    width: fit-content;
  }
}
.scroll {
  overflow-y: auto;
  scroll-padding: 5px !important;
  overflow-x: hidden;
  transition: 0.9s;
}
.scroll::-webkit-scrollbar {
  width: 4px;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #000000;
}
.scroll-not-visible {
  overflow-y: scroll;
  overflow-x: hidden;
}
.scroll-not-visible::-webkit-scrollbar {
  width: 0px;
}
.modal__action-warning {
  background-color: #f7cecb !important;
  color: #222629;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  width: 40px;
  border-radius: 4px;
  margin: 20px 0px 20px 0px;
  text-align: center !important;
  transition: all 0.8s linear;
}
.modal__action-warning .modal__action-warning__text {
  display: none;
  transition: all 0.8s linear;
}
.modal__action-warning .icon {
  color: #e03c31;
  font-size: 17px;
  margin-right: 0px !important;
  align-self: flex-start;
}
.modal__action-warning div {
  display: flex !important;
  flex-direction: column !important;
  transition: all 0.8s linear;
}
.modal__action-warning div p {
  width: fit-content;
}
.modal__action-warning:hover {
  width: fit-content;
  transition: all 0.8s linear;
}
.modal__action-warning:hover .modal__action-warning__text {
  display: flex;
  transition: all 0.8s linear;
}
.modal__action-warning:hover .icon {
  margin-right: 10px !important;
  transition: all 0.8s linear;
}
.year-editor {
  display: inline-block;
  height: 36px !important;
}
.year-editor .ui.icon.input,
.year-editor .remove.link.icon {
  height: 36px !important;
}
.request-table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid #d8d9da;
}
.request-table ul {
  padding: 0;
  margin: 0;
}
.request-table.hide {
  width: 0 !important;
}
.request-table .request-table-header {
  text-align: center;
  background: #f3f4f5;
  border-bottom: 1px solid #d8d9da;
  height: 65px;
}
.request-table .request-table-header .request-table-title {
  overflow: hidden;
  font-size: 1.1em;
  text-transform: capitalize;
  font-weight: bold;
  padding: 0.45em 0;
  color: #222629;
  border-bottom: 1px solid #d8d9da;
}
.request-table .request-table-header .request-table-filter {
  display: flex;
  justify-content: flex-end;
}
.request-table .request-table-header .request-table-filter button {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: #474b4f;
  color: white;
  border: none;
  cursor: pointer;
}
.request-table .request-table-header .request-table-filter button i {
  font-size: 14px;
}
.request-table .request-table-list {
  background-color: #d8d9da;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  user-select: none;
}
.request-table .request-table-list .request-table-list-item {
  border-left: solid 4px;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  list-style: none;
  border-bottom: 1px solid #d8d9da;
  padding: 0.8rem;
  display: flex;
}
.request-table .request-table-list .request-table-list-item.draft {
  color: #b0b0b0 !important;
}
.request-table .request-table-list .request-table-list-item .ui.grid.request-component__detail-wrapper {
  margin: 0;
}
.request-table .request-table-list .request-table-list-item .ui.grid.request-component__detail-wrapper > .row {
  padding: 0.2rem;
  align-items: center;
  flex-wrap: nowrap;
}
.request-table .request-table-list .request-table-list-item .ui.grid.request-component__detail-wrapper > .row:first-of-type {
  font-size: 1.1em;
}
.request-table .request-table-list .request-table-list-item .ui.grid.request-component__detail-wrapper > .row svg {
  margin: 0 0.5rem 0 0.3rem;
}
.request-table .request-table-list .request-table-list-item .ui.grid.request-component__detail-wrapper > .row svg.fa-circle {
  margin-left: 0;
  font-size: 1.3rem;
}
.request-table .request-table-list .request-table-list-item .request-component__options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.request-table .request-table-list .request-table-list-item .request-component__options .request-component__options-detail {
  text-align: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
}
.request-table .request-table-list .request-table-list-item .request-component__options :hover {
  background: whitesmoke;
  box-shadow: 0px 0px 4px 2px #00000014;
  color: black;
}
.request-table .request-table-list .request-table-list-item .request-component__options .request-component__options-icon {
  align-self: flex-end;
}
.request-popup-filter {
  padding: 0px !important;
}
.filter-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 90vh;
}
.filter-form .filter-form-title {
  padding: 16px;
  font-weight: bold;
  border-bottom: 1px solid #d8d9da;
}
.filter-form .filter-form-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}
.filter-form .filter-form-content .filter-form__item {
  margin-bottom: 8px;
}
.filter-form .filter-form-content .filter-form__item .filter-form__item-input {
  width: 200px !important;
  min-height: 38px !important;
  border: 1px solid #474b4f !important;
  border-radius: 4px;
}
.filter-form .filter-form-content .filter-form-actions {
  display: flex;
  padding-top: 16px;
  justify-content: space-between;
}
.filter-form .filter-form-content .filter-form-actions .filter-form-actions__button button {
  outline: none;
  cursor: pointer;
  background-color: #474b4f;
  height: 32px;
  min-width: 50px;
  border-radius: 4px;
  border: none;
  color: white;
  transition: all 0.2s;
}
.filter-form .filter-form-content .filter-form-actions .filter-form-actions__button button:hover {
  background: black;
}
.filter-form .filter-form-content .filter-form-actions .filter-form-actions__button button:disabled {
  background-color: #a3a3a3;
}
.filter-form .filter-form-content .filter-form-actions .filter-form-actions__button.clear-filters-button button {
  background-color: white;
  color: #474b4f;
  font-weight: bolder;
}
.filter-form .filter-form-content .filter-form-actions .filter-form-actions__button.ok-button button {
  min-width: 45px;
}
.dragged-request {
  background: #dedfdf;
  color: #dedfdf;
}
