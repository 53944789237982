.request-list__filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  justify-content: flex-end;
  align-items: flex-start;
}
.request-list__filters-wrapper .request-list__filter-dropdown {
  margin-bottom: 10px;
  display: flex !important;
  align-items: center !important;
  min-width: 160px !important;
  width: max-content !important;
  min-height: 38px !important;
  padding: 5px 26px 5px 6px !important;
  margin-right: 12px;
}
.request-list__filters-wrapper .request-list__filter-dropdown .visible.menu.transition {
  width: max-content !important;
  border: 1px solid rgba(128, 128, 128, 0.541);
}
.request-list__filters-wrapper .request-list__filter-dropdown .visible.menu.transition .text {
  min-width: fit-content !important;
  width: max-content !important;
  font-size: 11px;
  overflow: hidden;
}
.request-list__filters-wrapper .request-list__filter-dropdown .visible.menu.transition::-webkit-scrollbar {
  width: 4px;
}
.request-list__filters-wrapper .request-list__filter-dropdown .visible.menu.transition::-webkit-scrollbar-track {
  border-radius: 10px;
}
.request-list__filters-wrapper .request-list__filter-dropdown .visible.menu.transition::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #000000;
}
.request-list__filters-wrapper .request-list__filter-dropdown .icon {
  padding: 12px 8px !important;
}
.request-list__filters-wrapper .request__title-input {
  min-width: 150px !important;
  max-width: 150px !important;
  height: 38px !important;
  margin-right: 12px !important;
  margin-bottom: 10px !important;
}
.request-list__filters-wrapper .request__title-input .input {
  min-height: 38px !important;
  margin-right: 10px !important;
}
.request-list__filters-wrapper .request-list__date-input {
  width: 130px;
  margin-right: 12px !important;
}
.request-list__filters-wrapper .request-list__date-input .input {
  height: 38px !important;
  min-height: 38px !important;
}
.request-list__filters-wrapper .request-list__date-big-input {
  width: 150px;
  margin-right: 12px !important;
}
.request-list__filters-wrapper .request-list__date-big-input .input {
  height: 38px !important;
  min-height: 38px !important;
}
.request-list__new-request-btn {
  min-width: 138px !important;
}
.request-event__filter-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-right: 7px;
}
.request-event__filter-buttons .button {
  height: 38px;
  width: 38px;
  margin-right: 16px;
}
.request-event__filter-buttons #add-event-type .icons {
  margin-left: 10px !important;
}
.request-event__filter-buttons #add-event-type button {
  padding: 10px 11.5px 10px 14px !important;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.request-event__filter-buttons #add-event-type button > i.icons i.plus.circle {
  color: #474b4f;
  padding: 0 !important;
  font-size: 10px !important;
}
.request-event__filter-buttons #add-event-type button > i.icons i.plus.circle::before {
  background-color: #fff !important;
  border-radius: 100%;
  border: 1px solid #fff;
}
.request-event__filter-buttons #add-event-type button > i.icons i.circular.icon {
  width: 0 !important;
  height: 0 !important;
}
.request-event__filter-buttons #add-event-type button > i.icons i:not(.corner) {
  margin: 0 !important;
}
.request-event__filter-buttons #add-event-type button > i > i.calendar.check.large.icon {
  font-size: 18px !important;
}
.request-event__filter-buttons i.corner.hide-filter {
  text-shadow: unset;
  color: white;
  opacity: 1;
  background-color: red;
  border-radius: 1em;
  font-size: 0.7rem;
  height: 12px;
  width: 12px;
  line-height: 11px;
  left: 44%;
  bottom: 10%;
}
.request-event__filter-buttons i:not(.corner) {
  color: white;
  opacity: 1;
  margin: 0 !important;
}
.request-event__filter-buttons .field {
  margin-top: 0px !important;
  display: flex;
  align-items: flex-start;
}
.request-list__table-view .request-event__filter-buttons {
  margin-right: 0px;
}
.request-list__table-view .ui.secondary.stackable.right.floated.custom-table-main-actions.menu .item.menu-item-download {
  align-self: flex-start !important;
  margin-top: 6px;
  margin-right: 10px;
  margin-left: -10px;
}
.request-list__table-view .ui.secondary.stackable.right.floated.custom-table-main-actions.menu .fitted.item {
  align-self: flex-start !important;
  margin-top: 6px;
  margin-right: 15px;
}
.request-list__table-view > div.full-width.table__flex-container > table > thead > tr > th:nth-child(1) {
  min-width: fit-content !important;
  width: 25px !important;
}
.request-list__table-view > div.full-width.table__flex-container > table > thead > tr > th:nth-child(6) {
  min-width: 150px !important;
}
.request-list__table-view > div.full-width.table__flex-container > table > thead > tr > th:nth-child(12) {
  min-width: 130px !important;
}
.request-list__table-view > div.full-width.table__flex-container > table > thead > tr > th:nth-child(16) {
  max-width: 30px !important;
  min-width: 30px !important;
  width: 30px !important;
}
@media all and (max-width: 1300px) {
  .request-event__filter-buttons .button {
    margin-right: 12px;
  }
}
.user-modal.merge-error-modal {
  height: auto;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}
.fields.request-event__field-all-container-height-flexible .request-list__filters-wrapper.event-list__new-filters-container .field.events-home-page__multiple-dropdown-filter {
  margin-right: 12px;
}
