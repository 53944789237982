.b-sch-event-wrap {
  overflow: visible;
}
.b-sch-event-wrap.b-sch-travel-event {
  z-index: 4;
}
.b-sch-event-wrap.b-sch-travel-event:hover {
  z-index: 6;
}
.b-sch-event-wrap.b-milestone-wrap {
  margin-left: 0;
  font-size: 1rem;
}
.b-sch-event-wrap.b-milestone-wrap .b-milestone.b-sch-event:not(.b-sch-event-withicon):before,
.b-sch-event-wrap.b-milestone-wrap .b-milestone.b-task-baseline:before {
  content: none;
}
.b-sch-event.travel-event {
  position: relative;
  overflow: visible;
}
.b-sch-event.travel-event i.travel-icon {
  border-radius: 4px;
  border: 1px solid #b0b0b0;
  position: absolute;
  background-color: #b0b0b0;
  color: #dedfdf;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: default;
  height: 106%;
  width: 50px;
}
.b-sch-event.travel-event i.travel-icon.b-fa-plane-departure {
  left: -60px;
}
.b-sch-event.travel-event i.travel-icon.b-fa-plane-arrival {
  right: -58px;
}
.b-sch-event.travel-event i.travel-icon:hover {
  background-color: #474b4f;
  color: white !important;
}
.row-height-slider {
  background-color: #ffffff;
  padding: 4px;
}
input[type='range'] {
  -webkit-appearance: none;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.poc-config-modal__ranges-container .fields .field .planit-eventform-dates-period .ui.icon.input {
  max-width: 100px;
  margin-right: 24px;
  min-width: 100px;
  width: 100px;
  height: 32px;
}
.poc-config-modal__ranges-container .fields .field .planit-eventform-dates-period .ui.icon.input .icon {
  display: none;
}
.poc-config-modal__ranges-container .fields .field .planit-eventform-dates-period .ui.icon.input input {
  text-align: center;
  padding: 8px;
  padding-right: 8px !important;
}
/* Edit css for slider  with recomendations here: https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */
.scheduler-tooltip {
  border-radius: 10px;
}
.scheduler-tooltip.b-panel:not(.b-outer) {
  border-radius: 4px;
}
.scheduler-tooltip.b-panel:not(.b-outer) .b-tooltip-content {
  min-height: 100%;
  padding: 0.5em;
}
.scheduler-tooltip.b-panel:not(.b-outer) .b-panel-content {
  border-radius: 4px;
  background-color: #474b4f;
  padding: 1em;
  color: white;
}
.b-sch-foreground-canvas .today {
  display: none;
}
.b-sch-foreground-canvas .weekend {
  background: #f2f3f3 !important;
}
.b-sch-foreground-canvas .holiday {
  background: #f0aea9 !important;
  border: none !important;
  z-index: 3 !important;
}
.b-grid-header .b-sch-timerange {
  background: transparent;
}
.b-grid-header .b-sch-timerange.today {
  cursor: auto;
  background: #3be0d84d;
}
.b-popup.b-text-content {
  max-width: unset !important;
}
.scheduler-tooltip__wrapper {
  min-width: 460px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.scheduler-tooltip__wrapper::-webkit-scrollbar {
  width: 4px;
}
.scheduler-tooltip__wrapper::-webkit-scrollbar-track {
  background: transparent;
}
.scheduler-tooltip__wrapper::-webkit-scrollbar-thumb {
  background: #888;
}
.scheduler-tooltip__wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scheduler-tooltip__wrapper i.b-fa {
  margin-right: 0.5rem;
}
.scheduler-tooltip__wrapper .b-fa-circle,
.scheduler-tooltip__wrapper .fa-circle {
  font-size: 1.5rem;
}
.scheduler-tooltip__wrapper .tooltip__section-item {
  display: flex;
  justify-content: initial;
}
.scheduler-tooltip__wrapper .tooltip__header {
  margin: 0 4px 8px 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}
.scheduler-tooltip__wrapper .tooltip__header .tooltip__header-event-type {
  display: flex;
  margin: 0 1em;
}
.scheduler-tooltip__wrapper .tooltip__header p {
  white-space: nowrap;
  margin-bottom: 0;
}
.scheduler-tooltip__wrapper .tooltip__header .tooltip__status {
  align-content: flex-end;
}
.scheduler-tooltip__wrapper .tooltip__section {
  margin: 0 4px 8px 4px;
  display: flex;
  justify-content: space-between;
}
.scheduler-tooltip__wrapper .tooltip__section.machines {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.scheduler-tooltip__wrapper .tooltip__section.machines span {
  padding: 0 0.5rem;
}
.scheduler-tooltip__wrapper i {
  margin-right: 8px;
}
