.instructor-event-suggestion {
  margin: 20px 20px;
}

.instructor-event-suggestion-items {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.planner-assistant-wrapper {
  margin: 10px 20px;
  border: 0.5px solid black;
  border-radius: 10px;
  background-color: rgba(126, 126, 126, 0.25);
  padding: 10px;
  width: 45%;
  height: 30vh;
  float: left;
}

.header-section .header-section-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;

  i,
  p {
    margin: 0 5px;
  }
}

.section {
  margin: 10px;
  .section-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    i,
    p {
      margin: 0 5px;
    }
  }

  .section__btns {
    .ui.button.submit-btn {
      margin-left: 15px;
      color: white;
      background-color: #474b4f;
    }
    button.ui.inverted.secondary.button {
      margin-right: 15px;
      box-shadow: none !important;
      border: 1px solid #474b4f;
    }
  }
}
