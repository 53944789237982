@import '../../../../../assets/less/variables.less';

.assigment-employees-not-profiles__wrapper {
  .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
    margin-top: -48px;
  }

  .table__flex-container {
    .table.custom-table > thead > tr > th:nth-child(1) {
      min-width: 70px !important;
    }

    .checkbox-style-faCheck {
      width: 18px;
      height: 18px;
      padding: 1px 2px 1px 2px;
      border: 1px solid #474b4f;
      border-radius: 2px;
    }
  }
}

.assigment-employees-profiles__filters {
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  z-index: 10;

  .custom-editor {
    margin-right: 12px;
    min-width: 150px !important;
    max-width: 150px !important;
    margin-bottom: 5px;
  }

  .custom-editor-search {
    margin-right: 12px;
    margin-bottom: 5px;
  }

  .custom-table-main-actions {
    button {
      min-height: 38px !important;
    }
  }
}
