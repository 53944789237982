/*Toast overrides*/
.Toastify__toast-container {
  width: 35vw !important;
}

.ui.message.birch-toast {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;

  .content {
    width: 70%;

    .header {
      font-size: 1.2rem;
    }

    .msg {
      font-size: 0.95rem;
    }
  }
}

/*Toast overrides*/
