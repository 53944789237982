.events-form__documentation-container {
  display: flex;
  flex-direction: column;

  .form__tab__icon-element {
    align-items: flex-start !important;

    p {
      margin-bottom: 0px;
      margin-right: 16px;
    }

    .button {
      border: 1px solid #474b4f;
      color: #474b4f !important;

      .icon {
        background-color: transparent !important;
      }
    }
  }

  .documentation__links-list {
    display: flex !important;
    flex-direction: column !important;
  }

  .documentation__links-container {
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 11px;

    .icon {
      margin-left: 24px;
    }
  }
}

// .events-form__documentation__supporting-docs {
//   display: none !important;
// }

.event-form-documentation-popup-buttons {
  text-align: end;

  > button.ui.basic.button.cancel-button {
    margin-right: 16px;
  }
}

.event-form-documentation-popup {
  min-width: 398px !important;
  min-height: 120px;
  text-align: start;
  padding: 16px !important;

  .event-form-documentation-popup-input {
    margin-bottom: 5px;
    text-align: left;

    > div.ui.input > input {
      width: 200px;
      height: 32px;
    }
  }

  .popup-url-input {
    display: flex;
    justify-content: space-between;
    margin-right: 4px !important;
    input {
      min-width: 319px;
    }
  }
}
