.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-404__container {
  height: 100%;
  width: 100%;
  z-index: 12;
}

.error404__message-wrapper {
  flex-direction: column;
}

.error404__img-container {
  width: 250px;
  height: 250px;
  border-radius: 100%;
  background-color: #eeeeee;
  margin: 0px 0px 32px 0px;
}

.error404__image {
  width: 145px;
}

.error404__title {
  font-size: 26px;
  color: #222629;
  margin: 0px 0px 24px 0px;
  font-weight: bold;
}

.error404__info-text {
  text-align: center;
  color: #222629;
  max-width: 268px;
  font-size: 18px;
  font-weight: 500;
}
