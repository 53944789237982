#multiple-machine-model-selector-first-row {
  display: flex;
  flex-wrap: wrap;
  .flex-column {
    margin: 0 20px;
    label {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .search-tna-template__btn {
    margin-top: 8px;
  }
  .hidden-label {
    visibility: hidden;
  }
}

.machine-selection-item {
  margin-top: 15px;
  //margin-bottom: -15px;
  display: flex;
  h4 {
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 0;
  }
  div {
    margin-left: 45px;
    margin-bottom: -25px;
  }
}
