@import './variables.less';

.employee-flex {
  max-height: 375px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.employee-card__events-wrapper {
  width: 49.5%;
  height: 75px;
  &:nth-child(-n + 4) {
    margin-right: 10px;
  }
}

.events-employee__date-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 33% 67%;
  height: 100%;
  .date-box {
    background-color: #00b2a9;
    color: white;
    font-size: 18px;
    margin-bottom: 8px;

    .date-box__separator {
      padding: 0px 3px 0px 3px;
    }

    .date-box__day {
      font-size: 15px;
    }

    .date-box__month {
      font-size: 10px;
      padding-left: 2px;
      padding-top: 2px;
      padding-right: 2px;
    }

    .date-box__year {
      font-size: 15px;
    }

    .date-box__date-wrapper {
      height: 50px;
      min-height: fit-content;
    }
  }

  .table__tooltip {
    min-width: calc(100% - 25px);
    text-overflow: ellipsis;
  }

  .table__short-text {
    overflow: hidden;
    max-height: 20px;
    white-space: nowrap;
    min-width: inherit;
  }
}

.event-box {
  background-color: #f9f9f9;
  min-width: 215px;
  height: 50px;
  min-height: fit-content;
  margin-bottom: 8px;

  span {
    padding-left: 8px;
  }
}

.event-box.flex-column {
  justify-content: center;
  align-items: flex-start;
  height: 68px;
}

.angle-disabled {
  color: gainsboro !important;
}

.button-arrow-background {
  background-color: transparent !important;
  color: #474b4f !important;
  padding-right: 0px !important;
  font-size: 14px !important;
}

.events-employee__pag {
  display: grid;
  width: 100%;
  grid-template-columns: 145px calc(100% - 145px);
  justify-content: flex-start;
  text-align: left;

  .events-employee__pag__btns {
    display: flex;
    justify-content: flex-end;
  }
}

@media all and (max-width: 1300px) {
  .events-employee__date-wrapper {
    display: grid;
    grid-template-columns: 86px 202px;
    transition: ease-in 1s;

    .date-box {
      font-size: 16px;
      width: 86px;
      margin-bottom: 8px;

      .date-box__separator {
        padding: 0px 3px 0px 3px;
      }

      .date-box__day {
        font-size: 15px;
      }

      .date-box__month {
        font-size: 10px;
        padding-left: 2px;
        padding-top: 2px;
        padding-right: 2px;
      }

      .date-box__year {
        font-size: 15px;
      }

      .date-box__date-wrapper.flex-center {
        flex-direction: column;
        align-items: center;
        width: fit-content;
      }
    }
  }
}

@media all and (min-width: 1300px) {
  .event-box {
    min-width: 260px;
  }
}
