.form__feedback-container {
  .form__feedback-item {
    display: flex;
    margin-bottom: 16px;
    padding-left: 42px;

    p {
      margin: 0px;
    }

    .feedback-tab__item-title {
      font-weight: 600;
    }

    .button {
      background-color: #eeeeee;
    }
  }

  .form__feedback-item.flex-column {
    .feedback-tab__item-title {
      margin-bottom: 17px;
    }
  }

  .form__feedback-item.flex-start-center {
    p {
      width: 130px;
      max-width: 152px;
    }

    .feedback-tab__item-title {
      margin-right: 30px;
    }
  }
}

.have-warnings-popup.feedback__confirm-modal {
  width: 361px;

  .feedback-modal__cancel-button {
    background-color: transparent;
    border: none;
    color: white;
    font-weight: 500;

    &:hover {
      background-color: whitesmoke;
      color: black;
      transition: ease-in 0.4s;
    }
  }

  .feedback-modal__accept-button {
    margin-left: 10px;
  }
}

.form__feedback-table {
  display: grid;
  grid-template-columns: repeat(5, 1fr) 40px 40px;
  margin-bottom: 26.5px;
  width: 95%;

  p {
    margin: 0px;
    padding: 9px 9px 9px 12px;
  }

  .feedback-table__name-column {
    background-color: #eeeeee;
    font-weight: bold;
  }

  .feedback-table__cell {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  .feedback-table__comments-icon {
    color: #dedfdf;
    pointer-events: none;
  }

  .feedback__comments-icon__answered {
    color: #474b4f;
  }
}

.feedback-generation__answered-btn.button.disabled {
  background-color: #ccf0ee !important;
  opacity: 1 !important;
  color: #00b2a9;
}
