#practical-templates-list-wrapper .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
  display: flex;
  height: 38px;
  margin-top: -38px !important;
}
#practical-templates-list-wrapper .ui.secondary.stackable.right.floated.custom-table-main-actions.menu div.fitted.item {
  height: 37px !important;
}
#practical-templates-list-wrapper .ui.secondary.stackable.right.floated.custom-table-main-actions.menu .button.table__custom-white-outline-button {
  min-width: 155px;
  min-height: 37px;
  border-radius: 4px;
  background-color: white !important;
  color: #474b4f !important;
  justify-self: flex-end !important;
  border: 1px solid #474b4f;
  opacity: 1;
  padding: 0px 2px;
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 500;
  margin-right: 5px;
}
#practical-templates-list-wrapper .tna__inputs-wrapper {
  width: 74% !important;
}
#practical-templates-list-wrapper .tna__inputs-wrapper .adjust-filter-height {
  min-height: 38px !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
#practical-templates-list-wrapper .tna__inputs-wrapper .practical-boolean-editor {
  margin-right: 12px;
  margin-top: 10px;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  min-height: 38px;
}
#practical-templates-list-wrapper .tna__inputs-wrapper .question-bank__multiple-dropdown.question-bank__multiple-dropdown.question-bank__multiple-dropdown {
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
#practical-templates-list-wrapper .tna__inputs-wrapper #practical-template-list-event-types-filter {
  max-width: 460px !important;
}
#practical-templates-list-wrapper .tna__inputs-wrapper .ensure-200px-width {
  min-width: 200px !important;
  width: 200px !important;
}
#practical-templates-list-wrapper .tna__inputs-wrapper .ensure-175px-width {
  min-width: 175px !important;
  width: 175px !important;
}
#practical-templates-list-wrapper .tna__inputs-wrapper .ensure-200px-min-width {
  min-width: 200px !important;
}
