.scheduler-header__period-input {
  margin-right: 8px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-start;
  margin-bottom: 8px;

  div {
    display: inline-block;

    .field {
      height: 38px;
    }

    div.ui.icon.input {
      height: 38px !important;
      max-height: 38px !important;

      i {
        width: 36px;
        color: #b0b0b0 !important;
        font-size: medium;
      }
    }
  }

  div.field {
    &.from-date input {
      width: 120px;
      padding-right: 16px !important;
      border: 1px solid #474b4f !important;
      border-radius: 4px 0px 0px 4px !important;
      margin-right: 0px;
    }

    &.to-date input {
      width: 120px;
      padding-right: 16px !important;
      border: 1px solid #474b4f !important;
      margin-left: -1px;
      border-radius: 0px 4px 4px 0px !important;
    }

    input {
      width: 100px;
      height: 38px;
    }
  }
}

.sch-me-filter {
  align-self: flex-start !important;
  width: 75px;
  height: 38px;
  border-radius: 4px;
  color: #474b4f;
  background-color: #fff;
  outline: none;
  border: 1px solid #474b4f;
  cursor: pointer;
  margin-right: 6px;

  i.icons {
    font-size: 14px;
    margin-right: 4px;
  }

  &.active {
    background-color: #474b4f;
    color: #fff;

    i.icons {
      color: #00b2a9;
    }
  }
}
.sch-my-pillar {
  align-self: flex-start !important;
  width: 75px;
  height: 38px;
  border-radius: 4px;
  color: #474b4f;
  background-color: #fff;
  outline: none;
  border: 1px solid #474b4f;
  cursor: pointer;
  margin-right: 6px;

  i.icons {
    font-size: 14px;
    margin-right: 4px;
  }

  &.active {
    border-color: #00b2a9;
    background-color: #00b2a9;
    color: #fff;
  }
}
