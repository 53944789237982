.wizard__step.wizard__step-three {
  font-size: 12px;
  min-width: 100%;
}
.new-ssa-form-wizard_step3__btn-dropdown {
  margin-left: 62px;
  margin-bottom: -80px;
}
.new-ssa-form-wizard_step3__btn-dropdown #new-ssa-form-wizard_step3-row1_assessor-select {
  width: 263px;
  max-width: 100%;
  max-height: 38px !important;
  min-height: 38px !important;
  margin-left: 200px;
}
.new-ssa-form-wizard_step3-matrix-container {
  overflow: visible !important;
  padding-top: 98px !important;
  overflow-x: scroll !important;
  flex-direction: column !important;
  max-width: 95%;
  margin-bottom: 40.5px !important;
  margin-left: 62px;
}
.new-ssa-form-wizard_step3-matrix-container.tooltip-visible {
  overflow: visible !important;
}
#new-ssa-form-wizard_step3-matrix-table {
  width: fit-content;
}
.new-ssa-form-wizard_step3-table-cell {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  min-width: 150px !important;
  max-width: 150px !important;
}
.new-ssa-form-wizard_step3-table {
  max-width: min-content;
  margin: 0px 0px 20.5px 62px !important;
}
.new-ssa-form-wizard_step3-table .ui.checkbox label:hover::before {
  border-color: #d4d4d5 !important;
}
.new-ssa-form-wizard_step3-table .new-ssa-form-wizard_step3-table-header {
  color: #474b4f;
  font-weight: 900;
}
.new-ssa-form-wizard_step3-table .new-ssa-form-wizard_step3-table-header .checkbox {
  margin-left: 10px;
}
.new-ssa-form-wizard_step3-table :not(.new-ssa-form-wizard_step3-table-header) .new-ssa-form-wizard_step3-table-cell .checkbox {
  left: 40%;
}
.new-ssa-form-wizard_step3-table .new-ssa-form-wizard_step3-table-row {
  padding: 12.5px !important;
  flex-wrap: nowrap !important;
  border-bottom: 1px solid #dedfdf;
  max-width: 98%;
}
.new-ssa-form-wizard_step3-table .new-ssa-form-wizard_step3-table-row:after {
  content: '';
  padding: 12.5px !important;
  width: 119% !important;
  margin-bottom: -14px;
  min-width: 90px;
  margin-left: -70px;
}
.new-ssa-form-wizard_step3-table__assessor {
  margin-left: 0px !important;
}
.new-ssa-form-wizard_step3-table__assessor .new-ssa-form-wizard_step3-table-remove-cell {
  justify-content: flex-end !important;
  margin-left: 10px !important;
}
.new-ssa-form-wizard_step3-matrix-container::-webkit-scrollbar {
  height: 6px;
}
.new-ssa-form-wizard_step3-matrix-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.new-ssa-form-wizard_step3-matrix-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #8f9294;
}
.new-ssa-form-wizard_step3-row_btn {
  width: 160px;
  height: 38px;
  color: #474b4f;
  background-color: #eeeeee !important;
  box-shadow: none !important;
  border-radius: 4px;
  font-weight: 600 !important;
  z-index: 1;
}
#new-ssa-form-wizard_step3-assessors-table:after {
  content: '';
  padding-top: 12.5px !important;
  flex-wrap: nowrap !important;
  border-bottom: 1px solid white;
  width: 80%;
}
#new-ssa-form-wizard_step3-assessors-table .new-ssa-form-wizard_step3-table-remove-cell {
  justify-content: flex-end !important;
  margin-left: 10px !important;
  padding-left: 20px !important;
}
.add-assessor__btn {
  margin-left: 62px !important;
  margin-bottom: 24px !important;
}
.clear-all__btn {
  margin-left: 16px !important;
  width: 76px;
  padding: 5px !important;
}
@media all and (min-width: 1280px) {
  .new-ssa-form-wizard_step3__btn-dropdown #new-ssa-form-wizard_step3-row1_assessor-select {
    margin-left: 370px;
  }
}
