@import '../../../../assets/less/variables.less';
@import '../shared-scheduler-components/request-drag.style.less';

.employee-page__container {
  margin-top: 30px !important;
}

.avatar {
  border-radius: 500rem;
  background-position: center;
  background-size: cover;
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
}

.rows-user-card-box {
  margin-top: 3px;
  margin-bottom: 3px;
  min-height: 2em;
}

.min-height-box {
  min-height: 28em !important;
}

.bold-text {
  font-weight: bold;
  text-align: left;
}

.dark-grey-box {
  background-color: #b5b5b5;
}

.planit-main-container {
  background: white;
}

//Profile listing component styles

//Tabs styles
.employee__tab {
  height: 100%;
  width: 100%;

  .ui.pointing.secondary.menu {
    border-bottom: 0px solid @default-grey;

    .item {
      margin-bottom: 0.1px;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
      background-color: #f9f9f9;
      width: 128px;
      text-align: center !important;
      justify-content: center;

      &:hover:not(.active) {
        background-color: #eeeeee !important;
      }
    }

    .active.item,
    .active.item:hover {
      color: #ffffff;
      background-color: #474b4f;
      border-bottom: none;
    }
  }

  .ui.segment.active.tab {
    height: 95%;
    border: none;
    background-color: transparent;
    border-style: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.main-tab.sub-tab {
  .ui.pointing.secondary.menu {
    .active.item,
    .active.item:hover {
      color: #b0b0b0;
    }

    .item:hover {
      color: #b0b0b0;

      border-bottom-color: #b0b0b0;
    }
  }
}

//Tabs styles

.profile-card__profiles-container {
  padding-left: 0px !important;

  .ui.pointing.secondary.menu {
    margin-bottom: 0px !important;
  }

  .ui.segment.active.tab {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }

  .profile-machinery__list {
    padding-top: 50px;
    border: 1px solid #dedfdf;
    width: 100%;
    min-height: 50vh;

    .profile-machinery__machinery-list {
      margin-right: 10px;
      width: 470px;

      .inverted-save-btn {
        justify-self: flex-start;
      }
    }

    .profile-machinery__profile-list {
      margin-right: 20px;

      .button {
        margin: 0px 0px 4px;
        width: 128px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }

    .search {
      min-width: 465px !important;
      width: 465px;
      margin-bottom: 34px;
    }

    .phases-dropdown {
      min-width: 165px !important;
      width: 165px;
      margin-bottom: 34px;
    }

    .template-role-dropdown {
      min-width: 365px !important;
      width: 365px;
      margin-bottom: 34px;
      margin-right: 30px;
    }

    .button__inactive {
      background-color: #eeeeee !important;
      color: #474b4f !important;
    }

    .button__disabled {
      background-color: #b0b0b0 !important;
      color: #ffffff !important;
    }

    .profile-machinery__scores-title {
      width: 100%;
      display: grid;
      grid-template-columns: 40% 10% 40% 10%;
      column-gap: 10px;
      text-align: start;
      font-weight: 900;
    }

    .employee-card__btns-wrapper {
      display: flex;
      justify-content: flex-start;
    }

    .employee-card__overall-wrapper {
      display: flex;
      justify-content: flex-start;
      text-align: start;
      margin-bottom: 15px;
    }

    .employee-card__check-btn {
      margin-left: 24px;
    }

    .profile-machinery__scores {
      width: 100%;
      display: grid;
      grid-template-columns: 40% 10% 40% 10%;
      column-gap: 10px;
      text-align: start;
      margin-bottom: 40px;
      margin-right: 15px;
    }

    .flex-start {
      margin-bottom: 30px;
    }
  }

  .employee__-scores-radar {
    margin-bottom: 20px;
  }
}

.employee__-scores-radar {
  flex-wrap: wrap !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.employee-card__btn {
  border: 1px solid #00b2a9 !important;
  background-color: white !important;
  color: #00b2a9 !important;
}

.profile-machinery__list {
  padding-top: 16px;
}

.responsive-container__radar-chart {
  height: 300px !important;
  width: 700px !important;
  display: flex !important;
  justify-content: center !important;

  > tspan {
    width: 120px !important;
    white-space: nowrap;
  }
}

.recharts-surface {
  overflow: visible !important;

  tspan {
    font: normal 12px;
  }
}

.recharts-responsive-container.responsive-container__radar-chart {
  width: 50% !important;
}
.iws-table-container {
  max-width: 50%;
}

@media all and (max-width: 1280px) {
  .avatar {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
  }
}
