.wizard__step2__machine-related {
  .event-details-tab__mr-table {
    margin-bottom: 0px !important;
    overflow: visible !important;
    padding-bottom: 20px !important;
    width: 100% !important;
  }

  .wizard__step2__machine-related__content {
    .request-details-tab__mr__pattern-row-btns {
      width: 100%;

      .events-form__btn__positive {
        background-color: #00b2a9;
        text-align: center;
        width: 77px;
        color: white;
        font-weight: 500;

        &:hover {
          background-color: #00b2a9;
          color: white;
        }
      }

      .events-form__btn__negative {
        color: #b0b0b0;
        text-align: center;
        width: 77px;
        background-color: white;
        font-weight: 500;

        &:hover {
          color: #b0b0b0;
          background-color: white;
        }
      }
    }

    .wizard__mr-table {
      width: 100%;
      padding: 0px 5%;
    }
  }
}

.machine-related__title {
  text-align: center;
  margin-bottom: 24px;
}

.wizard__no-machine-related {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 20px;

  .flex-center {
    width: 50%;
  }

  .no-machine-related-wrapper__element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
    width: 300px !important;
    margin-right: 32px;

    label {
      width: 110px;
      margin-bottom: 8px;
    }

    .ui.search.selection.dropdown.custom-editor {
      min-width: 180px;
      width: 180px;
    }
  }
}

@media all and (min-width: 1340px) {
  .wizard__no-machine-related {
    justify-content: flex-start;
  }
}

@media all and (max-width: 1200px) {
  .wizard__no-machine-related {
    .flex-center {
      width: 100%;
    }
  }
}
