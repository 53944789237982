.machine-related__pattern-list-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: fit-content !important;
  min-height: fit-content !important;
  overflow: visible !important;
  overflow-x: hidden;

  .selection.dropdown.custom-editor {
    max-width: 118px !important;
    width: 118px !important;
    min-width: 118px !important;
    height: 44px !important;
    min-height: 44px !important;
    max-height: 44px !important;
    padding-bottom: 12px !important;
  }

  .ui.inline.multiple.search.selection.dropdown.planit-user-dropdown {
    max-width: 120px !important;
    width: 120px !important;
    min-width: 120px !important;
    height: 100% !important;
    min-height: 100% !important;
    max-height: fit-content !important;
    padding-top: 2px !important;
    margin-top: 0px !important;
    padding-bottom: 12px !important;
  }

  .request-wizard__machine-list__oem-dropdown {
    height: 44px;
    max-height: 44px;
    min-width: 118px !important;
  }

  .red {
    align-self: center;
    margin-top: 13px;
  }
}

.event__machine-related__pattern-list-wrapper {
  display: flex;
  justify-content: center !important;
  width: 100%;
  height: fit-content !important;
  min-height: fit-content !important;
  overflow: visible !important;
}

.machine-related__pattern-row {
  display: flex !important;
  justify-content: center !important;
  justify-self: center !important;

  > div.column {
    display: flex !important;
    justify-content: center !important;
  }
}
