.planit-main-container #main-container-grid #content-container h3:first-child {
  text-transform: none;
}

.template-wizard-title {
  // margin-bottom: 53px;
  margin-top: 0 !important;
}

.template-wizard__step-title {
  font-size: 14px !important;
}

.wizard__steps-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0px 15px;

  p {
    font-size: 12px;
  }

  .wizard__step-margin-left {
    margin-left: 62px !important;
  }
}

.tna-templates-wizard__btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  margin-top: 27px;
  margin-bottom: 34px;

  .button.button.button.button.button {
    &:first-child {
      margin-right: 16px;
      box-shadow: none !important;
      border: 1px solid #474b4f;
    }
  }
}

.tna-confirmation-modal {
  background-color: #474b4f !important;

  .tna-confirmation-modal__content {
    background-color: #474b4f !important;
    color: white;
  }

  .actions {
    .tna-confirmation-modal__btn {
      text-align: center;
      font-size: 12px !important;
      padding: 8px;
      height: 32px !important;
      min-width: 64px !important;
      width: fit-content !important;
      color: #474b4f;

      &.cancel {
        background-color: transparent;
        color: white;
      }
    }
  }
}
