.ssa-form-language-selector {
  position: relative;
  float: right;
  margin: 10px 0px;
  width: 10%;
  transition: transform 1s;
}

.form__all-wrapper {
  //Main
  &.tna-form {
    margin-top: 0;
    .form__all-wrapper__main-content {
      height: 80vh;
    }
  }
  .tna-edit-form__header__evaluation-criteria {
    margin-bottom: 20px;
    padding: 0px 24px;
    font-weight: 500;
    border: 1px solid #474b4f;
    white-space: pre-wrap;

    .evaluation-criteria__title {
      margin-bottom: 12px;
      margin-top: 16px;
    }
  }

  .form__all-wrapper__main-content {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;

    .ssa-form__returned__assessor-comments {
      border: 1px solid #dedfdf;
      width: 100%;
      padding: 16px;
      margin-bottom: 16px;
    }

    .tna-form-main-tabs {
      .ui.pointing.secondary.menu {
        border-bottom: 16px solid #ccf0ee;
        margin-bottom: 0px;

        .item {
          text-transform: uppercase;
          font-weight: 500;
          padding-left: 12px;
          padding-right: 12px;
          font-size: 14px;
          border: 1px solid #ccf0ee;
          color: #b0b0b0;
        }

        .active.item,
        .active.item:hover {
          background-color: #ccf0ee;
          color: #474b4f;
        }
      }

      .copy-answer-editor {
        margin-top: 10px;
        margin-right: 10px;
        float: right;
      }

      .machine-related__score-by-skills-table__container {
        border: 1px solid #ccf0ee;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        padding: 20px 20px 32px 40px;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          margin-bottom: 12px;
          font-size: 14px;
        }

        .machine-related__score-by-skills-table {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
          grid-template-rows: auto;

          &.line-manager-view {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: auto;
          }

          input,
          input:focus {
            border-radius: 0;
          }

          p {
            margin-bottom: 0px;
            font-weight: 600;
            border: 1px solid #dedfdf;
            padding: 10px;
          }
        }
      }

      .machine-related-section {
        .tna-form-theoretical-btn {
          color: #00b2a9;
          border: 1px solid #00b2a9;
          background-color: white;
          margin-top: 20px;
          margin-right: 16px;
          &:hover {
            background-color: #dedfdf;
            color: #00928a;
          }
        }
        h2 {
          background-color: #00b2a933;
          padding: 8px 12px;
          width: 400px;
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
          margin-top: 37px;
        }
      }

      .tna-form-btn {
        font-size: large;
        color: #00b2a9;
        border: 1px solid #00b2a9;
        background-color: white;
        margin-top: 20px;
        margin-right: 16px;
        &:hover {
          background-color: #dedfdf;
          color: #00928a;
        }
      }

      .ssa-form-btn {
        font-size: large;
        color: #00b2a9;
        border: 1px solid #00b2a9;
        background-color: white;
        margin-top: 20px;
        margin-right: 16px;
        &:hover {
          background-color: #dedfdf;
          color: #00928a;
        }
      }

      .iws-form-btn {
        font-size: large;
        color: #00b2a9;
        border: 1px solid #00b2a9;
        background-color: white;
        margin-top: 20px;
        margin-right: 16px;
        &:hover {
          background-color: #dedfdf;
          color: #00928a;
        }
      }
    }

    .tna-form__main-tab__skill-section {
      font-size: 14px;

      h3 {
        font-weight: bold;
        text-transform: uppercase !important;
        border-bottom: 3px solid #eaaa00;
        width: fit-content;
        padding-bottom: 0.5px;
        margin-top: 0.5px;
        font-size: 14px;
      }

      .assesor__result-score-container {
        margin-top: -22px;
        margin-right: 10px;
        width: 140px;
        align-items: center !important;
        text-align: center;

        .employee-self-text {
          margin-bottom: 6px;
          min-width: fit-content;
          width: 140px;
        }

        .main-tab__skill-section__score {
          margin-right: 0px !important;
        }

        .scores-list__assesor__result-score {
          margin: 0px !important;
        }
      }

      .skill-section__question {
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .skill-section__question:not(:first-of-type) {
        margin-top: 15px;
        font-weight: bold;
      }

      .skill-section__scores-list {
        display: flex;
        justify-content: center;

        .scores-list__assesor__result-score {
          justify-self: flex-end !important;
          margin-left: 5%;

          .main-tab__skill-section__score {
            margin-right: 10px;
            border-top: 4px solid #474b4f;
          }
        }
      }

      .skill-section__poc-admin__scores-list {
        display: flex;
        justify-content: flex-end;
      }

      .main-tab__skill-section__score-items {
        display: flex;
        justify-content: center;
        flex-direction: row;

        .main-tab__skill-section__score {
          width: fit-content;
          margin-bottom: 21px;
          margin-right: 70px;
          min-height: fit-content;
          border: 1px solid #dedfdf;
          font-size: 11px;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;

          // &.active {
          //   border: 2px solid black;
          //   background-color: #00b2a9;
          //   font-weight: bold;
          //   color: white;
          // }

          &.active {
            border-top: 2px solid #eaaa00;
            border: 1px solid #eaaa00;

            .skill-section__score__number {
              background-color: #eaaa00;
              color: white;
            }
          }

          p {
            margin: 0px;
          }

          p:nth-child(2) {
            padding: 3px 5px;
          }

          .skill-section__score__number {
            border-bottom: 1px solid #dedfdf;
            height: 23.5px;
            padding-top: 5px;
          }
        }
      }

      .skill-section__comments {
        width: 100%;
        min-height: 60px !important;
        height: 60px !important;
      }

      .skill-section__assesor-comments-area {
        display: flex;
        justify-content: space-between;

        .flex-column {
          width: 48.7%;
        }

        p {
          margin-bottom: 8px;
        }

        .skill-section__comments {
          min-width: 100%;
          min-height: 60px !important;
          height: 60px !important;
        }
      }

      .skill-section__poc-admin__comments-area {
        margin-right: -24px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .flex-column {
          width: calc(33% - 24px);
          margin-right: 24px !important;
          margin-top: 10px;
        }

        .skill-section__comments {
          // min-width: 450px;
          // width: 941px;
          min-height: 60px !important;
          height: 60px !important;
        }
      }
    }
  }
}

.tna-footer-action-buttons {
  margin-top: 5px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  .status-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .save-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 15px;
  }
}
