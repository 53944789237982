.wizard__steps-container {
  padding: 0px 15px;
  .wizard__step-two {
    .step-two__machinery-wrapper {
      margin-bottom: 20px;
      .fields {
        width: 100%;
        display: flex;

        .field.machinery__label-input-field {
          width: 175px;
          margin-right: 16px;

          label {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }

      .add-row-btn {
        width: 110px;
        height: 32px !important;
        align-self: center;
        margin-left: 16px;
      }
    }

    .custom-editor {
      min-width: 175px !important;
      max-width: 175px !important;
      margin-bottom: 24.5px;
      margin-top: 8px;
    }

    .editor-32px {
      .ui.search.selection.dropdown {
        min-height: 32px;
        height: unset !important;
        padding: 10px;

        input {
          padding: 10px;
        }

        i.dropdown.icon {
          padding: 9px !important;
        }

        &.loading.loading.loading.loading {
          i.dropdown.icon {
            padding: 17px !important;
          }
        }

        div.text {
          font-size: 12px !important;
          width: calc(100% - 20px) !important;
          top: 1px;
        }
      }
    }

    .assigned-profile-row-machine__info {
      display: flex;
      margin-left: 62px;

      justify-content: flex-start;
      height: fit-content;

      &:last-child {
        margin-bottom: 24px;

        .assigned-profile-row-machine__items {
          border-bottom: 1px solid #b0b0b0;
        }
      }

      .assigned-profile-row-machine__items {
        display: flex;
        border-top: 1px solid #b0b0b0;
        padding: 14px 0px;

        .machine-cell {
          width: 175px;
          margin: 0px 16px 0px 0px;
          min-width: 175px;
          max-width: 175px;
          font-size: 12px;
          font-weight: normal;
          align-self: center;

          &:last-child {
            margin-right: 0px !important;
          }
        }

        .machine-cell-bold {
          width: 175px;
          margin: 0px 16px 0px 0px;
          min-width: 175px;
          max-width: 175px;
          font-size: 12px;
          font-weight: bold;
          align-self: center;

          &:last-child {
            margin-right: 0px !important;
          }
        }
      }

      i {
        cursor: pointer;
        margin-left: 32px;
        width: fit-content;
      }
    }
  }
}

.tna-w__button-export-excel__wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 4px;

  .custom-table-upload-btn.black-btn {
    margin-left: 10px;
    margin-top: 3px;
  }
}

.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .tna-w__button-export-excel__wrapper .add-row-btn {
  align-self: flex-end;
}
