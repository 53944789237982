.form-main-tabs .ui.segment {
  position: inherit;
  border: none;
  box-shadow: none;
  padding: 1rem 0;
}
.form-main-tabs .ui.pointing.secondary.menu {
  border-bottom: 16px solid #ccf0ee;
  margin-bottom: 0px;
}
.form-main-tabs .ui.pointing.secondary.menu .item {
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  border: 1px solid #ccf0ee;
  color: #b0b0b0;
}
.form-main-tabs .ui.pointing.secondary.menu .active.item,
.form-main-tabs .ui.pointing.secondary.menu .active.item:hover {
  background-color: #ccf0ee;
  color: #474b4f;
}
.form__all-wrapper {
  margin-bottom: 16px;
  padding: 0px 15px !important;
}
.form__all-wrapper .ui.divider {
  border-top-color: black !important;
  border-bottom: none;
}
.form__all-wrapper .form__all-wrapper__main-content {
  height: 70vh;
}
.form__all-wrapper .form__all-wrapper__main-content .ui.button {
  min-width: 80px;
  min-height: 32px;
  margin-right: 16px;
}
.form__all-wrapper .form__all-wrapper__main-content .ui.button.active {
  color: white;
  background-color: #00b2a9;
}
.form__all-wrapper .form__all-wrapper__main-content .ui.button.active:hover {
  background-color: #018881;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-content {
  width: 100%;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-content .form-header {
  font-family: inherit;
  border: 1px solid black;
  padding: 16px;
  display: flex;
  white-space: break-spaces;
  flex: 1 1 auto;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question {
  width: 100%;
  margin: 0;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__label {
  padding: 10px;
  width: 100%;
  border-radius: 0;
  background-color: #f9f9f9;
  margin: 8px 0 16px 0;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container {
  width: 100%;
  margin: 0;
  padding: 0 16px;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container img {
  margin-bottom: 16px;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container iframe {
  margin-bottom: 10px;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .form-main-question__available-checkbox {
  margin-bottom: 12px;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .form-main-question__available-checkbox.ui.checkbox label {
  padding-left: 32px;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .form-main-question__available-checkbox.ui.checkbox label::before {
  border-color: #707070;
  border-radius: 0;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .form-main-question__media-container {
  text-align: center;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .rating-answer__container {
  display: flex;
  margin: 0 !important;
  flex-direction: column;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .rating-answer__container .answer-checkbox {
  padding: 8px 0;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .rating-answer__container .ui.checkbox label {
  padding-left: 32px;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .rating-answer__container .ui.checkbox label::before {
  border-color: #707070;
}
.form__all-wrapper .form__all-wrapper__main-content .form-main-question .form-main-question__container .rating-answer__container .ui.radio.checkbox input:checked ~ label:after {
  transition: border 0.1s ease, opacity 0.5s ease, transform 0.1s ease, box-shadow 0.1s ease;
  transform: scale(0.7);
  background-color: #018881;
}
.form__all-wrapper .form__all-wrapper__footer .ui.button {
  background-color: #474b4f;
  color: white;
  min-width: 150px;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}
.form__all-wrapper .form__all-wrapper__footer .spacer {
  flex: 1 1 auto;
}
.theoretical-summary__table {
  display: grid;
  grid-template-columns: 70px 100px 250px repeat(5, 1fr);
  width: 100%;
}
.theoretical-summary__table .theoretical-summary__table-title {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  width: 100%;
  padding: 8px;
  margin-right: 0px;
  margin-bottom: 0;
}
.theoretical-summary__table .border-bottom-gray {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedfdf;
  padding: 1em;
}
.theoretical-summary__table span {
  display: flex;
  align-items: center;
}
.theoretical-summary__table .text__transparent {
  color: transparent;
}
.theoretical-summary__table .icons {
  display: flex;
  align-items: center;
}
