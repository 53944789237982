.feedback-form__header__data-language {
  .feedback-form__header__general-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;

    .feedback-form__header__general-info__element {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-right: 4%;
      margin-top: 10px;
      min-width: 150px;

      h5 {
        margin-bottom: 8px;
        font-weight: 500;
      }

      p {
        font-weight: 600;
      }
    }
  }

  .planit-user-dropdown-custom {
    min-width: 160px;
  }
}

.feedback-form__main-wrapper {
  height: 71vh;

  .feedback-form__question {
    background-color: #f9f9f9;
    padding: 8px 8px 8px 16px;
    margin-bottom: 24px;
    margin-top: 24px;
    width: 98%;
  }

  .feedback-form_stars-container {
    display: flex;
    align-items: center;
    margin-left: 16px !important;

    .ui.star.massive.rating.feedback-form_stars {
      .icon {
        color: white !important;
        text-shadow: 0px -1px 0px #00b2a9, -1px 0px 0px #00b2a9, 0px 1px 0px #00b2a9, 1px 0px 0px #00b2a9 !important;

        &:focus {
          outline: none;
        }
      }

      .active.icon {
        color: #00b2a9 !important;
        text-shadow: none !important;

        &:focus {
          outline: none;
        }
      }
    }

    .ui.star.massive.rating.feedback-form_star-na {
      margin-left: 39.92px;

      .icon {
        color: white !important;
        text-shadow: 0px -1px 0px #b0b0b0, -1px 0px 0px #b0b0b0, 0px 1px 0px #b0b0b0, 1px 0px 0px #b0b0b0 !important;

        &:focus {
          outline: none;
        }
      }

      .icon.active {
        color: #b0b0b0 !important;
        text-shadow: 0px -1px 0px #b0b0b0, -1px 0px 0px #b0b0b0, 0px 1px 0px #b0b0b0, 1px 0px 0px #b0b0b0 !important;

        &:focus {
          outline: none;
        }
      }
    }

    p {
      color: #b0b0b0;
      font-weight: 600;
      margin-left: 8px;
    }
  }

  .feedback-form__comment {
    resize: none !important;
    width: 96% !important;
    margin-left: 16px !important;
  }

  .feedback-form__question__buttons {
    display: flex;
    align-items: center;
    padding-left: 16px;

    .button {
      background-color: #eeeeee;
      width: 60px;
    }

    .button:first-of-type {
      margin-right: 24px;
    }
  }
}

.feedback-form__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.feedback-form__header__evaluation-criteria {
  margin-bottom: 20px;
  padding: 0px 24px;
  font-weight: 500;
  border: 1px solid #474b4f;
  white-space: pre-wrap;
}
