// b-drag-proxy b-dragging b-sch-drag-request b-drag-invalid
.b-drag-proxy {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
  background: white !important;
  border-radius: 4px;
  border-left-width: 10px;
  border-style: solid;
}

.b-drag-invalid {
  opacity: 0.6;
  color: @inverted-color;
  font-weight: bolder;
  border-right-style: none;
  border-top-style: none;
  border-bottom-style: none;
  margin: auto;
}

.b-drag-proxy:not(.b-drag-invalid) {
  opacity: 1;
  word-break: break-all !important;
  color: @inverted-color;
  font-weight: bolder;
  border-right-style: none;
  border-top-style: none;
  border-bottom-style: none;
  margin: auto;
}

.drag-element-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 100%;
  height: 100%;
}
