.practical-form-test .form__all-wrapper {
  padding: 0px 15px !important;
}
.practical-form-test .form__all-wrapper .form__main-wrapper {
  min-height: 70vh;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .form-header {
  font-family: inherit;
  border: 1px solid black;
  padding: 16px;
  display: flex;
  white-space: break-spaces;
  flex: 1 1 auto;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .form__accordion-title {
  padding: 16px;
  margin-bottom: 16px;
  color: #474b4f;
  background-color: #e5f7f6;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0 !important;
  margin-right: 80px;
  height: 32px;
  width: auto !important;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item .ui.input.minutes-input {
  max-height: 32px;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item .ui.input.minutes-input input {
  width: 50px;
  padding-right: 4px;
  outline: none;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item .ui.input.minutes-input input:focus {
  border: 1px solid #22242626;
  border-right: none;
  border-color: #22242626;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item .ui.input.minutes-input div.ui.label.label {
  max-height: 32px;
  min-width: 50px;
  padding-left: 4px;
  background-color: transparent;
  border-top: 1px solid #22242626;
  border-bottom: 1px solid #22242626;
  border-right: 1px solid #22242626;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item .ui.inline.dropdown {
  min-width: 150px !important;
  max-height: 32px !important;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item .ui.inline.dropdown .visible.menu.transition {
  margin-top: 0;
  border-radius: 0 0 4px 4px;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item .ui.label {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: 100px;
  height: 100%;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__info .form__header__row.practical-question__info-item p {
  white-space: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__observations textarea.scroll {
  padding: 16px;
  height: 200px;
  width: 100% !important;
}
.practical-form-test .form__all-wrapper .form__main-wrapper .practical-question__wrapper .practical-question__observations .label {
  margin-bottom: 8px;
}
.practical-form-test .form__all-wrapper .form__all-wrapper__footer .practical-form__save-btn {
  min-width: 100px;
  width: 100px;
  background-color: white;
  color: #474b4f;
  border: 1px solid #474b4f;
  justify-content: center;
  margin: 12px 0px 23px;
  height: 32px;
  padding: 9px;
}
