.modal-header-title {
  font-size: 16px !important;
}
.search__modal .search__modal__header.header {
  border-bottom: 1px solid rgba(181, 181, 181, 0.342) !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}
.fit-content {
  width: fit-content;
}
.text__center {
  text-align: center;
}
.add-row-btn {
  background-color: #eeeeee !important;
  border: 1px solid #eeeeee !important;
  padding: 2px !important;
}
.question-bank__cell__tag {
  background-color: #eeeeee;
  margin: 5px;
  color: #474b4f;
  font-weight: 500;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  border-radius: 10px;
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.text__regular {
  font-weight: 500;
}
.text__bold {
  font-weight: 700 !important;
}
.flex-baseline {
  display: flex;
  align-items: baseline;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.padding-margin-right-none {
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.text__grey {
  color: grey;
}
.margin-zero {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.padding-zero {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.form__box-content {
  border: 1px solid #c1c5c5 !important;
  background-color: white !important;
}
.form__header__row {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 100% !important;
  margin-bottom: 24px !important;
}
.form__header__row-icon {
  margin-right: 24px !important;
  font-size: 18px !important;
  margin-bottom: 5px !important;
  color: #474b4f !important;
  height: 18px !important;
  width: 18px !important;
}
.form__field-numeric {
  width: 70px !important;
  height: 32px !important;
  margin-right: 50px;
}
.form__planned-duration__numeric__wd {
  width: 90px !important;
}
.form__planned-duration__numeric__wd input {
  min-width: 90px;
}
.text__transparent {
  color: transparent !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #dedfdf !important;
}
.students-table__column-title {
  background-color: #eeeeee !important;
  width: 100% !important;
  padding: 8px !important;
  margin-right: 0px !important;
}
.form__tab__icon-element {
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}
.error-message__style {
  width: 95%;
  border: 1px solid #e03c31;
  background-color: white !important;
  color: #222629 !important;
  font-size: 11px;
  margin-bottom: 32px;
  box-shadow: none !important;
}
.error-message__style .list {
  margin: 0px;
}
.error-message__style li.content::before {
  display: none;
}
.error-message__style .icon {
  font-size: 16px !important;
  color: #e03c31;
  align-self: flex-start;
}
.error-message__style .header {
  padding-left: 14px !important;
  color: #e03c31 !important;
}
.ui.modal .actions.form__action-buttons-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #eeeeee !important;
  padding: 16px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__actions__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-right: 16px;
  font-weight: 500;
}
.ui.modal .actions.form__action-buttons-wrapper .form__align-right-btns {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
}
.ui.modal .actions.form__action-buttons-wrapper .form__white-btn {
  background-color: white;
  color: #474b4f;
  border: 1px solid #474b4f;
}
.ui.modal .actions.form__action-buttons-wrapper .form__white-btn .icon {
  color: #474b4f;
  background-color: transparent;
  padding: 0px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__request-changes-btn {
  padding: 10px;
  min-width: 120px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__next-status-accept-btn {
  background-color: #00b2a9;
  color: white;
  font-weight: 600;
}
.ui.modal .actions.form__action-buttons-wrapper .request-form__reject-button {
  background-color: white;
  color: #e03c31;
  border: 1px solid #e03c31;
}
.form__cancel-btn {
  background-color: #ec8a83 !important;
  color: white !important;
  min-width: 110px;
  padding: 2px;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    width: 40px;
  }
  100% {
    opacity: 1;
    width: fit-content;
  }
}
.scroll {
  overflow-y: auto;
  scroll-padding: 5px !important;
  overflow-x: hidden;
  transition: 0.9s;
}
.scroll::-webkit-scrollbar {
  width: 4px;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #000000;
}
.scroll-not-visible {
  overflow-y: scroll;
  overflow-x: hidden;
}
.scroll-not-visible::-webkit-scrollbar {
  width: 0px;
}
.modal__action-warning {
  background-color: #f7cecb !important;
  color: #222629;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  width: 40px;
  border-radius: 4px;
  margin: 20px 0px 20px 0px;
  text-align: center !important;
  transition: all 0.8s linear;
}
.modal__action-warning .modal__action-warning__text {
  display: none;
  transition: all 0.8s linear;
}
.modal__action-warning .icon {
  color: #e03c31;
  font-size: 17px;
  margin-right: 0px !important;
  align-self: flex-start;
}
.modal__action-warning div {
  display: flex !important;
  flex-direction: column !important;
  transition: all 0.8s linear;
}
.modal__action-warning div p {
  width: fit-content;
}
.modal__action-warning:hover {
  width: fit-content;
  transition: all 0.8s linear;
}
.modal__action-warning:hover .modal__action-warning__text {
  display: flex;
  transition: all 0.8s linear;
}
.modal__action-warning:hover .icon {
  margin-right: 10px !important;
  transition: all 0.8s linear;
}
.year-editor {
  display: inline-block;
  height: 36px !important;
}
.year-editor .ui.icon.input,
.year-editor .remove.link.icon {
  height: 36px !important;
}
html,
html * {
  font-family: 'Roboto', sans-serif;
}
html,
body {
  height: 100%;
  overflow: hidden;
}
div#root {
  height: 100%;
  overflow: auto;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.row-selected {
  background: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.95) !important;
}
.planit-main-container {
  margin-left: 16.8rem;
  padding: 0px;
  height: 95%;
  -webkit-transition: margin-left 0.5s ease;
  transition: margin-left 0.5s ease;
  background: white;
}
.planit-main-container.scheduler {
  background: white;
}
.planit-main-container.expanded {
  margin-left: 4.5rem;
}
.planit-main-container #main-container-grid {
  margin: 0;
  height: 95%;
  position: relative;
}
.planit-main-container #main-container-grid #notifications-row {
  position: absolute;
  z-index: 998 !important;
  height: 5%;
  padding: 0px 5px;
  top: 10px;
  right: 10px;
  width: 35% !important;
}
.planit-main-container #main-container-grid #notifications-row p {
  text-align: right;
  width: 100%;
}
.planit-main-container #main-container-grid #notifications-row p i {
  background-color: #474b4f !important;
  cursor: pointer;
}
.planit-main-container #main-container-grid #content-container {
  height: 100%;
  display: block !important;
}
.planit-main-container #main-container-grid #content-container > h3:first-child {
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 0px;
  background-color: #00b2a9;
  color: white;
  height: 7%;
  position: relative;
  top: -12px;
  padding: 15px;
}
.planit-main-container #main-container-grid #content-container h3:first-child + .planit-line-separator {
  padding-top: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.planit-main-container #main-container-grid #content-container .main-tab:first-child {
  margin-top: 10px;
}
.planit-main-container #main-container-grid #content-container .header-accordion-spacer {
  margin-top: 20px !important;
}
.planit-main-container #main-container-grid #content-container .header-instructions-accordion-spacer {
  margin-top: 10px !important;
}
#planit-home-div #img {
  width: 4rem;
  display: inline-block;
}
#planit-home-div #img img {
  height: 30px;
}
#planit-home-div #text {
  width: 85%;
  display: inline-block;
}
#planit-home-div #subtitle {
  font-size: 15px;
}
.clickable-icon {
  cursor: pointer;
}
.user-dropdown {
  min-width: 300px;
  min-height: 32px;
}
.planit-general-error-container {
  font-size: 150%;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding-top: 30vh;
}
.planit-users-inputs {
  min-width: 300px;
  min-height: 32px;
}
.planit-users-fields {
  min-width: 400px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.planit-user-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedfdf;
  border-radius: 4px;
  opacity: 1;
}
.planit-user-dropdown i {
  padding: 0px !important;
}
.planit__multi-plc__dropdown i {
  padding: 10px !important;
}
.planit-user-selector {
  min-width: 250px !important;
  min-height: 32px;
}
.planit-users-roles-in-location-left {
  text-align: right;
  padding-left: 155px;
  max-height: 70px;
  padding-right: 0px;
}
.planit-users-roles-in-location-right {
  text-align: left;
  max-height: 32px;
  padding-left: 4.5rem;
}
.planit-users-roles-in-location-selector {
  width: 250px !important;
  padding-right: 10px;
}
.planit-roles-checkbox {
  width: 16px;
  height: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #474b4f;
  border-radius: 4px;
  opacity: 1;
}
#notification-icon {
  height: 38px !important;
  width: 38px !important;
  font-size: 1.4em !important;
}
/* Toggle overrides */
.ui.checkbox.toggle input:checked ~ .box:after,
.ui.checkbox.toggle input:checked ~ label:after {
  border: 2px solid #00b2a9;
}
.ui.checkbox.toggle input:checked ~ .box:after,
.ui.checkbox.toggle input:checked ~ label:hover::before {
  background-color: none;
}
.ui.checkbox.toggle input:not(:checked) ~ .box:after,
.ui.checkbox.toggle input:not(:checked) ~ label:after {
  background: none;
  border: 2px solid #b0b0b0;
  box-shadow: none;
}
.ui.checkbox.toggle input:not(:checked) ~ .box:after,
.ui.checkbox.toggle input:not(:checked) ~ label:before {
  background: none;
  border: 2px solid #b0b0b0;
  box-shadow: none;
}
/* Toggle overrides */
/*Modal action buttons override*/
.ui.modal .actions {
  background: unset !important;
  border-top: 0px !important;
}
.ui.modal .actions .button {
  height: 36px;
  width: 100px;
}
.ui.modal .actions .button.basic {
  border: 1px solid #474b4f;
  color: #474b4f !important;
  opacity: 1;
  line-height: 13px;
  box-shadow: none !important;
}
.ui.modal .actions .button.positive {
  font-weight: normal !important;
}
.custom-actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.5rem;
  padding-top: 0;
}
.custom-actions .field {
  margin-right: 3em;
}
.custom-actions .field label {
  font-weight: bold;
  margin-bottom: 0.4rem;
  display: block;
}
.custom-actions button.button {
  margin-bottom: 0 !important;
}
.inverted-color-btn {
  color: white !important;
  background-color: #474b4f !important;
  font-size: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
}
.white-save-btn {
  color: #474b4f !important;
  background-color: white !important;
  border: 1px solid #474b4f !important;
  font-size: 1rem !important;
}
.disabled-inverted-save-btn {
  color: #b0b0b0 !important;
  background-color: white !important;
  border: 1px solid #b0b0b0 !important;
  font-size: 1rem !important;
  opacity: 1 !important;
}
.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button {
  color: white !important;
  background-color: #00b2a9 !important;
}
/*Modal action buttons override*/
/* Color pickers*/
.color-picker-popup {
  padding: 0 !important;
  margin-top: 4px !important;
}
.color-picker-item {
  padding: 7px 4px 5px 4px !important;
}
.color-picker-item i {
  right: 7px !important;
}
/* Color pickers*/
/* Icon pickers*/
.icon-picker {
  padding: 7px 4px !important;
}
.icon-picker i {
  right: 7px !important;
}
.icon-picker .menu.menu.menu.menu.menu .item {
  padding: 0.75rem 5px !important;
}
/* Icon pickers*/
/*Table Students Request Wizard Row*/
.table-border-rows {
  width: 100%;
  font-weight: bold;
  border: 1px solid black;
  border-collapse: collapse;
}
.table-border-rows tr {
  border: 1px solid black;
  border-collapse: collapse;
}
.table-border-rows tr td {
  padding: 10px 20px;
}
/*Table Students Request Wizard Row*/
/*Table Machines Request Wizard Row*/
.table-machines-wizard {
  justify-self: center;
  width: 100%;
  overflow-y: scroll;
}
.table-machines-wizard th {
  width: 10%;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
.table-machines-wizard tr {
  padding: 10px 20px;
}
.table-machines-wizard .table-machines-wizard-header {
  border-bottom: 1px solid black;
}
/*Table Machines Request Wizard Row*/
.medium-remove-red-button {
  font-size: 1em !important;
  cursor: pointer;
}
.custom-editor {
  width: 100% !important;
}
.custom-editor.ui.loading.selection.dropdown > i.icon {
  padding: 1.5em 1.5em !important;
}
.custom-editor div {
  width: 100%;
}
.ui.loading.selection.dropdown.selection.selection.selection.selection > i.icon {
  padding: 1.5em 1.5em !important;
}
.button.inverted-btn.inverted-btn.inverted-btn.inverted-btn.inverted-btn {
  background-color: #474b4f !important;
  color: white !important;
}
.button.inverted-btn.inverted-btn.inverted-btn.inverted-btn.inverted-btn i {
  color: white !important;
}
.button.add-user {
  padding: 0.91em 0.82em 0.7em 0.82em !important;
}
.button.add-user i {
  zoom: 1.6 !important;
}
.scroll-content {
  overflow: scroll;
  padding-bottom: 20px;
}
.rounded-button {
  border-radius: 50% !important;
}
.ui.icon.input > i.icon.calendar {
  color: #dedfdf;
  opacity: 1;
}
.float-right {
  float: right;
}
.gray-onhover {
  cursor: pointer;
}
.gray-onhover:hover {
  background-color: #efefef !important;
}
.turquose-onhover:hover {
  transition: 0.9s;
  color: #00b2a9 !important;
  cursor: pointer;
  background-color: white !important;
}
.ui.selection.dropdown.planit-multi-dropdown div.menu {
  overflow-y: hidden;
}
.ui.selection.dropdown.planit-multi-dropdown div.menu.visible {
  overflow-y: auto;
}
.ui.selection.dropdown.planit-multi-dropdown div.menu .ui.input {
  margin: 6px;
}
.ui.selection.dropdown.planit-multi-dropdown div.menu .ui.input input {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.ui.selection.dropdown.planit-multi-dropdown div.menu div[role='option'].item {
  display: flex;
  align-items: center;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  cursor: pointer;
}
.ui.selection.dropdown.planit-multi-dropdown div.menu .text {
  margin-left: 9px;
}
.ui.selection.dropdown.planit-multi-dropdown .planit-multi-dropdown__selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ui.selection.dropdown.planit-multi-dropdown .planit-multi-dropdown__selected-option .planit-multi-dropdown__selected-option__number {
  font-weight: 700;
}
.ui.selection.dropdown.planit-multi-dropdown .planit-multi-dropdown__options {
  margin: 0.6rem 0px 0px 0.6rem;
  max-height: 9rem;
  min-height: 9rem;
  overflow-y: auto;
}
.ui.selection.dropdown.planit-multi-dropdown .ui.divider {
  margin: 2px;
}
.ui.selection.dropdown.planit-multi-dropdown .planit-multi-dropdown__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2rem;
}
.ui.selection.dropdown.planit-multi-dropdown .planit-multi-dropdown__actions a {
  text-decoration: none;
  cursor: pointer;
  color: #222629;
  font-weight: bolder;
  padding: 4px;
}
.multi-user-dropdown__me > span {
  margin-left: 0.6rem;
}
.margin-top-6px {
  margin-top: 6px !important;
}
.margin-top-2em {
  margin-top: 2em !important;
}
.margin-top-0.margin-top-0.margin-top-0.margin-top-0 {
  margin-top: 0px !important;
}
.margin-0.margin-0.margin-0.margin-0 {
  margin: 0px !important;
}
.height-3em {
  height: 3em !important;
}
.visible.menu.transition {
  min-height: 100px;
  width: max-content !important;
  max-width: 700px !important;
  border: 1px solid rgba(128, 128, 128, 0.541);
}
.visible.menu.transition::-webkit-scrollbar {
  width: 4px;
}
.visible.menu.transition::-webkit-scrollbar-track {
  border-radius: 10px;
}
.visible.menu.transition::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #000000;
}
/*DATE PICKERS*/
.semantic-ui-calendar-react-node.bottom::before {
  background: #474b4f !important;
}
/*DATE PICKERS*/
.b-float-root {
  z-index: 1001 !important;
}
.b-float-root > .b-floating.b-toast {
  border-radius: 4px !important;
  color: #fff !important;
}
.b-float-root > .b-floating.b-toast i {
  color: #fff;
  margin-right: 16px;
}
.b-float-root > .b-floating.b-toast.warning-toast {
  background-color: #eaaa00 !important;
}
.b-float-root > .b-floating.b-toast.success-toast {
  background-color: #407ec9 !important;
}
.b-float-root > .b-floating.b-toast.information-toast {
  background-color: #eaaa00 !important;
}
.b-float-root > .b-floating.b-toast.error-toast {
  background-color: #e03c31 !important;
}
.min-width-100pc.min-width-100pc.min-width-100pc.min-width-100pc {
  min-width: 100% !important;
}
.normal-look-readonly-select {
  color: black !important;
  opacity: 1 !important;
}
.normal-look-readonly-select:not(.loading) i.icon {
  opacity: 0 !important;
}
.user-list-table__roles-columns-text {
  max-width: 150px;
  text-overflow: ellipsis;
}
.planit-multi-dropdown {
  min-width: 150px !important;
  height: 38px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 6px;
}
.planit-multi-dropdown > div.menu.visible.transition {
  width: fit-content !important;
  border: 1px solid rgba(128, 128, 128, 0.541);
}
.planit-multi-dropdown__options {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.planit-multi-dropdown__options > span > div {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.planit-multi-dropdown__options > span > div > span {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.planit-multi-dropdown__options::-webkit-scrollbar {
  width: 4px;
}
.planit-multi-dropdown__options::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.planit-multi-dropdown__options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #000000;
}
.label-required.label-required.label-required::after {
  margin: -0.2em 0em 0em 0.2em;
  content: '*';
  color: #db2828;
}
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input {
  opacity: 1 !important;
}
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input label,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.input,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.dropdown {
  opacity: 1 !important;
}
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input label .dropdown.icon,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.input .dropdown.icon,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.dropdown .dropdown.icon {
  opacity: 0 !important;
}
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input label input,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.input input,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.dropdown input {
  opacity: 1 !important;
}
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input label input:focus,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.input input:focus,
.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input div.dropdown input:focus {
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.custom-editor-search {
  min-height: 36px !important;
}
.template-step {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.template-step.inactive {
  color: #dedfdf;
}
.template-step.inactive .ui.circular.label.step__label {
  background-color: #dedfdf;
  border-color: transparent;
}
.template-step .ui.circular.label.step__label {
  background-color: #00b2a9;
  padding: 0px;
  width: 38px;
  height: 38px;
  vertical-align: middle;
  color: white;
  line-height: 2em;
}
.template-step .step__title {
  margin-left: 24px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
.media-input {
  border-radius: 4px;
  border: 1px solid #dedfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 8px;
  width: 100%;
  max-width: 100%;
}
.media-input .table__tooltip {
  min-width: 90%;
}
.spacer {
  flex: 1 1 auto;
}
.no-arrows input[type='number'] input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media all and (max-width: 1200px) {
  .sch-instructors__header .planit-multi-dropdown {
    margin-bottom: 10px;
  }
  .sch-instructors__header .planit-multi-dropdown > span {
    line-height: 12px !important;
  }
}
@media only screen and (max-height: 670px) {
  #content-container#content-container#content-container h3:first-child {
    font-size: 16px !important;
  }
}
.bold-label-checkbox label {
  font-weight: 900;
}
.custom-table thead tr th.no-width {
  min-width: 0px !important;
}
.ui.selection.selection.selection.selection.selection.selection div.default.default.default.default.text,
.ui.dropdown.dropdown.dropdown.dropdown.dropdown.dropdown.dropdown div.default.default.default.default.text {
  font-weight: normal !important;
}
.poc-config-modal__ranges-container .fields .field .planit-eventform-dates-period .ui.icon.input {
  max-width: 100px;
  margin-right: 24px;
  min-width: 100px;
  width: 100px;
  height: 32px;
}
.poc-config-modal__ranges-container .fields .field .planit-eventform-dates-period .ui.icon.input .icon {
  display: none;
}
.poc-config-modal__ranges-container .fields .field .planit-eventform-dates-period .ui.icon.input input {
  text-align: center;
  padding: 8px;
  padding-right: 8px !important;
}
.ui.modal .header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.underlined {
  text-decoration: underline;
}
.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.basic-padding {
  padding: 1em 1rem;
}
.ui.button.sch-button {
  min-width: fit-content;
  white-space: nowrap;
  height: 38px;
  border-radius: 4px;
  color: #474b4f;
  background-color: #fff;
  outline: none;
  border: 1px solid #474b4f;
  cursor: pointer;
  margin-right: 6px;
}
.ui.button.sch-button:visited,
.ui.button.sch-button:focus {
  background-color: inherit;
}
.ui.button.sch-button:hover {
  background-color: whitesmoke;
}
.ui.button.sch-button.active {
  background-color: #474b4f;
  color: #fff;
}
.ui.button.sch-button.active i.icons {
  color: #00b2a9;
}
.request-location-region__input {
  margin-right: 12px !important;
}
.no-margin-bottom {
  margin-bottom: -1em !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields.request-event__field-all-container-height-flexible {
  flex-direction: column !important;
  padding: 0px 15px !important;
}
.request-list__filters-wrapper.event-list__new-filters-container {
  margin-right: -60px;
  width: calc(100vw - 300px);
}
#planit-left-menu {
  z-index: 1000;
  -webkit-transition: width 0.5s ease;
  transition: width 0.5s ease;
  background-color: #eeeeee;
  width: 16.8rem;
}
#planit-left-menu a.disabled.item {
  color: rgba(255, 255, 255, 0.9) !important;
  font-weight: bold;
  font-size: 14px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
#planit-left-menu .planit__user-name {
  word-break: break-word;
}
#planit-left-menu a {
  font-weight: bold;
  font-size: 14px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
#planit-left-menu a i {
  font-size: 1.3em;
  margin-right: 20px;
}
#planit-left-menu a .users.icon {
  margin-right: 23px;
}
#planit-left-menu a .home.icon,
#planit-left-menu a .fa-file-contract {
  margin-right: 22px;
}
#planit-left-menu a svg {
  font-size: 1.4em;
  margin-right: 22px;
  margin-left: 4px;
}
#planit-left-menu .item::before,
#planit-left-menu .item::after {
  background: #eeeeee !important;
}
#planit-left-menu a.active.item,
#planit-left-menu .item:hover {
  background: #eeeeee !important;
  color: #00b2a9 !important;
}
#planit-left-menu.shrunk {
  width: 4.5rem;
}
#planit-left-menu.shrunk .menu.visible,
#planit-left-menu.shrunk .menu.transition {
  width: 300%;
}
#planit-left-menu.shrunk #expand-menu-icon {
  text-align: left !important;
}
#planit-left-menu.shrunk a i,
#planit-left-menu.shrunk span i,
#planit-left-menu.shrunk a #assessments-composite-icon,
#planit-left-menu.shrunk span #assessments-composite-icon,
#planit-left-menu.shrunk a svg,
#planit-left-menu.shrunk span svg {
  margin-right: 0px !important;
}
#planit-left-menu #expand-menu-icon {
  text-align: right;
  margin: 5px;
}
#planit-left-menu #expand-menu-icon i {
  font-size: 1.4rem;
  float: right;
}
#planit-left-menu #expand-menu-icon-expanded {
  text-align: right;
  margin-left: 85px;
}
#planit-left-menu #expand-menu-icon-expanded i {
  font-size: 1.4rem;
  float: left;
}
#planit-left-menu #jti-white-container #jti-white {
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  min-width: 47px;
  max-width: 47px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
#planit-left-menu #jti-white-container.shrunk {
  padding: 12px 3px;
}
#planit-left-menu .left-menu-item {
  font-weight: bold;
  font-size: 14px;
  color: rgba(25, 25, 25, 0.9) !important;
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  padding: 12px 1.14285714em;
  text-transform: none;
}
#planit-left-menu .left-menu-item.active,
#planit-left-menu .left-menu-item:hover {
  background: #eeeeee !important;
  color: #00b2a9 !important;
}
#planit-left-menu .left-menu-item i.dropdown.icon {
  visibility: hidden;
}
#planit-left-menu .left-menu-item.secondary-menu {
  display: flex;
  justify-content: space-between;
}
#planit-left-menu .left-menu-item.secondary-menu i.icon {
  visibility: visible;
  margin-right: 0px !important;
}
#planit-left-menu .left-menu-item span i {
  font-size: 1.3em;
  margin-right: 20px;
}
#planit-left-menu .left-menu-item span .users.icon {
  margin-right: 23px;
}
#planit-left-menu .left-menu-item span .home.icon {
  margin-right: 22px;
}
#planit-left-menu #avatar-container {
  font-weight: bold;
  font-size: 14px;
  color: rgba(25, 25, 25, 0.9) !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 9px;
}
#planit-left-menu #avatar-container.active,
#planit-left-menu #avatar-container:hover {
  background: #eeeeee !important;
  color: #00b2a9 !important;
}
#planit-left-menu #avatar-container i.dropdown.icon {
  visibility: hidden;
}
#planit-left-menu #avatar-container #user-avatar {
  max-width: 30px;
  max-height: 30px;
  min-width: 30px;
  min-height: 30px;
  width: 100%;
  height: 100%;
  margin: 0px 11px 3px 7px;
}
#planit-left-menu #avatar-container.shrunk {
  padding-left: 1em;
}
#planit-left-menu #avatar-container.shrunk #user-avatar {
  margin-left: 0px;
}
#planit-left-menu .menu.visible,
#planit-left-menu .menu.transition {
  color: rgba(255, 255, 255, 0.9) !important;
  background: #eeeeee !important;
  border-radius: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 250px;
  top: 0 !important;
  z-index: 0 !important;
  letter-spacing: 1px;
}
#planit-left-menu .menu.visible .circular,
#planit-left-menu .menu.transition .circular {
  font-weight: lighter;
  width: 32px;
  height: 32px;
  padding-top: 10px !important;
}
#planit-left-menu .menu.visible .header,
#planit-left-menu .menu.transition .header {
  color: rgba(25, 25, 25, 0.9) !important;
  text-transform: capitalize;
}
#planit-left-menu .menu.visible .header .role-location-list,
#planit-left-menu .menu.transition .header .role-location-list {
  font-weight: lighter;
}
#planit-left-menu .menu.visible .divider,
#planit-left-menu .menu.transition .divider {
  background-color: #eeeeee;
}
#planit-left-menu .menu.visible p,
#planit-left-menu .menu.transition p {
  width: 100% !important;
}
#planit-left-menu .menu.visible .item[role='option'],
#planit-left-menu .menu.transition .item[role='option'] {
  color: rgba(25, 25, 25, 0.9) !important;
}
#planit-left-menu .menu.visible .item[role='option']:hover,
#planit-left-menu .menu.transition .item[role='option']:hover,
#planit-left-menu .menu.visible .item[role='option'].active,
#planit-left-menu .menu.transition .item[role='option'].active {
  background: #00b2a933 !important;
  color: rgba(25, 25, 25, 0.9) !important;
}
#planit-left-menu .menu.visible .user-item__active,
#planit-left-menu .menu.transition .user-item__active {
  background-color: #00b2a933 !important;
}
#planit-left-menu .menu.visible .update-photo__box,
#planit-left-menu .menu.transition .update-photo__box {
  font-weight: lighter;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}
#planit-left-menu .menu.visible .update-photo__box .icon,
#planit-left-menu .menu.transition .update-photo__box .icon {
  margin-left: 5px;
}
#planit-left-menu .menu.visible .role-switch__main-text,
#planit-left-menu .menu.transition .role-switch__main-text {
  color: #00b2a9;
  font-weight: medium;
  width: 100%;
  right: 0px;
  padding-left: 200px;
}
#content-container > div.ui.grid.event-types-list-grid > div.row.event-types-list-items-row > div > div.ui.active.transition.visible.dimmer {
  z-index: 0 !important;
  padding: 0px 15px !important;
}
#assessments-composite-icon {
  font-size: 1.4em;
  margin-right: 21px;
}
#assessments-composite-icon .fa-file {
  margin-left: 4px;
}
#assessments-composite-icon .fa-tasks {
  -webkit-transform-origin: 0.5em 0.5em;
  transform-origin: 0.5em 0.5em;
  font-size: 0.8em;
  color: #eeeeee;
  margin-top: 6px;
  margin-left: 4px;
}
.role-location-list {
  margin-bottom: 5px;
}
.menu__collapsable-dropdown {
  width: 165px;
  min-height: fit-content !important;
  height: fit-content !important;
  z-index: 4 !important;
}
.role-switch__menu {
  min-width: 245px !important;
  width: fit-content !important;
  font-weight: 200;
  max-height: 90vh;
  overflow-y: auto;
}
.role-switch__menu #role-switch__menu__logout-divider {
  margin-bottom: 0 !important;
}
@media only screen and (max-height: 670px) {
  #planit-left-menu * {
    transform: scale(0.95) !important;
  }
  #planit-left-menu .item {
    padding-top: 7.8px !important;
    padding-bottom: 7.8px !important;
  }
  #planit-left-menu i,
  #user-avatar {
    transform: scale(0.8) !important;
  }
  #planit-left-menu #assessments-composite-icon {
    font-size: 1.3em;
    margin-left: 2px;
  }
  #planit-left-menu #assessments-composite-icon .fa-tasks {
    font-size: 0.6em;
    margin-left: 6px;
  }
  #planit-left-menu .fa-file-contract {
    font-size: 1.2em;
    margin-left: 5px;
    margin-right: 23px;
  }
  #planit-left-menu .menu.visible,
  #planit-left-menu .menu.transition {
    left: 99% !important;
  }
  #planit-left-menu .menu.visible .item,
  #planit-left-menu .menu.transition .item {
    transform: unset !important;
    font-size: 13px !important;
  }
  #planit-left-menu.shrunk .menu.visible,
  #planit-left-menu.shrunk .menu.transition {
    left: 4rem !important;
  }
}
@media only screen and (max-height: 580px) {
  #planit-left-menu * {
    transform: scale(0.85) !important;
  }
  #planit-left-menu #assessments-composite-icon {
    font-size: 1.8em;
    margin-right: 13px;
  }
  #planit-left-menu #assessments-composite-icon .fa-file {
    margin-left: 1px !important;
  }
  #planit-left-menu #assessments-composite-icon .fa-tasks {
    margin-left: 4px !important;
  }
  #planit-left-menu .fa-file-contract {
    font-size: 1.8em;
    margin-left: 1px;
  }
  #planit-left-menu.shrunk .menu.visible,
  #planit-left-menu.shrunk .menu.transition {
    left: 3.3rem !important;
  }
}
/*Toast overrides*/
.Toastify__toast-container {
  width: 35vw !important;
}
.ui.message.birch-toast {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.ui.message.birch-toast .content {
  width: 70%;
}
.ui.message.birch-toast .content .header {
  font-size: 1.2rem;
}
.ui.message.birch-toast .content .msg {
  font-size: 0.95rem;
}
/*Toast overrides*/
.ui.fluid.selection.dropdown.custom-table-pagination-select {
  max-height: 35.28px;
  height: 100% !important;
  min-height: 2.5rem !important;
  padding: 11px;
}
.ui.fluid.selection.dropdown.custom-table-pagination-select i {
  padding: 10px;
}
.ui.fluid.selection.dropdown.custom-table-pagination-select .text,
.ui.fluid.selection.dropdown.custom-table-pagination-select .item {
  font-size: 12px;
}
.custom-table-footer {
  width: 100% !important;
}
.custom-table-pagination a.active.item {
  background-color: #474b4f !important;
  color: white !important;
}
.custom-table-pagination-select-column.custom-table-pagination-select-column {
  width: 125px !important;
  padding: 0 !important;
  height: 35.28px;
}
.custom-table-pagination-column.custom-table-pagination-column {
  width: calc(100% - 255px) !important;
}
.custom-table.ui.table thead tr th a {
  color: white;
}
.custom-table thead tr th {
  background-color: #474b4f !important;
  min-width: 110px !important;
}
.custom-table thead tr th.small-icon {
  min-width: 3em !important;
}
.custom-table thead tr th.text-long {
  min-width: 350px !important;
}
.custom-table > thead > tr > th:last-child {
  max-width: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
}
.custom-table td span {
  text-overflow: ellipsis !important;
  white-space: nowrap;
  display: block;
}
.custom-table td span .table__short-textable {
  max-width: 135px;
}
.table__cell-selected-background-no-check {
  background-color: #eeeeee !important;
}
.custom-table-clear-sorts,
.custom-table-clear-filters,
.custom-table-add-btn {
  height: 100%;
}
.custom-table-actions .item,
.custom-table-main-actions .item {
  height: fit-content !important;
}
tr.active.active.active.active.active td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.custom-table-main-actions {
  margin-bottom: 16px !important;
}
.custom-table-main-actions .fitted.item .button:not(.custom-table-add-btn) {
  background-color: #474b4f !important;
  color: white !important;
  width: 3.25em;
  height: 38px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500;
}
.custom-table-main-actions .fitted.item .button:not(.custom-table-add-btn) .icons i {
  color: white !important;
  opacity: 1 !important;
  text-shadow: none !important;
}
.custom-table-main-actions .fitted.item .button:not(.custom-table-add-btn) .icons i.corner {
  left: 80%;
  font-size: 0.6rem;
}
.custom-table-add-btn {
  background: #474b4f !important;
  color: white !important;
  font-size: 1rem !important;
  height: 38px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
}
.custom-table-add-btn i {
  color: #474b4f !important;
  background-color: white !important;
}
.custom-table-add-btn .add.tiny.circular.icon {
  margin-left: 6px !important;
}
.custom-table-add-btn:hover {
  border: 1px solid #00b2a9 !important;
}
.custom-table-add-row-btn-inverted {
  background: white !important;
  border: 1px solid #474b4f !important;
  color: #474b4f !important;
  font-size: 1rem !important;
  height: 38px;
}
.custom-table-add-row-btn-inverted i {
  background-color: #474b4f !important;
  color: white !important;
}
/*Expanded rows*/
.custom-table-expand-column {
  width: 3% !important;
}
.custom-table-expanded-row td {
  border-top: 0 !important;
  padding-top: 0 !important;
}
.custom-table-expanded-row-parent td {
  padding-bottom: 0 !important;
}
.row-disable-layout {
  color: #b0b0b0;
}
.row-disable-layout .question-bank__cell__tag.question-bank__cell__tag {
  background-color: #eeeeee9d;
  color: #b0b0b0;
}
.custom-table-expand-icon {
  cursor: pointer;
  font-size: 14px !important;
  color: #474b4f;
}
.custom-table__checkbox-invisible svg {
  display: none;
}
/*Expanded rows*/
/*Extra inline actions*/
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions {
  border: 0 !important;
  box-shadow: none !important;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions:hover {
  box-shadow: none !important;
  border: 0 !important;
  background: none;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions button {
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 0.5rem !important;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions button i {
  font-size: 18px !important;
  color: #474b4f;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions button:hover,
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions button:active {
  box-shadow: none !important;
  background: none !important;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions .toggle.toggle.toggle.toggle {
  width: 2rem !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions .toggle.toggle.toggle.toggle input {
  width: 2rem !important;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions .toggle.toggle.toggle.toggle label {
  padding: 0 !important;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions :last-child.button {
  padding-right: 0 !important;
}
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions :last-child.toggle {
  margin-right: 0 !important;
}
.padding-right-zero {
  padding-right: 0 !important;
}
/*Extra inline actions*/
/* FILTERS*/
/*Semantic ui tables override*/
.ui.table thead tr th.selectable {
  padding: 0;
  cursor: pointer;
}
.ui.table thead tr th.selectable .filter {
  margin-left: 4px;
}
.ui.table thead tr th a .filter {
  margin-left: 2px;
}
.ui.table thead tr th {
  line-height: 14px;
}
.ui.table thead th {
  width: fit-content !important;
}
.ui.table thead th b {
  margin-right: 5px;
  padding-right: 0px !important;
}
.ui.table thead th a {
  margin-right: 5px;
  padding-right: 0px !important;
}
.ui.table thead tr th.selectable > a:not(.ui) {
  display: block;
  color: inherit;
  padding: 0.78571429em 0.78571429em;
}
.options-filter {
  min-width: 15rem;
}
.options-filter .select-field {
  padding-right: 0 !important;
}
.options-filter .select-field .selection.dropdown {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.options-filter .filter-button-field {
  padding-left: 0 !important;
}
.options-filter .filter-button-field button {
  height: 100%;
  border-radius: 0px 0.28571429rem 0.28571429rem 0px !important;
}
.dates-filter .filter-button-field button {
  border-radius: 0.28571429rem !important;
}
.dates-filter form {
  width: 21rem !important;
}
.dates-filter .small.table * {
  font-size: 1.03em !important;
}
.dates-filter .small.table {
  min-width: 100% !important;
}
.checkbox-filter .filter-button-field button {
  border-radius: 0.28571429rem !important;
}
.textbox-filter input {
  font-size: 1.5em !important;
}
/* FILTERS*/
/*toggle*/
table > tbody > tr > td .ui.toggle.checkbox {
  min-height: 1.3rem !important;
}
table > tbody > tr > td .ui.toggle.checkbox input {
  height: 1.3rem !important;
}
table > tbody > tr > td .ui.toggle.checkbox .box,
table > tbody > tr > td .ui.toggle.checkbox label {
  min-height: 1.3rem !important;
  padding-left: 3rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  width: 1rem;
}
table > tbody > tr > td .ui.toggle.checkbox .box:after,
table > tbody > tr > td .ui.toggle.checkbox label:after {
  width: 1.3rem !important;
  height: 1.3rem !important;
  z-index: 1;
}
table > tbody > tr > td .ui.toggle.checkbox .box:before,
table > tbody > tr > td .ui.toggle.checkbox label:before {
  width: 2rem !important;
  height: 1.3rem !important;
  border-radius: 1.3rem !important;
}
table > tbody > tr > td .ui.toggle.checkbox input:checked ~ .box:after,
table > tbody > tr > td .ui.toggle.checkbox input:checked ~ label:after {
  left: 0.9rem !important;
}
/*toggle*/
/*full width table*/
.full-width {
  width: 100%;
}
/*full width table*/
.menu-item-download {
  padding: 0px !important;
}
.transparent-btn {
  background-color: transparent !important;
  border: solid 1px lightgray !important;
}
.black-btn {
  background-color: #474b4f !important;
  border: solid 1px lightgray !important;
  color: white !important;
}
.custom-table-upload-btn svg {
  font-size: 1.75rem;
}
.custom-table-hoverable-btn {
  box-shadow: none !important;
  border: none !important;
  height: 38px !important;
}
.custom-table-hoverable-btn:hover {
  border: 1px solid #00b2a9 !important;
}
.custom-table-hoverable-btn i {
  opacity: 1 !important;
  color: white !important;
}
.custom-table-upload-btn {
  width: 38px;
  height: 38px;
  padding: 5px !important;
}
.custom-table-upload-btn__up-down:hover {
  color: #00b2a9 !important;
  border: 1px solid #00b2a9 !important;
}
/*Semantic ui tables override*/
.bottom.right.popup.transition.visible {
  left: 9px !important;
}
.table__flex-container {
  overflow-x: scroll;
  height: 70vh;
  margin-bottom: 10px;
  scrollbar-width: thin;
}
.table__flex-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 3px;
  background-color: rgba(71, 75, 79, 0.63);
  transition: 0.9s;
}
.table__flex-container::-webkit-scrollbar-track {
  border-radius: 10px;
}
.full-width.table__flex-container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
:hover .full-width.table__flex-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.table__flex-container > table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.table-general__pagination-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.table-general__pagination-container .custom-table-pagination-select {
  width: 100px !important;
  margin-left: 20px;
}
.table-general__pagination-container .custom-table-pagination-total-rows {
  margin-left: 10px;
  padding: 11px;
}
.custom-table-hide-column-btn i.large.icon {
  line-height: 0.83333333em;
  height: 0.83333333em;
}
.table__span-max-width-fit-content {
  max-width: fit-content !important;
}
.custom-table tbody tr:last-child td {
  border-bottom: 1px solid #2224261a;
}
.modal-content.user-imports .custom-table thead tr th:first-of-type {
  min-width: 0 !important;
}
.modal-content.user-imports .custom-table td span {
  white-space: pre-wrap;
}
.filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 97%;
  margin-top: -8px;
  padding: 0px 15px !important;
  z-index: 10;
}
.filters-wrapper .filter {
  margin-top: 8px;
  margin-right: 16px;
  min-height: 38px;
  height: 38px;
}
.filters-wrapper .filter-dates .input {
  min-width: max-content;
}
.filters-wrapper .planit-user-dropdown div.text {
  font-weight: normal !important;
}
.table__filters-share-main-actions {
  margin-bottom: -36px !important;
  padding: 0px 15px !important;
}
.ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}
.ui.secondary.stackable.right.floated.custom-table-main-actions.menu div .fitted.item .ui.mini.icon.button.custom-table-add-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
@media all and (max-height: 750px) {
  .table__flex-container {
    overflow: scroll;
    height: 58vh;
  }
}
@media all and (min-width: 1300px) {
  .custom-table td span .table__short-text {
    max-width: 170px;
  }
}
.main-tab {
  height: 100%;
  width: 100%;
  padding: 0px 15px;
}
.main-tab .ui.pointing.secondary.menu {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 3px solid #474b4f;
}
.main-tab .ui.pointing.secondary.menu .item {
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  border-bottom: 3px solid #474b4f;
  margin: 0em 0em -3px;
}
.main-tab .ui.pointing.secondary.menu .active.item,
.main-tab .ui.pointing.secondary.menu .active.item:hover {
  margin: 0em 0em -3px;
  border-bottom-width: 3px;
  color: #00b2a9;
  border-bottom-color: #00b2a9;
}
.main-tab .ui.pointing.secondary.menu .item:hover {
  margin: 0em 0em -3px;
  border-bottom-width: 3px;
  color: #00b2a9;
  border-bottom-color: #00b2a9;
}
.main-tab .ui.segment.active.tab {
  height: 99%;
  border: none;
  background-color: transparent;
  border-style: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.main-tab.sub-tab .ui.pointing.secondary.menu {
  border-bottom: none !important;
}
.main-tab.sub-tab .ui.pointing.secondary.menu .active.item,
.main-tab.sub-tab .ui.pointing.secondary.menu .active.item:hover {
  color: #b0b0b0;
  border-bottom-color: #b0b0b0;
}
.main-tab.sub-tab .ui.pointing.secondary.menu .item:hover {
  color: #b0b0b0;
  border-bottom-color: #b0b0b0;
}
.useful-link-form-group .field {
  z-index: 11;
}
.custom-rte.rte-30vh .public-DraftEditor-content {
  max-height: 30vh;
  height: 30vh !important;
}
.custom-check-box.custom-position {
  margin-top: 29px !important;
}
.new-event-type__color-picker-wrap {
  max-width: 60px;
}
.ui.mini.vertical.menu.new-event-type__custom-menu {
  width: 100%;
  margin: 0 !important;
}
#left-column > div:nth-child(1) > div.right.aligned.middle.aligned.five.wide.computer.four.wide.widescreen.column.field-title.required.field {
  max-width: 88px !important;
  line-height: 18px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.ui.buttons.filter-button-group {
  width: 150px;
}
.ui.buttons.filter-button-group.three-btns {
  width: 245px;
}
.ui.buttons.filter-button-group.fix-height .button {
  height: 2.9em;
}
.ui.buttons.filter-button-group.green .button.active {
  color: white !important;
  font-weight: 700 !important;
  letter-spacing: 0.24px;
  background-color: #00b2a9 !important;
  border: 1px solid #00b2a9 !important;
}
.ui.buttons.filter-button-group.green .button.active.gray-border {
  border: 1px solid #00b2a9 !important;
}
.ui.buttons.filter-button-group .button:not(.active) {
  color: #b0b0b0 !important;
  font-weight: 700 !important;
  letter-spacing: 0.24px;
  background-color: white !important;
}
.ui.buttons.filter-button-group .button:not(.active).gray-border {
  color: #474b4f !important;
}
.ui.buttons.filter-button-group .button.active {
  background-color: #474b4f !important;
  color: white !important;
  font-weight: 700 !important;
  letter-spacing: 0.24px;
  border: 1px solid #474b4f;
}
.ui.buttons.filter-button-group .button.active.gray-border {
  border: 1px solid #474b4f !important;
}
.ui.buttons.filter-button-group .button:first-child {
  border-right: 1px solid #474b4f;
  border-top: 1px solid #474b4f;
  border-bottom: 1px solid #474b4f;
  border-left: 1px solid #474b4f;
  width: 52.01px !important;
}
.ui.buttons.filter-button-group .button:first-child.gray-border {
  border-right: 1px solid #dedfdf;
  border-top: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
  border-left: 1px solid #dedfdf;
}
.ui.buttons.filter-button-group .button.middle {
  border-top: 1px solid #474b4f;
  border-right: 1px solid #474b4f;
  border-bottom: 1px solid #474b4f;
  width: 75px !important;
  padding: 11px !important;
}
.ui.buttons.filter-button-group .button.middle.gray-border {
  border-top: 1px solid #dedfdf;
  border-right: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
}
.ui.buttons.filter-button-group .button:last-child {
  border-right: 1px solid #474b4f;
  border-top: 1px solid #474b4f;
  border-bottom: 1px solid #474b4f;
  border-left: 0 solid;
  width: 52.01px !important;
}
.ui.buttons.filter-button-group .button:last-child.gray-border {
  border-right: 1px solid #dedfdf;
  border-top: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
}
.event-wrapper {
  padding: 0px 15px !important;
}
.event-types-list-wrapper {
  padding: 0px 0px 0px 15px !important;
}
.event-types-options-list-wrapper {
  padding: 0px 15px 0px 0px !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.event-types-list-grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0px !important;
}
.event-types-list-grid .event-types-list-filter-row {
  padding: 0 !important;
}
.event-types-list-grid .event-types-list-filter-row .form {
  width: 100%;
}
.event-types-list-grid .event-types-list-filter-row .form .fields {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .field div.default.text,
.event-types-list-grid .event-types-list-filter-row .form .fields .field input::-webkit-input-placeholder {
  color: #b0b0b0 !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .field div.default.text,
.event-types-list-grid .event-types-list-filter-row .form .fields .field input:-ms-input-placeholder {
  color: #b0b0b0 !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .field div.default.text,
.event-types-list-grid .event-types-list-filter-row .form .fields .field input::-ms-input-placeholder {
  color: #b0b0b0 !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .field div.default.text,
.event-types-list-grid .event-types-list-filter-row .form .fields .field input::placeholder {
  color: #b0b0b0 !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .field div.input > input {
  width: 175px;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .field div.ui.dropdown {
  min-width: 150px;
}
.event-types-list-grid .event-types-list-filter-row .form .fields div.ui.dropdown {
  max-height: 2.96428571em;
}
.event-types-list-grid .event-types-list-filter-row .form .fields #event-type-search-input div.ui.icon.input {
  height: 2.964em !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .event-type-filter-icon-btn {
  height: 38px !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields #add-event-type {
  height: 48px !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields #add-event-type button {
  height: 38px !important;
  padding: 7.5% 18px;
}
.event-types-list-grid .event-types-list-filter-row .form .fields #add-event-type button i {
  margin: 0;
  margin-left: 5px;
  color: white;
  opacity: 1;
  font-size: 1.2rem;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .new-event-btn-form button {
  min-width: 125px !important;
  max-width: 125px !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .event-type-hide-filters-btn {
  padding-top: 0.7rem;
  padding-bottom: 0.65rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  line-height: 1;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .event-type-hide-filters-btn > i.icons i.corner.hide-filter {
  text-shadow: unset !important;
  color: white !important;
  opacity: 1;
  background-color: red;
  border-radius: 1em;
  font-size: 0.7rem;
  height: 12px;
  width: 12px;
  line-height: 11px;
  left: 44%;
  bottom: 10%;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .event-type-hide-filters-btn > i.icons i:not(.corner) {
  font-size: 1.6rem;
  color: white;
  opacity: 1;
  margin: 0 !important;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .event-type-filter-by-update-flag-btn {
  padding-top: 0.7rem;
  padding-bottom: 0.65rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  line-height: 1;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .event-type-filter-by-update-flag-btn > i.icons i.corner.hide-filter {
  text-shadow: unset !important;
  color: white !important;
  opacity: 1;
  background-color: red;
  border-radius: 1em;
  font-size: 0.7rem;
  height: 12px;
  width: 12px;
  line-height: 11px;
  left: 44%;
  bottom: 10%;
}
.event-types-list-grid .event-types-list-filter-row .form .fields .event-type-filter-by-update-flag-btn > i.icons i:not(.corner) {
  font-size: 1.6rem;
  color: white;
  opacity: 1;
  margin: 0 !important;
}
.event-types-list-grid .event-types-list-error-row .ui.error.message {
  width: 100%;
}
.event-types-list-grid .events-home-page__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(5) {
  min-width: 130px !important;
}
.event-types-list-grid .events-home-page__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(9) {
  min-width: 130px !important;
}
.event-types-list-grid .events-home-page__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(11) {
  min-width: 160px !important;
}
.event-types-list-grid .events-home-page__table .request-event__filter-buttons {
  margin-right: 6px;
}
.event-types-list-grid .events-home-page__table .ui.secondary.stackable.right.floated.custom-table-main-actions.menu .fitted.item {
  align-self: flex-start !important;
  margin: 6px 0px 0px 16px;
}
.event-types-list-grid .event-types-list-items-row {
  min-height: calc(100vh - 16em);
  max-height: calc(100vh - 16em);
  padding: 0 !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item {
  min-height: 145px !important;
  max-height: fit-content !important;
  background-color: #eeeeee;
  margin: 1em 0;
  border-radius: 8px;
  padding: 4px 4px 4px 20px;
  display: block !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item.inactive-event-type__item {
  opacity: 0.4;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .row-0 {
  padding-bottom: 10 !important;
  height: 30% !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .row-0 h3 {
  margin: 0 0 0 10px;
  font-size: 16px;
  text-transform: none !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .row-0 .label {
  max-height: 2em !important;
}
@media only screen and (min-width: 1600px) {
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .column-1.column-1.column-1 {
    width: 18% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column {
    width: 37% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-2 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-3 {
    width: 22% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-4 {
    width: 16% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-5 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .column-6.column-6.column-6 {
    width: 33% !important;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1600px) {
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .column-1.column-1.column-1 {
    width: 18% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column {
    width: 40% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-2 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-3 {
    width: 27% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-4 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-5 {
    width: 23% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .column-6.column-6.column-6 {
    width: 30% !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .column-1.column-1.column-1 {
    width: 18% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column {
    width: 40% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-2 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-3 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-4 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column.central-column.central-column .column-5 {
    width: 25% !important;
  }
  .event-types-list-grid .event-types-list-items-row .event-types-list-item .column-6.column-6.column-6 {
    width: 30% !important;
  }
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .row-1 {
  height: 70% !important;
  padding-top: 0 !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-1 {
  width: 20% !important;
  padding-left: 25px !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column {
  width: 35% !important;
  padding: 0 10px !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-2 {
  width: 26% !important;
  padding: 12px 6px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-2 h5 {
  margin-bottom: 0.4em;
  font-size: 14px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-3 {
  width: 23% !important;
  padding: 12px 6px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-3 h5 {
  margin-bottom: 0.4em;
  font-size: 14px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-4 {
  width: 17% !important;
  padding: 12px 6px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-4 h5 {
  margin-bottom: 0.4em;
  font-size: 14px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-5 {
  width: 30% !important;
  padding: 12px 6px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .central-column .column-5 h5 {
  margin-bottom: 0.4em;
  font-size: 14px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-6 {
  width: 33% !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-6 h5 {
  margin-bottom: 0.4em;
  font-size: 14px;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 {
  width: 10% !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox {
  min-height: 1.3rem !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox input {
  height: 1.3rem !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox .box,
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox label {
  min-height: 1.3rem !important;
  padding-left: 3rem !important;
  width: 1rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox .box:after,
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox label:after {
  width: 1.3rem !important;
  height: 1.3rem !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox .box:before,
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox label:before {
  width: 2rem !important;
  height: 1.3rem !important;
  border-radius: 1.3rem !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox input:checked ~ .box:after,
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 .ui.toggle.checkbox input:checked ~ label:after {
  left: 0.9rem !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .column-7 i.ellipsis {
  margin-left: 8%;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .event-type-label {
  margin: 2px;
  line-height: 18px;
  padding: 0.2em 0.8em !important;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .event-type-label.label-1 {
  background-color: white;
  border: 1px solid #00b2a9;
  color: #00b2a9;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .event-type-label.label-2 {
  color: white;
  border: 1px solid #00b2a9;
  background-color: #00b2a9;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .event-type-label.label-3 {
  color: #222629;
  border: 1px solid #222629;
  background-color: transparent;
}
.event-types-list-grid .event-types-list-items-row .event-types-list-item .event-type-label.label-4 {
  color: #474b4f;
  border: 1px solid #dedfdf;
  background-color: #dedfdf;
}
.event-type-pop-up {
  padding: 5px !important;
}
.event-type-pop-up .menu.event-type-pop-menu .item {
  font-size: 14px;
  margin: 0 !important;
  padding: 7px !important;
}
#new-event-modal {
  background: linear-gradient(to left, #ffffff 56.25%, #00b2a9 43.75%);
  width: 80vw !important;
  height: 90vh;
}
#new-event-modal .error.message {
  margin: 10px 10px -18px 10px;
  z-index: 99;
}
#new-event-modal .image.content {
  min-height: 70vh;
  padding: 0 !important;
  border-radius: 8px;
}
#new-event-modal .image.content .container {
  margin: 0 !important;
  width: 100% !important;
}
#new-event-modal .image.content .container #new-event-form {
  height: 90vh;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid {
  margin: 0;
  height: 100%;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid .fields {
  margin-bottom: 0;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column {
  background-color: #00b2a9;
  border-radius: 8px 0 0 8px;
  padding: 20px 16px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .icon-selector-wrapper {
  height: 36px !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .fit-content-column {
  height: fit-content;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .editing-title {
  background-color: #00b2a9 !important;
  color: white;
  font-weight: 700;
  font-size: 14px;
  border: 1px white solid;
  border-radius: 4px;
  padding: 9px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field-title {
  font-size: 14px;
  color: white;
  letter-spacing: 0.24px;
  line-height: 2.5em;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .required.field::after {
  margin: -0.2em 0em 0em 0.2em;
  content: ' *';
  color: #db2828;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .group-title {
  font-size: 16px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.24px;
  padding-left: 13px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field {
  margin: 0 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field div.dropdown {
  min-width: 50px;
  max-width: 50px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field .dropdown.full-width {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .row.no-padding {
  padding: 0;
  margin-bottom: 1em;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .row.no-padding .required.field::after {
  display: contents;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .row.no-padding.first {
  padding-top: 1rem;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .row > div.dropdown {
  min-width: 100% !important;
  max-width: 100% !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field-group .row {
  margin-bottom: 1em;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field-group-grid:not(:last-child):not(:first-child) {
  margin: 15px 0;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field-group-grid:last-child {
  margin-top: 15px;
  margin-left: 0;
  margin-right: 0;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .field-group-grid:first-child {
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .inverted-chckbx span {
  position: relative;
  bottom: 4px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #left-column .inverted-chckbx .ui.checkbox {
  margin-right: 12px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column {
  padding: 20px 16px;
  background-color: white;
  border-radius: 0 8px 8px 0;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .green-group-title {
  color: #00b2a9;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.24px;
  margin-bottom: 0.5em;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid {
  border-bottom: 1px solid #eeeeee;
  margin-left: 0;
  margin-right: 0;
  padding: 0.5em;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.error.error.error.error.error.error * {
  color: #9f3a38 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.error.error.error.error.error.error .status-label {
  border: 1px solid #9f3a38 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.error.error.error.error.error.error i.add-status-role.add-status-role.add-status-role {
  color: #9f3a38 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.error.error.error.error.error.error i.add-config-role.add-config-role.add-config-role {
  background-color: #9f3a38 !important;
  color: whitesmoke !important;
  border: 1px solid #9f3a38;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.inactive:not(.error) * {
  color: #b0b0b0 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.inactive:not(.error) .status-label {
  border: 1px solid #b0b0b0 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.inactive:not(.error) i.add-status-role.add-status-role.add-status-role {
  color: #b0b0b0 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.inactive:not(.error) i.add-config-role.add-config-role.add-config-role {
  background-color: grey !important;
  color: #b0b0b0 !important;
  border: 1px solid grey;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid.extra-margin-bottom {
  margin-bottom: 30px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .status-row-1-p span.status-name {
  font-size: 14px;
  color: #222629;
  font-weight: bold;
  letter-spacing: 0.24px;
  margin-right: 1em;
  text-transform: uppercase;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .status-row-1-p i.add-status-role {
  margin-left: 1em;
  font-size: 19px !important;
  color: #222629 !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .status-row-1-p .status-label {
  border: 1px solid #222629;
  background-color: transparent;
  color: #222629;
  letter-spacing: 0.24px;
  font-weight: 700;
  margin: 1px !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .status-row-1-p .status-label i {
  margin: 0 !important;
  color: #222629;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox {
  min-height: 1.3rem !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox input {
  height: 1.3rem !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox .box,
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox label {
  min-height: 1.3rem !important;
  padding-left: 3rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  width: 1rem;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox .box:after,
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox label:after {
  width: 1.3rem !important;
  height: 1.3rem !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox .box:before,
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox label:before {
  width: 2rem !important;
  height: 1.3rem !important;
  border-radius: 1.3rem !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox input:checked ~ .box:after,
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-1 .ui.toggle.checkbox input:checked ~ label:after {
  left: 0.9rem !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-2 .status-row-2-p span.status-row-2-ptitle {
  font-size: 12px;
  color: #222629;
  font-weight: 700;
  letter-spacing: 0.21px;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-2 .status-row-2-p i.add-config-role {
  margin-left: 1em;
  font-size: 16px !important;
  background-color: #474b4f !important;
  border-radius: 4px !important;
  border: 1px solid #474b4f;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .field-group-grid .status-row-2 .status-row-2-p i.add-config-role.active {
  color: #00b2a9 !important;
  border: 1px solid #00b2a9;
  background-color: white !important;
}
#new-event-modal .image.content .container #new-event-form #new-event-form-grid #right-column .event-types-modal-actions {
  position: absolute;
  bottom: 32px;
  right: 16px;
}
#new-event-modal .image.content .container #new-event-form .main-tab .ui.pointing.secondary.menu {
  border: 0;
}
#new-event-modal .image.content .container #new-event-form .main-tab .ui.pointing.secondary.menu .item {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
}
#new-event-modal .image.content .container #new-event-form .main-tab .ui.pointing.secondary.menu .active.item,
#new-event-modal .image.content .container #new-event-form .main-tab .ui.pointing.secondary.menu .item:hover {
  border-bottom-color: #eaaa00;
}
.field-group-description-popup {
  width: 175px;
}
.status-actors-popup {
  padding: 4px !important;
}
.status-actors-popup .menu a.item.item.item {
  margin: 0 !important;
  z-index: 4 !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  color: #222629 !important;
  font-weight: 700;
  letter-spacing: 0.24px;
}
.status-actors-popup .menu a.item.item.item:hover {
  background-color: #474b4f !important;
  color: white !important;
}
.status-config-popup {
  padding: 12px !important;
  width: max-content !important;
  min-width: 190px !important;
  max-width: 25em !important;
}
.status-config-popup .checkbox label {
  font-size: 14px !important;
  color: #222629 !important;
  font-weight: 700;
  letter-spacing: 0.24px;
}
.status-config-popup i.popup-icon {
  color: #b0b0b0;
  font-size: 14px;
  cursor: pointer;
}
.status-config-popup i.popup-icon.active {
  color: #00b2a9;
}
.status-config-popup .content:not(.floated) {
  margin-right: 5em !important;
}
.event-workflow__item__icon {
  padding-bottom: -20px !important;
  position: absolute;
  left: 10px;
}
#event-type-request-creators a.ui.label[value='FactoryLead'] {
  pointer-events: none !important;
  height: 22px !important;
}
#event-type-request-creators a.ui.label[value='FactoryLead'] i {
  visibility: hidden;
  width: 0 !important;
}
#event-type-request-creators a.ui.label[value='FactoryLead'] i:before {
  content: '';
}
@media all and (max-width: 1700px) {
  .event-types-list-grid {
    padding: 0px !important;
  }
  .event-types-list-grid .event-types-list-filter-row .form {
    width: 100%;
  }
  .event-types-list-grid .event-types-list-filter-row .form .fields .field {
    padding-bottom: 5px;
  }
  #new-event-modal#new-event-modal#new-event-modal {
    width: 95vw !important;
  }
}
.planit-eventform-comments {
  min-width: 100%;
  min-height: 100%;
  font-weight: bold;
}
.planit-eventform-comments-readonly {
  opacity: 0.6;
}
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container {
  background-color: #474b4f;
}
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container h2 {
  color: white;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container div:not(.stepper-back) div div div a,
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container div:not(.stepper-back) div div div span {
  line-height: 24px !important;
  font-weight: 300 !important;
  letter-spacing: 0.24px !important;
}
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container div:not(.stepper-back) div div div img {
  width: 100%;
  color: white;
  font-weight: 300 !important;
  letter-spacing: 0.24px !important;
}
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container div:not(.stepper-back) div div a,
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container div:not(.stepper-back) div div div {
  font-weight: 300 !important;
  letter-spacing: 0.24px !important;
}
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container div:not(.stepper-back) div div div:empty:nth-last-child(1) {
  border-top: 2px solid #b0b0b0 !important;
}
.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container div:not(.stepper-back) div div div:empty:nth-last-child(2) {
  border-top: 2px solid #b0b0b0 !important;
}
* {
  list-style: none;
}
.form__modal__right-container__chat .ui.attached.tabular.menu {
  height: 53px;
}
.form__modal__right-container__chat .ui.dividing.header {
  margin-top: 0px !important;
}
.form__modal__right-container__chat > div:first-of-type {
  height: 100%;
}
.form__modal__right-container__chat > div:first-of-type .tab.tab-panes-chat {
  height: calc(100% - 53px);
}
.form__modal__right-container__chat > div:first-of-type .tab.tab-panes-chat .dimmed.dimmable {
  height: calc(100% - 95px);
  background-color: #eeeeee;
}
.form__modal__right-container__chat > div:first-of-type .tab.tab-panes-chat .ui.segment {
  margin-bottom: 0px !important;
}
.form__modal__right-container__chat > div:first-of-type .tab.tab-panes-chat .chat-div {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #eeeeee;
  scroll-behavior: smooth;
  transition: 0.9s;
  border: none;
  border-style: none;
  max-height: 590px;
  min-height: 100%;
}
.events-chat__write-section {
  background-color: #f9f9f9 !important;
  height: 95px;
}
.events-chat__write-section .field {
  height: 100%;
}
.events-chat__write-section .field .chat-input-div {
  width: 100%;
  height: 100%;
}
.events-chat__write-section .field .chat-input-div .chat__textarea-message {
  padding: 20px;
  padding-right: 20%;
  resize: none;
  font-weight: bold;
  line-height: 14px;
  overflow-y: hidden;
  border: none;
  border-radius: 0px;
  background-color: transparent;
  height: 100%;
}
.tab-panes-chat {
  background-color: #eeeeee !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: none !important;
  min-height: 95%;
}
.chat__textarea-container {
  padding-right: 0px;
  width: 100%;
  overflow-x: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  resize: none;
}
.chat__textarea-message {
  padding-right: 20% !important;
  font-weight: 600;
  background-color: #f9f9f9 !important;
  border: none !important;
}
.chat-button-div {
  width: 20%;
  padding-bottom: 5px;
  position: absolute;
  right: 16px;
  top: 14px;
  padding-left: 20px;
}
.chat-button-div .chat-button {
  align-content: right;
  align-items: right;
  height: 100%;
}
.comment-own-color {
  background-color: #e2d5b1;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: left;
  margin-left: 3em;
  width: 85%;
  border-radius: 3px;
}
.comment-own-color .comment {
  padding-right: 5px !important;
}
.comment .text {
  font-weight: bold !important;
}
.comment .text.other-author {
  color: #59cba8 !important;
}
.comment-basic-color {
  background-color: #ffffff;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 0.1em;
  text-align: left;
  width: 85%;
  border-radius: 3px;
}
.comment-system-color {
  background-color: #8cb2df;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 0.1em;
  text-align: left;
  width: 85%;
  border-radius: 3px;
}
.comment-metadata {
  text-align: right !important;
  padding-right: 10px;
  padding-bottom: 5px;
}
.chat-container-btn {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  border-radius: 0% !important;
}
.chat-div-request__users-icon-btn {
  background-color: #474b4f !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 100% !important;
  color: white;
}
.chat-div__users-icon-btn {
  background-color: #474b4f !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 100% !important;
  color: white;
  padding-top: 5px !important;
}
.chat-div__users-icon {
  color: white !important;
  font-size: 18px;
  vertical-align: middle !important;
}
.chat-div__users-icon-btn-students {
  background-color: #8031a7 !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 100% !important;
  color: white;
  padding-top: 5px !important;
}
.chat-div__users-icon-students {
  color: white !important;
  background: purple;
  font-size: 18px;
}
.chat-selected-tab {
  background-color: #eeeeee !important;
}
.form__request-chat-container > div:first-of-type {
  height: 90%;
}
.form__request-chat-container .ui.segment.dimmed.dimmable {
  margin-bottom: 0px !important;
  background-color: #eeeeee !important;
}
.form__request-chat-container .chat-div.scroll {
  min-height: 100%;
}
@media all and (min-height: 700px) {
  .form__modal__right-container__chat .chat-div {
    max-height: 800px !important;
  }
}
.employee-search__modal {
  transform: translateY(10vh);
}
.employee-search__modal .ui.custom-table thead tr th:first-of-type {
  min-width: unset !important;
}
.employee-search__modal .full-width.table__flex-container {
  height: fit-content;
}
.employee-search__modal .table__flex-container {
  overflow-x: hidden;
}
.employee-search__modal .employee-search-filter {
  margin-right: 12px;
  margin-bottom: 12px;
}
.employee-search__modal .ui.inline.dropdown.employee-search-filter .dropdown.icon {
  margin: -1em;
}
.employee-search__modal .search__modal__header.header {
  border-bottom: 1px solid rgba(181, 181, 181, 0.342) !important;
}
.employee-search__modal .custom-table-main-actions {
  display: none !important;
}
.ui.container.student-list-table {
  min-height: 40vh;
}
.student-list-table .table__flex-container {
  max-height: 50vh !important;
}
.choose-instructor-form__table-wrapper .full-width.table__flex-container {
  height: 40vh;
  overflow-x: hidden;
}
.choose-instructor-form__table-wrapper .ui.right.floated.menu {
  display: none;
}
.ui.header.modal-header-title {
  padding-top: 1rem;
  padding-left: 2rem;
  letter-spacing: 0.28px;
  color: #222629;
  opacity: 1;
}
.ui.modal > .content.modal-content {
  padding-left: 3rem;
  padding-right: 3rem;
}
.add-participants-button {
  background: #00b2a9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  min-width: 125px;
}
.selected-icon-cell {
  width: 50px;
}
.ui.celled.grid.students-list-grid {
  min-width: fit-content;
  margin-top: 0px;
  margin-left: 5px;
  box-shadow: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ui.celled.grid.students-list-grid div.row:first-child {
  border-radius: 4px 4px 0px 0px !important;
  border-top: 1px solid #dedfdf !important;
}
.ui.celled.grid.students-list-grid div.row:last-child {
  border-bottom: 1px solid #dedfdf !important;
  border-bottom-right-radius: 4px;
}
.ui.celled.grid.students-list-grid div.row {
  box-shadow: none;
  border-bottom: 0.5px solid #dedfdf !important;
  border-top: 0.5px solid #dedfdf !important;
  border-left: 1px solid #dedfdf !important;
  border-right: 1px solid #dedfdf !important;
}
.ui.celled.grid.students-list-grid div.row > .column {
  padding: 6px;
  box-shadow: none;
}
.ui.celled.grid.students-list-grid div.row > div.center.aligned.one.wide.column {
  width: 23px !important;
}
.ui.table.events-form-requests-list {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
}
.ui.table.events-form-requests-list > thead > tr > th {
  color: black;
  font-weight: normal;
  padding: 6px;
  padding-bottom: 8px !important;
}
.ui.table.events-form-requests-list > tbody > tr > td {
  border-top: none;
  padding: 6px;
}
.ui.table.events-form-requests-list > tbody > tr.bold-row {
  font-weight: bold;
}
.ui.table.events-form-requests-list > thead > tr > th:first-child {
  padding-left: 1em !important;
}
.ui.table.events-form-requests-list > tbody > tr > td:first-child {
  padding-left: 1em !important;
}
.ui.grid.event-pause-period-grid {
  margin-left: 0;
  margin-right: 0;
}
.ui.grid.event-pause-period-grid > div.row {
  padding-top: 3px;
  padding-bottom: 3px;
}
.ui.grid.event-pause-period-grid > div.row > div.column {
  padding-right: 4px;
  padding-left: 4px;
}
.ui.grid.event-pause-period-grid div.ui.dropdown,
.ui.grid.event-pause-period-grid div.ui.fields,
.ui.grid.event-pause-period-grid div.inline.fields,
.ui.grid.event-pause-period-grid input,
.ui.grid.event-pause-period-grid div.planit-events-periods {
  height: 32px;
}
.requests-events-wizard___modal {
  min-width: 80%;
}
.requests-events-wizard___modal > div.content {
  min-height: 50vh;
}
.requests-events-wizard___modal .wizard__form__margin {
  margin-top: 39px;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  padding: 10px 32px 32px !important;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .hidden {
  display: none;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .wizard__go-back-btn {
  min-width: 100px;
  color: #474b4f;
  background-color: #dedfdf;
  min-height: 32px;
  padding: 0px;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .wizard__go-back-btn .icon {
  background-color: #dedfdf !important;
  padding: 0px !important;
  font-weight: 600;
  font-size: 14px;
  color: #474b4f;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .wizard__go-back-btn.hidden {
  visibility: hidden !important;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .wizard__go-back-btn:hover {
  background-color: #dedfdf !important;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .ui.positive.button {
  margin-left: 16px;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .form__align-right-btns {
  width: 100%;
}
.requests-events-wizard___modal .actions.wizard__action-buttons-wrapper .multi__wizard__dropdown.ui.inline.dropdown .dropdown.icon {
  margin: -10px 0px !important;
}
.requests-events-wizard___modal .form__header__row-icon {
  color: transparent;
}
.requests-events-wizard___modal .wizard__basic-step__container {
  margin-top: 39px;
}
.requests-events-wizard___modal .wizard__step2__machine-related h3 {
  font-size: 14px;
}
.requests-events-wizard___modal .wizard__step2__machine-related .wizard__step2__machine-related__content .wizard__mr-table .mr-table__column-title {
  background-color: transparent !important;
  border-bottom: 1px solid #dedfdf;
}
.requests-events-wizard___modal .wizard__step2__machine-related .wizard__step2__machine-related__content .wizard__mr-table .event-details-tab__mr-table__remove {
  padding-left: 10px;
  padding-bottom: 17px;
}
.requests-events-wizard___modal .wizard__step2__machine-related .wizard__step2__machine-related__content .wizard__mr-table .border-bottom-gray:nth-last-of-type(-n + 7) {
  border-bottom: 1px solid transparent !important;
}
.requests-events-wizard___modal .wizard__students-step__container {
  padding: 0px 11%;
}
.requests-events-wizard___modal .wizard__students-step__container .form__header__row .form__header__row-icon {
  display: none;
}
.requests-events-wizard___modal .wizard__students-step__container .students-tab__students-table {
  padding: 0px;
}
.requests-events-wizard___modal .ui.icon.button.form__actions__button.wizard__save-draft-btn {
  background-color: white !important;
  color: #00b2a9 !important;
  border: 1px solid #00b2a9 !important;
  padding: 5px 20px 5px 12px !important;
  min-width: 128px;
  margin-left: 16px !important;
  text-align: left;
  font-weight: 500;
}
.requests-events-wizard___modal .ui.icon.button.form__actions__button.wizard__save-draft-btn .icon {
  color: #00b2a9;
  background-color: transparent !important;
  padding: 0px 0px 0px 6px !important;
  font-size: 16px;
}
.requests-events-wizard___modal .visible.menu.transition::-webkit-scrollbar {
  width: 4px;
}
.requests-events-wizard___modal .visible.menu.transition::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.requests-events-wizard___modal .visible.menu.transition::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.356);
}
.request-wizard__step5-instructors__container {
  margin-top: 39px;
}
.ui.input.request-title__input {
  width: 545px;
}
.wizard__action-warning {
  margin: 20px 0px 10px 20px !important;
}
.wizard__action-warning:hover {
  min-width: fit-content;
  width: 200px;
}
.user-import-view-grid .user-import-view-action-row {
  padding: 0 !important;
}
.user-import-view-grid .user-import-view-action-row .form {
  width: 100%;
}
.user-import-view-grid .user-import-view-action-row .form .fields .user-import-action-icon-btn > i.icons i.corner.hide-filter {
  opacity: 1;
}
.user-import-view-grid .user-import-view-action-row .form .fields .user-import-action-icon-btn > i.icons i:not(.corner) {
  margin: 0 !important;
}
.user-import-view-grid .user-import-view-error-row .ui.error.message {
  width: 100%;
}
.user-import-view-grid .user-import-view-items-row {
  padding: 0 !important;
}
.user-import-action-icon-btn {
  height: 38px;
  width: 38px;
}
div.row.user-import-view-items-row > div > div.ui.secondary.stackable.right.floated.custom-table-main-actions.menu .item {
  height: 0 !important;
}
.user-import__matcher_btn {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1em;
}
.user-import__matcher_btn_icon {
  font-style: normal;
  font-size: xx-large;
  font-weight: bold;
}
div.right.aligned.row.user-import-view-action-row > form > div {
  justify-content: flex-end;
}
.user-import-view-items-row {
  font-weight: bold;
}
.user-import__table__sub-row-style {
  color: #b0b0b0;
}
.text__red {
  color: red !important;
}
.users__instructor-expertise-tab__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(3) {
  min-width: 100px !important;
}
.users__instructor-expertise-tab__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(5) {
  min-width: 130px !important;
}
.users__instructor-expertise-tab__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(9) {
  min-width: 150px !important;
}
.first-child-form-modal {
  margin-bottom: 0.33333333rem !important;
}
.configuration-event-n-request__modal .ui.form {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  flex-direction: row !important;
}
.configuration-event-n-request__modal .ui.form .search.selection.dropdown.custom-editor {
  max-width: 100px;
}
.configuration-event-n-request__modal .ui.form .search.multiple.selection.dropdown {
  max-width: 250px;
}
.configuration-event-n-request__modal .configuration-event-n-request__modal__element {
  margin-bottom: 0px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.configuration-event-n-request__modal .configuration-event-n-request__modal__element label {
  min-width: fit-content;
  margin-left: 5px;
}
.configuration-event-n-request__modal .configuration-event-n-request__modal__element .field {
  display: flex !important;
  align-items: center !important;
}
.configuration-event-n-request__modal .configuration-event-n-request__modal__element .field .input {
  min-width: 150px;
  margin-left: 10px !important;
}
.merge-event-form .event-modal__first-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}
.merge-event-form .event-modal__first-row p {
  width: fit-content;
  max-width: 160px;
  line-height: 15px;
  margin-right: 0px;
}
.merge-event-form .form__modal__left-container {
  width: 100%;
}
.merge-event-form .event-modal__second-row {
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.merge-event-form .event-modal__second-row p {
  margin: 0px 5px 0px 0px !important;
  align-self: center;
  min-width: 90px;
  max-width: fit-content !important;
}
.merge-event-form .event-modal__second-row .event-form__inputs-dates-wrapper .planit-eventform-dates.eventform-dates__first {
  width: 100px;
}
.merge-event-form .event-modal__second-row .event-form__inputs-dates-wrapper .planit-eventform-dates.eventform-dates__first > div > input[type='text'] {
  width: fit-content;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 35px !important;
}
.merge-event-form .event-modal__second-row .event-form__inputs-dates-wrapper .planit-eventform-dates.eventform-dates__second {
  width: fit-content;
}
.merge-event-form .event-modal__second-row .event-form__inputs-dates-wrapper .planit-eventform-dates.eventform-dates__second > div > input[type='text'] {
  min-width: 115px !important;
  width: 115px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 35px !important;
}
.merge-event-form .event-form-merge__draft-third-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  display: grid;
}
.merge-event-form .event-form-merge__draft-third-row div {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.merge-event-form .event-form-merge__draft-third-row p {
  margin: 0px !important;
  max-width: auto !important;
  min-width: fit-content !important;
  margin-right: 5px !important;
}
.merge-event-form .merge-event-body .event-modal__second-row {
  margin-bottom: 15px;
}
.merge-event-form .event-form-merge__numeric-input {
  width: 70px;
  height: 32px;
  margin-right: 10px;
}
.merge-event-form .event-details__all-config-container {
  display: flex !important;
  justify-content: space-around !important;
}
.merge-event-form .eventsform__training__first-row__info__edit-mode p {
  min-width: fit-content;
  padding-top: 12px;
}
.merge-event-form .eventsform__training__second-row__info p {
  margin-right: 23px;
}
.merge-event-form .eventform__instructortab__container {
  grid-template-columns: auto auto auto auto auto auto 20px;
  grid-auto-rows: minmax(min-content, max-content);
  column-gap: 5px;
  row-gap: 5px;
  width: 100%;
  min-height: 75%;
}
.merge-event-form .eventform__instructortab__container p {
  min-width: fit-content;
}
.merge-event-form .eventform__instructortab__container .ui.selection.dropdown.planit-eventform-inputs {
  width: 150px;
  height: 40px;
}
.merge-event-form .merge-event-form {
  margin: 20px;
}
.merge-event-form .merge-event-form .border-header {
  padding: 10px;
}
.merge-event-form .merge-event-body {
  overflow-x: hidden;
  overflow-y: scroll;
}
.merge-event-form .merge-event-body p {
  margin: 0em 0em 1em;
  line-height: 2.4285em;
}
.merge-event-form .merge-event-body .first-row__event-type__circle {
  margin: 5px;
}
.merge-event-form .merge-event-body .event-form-input-large,
.merge-event-form .merge-event-body .planit-eventform-dates {
  margin-left: 10px;
}
.merge-event-form .merge-event-body .inline {
  display: inline-flex;
}
.question-bank__wrapper {
  padding: 0px !important;
  font-size: 1rem;
}
.question-bank__wrapper .question-bank__language__div {
  display: flex;
}
.question-bank__wrapper .custom-table-main-actions {
  margin-left: 30px !important;
}
.question-bank__wrapper .custom-table-main-actions .custom-table-add-btn .icon {
  display: none;
}
.question-bank__wrapper .ui.table.custom-table th:nth-child(1) {
  min-width: fit-content !important;
  width: 25px !important;
}
.question-bank__wrapper .ui.table.custom-table th {
  min-width: fit-content !important;
}
.question-bank__wrapper .ui.table.custom-table th:nth-child(2) {
  min-width: fit-content !important;
  width: fit-content !important;
  max-width: 359px !important;
}
.question-bank__wrapper .ui.table.custom-table td:nth-child(2) > div {
  max-width: 359px !important;
  min-width: 260px !important;
  width: fit-content !important;
}
.question-bank__wrapper .ui.table.custom-table > tbody > tr > td:nth-child(5) > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.question-bank__wrapper .ui.table.custom-table .table-cell__more-width {
  min-width: 240px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.question-bank__wrapper .question-bank__cell__tag {
  font-weight: 500;
  background-color: #eeeeee;
  margin: 5px;
  color: #474b4f;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question-bank__wrapper .content_transparent {
  height: 22px;
}
.question-bank__wrapper .popup a {
  font-weight: bold !important;
}
.question-bank__wrapper .custom-table-upload-btn.black-btn {
  background-color: white !important;
  color: #474b4f !important;
  border: 1.3px solid #474b4f !important;
}
.question-bank__wrapper .ui.mini.icon.button.custom-table-upload-btn.custom-table-upload-btn__up-down.transparent-btn {
  background-color: white !important;
  color: #474b4f !important;
  border: 1.3px solid #474b4f !important;
}
.question-bank__wrapper .custom-table-upload-btn:hover {
  color: #00b2a9 !important;
  border: 1px solid #00b2a9 !important;
}
.question-bank__wrapper .ui.mini.icon.button.custom-table-upload-btn.custom-table-upload-btn__up-down.transparent-btn:hover {
  color: #00b2a9 !important;
  border: 1px solid #00b2a9 !important;
}
@media (max-height: 750px) {
  .question-bank__wrapper .table__flex-container {
    overflow: scroll;
    height: 54vh;
  }
}
@media (min-height: 750px) {
  .question-bank__wrapper .table__flex-container {
    overflow: scroll;
    height: 64vh;
  }
}
.tna__inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  z-index: 10;
}
.tna__inputs-wrapper .ui.search.selection.dropdown.custom-editor {
  min-width: 180px !important;
  width: 180px !important;
  height: 38px !important;
  margin-right: 12px;
  margin-top: 10px;
}
.tna__inputs-wrapper .ui.input:first-child {
  margin-right: 12px;
  width: 200px;
  margin-top: 10px;
  max-height: 38px;
}
.tna__inputs-wrapper .ui.inline.dropdown > .text {
  font-weight: 100 !important;
}
.tna__inputs-wrapper .planit-users-inputs.planit-user-dropdown.planit-user-selector {
  min-width: 160px !important;
  margin-right: 12px;
  margin-top: 10px;
  height: 38px;
}
.tna__inputs-wrapper .planit-users-inputs.planit-user-dropdown.planit-user-selector i {
  color: grey;
}
.tna__inputs-wrapper .question-bank__multiple-dropdown {
  min-width: 180px;
  max-width: 460px;
  min-height: 38px;
  margin-top: 10px;
  margin-right: 12px;
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}
.tna__inputs-wrapper .question-bank__multiple-dropdown .icon {
  color: #474b4fbd;
}
.tna__inputs-wrapper .question-bank__multiple-dropdown a.ui.label {
  border: 1px solid #474b4f;
  border-radius: 11px;
  background-color: transparent;
}
.tna__inputs-wrapper .question-bank__multiple-dropdown a.ui.label > .delete.icon {
  align-self: center !important;
  margin-bottom: 1.5px !important;
  color: white !important;
  background-color: #474b4f !important;
  opacity: 1 !important;
  border-radius: 100% !important;
}
.tna__inputs-wrapper .question-bank__multiple-dropdown i.delete.icon::before {
  color: white !important;
  background-color: #474b4f !important;
  opacity: 1 !important;
  border-radius: 100% !important;
  font-size: 7px;
  padding: 4px !important;
  transition: opacity 0.1s ease;
  z-index: 3 !important;
  margin-bottom: 1.5px !important;
}
.ssa__inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}
.ssa__inputs-wrapper .ui.search.selection.dropdown.custom-editor {
  min-width: 180px !important;
  width: 180px !important;
  height: 38px !important;
  margin-right: 12px;
  margin-top: 10px;
}
.ssa__inputs-wrapper .ui.input:first-child {
  margin-right: 12px;
  width: 200px;
  margin-top: 10px;
  max-height: 38px;
}
.ssa__inputs-wrapper .ui.inline.dropdown > .text {
  font-weight: 100 !important;
}
.ssa__inputs-wrapper .planit-users-inputs.planit-user-dropdown.planit-user-selector {
  min-width: 160px !important;
  margin-right: 12px;
  margin-top: 10px;
  height: 38px;
}
.ssa__inputs-wrapper .planit-users-inputs.planit-user-dropdown.planit-user-selector i {
  color: grey;
}
.ssa__inputs-wrapper .question-bank__multiple-dropdown {
  min-width: 180px;
  max-width: 460px;
  min-height: 38px;
  margin-top: 10px;
  margin-right: 12px;
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}
.ssa__inputs-wrapper .question-bank__multiple-dropdown .icon {
  color: #474b4fbd;
}
.ssa__inputs-wrapper .question-bank__multiple-dropdown a.ui.label {
  border: 1px solid #474b4f;
  border-radius: 11px;
  background-color: transparent;
}
.ssa__inputs-wrapper .question-bank__multiple-dropdown a.ui.label > .delete.icon {
  align-self: center !important;
  margin-bottom: 1.5px !important;
  color: white !important;
  background-color: #474b4f !important;
  opacity: 1 !important;
  border-radius: 100% !important;
}
.ssa__inputs-wrapper .question-bank__multiple-dropdown i.delete.icon::before {
  color: white !important;
  background-color: #474b4f !important;
  opacity: 1 !important;
  border-radius: 100% !important;
  font-size: 7px;
  padding: 4px !important;
  transition: opacity 0.1s ease;
  z-index: 3 !important;
  margin-bottom: 1.5px !important;
}
.tna__skills__wrapper .custom-table .table__short-text {
  white-space: break-spaces;
}
.tna__skills__wrapper .ui.table.custom-table th:nth-child(3) {
  min-width: fit-content !important;
  width: fit-content !important;
  max-width: 359px !important;
}
.tna__skills__wrapper .ui.table.custom-table td:nth-child(3) > div {
  max-width: 359px !important;
  min-width: fit-content !important;
  width: fit-content !important;
}
.tna__skills__wrapper .tna__inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 72%;
}
.tna__skills__wrapper .tna__inputs-wrapper .ui.input {
  margin-right: 12px;
  width: 204px;
  margin-top: 10px;
}
.tna__skills__wrapper .tna__inputs-wrapper .ui.inline.dropdown > .text {
  font-weight: 100 !important;
  min-width: 144px;
  width: 144px;
}
.tna__skills__wrapper .tna__inputs-wrapper .planit-users-inputs.planit-user-dropdown.planit-user-selector {
  min-width: 160px !important;
  margin-right: 12px;
  margin-top: 10px;
}
.tna__skills__wrapper .tna__inputs-wrapper .planit-users-inputs.planit-user-dropdown.planit-user-selector i {
  color: grey;
}
.tna__skills__wrapper .question-bank__cell__tag {
  font-weight: 500;
  background-color: #eeeeee;
  margin: 5px;
  color: #474b4f;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tna__skills__wrapper .custom-table-main-actions {
  margin-left: 30px !important;
}
.tna__skills__wrapper .custom-table-main-actions .custom-table-add-btn .icon {
  display: none;
}
.tna__skills__wrapper .popup a {
  font-weight: bold !important;
}
.tna__skills__wrapper .planit-user-dropdown {
  width: 180px !important;
  min-height: 32px !important;
  word-break: break-all !important;
  height: fit-content;
  justify-content: center !important;
}
.tna__skills__wrapper .planit-user-dropdown-custom {
  width: 180px !important;
  min-height: 32px !important;
  height: fit-content;
  justify-content: center !important;
}
.tna-skill__label-input {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  width: fit-content !important;
  padding: 0px;
}
.tna-skill__label-input label {
  margin-right: 20px !important;
  min-width: fit-content;
  width: 108px;
  text-align: end;
}
.tna-skill__selctor-group {
  display: flex;
  justify-content: flex-end !important;
  width: fit-content;
}
.tna-skill__label-textarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: fit-content;
}
.tna-skill__label-textarea label {
  margin-right: 20px !important;
  margin-top: 10px !important;
}
.tna-skill__modal {
  border-radius: 8px;
  width: 772px !important;
  min-height: 353px !important;
  height: fit-content !important;
}
.tna-skill__custom-editorequest__textarea {
  height: 46px;
  width: 500px !important;
  resize: none !important;
}
.tna-skill__custom-editorequest__textarea.longArea {
  height: 67px;
}
.tna-skill__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 24px;
}
.tna-skill__modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.new-tna-skill__btns {
  margin-right: 24px;
}
@media all and (min-width: 1285px) {
  .tna-skill__modal {
    width: 842px !important;
  }
}
@media all and (min-width: 1285px) {
  .tna-skill__wrapper {
    margin-right: 104px;
  }
}
@media all and (min-width: 1285px) {
  .new-tna-skill__btns {
    margin-right: 32px;
  }
}
.tna-question-bank__modal {
  width: 742px !important;
}
.tna-question-bank__modal .ui.form .fields {
  margin: 0px !important;
  width: 100% !important;
}
.tna-question-bank__modal .image.content {
  padding-right: 30px !important;
}
.tna-question-bank__modal .tna-squestion-bank__modal-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.tna-question-bank__modal .tna-question-bank__wrapper {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  width: 98.3%;
  padding: 20px 0px 0px 0px !important;
  overflow-y: scroll;
  max-height: 65vh !important;
}
.tna-question-bank__modal .tna-question-bank__wrapper .tna-question-bank__selctor-group {
  padding-right: 20px;
  width: 80%;
  display: flex !important;
  justify-content: space-between !important;
}
.tna-question-bank__modal .tna-question-bank__wrapper .tna-question-bank__selctor-group .tna-question-bank__label-input {
  margin-bottom: 12px !important;
  display: flex !important;
  justify-content: flex-end;
  width: auto;
}
.tna-question-bank__modal .tna-question-bank__wrapper .tna-question-bank__selctor-group .tna-question-bank__label-input label {
  margin-right: 16px;
  width: 100px;
  text-align: right;
  margin-top: 9px;
}
.tna-question-bank__modal .tna-question-bank__selctor-textarea {
  display: flex !important;
  justify-content: center !important;
  padding-left: 15px;
}
.tna-question-bank__modal .tna-question-bank__selctor-textarea .ui.button {
  margin: 0px 0px 0px 24px !important;
}
.tna-question-bank__modal .tna-question-bank__selctor-textarea .tna-question-bank__label-textarea {
  display: flex !important;
  justify-content: flex-end !important;
  margin-bottom: 12px !important;
  width: 94%;
}
.tna-question-bank__modal .tna-question-bank__selctor-textarea .tna-question-bank__label-textarea label {
  width: 80px;
  text-align: end;
  padding-right: 16px;
  margin-top: 10px !important;
}
.tna-question-bank__modal .tna-question-bank__selctor-textarea .tna-question-bank__label-textarea .tna-question-bank__custom-editorequest__textarea {
  width: 406px;
  height: 64px;
  font-weight: 600;
  resize: none !important;
}
.tna-question-bank__modal .custom-table-add-row-btn {
  min-width: 100px;
  margin-left: 16px !important;
  max-height: 32px;
}
.tna-question-bank__modal .tna-question-bank__new-rows {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}
.tna-question-bank__modal .tna-question-bank__new-rows .tna-question-bank__label-textarea {
  display: flex !important;
  justify-content: flex-end !important;
  width: 70%;
}
.tna-question-bank__modal .tna-question-bank__new-rows .tna-question-bank__new-rows__textarea {
  width: 406px;
  height: 42px;
  margin-bottom: 12px !important;
  font-weight: 600;
  resize: none !important;
}
.tna-question-bank__modal .tna-question-bank__new-rows .tna-question-bank__new-rows__drop-remove {
  display: flex !important;
  justify-content: flex-start !important;
  width: 18.7%;
  margin-left: 16px !important;
}
.tna-question-bank__modal .tna-question-bank__new-rows .tna-question-bank__new-rows__drop-remove .search {
  min-width: 100px !important;
  width: 100px !important;
}
.tna-question-bank__modal .tna-question-bank__new-rows .tna-question-bank__new-rows__drop-remove .new-pattern__remove-icon {
  width: 5px;
  align-self: center;
}
.tna-question-bank__modal .tna-question-bank__new-rows .tna-question-bank__new-rows__drop-remove .red.remove.icon.clickable-icon.remove-icon {
  padding-top: 0px;
  margin-left: 10px !important;
}
.tna-question-bank__modal .actions.new-tna-question-bank__btns {
  margin-right: 18px !important;
  margin-top: 15px !important;
  padding-bottom: 24px !important;
}
.assessment-forms-grey-tag {
  background-color: #eeeeee;
  margin: 5px;
  color: #474b4f;
  font-weight: 500;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  border-radius: 10px;
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assessment-forms__table__tag {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 5px;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  line-height: 11;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  background-color: white;
}
.assessment-forms-employee-tag {
  color: #75a2d8;
  border: 1px #75a2d8 solid;
}
.assessment-forms-done-tag {
  color: #4bc982;
  border: 1px #4bc982 solid;
}
.assessment-forms-out-of-date-tag {
  color: #b0b0b0;
  border: 1px #b0b0b0 solid;
}
.assessment-forms-assessors-tag {
  color: #f2cc67;
  border: 1px #f2cc67 solid;
}
#assessment-forms-list-filters__title + .planit-line-separator {
  margin-bottom: 0 !important;
}
.specific-margin-bottom-equal-filters {
  margin-bottom: -49px;
}
.specific-margin-top-equal-filters {
  margin-top: 5px;
}
#assessment-forms-list-filters__admin-or-poc-container {
  display: inline-flex;
  width: calc(100% - 5px);
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container .row {
  display: flex;
  flex-wrap: wrap;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container .field {
  margin: 0px 12px 12px 0px;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container .field .planit-multi-dropdown {
  margin-bottom: unset;
  margin-right: unset;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container .field .input {
  height: 38px;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container .field .ui.search.selection.dropdown,
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container .field .ui.icon.input {
  height: 38px !important;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__lef-container .width-14em.width-14em.width-14em.width-14em {
  width: 14em !important;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__right-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: -25px;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__right-container .field {
  margin: 0.5em 0;
  height: 35.4px;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__right-container button {
  margin: 0 !important;
  height: 38px;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__right-container .orderby {
  text-align: end;
  width: fit-content;
  white-space: nowrap;
  padding-right: 55px;
  margin-top: 20px;
}
#assessment-forms-list-filters__admin-or-poc-container #assessment-forms-list-filters__admin-or-poc-container__right-container .include-users {
  padding-right: 100px !important;
}
#assessment-forms-list-filters__employee-but-no-assessor-container {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -41px !important;
}
#assessment-forms-list-filters__employee-but-no-assessor-container .field {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}
#assessment-forms-list-filters__employee-but-no-assessor-container .field .input,
#assessment-forms-list-filters__employee-but-no-assessor-container .field .search,
#assessment-forms-list-filters__employee-but-no-assessor-container .field .custom-editor {
  height: 38px;
}
#assessment-forms-list-filters__employee-but-no-assessor-container .width-14em.width-14em.width-14em.width-14em {
  width: 14em !important;
}
.assessment-forms-list-deactivate-button {
  font-weight: bold;
  color: red !important;
}
.tna-theoretical__wrapper .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
  margin-top: -47px;
}
.ui.container.employees-list-table {
  min-height: 40vh;
  margin-top: 10px;
}
.ui.container.employees-list-table > div > div.custom-table-main-actions {
  display: none;
}
.add-employees-button {
  background: #00b2a9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  min-width: 135px;
}
.template-search__modal.choose-employees .full-width.table__flex-container {
  overflow-x: scroll;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input {
  min-width: 200px;
  width: 200px;
  height: 36px !important;
  margin-bottom: 8px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.long-input {
  width: 250px;
  min-width: 250px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.feedback-form__long-input {
  width: fit-content !important;
  min-width: 210px !important;
  padding: 5px 25px 5px 5px !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  margin-bottom: 10px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.feedback-form__long-input > input {
  padding-right: 10px !important;
  padding-left: 5px !important;
  border: none;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.feedback-form__short-input {
  min-width: 180px !important;
  width: 180px !important;
  margin-bottom: 10px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown {
  min-width: 200px;
  width: 200px !important;
  margin-right: 12px;
  min-height: 36px;
  max-height: 36px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown .default.text {
  font-weight: 500 !important;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown .icon {
  color: #474b4f !important;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown .dropdown.icon {
  top: 0;
  right: 0;
}
.ui.custom-editor.dropdown.feedback-form__dropdown-input {
  min-width: 140px;
  width: 140px !important;
  margin-right: 12px;
  height: max-content;
}
.ui.custom-editor.dropdown.feedback-form__dropdown-input > div.default.text {
  font-weight: 500 !important;
}
.ui.custom-editor.dropdown.feedback-form__dropdown-input input.search {
  font-weight: 500 !important;
  min-height: fit-content !important;
  height: fit-content !important;
}
.template-search__modal .ui.table td {
  padding: 10px 0.75em;
  text-align: inherit;
}
.template-search__modal .borderless-header .arrow {
  display: none;
}
.template-search__modal .ui.header.modal-header-title {
  padding-top: 1rem;
  padding-left: 2rem;
  letter-spacing: 0.28px;
  color: #222629;
  opacity: 1;
  font-size: 16px !important;
  margin-top: 0px;
}
.template-search__modal .full-width.table__flex-container {
  overflow-x: hidden;
  margin-bottom: 16.5px;
}
.template-search__modal .template-search__first-row__column-filters {
  margin-right: 16px;
}
.template-search__modal .template-search__first-row__column-filters .input {
  min-width: 250px;
  margin-right: 12px;
}
.template-search__modal .template-search__first-row__column-filters .ui.input.template-search-filter > input {
  border: 1px solid #474b4f;
  border-radius: 4px;
}
.template-search__modal .template-search__first-row__column-filters .keep-right {
  float: right;
}
.template-search__modal .template-search__first-row__column-filters .skill-name {
  font-weight: bold;
  color: #474b4f;
  transform: translateY(50%);
}
.template-search__modal .choose-template-search__first-row__column-filters {
  margin-right: 12px !important;
}
.template-search__modal .choose-template-search__first-row__column-filters .input {
  min-width: 230px;
}
.template-search__modal .choose-template-search__first-row__column-filters .ui.input.template-search-filter > input {
  border: 1px solid #474b4f;
  border-radius: 4px;
}
.template-search__modal .ui.icon.input.custom-editor-search {
  margin-right: 12px !important;
}
.template-search__modal .custom-editor-search__names {
  min-width: 250px;
}
.template-search__modal .ui.container.template-list-table {
  min-height: 40vh;
  margin-top: 16px;
  max-height: 70vh;
}
.template-search__modal .ui.container.template-list-table label:before {
  border: 1px solid #474b4f !important;
}
.template-search__modal .ui.container.template-list-table > div > div.custom-table-main-actions {
  display: none;
}
.template-search__modal .ui.container.template-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(1) {
  min-width: 40px !important;
}
.template-search__modal .ui.container.template-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(2) {
  min-width: 40px !important;
}
.template-search__modal .ui.container.template-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(2) i {
  font-size: 16px;
}
.template-search__modal .ui.container.template-list-table > div.full-width.table__flex-container > table > tbody > tr:nth-child(1) > td:nth-child(1) {
  width: 40px;
}
.template-search__modal .ui.container.template-list-table > div.full-width.table__flex-container > table > tbody > tr:nth-child(1) > td:nth-child(2) {
  width: 130px;
}
.template-search__modal .template-list-table .full-width.table__flex-container {
  height: 52vh;
  overflow-x: hidden;
}
.template-search__modal .template-list-table .ui.right.floated.menu {
  display: none;
}
.template-search__modal .ui.search.dropdown.template-search-filter {
  border: 1px solid #474b4f !important;
  border-radius: 4px;
}
.template-search__modal .modal-back-icon {
  padding-top: 10px;
}
.template-search__modal .ui.modal > .content.modal-content {
  padding-left: 3rem;
  padding-right: 3rem;
}
.template-search__modal .actions {
  padding-left: 4rem;
  padding-right: 33px !important;
}
.template-search__modal .add-template-button {
  background: #00b2a9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  min-width: 135px;
}
.template-search__modal .selected-icon-cell {
  width: 50px;
}
.ui.container.employee-list-table {
  min-height: 52vh;
  margin-top: 16px;
}
.ui.container.employee-list-table label:before {
  border: 1px solid #474b4f !important;
}
.ui.container.employee-list-table > div > div.custom-table-main-actions {
  display: none;
}
.ui.container.employee-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(1) {
  min-width: 40px !important;
}
.ui.container.employee-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(2) {
  min-width: 150px !important;
}
.ui.container.employee-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(2) i {
  font-size: 16px;
}
.ui.container.employee-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(3) {
  min-width: 150px !important;
}
.ui.container.employee-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(4) {
  min-width: 150px !important;
}
.ui.container.employee-list-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(5) {
  min-width: 150px !important;
}
@media all and (min-width: 1400px) {
  .modal-content.content {
    padding: 26.5px 55px 50px 48px !important;
  }
  .actions {
    padding-right: 0px 55px 0px 48px !important;
  }
}
.generic-date-picker__wrapper {
  display: flex;
  align-items: center !important;
  height: 32px;
}
.generic-date-picker__wrapper .date-picker__left {
  width: 120px !important;
  height: 32px;
  margin-right: -1px !important;
}
.generic-date-picker__wrapper .date-picker__left input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  text-align: center !important;
  padding: 4px 20px 4px 2px !important;
}
.generic-date-picker__wrapper .date-picker__right {
  height: 32px;
  width: 120px;
}
.generic-date-picker__wrapper .date-picker__right input {
  text-align: center !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: -1px !important;
  padding: 4px 20px 4px 2px !important;
}
.generic-date-picker-modificable__wrapper {
  display: flex;
  align-items: center !important;
  height: 32px;
}
.generic-date-picker-modificable__wrapper .eventform-dates__first > div {
  width: 110px;
  height: 32px;
  margin-right: -1px !important;
}
.generic-date-picker-modificable__wrapper .eventform-dates__first > div > i {
  display: none;
}
.generic-date-picker-modificable__wrapper .eventform-dates__first > div > input[type='text'] {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  text-align: center;
  padding: 9px 22px 9px 22px !important;
}
.generic-date-picker-modificable__wrapper .eventform-dates__second > div {
  width: 110px;
  height: 32px;
}
.generic-date-picker-modificable__wrapper .eventform-dates__second > div > i {
  display: none;
}
.generic-date-picker-modificable__wrapper .eventform-dates__second > div > input[type='text'] {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-align: center;
  padding: 9px 22px 9px 22px !important;
}
.costs-container {
  display: flex;
  align-items: center;
}
.costs-container .cost-label {
  font-size: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
}
.costs-container .cost-label span {
  height: 14px;
}
.cost-component {
  display: flex;
  justify-content: center;
  border: 1px solid #dedfdf;
  border-radius: 4px;
  width: 90px;
  height: 32px;
  min-width: 80px;
  background-color: white;
}
.cost-component.disabled input {
  background-color: inherit;
}
.cost-component input {
  padding-left: 8px;
  outline: none;
  line-height: 0.8em;
  width: 100%;
}
.cost-component i {
  line-height: 1.5em;
  margin-top: 6px !important;
  margin-right: 6px !important;
  font-size: 12px !important;
}
