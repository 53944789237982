.tna-development-plan-by-location__wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;

  .tna-development-plan-by-location__filters {
    display: flex;
    margin-bottom: 10px;

    .custom-editor {
      margin-right: 12px;
      min-width: 150px !important;
      max-width: 150px !important;
      margin-bottom: 5px;
      min-height: 36px !important;
      height: 36px !important;
    }

    .custom-editor-search {
      margin-right: 12px;
      margin-bottom: 5px;
    }
  }

  .custom-table-main-actions.menu {
    display: none;
  }

  .ui.table.custom-table thead tr:first-child > th:first-child {
    padding-left: 15px !important;
  }

  .ui.table.custom-table tbody tr > td:first-child {
    padding-left: 15px !important;
  }
}
