.form__feedback-container .form__feedback-item {
  display: flex;
  margin-bottom: 16px;
}
.form__feedback-container .form__feedback-item p {
  margin: 0px;
}
.form__feedback-container .form__feedback-item .feedback-tab__item-title {
  font-weight: 600;
}
.form__feedback-container .form__feedback-item .button {
  background-color: #eeeeee;
}
.form__feedback-container .form__feedback-item.flex-column .feedback-tab__item-title {
  margin-bottom: 17px;
  margin-right: 24px;
}
.form__feedback-container .form__feedback-item.flex-column .feedback-tab__item__status-flag {
  background-color: #f9f9f9;
  padding: 10px;
  height: 32px;
  margin-bottom: 17px;
  border-radius: 4px;
}
.form__feedback-container .form__feedback-item.flex-start-center p {
  width: 130px;
  max-width: 152px;
}
.form__feedback-container .form__feedback-item.flex-start-center .feedback-tab__item-title {
  margin-right: 30px;
}
.have-warnings-popup.feedback__confirm-modal {
  width: 361px;
}
.have-warnings-popup.feedback__confirm-modal .feedback-modal__cancel-button {
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 500;
}
.have-warnings-popup.feedback__confirm-modal .feedback-modal__cancel-button:hover {
  background-color: whitesmoke;
  color: black;
  transition: ease-in 0.4s;
}
.have-warnings-popup.feedback__confirm-modal .feedback-modal__accept-button {
  margin-left: 10px;
}
.form__practical-table {
  overflow-x: auto;
}
.form__practical-table::-webkit-scrollbar {
  height: 4.5px;
}
.form__practical-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.form__practical-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #c4c9c9;
}
.form__practical-table p {
  margin: 0px;
  padding: 9px 9px 9px 12px;
}
.form__practical-table .feedback-table__name-column {
  background-color: #eeeeee;
  font-weight: bold;
}
.form__practical-table .feedback-table__cell {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.form__practical__admins-view {
  display: grid;
  grid-template-columns: repeat(8, 1fr) 60px;
  margin-bottom: 26.5px;
  width: 95%;
}
.form__practical__admins-view .feedback-table__comments-icon {
  color: #dedfdf;
}
.form__practical__admins-view .feedback__comments-icon__answered {
  color: #474b4f;
}
.form__practical-table__employee-view {
  display: grid;
  grid-template-columns: repeat(6, 1fr) auto;
  margin-bottom: 26.5px;
  width: 95%;
}
.feedback-generation__answered-btn.button.disabled {
  background-color: #ccf0ee !important;
  opacity: 1 !important;
  color: #00b2a9;
}
.practical-form-list__wrapper .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
  margin-top: -47px;
}
