.tna-templates-wizard__btns button.secondary {
  width: 100px;
}
.wizard__step.wizard__step-one .step-one__content {
  margin-left: 0px;
}
.wizard__step.wizard__step-one .step-one__content > label {
  font-weight: bold;
}
.wizard__step.wizard__step-one #practical-template-wizard-title {
  font-size: 12px;
}
.wizard__step.wizard__step-one textarea {
  font-size: 12px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: unset;
  padding: 0.8em 1.16em;
}
.wizard__step.wizard__step-one textarea:focus {
  border-color: #85b7d9;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.8);
  box-shadow: none;
}
.wizard__step.wizard__step-one .practical-template-wizard-adjust label {
  transform: translateY(50%);
}
.wizard__step.wizard__step-one .practical-template-wizard-adjust label:not(:first-of-type) {
  margin-left: 80px;
}
.wizard__step.wizard__step-one .practical-template-wizard-adjust .dropdown {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 38px;
  padding-top: 13px;
  padding-bottom: 9px;
  font-size: 12px;
}
.wizard__step.wizard__step-one .practical-template-wizard-adjust .dropdown i {
  padding: 0 10px !important;
  line-height: 38px !important;
}
.wizard__step.wizard__step-one #practical-template-event-type-selector {
  font-size: 12px;
}
.wizard__step.wizard__step-one #practical-template-event-type-selector label {
  transform: translateY(10px);
}
.wizard__step.wizard__step-one #practical-template-event-type-selector #practical-template-list-event-types-filter {
  min-height: 38px !important;
  height: fit-content;
  opacity: unset;
  max-width: 40.15em;
  width: 40.15em;
}
.wizard__step.wizard__step-one #practical-template-event-type-selector #practical-template-list-event-types-filter input {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 28px;
}
.wizard__step.wizard__step-one #practical-template-event-type-selector #practical-template-list-event-types-filter i {
  top: 10px;
}
