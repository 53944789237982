.form__theoretical-container .form__theoretical-item {
  display: flex;
  margin-bottom: 16px;
  padding-left: 42px;
}
.form__theoretical-container .form__theoretical-item p {
  margin: 0px;
}
.form__theoretical-container .form__theoretical-item .theoretical-tab__item-title {
  font-weight: 600;
}
.form__theoretical-container .form__theoretical-item .button {
  background-color: #eeeeee;
}
.form__theoretical-container .form__theoretical-item.flex-column {
  margin-right: 10px;
}
.form__theoretical-container .form__theoretical-item.flex-column-not-visible {
  overflow-y: scroll;
  overflow-x: hidden;
}
.form__theoretical-container .form__theoretical-item.flex-column .theoretical-tab__item-title {
  margin-bottom: 17px;
  margin-right: 24px;
}
.form__theoretical-container .form__theoretical-item.flex-column .theoretical-tab__item__status-flag {
  background-color: #f9f9f9;
  padding: 10px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  min-height: 32px;
  align-items: center;
  margin-bottom: 17px;
  border-radius: 4px;
}
.form__theoretical-container .form__theoretical-item.flex-start-center p {
  width: 130px;
  max-width: 152px;
}
.form__theoretical-container .form__theoretical-item.flex-start-center .theoretical-tab__item-title {
  margin-right: 30px;
}
.have-warnings-popup.theoretical__confirm-modal {
  width: 361px;
}
.have-warnings-popup.theoretical__confirm-modal .theoretical-modal__cancel-button {
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 500;
}
.have-warnings-popup.theoretical__confirm-modal .theoretical-modal__cancel-button:hover {
  background-color: whitesmoke;
  color: black;
  transition: ease-in 0.4s;
}
.have-warnings-popup.theoretical__confirm-modal .theoretical-modal__accept-button {
  margin-left: 10px;
}
.form__theoretical-table {
  display: grid;
  grid-template-columns: repeat(8, 1fr) 40px;
  margin-bottom: 26.5px;
  width: 95%;
  overflow-x: auto;
}
.form__theoretical-table::-webkit-scrollbar {
  height: 4.5px;
}
.form__theoretical-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.form__theoretical-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #c4c9c9;
}
.form__theoretical-table p {
  margin: 0px;
  padding: 9px 9px 9px 12px;
}
.form__theoretical-table .theoretical-table__name-column {
  background-color: #eeeeee;
  font-weight: bold;
}
.form__theoretical-table .theoretical-table__cell {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.form__theoretical-table .theoretical-table__comments-icon {
  color: #dedfdf;
}
.form__theoretical-table .theoretical__comments-icon__answered {
  color: #474b4f;
}
.form__theoretical-table-admin {
  display: grid;
  grid-template-columns: repeat(8, 1fr) 40px 40px 40px;
  margin-bottom: 26.5px;
  width: 95%;
  overflow-x: auto;
}
.form__theoretical-table-admin::-webkit-scrollbar {
  height: 4.5px;
}
.form__theoretical-table-admin::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.form__theoretical-table-admin::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #c4c9c9;
}
.form__theoretical-table-admin p {
  margin: 0px;
  padding: 9px 9px 9px 12px;
}
.form__theoretical-table-admin .theoretical-table__name-column {
  background-color: #eeeeee;
  font-weight: bold;
}
.form__theoretical-table-admin .theoretical-table__cell {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.form__theoretical-table-admin .theoretical-table__comments-icon {
  color: #dedfdf;
}
.form__theoretical-table-admin .theoretical__comments-icon__answered {
  color: #474b4f;
}
.theoretical-generation__answered-btn.button.disabled {
  background-color: #ccf0ee !important;
  opacity: 1 !important;
  color: #00b2a9;
}
.form__theoretical-table__employee-view {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-bottom: 40px;
  width: 95%;
}
.form__theoretical-table__employee-view p {
  margin: 0px;
  padding: 9px 9px 9px 12px;
}
.form__theoretical-table__employee-view .theoretical-table__name-column {
  background-color: #eeeeee;
  font-weight: bold;
}
.form__theoretical-table__employee-view .theoretical-table__cell {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.theoretical-tab__item__status-flag {
  background-color: #f9f9f9;
  padding: 10px;
  height: 32px;
  border-radius: 4px;
  margin-bottom: 12px !important;
  margin-left: 10px;
}
.theoretical-tab__item-title {
  max-width: 300px !important;
  width: 300px !important;
}
.theoretical-tab__employee-table-title-wrapper {
  margin-top: 40px;
  padding-left: 0px !important;
}
