.form__instructors-tab__container {
  display: flex;
  flex-direction: column;

  .instructors__delete-icon__container {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
    width: 100%;
  }

  .form__event-details-tab__element {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 16px;

    p {
      margin-right: 12px;
      margin-bottom: 0px;
    }

    .flex-start-center {
      margin-bottom: 5px;

      .form__field-numeric {
        margin-right: 32px;
      }
    }

    .search {
      width: 180px;
      margin-right: 32px;
    }

    .form__inputs-dates-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0px;
      margin-right: 32px;

      .fields {
        margin-bottom: 0px;
      }

      .planit-eventform-dates-period {
        width: 100px;

        > div > i {
          display: none !important;
        }

        div > input[type='text'] {
          padding: 10px !important;
          text-align: center;
          display: flex;
          justify-content: center;
        }
      }

      .from-date {
        div > input[type='text'] {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      .to-date {
        div > input[type='text'] {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          margin-left: -1px;
        }
      }
    }
  }

  .form__instructors__second-row-styles {
    .travel-days {
      i.icon {
        display: none;
      }
      .travel-days__departure .ui.input > input {
        padding-right: 1em !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .travel-days__arrival .ui.input > input {
        padding-right: 1em !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
      }
      .field {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
    p {
      width: 45px;
    }
  }
}

.form__instructors-tab__buttons-row {
  display: flex;
  align-items: center;
  margin-bottom: 24.5px;

  .icon {
    margin-right: 22.14px;
  }

  .instructors-tab__add-instructor-button {
    display: flex;
    width: 100%;

    .form__add-instructor-btn-event-creation {
      border: 1px solid #474b4f;
      background-color: white;
      color: #474b4f;
      margin-right: 24px;
      width: 120px;
      height: 36px;

      .icon {
        background-color: transparent !important;
        padding-right: 0px;
      }
    }

    .form__add-instructor-btn {
      border: 1px solid #474b4f;
      background-color: white;
      color: #474b4f;
      margin-right: 24px;
      width: 120px;

      .icon {
        background-color: transparent !important;
        padding-right: 0px;
      }
    }

    .ui[class*='right labeled'].icon.button {
      padding: 3px 24px 3px 4px !important;
    }

    .form__show-all-instructor-btn {
      border: 1px solid #00b2a9;
      background-color: white;
      color: #00b2a9;
      margin-right: 24px;
    }

    .ui.toggle.checkbox {
      label:before {
        width: 2rem !important;
        height: 1.3rem !important;
        border-radius: 1.3rem !important;
      }
      label:after {
        width: 1.3rem !important;
        height: 1.3rem !important;
        z-index: 1;
      }
      input:checked ~ label::after {
        left: 0.9rem !important;
      }
    }

    .ui.fitted.toggle.checkbox {
      width: 2rem !important;
    }

    .instructors-tab-toggles__wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .unblock-label {
        margin-right: 15px;
      }

      .instructors-tab-toggles__items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 15px;

        label {
          padding-bottom: 10px;
          width: 100%;
        }
      }
    }
  }
}
