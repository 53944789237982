.new-entry-btn {
  min-width: 140px !important;
  min-height: 32px;
  padding: 9px 12px !important;
}

.search-form__search-employees-btn {
  min-width: 140px;
  background-color: #ffffff;
  display: block;
  border: 1px solid #474b4f !important;
  border-radius: 4px;
  color: #474b4f !important;
  align-items: center;
  box-shadow: none;
}

.filters__employee-wrapper {
  margin-right: 12px !important;
}

.ssa-development-plan-modal__textarea {
  border: 1px solid #dedfdf;
  min-width: 479px;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  resize: none;

  &::placeholder {
    color: #dedfdf;
  }
}
