.tab-pane-with-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  h4 {
    margin-left: 42px;
    margin-bottom: 32px;
  }

  .tab-pane-with-header__lateral-menu {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item {
      width: 100%;
      height: 32px;
      vertical-align: middle;
      padding: 9px 0px 9px 24px;
      font-weight: 600;
      color: #474b4f;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .item.active {
      background-color: #e5f7f6 !important;
      color: #00b2a9;
      border-right: 6px solid #00b2a9;
    }
  }

  .tab-pane-with-header__content,
  .tab-pane-with-header__tab-header-content,
  .tab-pane-with-header__footer-content {
    width: 100%;
  }

  .tab-pane-with-header__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
