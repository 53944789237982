@import './variables.less';

html,
html * {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

div#root {
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row-selected {
  background: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.planit-main-container {
  margin-left: 16.8rem;
  padding: 0px;
  height: 95%;
  -webkit-transition: margin-left 0.5s ease;
  transition: margin-left 0.5s ease;

  background: white;

  &.scheduler {
    background: white;
  }

  &.expanded {
    margin-left: 4.5rem;
  }

  #main-container-grid {
    margin: 0;
    height: 95%;
    position: relative;

    #notifications-row {
      position: absolute;
      z-index: 998 !important;
      height: 5%;
      padding: 0px 5px;
      top: 10px;
      right: 10px;
      width: 35% !important;

      p {
        text-align: right;
        width: 100%;

        i {
          background-color: @inverted-color !important;
          cursor: pointer;
        }
      }
    }

    #content-container {
      height: 100%;
      display: block !important;

      > h3:first-child {
        // text-transform: uppercase;
        font-size: 25px;
        font-weight: normal;
        //color: @inverted-color;
        margin-bottom: 0px;
        background-color: #00b2a9;
        color: white;
        height: 7%;
        position: relative;
        top: -12px;
        padding: 15px;
      }

      h3:first-child + .planit-line-separator {
        padding-top: 1px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .main-tab:first-child {
        margin-top: 10px;
      }

      .header-accordion-spacer {
        margin-top: 20px !important;
      }

      .header-instructions-accordion-spacer {
        margin-top: 10px !important;
      }
    }
  }
}

#planit-home-div {
  // margin-top: 2rem;

  #img {
    width: 4rem;
    display: inline-block;

    img {
      height: 30px;
    }
  }

  #text {
    width: 85%;
    display: inline-block;
  }

  #subtitle {
    font-size: 15px;
  }
}

.clickable-icon {
  cursor: pointer;
}

.user-dropdown {
  min-width: 300px;
  min-height: 32px;
}

.planit-general-error-container {
  font-size: 150%;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding-top: 30vh;
}

.planit-users-inputs {
  min-width: 300px;
  min-height: 32px;
}

.planit-users-fields {
  min-width: 400px;
  //text-align: left;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.planit-user-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dedfdf;
  border-radius: 4px;
  opacity: 1;

  i {
    padding: 0px !important;
  }
}

.planit__multi-plc__dropdown {
  i {
    padding: 10px !important;
  }
}

.planit-user-selector {
  min-width: 250px !important;
  min-height: 32px;
}

.planit-users-roles-in-location-left {
  text-align: right;
  padding-left: 155px;
  max-height: 70px;
  padding-right: 0px;
}

.planit-users-roles-in-location-right {
  text-align: left;
  max-height: 32px;
  padding-left: 4.5rem;
}

.planit-users-roles-in-location-selector {
  width: 250px !important;
  padding-right: 10px;
}

.planit-roles-checkbox {
  width: 16px;
  height: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #474b4f;
  border-radius: 4px;
  opacity: 1;
}

#notification-icon {
  height: 38px !important;
  width: 38px !important;
  font-size: 1.4em !important;
}

/* Toggle overrides */
.ui.checkbox.toggle input:checked ~ .box:after,
.ui.checkbox.toggle input:checked ~ label:after {
  border: 2px solid #00b2a9;
}

.ui.checkbox.toggle input:checked ~ .box:after,
.ui.checkbox.toggle input:checked ~ label:hover::before {
  background-color: none;
}

.ui.checkbox.toggle input:not(:checked) ~ .box:after,
.ui.checkbox.toggle input:not(:checked) ~ label:after {
  background: none;
  border: 2px solid #b0b0b0;
  box-shadow: none;
}

.ui.checkbox.toggle input:not(:checked) ~ .box:after,
.ui.checkbox.toggle input:not(:checked) ~ label:before {
  background: none;
  border: 2px solid #b0b0b0;
  box-shadow: none;
}

/* Toggle overrides */

/*Modal action buttons override*/
.ui.modal .actions {
  background: unset !important;
  border-top: 0px !important;

  .button {
    height: 36px;
    width: 100px;

    &.basic {
      border: 1px solid #474b4f;
      color: #474b4f !important;
      opacity: 1;
      line-height: 13px;
      box-shadow: none !important;
    }

    &.positive {
      font-weight: normal !important;
    }
  }
}

.custom-actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.5rem;
  padding-top: 0;

  .field {
    margin-right: 3em;

    label {
      font-weight: bold;
      margin-bottom: 0.4rem;
      display: block;
    }
  }

  button.button {
    margin-bottom: 0 !important;
  }
}

.inverted-color-btn {
  color: white !important;
  background-color: @inverted-color !important;
  font-size: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
}

.white-save-btn {
  color: @inverted-color !important;
  background-color: white !important;
  border: 1px solid @inverted-color !important;
  font-size: 1rem !important;
}

.disabled-inverted-save-btn {
  color: #b0b0b0 !important;
  background-color: white !important;
  border: 1px solid #b0b0b0 !important;
  font-size: 1rem !important;
  opacity: 1 !important;
}

.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button.filtered-link-button {
  color: white !important;
  background-color: #00b2a9 !important;
}

/*Modal action buttons override*/

/* Color pickers*/
.color-picker-popup {
  padding: 0 !important;
  margin-top: 4px !important;
}

.color-picker-item {
  padding: 7px 4px 5px 4px !important;

  i {
    right: 7px !important;
  }
}

/* Color pickers*/

/* Icon pickers*/
.icon-picker {
  padding: 7px 4px !important;

  i {
    right: 7px !important;
  }

  .menu.menu.menu.menu.menu {
    .item {
      padding: 0.75rem 5px !important;
    }
  }
}

/* Icon pickers*/

/*Table Students Request Wizard Row*/
.table-border-rows {
  width: 100%;
  font-weight: bold;
  border: 1px solid black;
  border-collapse: collapse;

  tr {
    border: 1px solid black;
    border-collapse: collapse;

    td {
      padding: 10px 20px;
    }
  }
}

/*Table Students Request Wizard Row*/

/*Table Machines Request Wizard Row*/
.table-machines-wizard {
  justify-self: center;
  width: 100%;
  overflow-y: scroll;

  th {
    width: 10%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }

  tr {
    padding: 10px 20px;
  }

  .table-machines-wizard-header {
    border-bottom: 1px solid black;
  }
}

/*Table Machines Request Wizard Row*/

.medium-remove-red-button {
  font-size: 1em !important;
  cursor: pointer;
}

.custom-editor {
  width: 100% !important;

  &.ui.loading.selection.dropdown > i.icon {
    padding: 1.5em 1.5em !important;
  }

  div {
    width: 100%;
  }
}

.ui.loading.selection.dropdown.selection.selection.selection.selection > i.icon {
  padding: 1.5em 1.5em !important;
}

.button.inverted-btn.inverted-btn.inverted-btn.inverted-btn.inverted-btn {
  background-color: @inverted-color !important;
  color: white !important;

  i {
    color: white !important;
  }
}

.button.add-user {
  padding: 0.91em 0.82em 0.7em 0.82em !important;

  i {
    zoom: 1.6 !important;
  }
}

.scroll-content {
  overflow: scroll;
  padding-bottom: 20px;
}

.rounded-button {
  border-radius: 50% !important;
}

.ui.icon.input > i.icon.calendar {
  color: #dedfdf;
  opacity: 1;
}

.float-right {
  float: right;
}

.gray-onhover {
  cursor: pointer;
}

.gray-onhover:hover {
  background-color: #efefef !important;
}

.turquose-onhover:hover {
  transition: 0.9s;
  color: #00b2a9 !important;
  cursor: pointer;
  background-color: white !important;
}

.ui.selection.dropdown.planit-multi-dropdown {
  div.menu {
    overflow-y: hidden;

    &.visible {
      overflow-y: auto;
    }

    .ui.input {
      margin: 6px;

      input {
        background-color: #eeeeee;
        border-color: #eeeeee;
      }
    }

    div[role='option'].item {
      display: flex;
      align-items: center;
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
      cursor: pointer;
    }

    .text {
      margin-left: 9px;
    }
  }

  .planit-multi-dropdown__selected-option {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .planit-multi-dropdown__selected-option__number {
      font-weight: 700;
    }
  }

  .planit-multi-dropdown__options {
    margin: 0.6rem 0px 0px 0.6rem;
    max-height: 9rem;
    min-height: 9rem;
    overflow-y: auto;
  }

  .ui.divider {
    margin: 2px;
  }

  .planit-multi-dropdown__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 2rem;

    a {
      text-decoration: none;
      cursor: pointer;
      color: #222629;
      font-weight: bolder;
      padding: 4px;
    }
  }
}

.multi-user-dropdown__me > span {
  margin-left: 0.6rem;
}

.margin-top-6px {
  margin-top: 6px !important;
}

.margin-top-2em {
  margin-top: 2em !important;
}

.margin-top-0.margin-top-0.margin-top-0.margin-top-0 {
  margin-top: 0px !important;
}

.margin-0.margin-0.margin-0.margin-0 {
  margin: 0px !important;
}

.height-3em {
  height: 3em !important;
}

.visible.menu.transition {
  min-height: 100px;
  width: max-content !important;
  max-width: 700px !important;
  border: 1px solid rgba(128, 128, 128, 0.541);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(0, 0, 0);
  }
}

/*DATE PICKERS*/
.semantic-ui-calendar-react-node.bottom::before {
  background: #474b4f !important;
}

/*DATE PICKERS*/

// TOAST
.b-float-root {
  z-index: 1001 !important;
}

.b-float-root > .b-floating.b-toast {
  border-radius: 4px !important;
  color: #fff !important;

  i {
    color: #fff;
    margin-right: 16px;
  }

  &.warning-toast {
    background-color: #eaaa00 !important;
  }

  &.success-toast {
    background-color: #407ec9 !important;
  }

  &.information-toast {
    background-color: #eaaa00 !important;
  }

  &.error-toast {
    background-color: #e03c31 !important;
  }
}

.min-width-100pc.min-width-100pc.min-width-100pc.min-width-100pc {
  min-width: 100% !important;
}

.normal-look-readonly-select {
  color: black !important;
  opacity: 1 !important;

  &:not(.loading) {
    i.icon {
      opacity: 0 !important;
    }
  }
}

.user-list-table__roles-columns-text {
  max-width: 150px;
  text-overflow: ellipsis;
}

//multiple dropdown

.planit-multi-dropdown {
  min-width: 150px !important;
  height: 38px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 6px;

  > div.menu.visible.transition {
    width: fit-content !important;
    border: 1px solid rgba(128, 128, 128, 0.541);
  }
}

.planit-multi-dropdown__options {
  overflow-x: hidden;
  text-overflow: ellipsis;

  > span > div {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > span > div > span {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.planit-multi-dropdown__options::-webkit-scrollbar {
  width: 4px;
}

.planit-multi-dropdown__options::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

.planit-multi-dropdown__options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0, 0, 0);
}

.label-required.label-required.label-required::after {
  margin: -0.2em 0em 0em 0.2em;
  content: '*';
  color: #db2828;
}

.normal-looking-disabled-input.normal-looking-disabled-input.normal-looking-disabled-input {
  opacity: 1 !important;

  label,
  div.input,
  div.dropdown {
    opacity: 1 !important;

    .dropdown.icon {
      opacity: 0 !important;
    }

    input {
      opacity: 1 !important;

      &:focus {
        border: 1px solid rgba(34, 36, 38, 0.15);
      }
    }
  }
}

.custom-editor-search {
  min-height: 36px !important;
}

.template-step {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &.inactive {
    .ui.circular.label.step__label {
      background-color: #dedfdf;
      border-color: transparent;
    }

    color: #dedfdf;
  }

  .ui.circular.label.step__label {
    background-color: #00b2a9;
    padding: 0px;
    width: 38px;
    height: 38px;
    vertical-align: middle;
    color: white;
    line-height: 2em;
  }

  .step__title {
    margin-left: 24px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
}

.media-input {
  border-radius: 4px;
  border: 1px solid @default-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 8px;
  width: 100%;
  max-width: 100%;

  .table__tooltip {
    min-width: 90%;
  }
}

.spacer {
  flex: 1 1 auto;
}

.no-arrows input[type='number'] input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//MEDIA QUERIES
@media all and (max-width: 1200px) {
  .sch-instructors__header {
    .planit-multi-dropdown {
      margin-bottom: 10px;

      > span {
        line-height: 12px !important;
      }
    }
  }
}

@media only screen and (max-height: 670px) {
  #content-container#content-container#content-container {
    h3:first-child {
      font-size: 16px !important;
    }
  }
}

.bold-label-checkbox {
  label {
    font-weight: 900;
  }
}

.custom-table thead tr th.no-width {
  min-width: 0px !important;
}

.ui.selection.selection.selection.selection.selection.selection,
.ui.dropdown.dropdown.dropdown.dropdown.dropdown.dropdown.dropdown {
  div.default.default.default.default.text {
    font-weight: normal !important;
  }
}

.poc-config-modal__ranges-container {
  .fields .field .planit-eventform-dates-period .ui.icon.input {
    .icon {
      display: none;
    }

    input {
      text-align: center;
      padding: 8px;
      padding-right: 8px !important;
    }

    max-width: 100px;
    margin-right: 24px;
    min-width: 100px;
    width: 100px;
    height: 32px;
  }
}

.ui.modal {
  .header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}

.underlined {
  text-decoration: underline;
}

.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.basic-padding {
  padding: 1em 1rem;
}

.ui.button.sch-button {
  min-width: fit-content;
  white-space: nowrap;
  height: 38px;
  border-radius: 4px;
  color: @inverted-color;
  background-color: #fff;
  outline: none;
  border: 1px solid @inverted-color;
  cursor: pointer;
  margin-right: 6px;

  &:visited,
  &:focus {
    background-color: inherit;
  }

  &:hover {
    background-color: whitesmoke;
  }

  &.active {
    background-color: @inverted-color;
    color: #fff;

    i.icons {
      color: #00b2a9;
    }
  }
}

.request-location-region__input {
  margin-right: 12px !important;
}

.no-margin-bottom {
  margin-bottom: -1em !important;
}

.event-types-list-grid .event-types-list-filter-row .form .fields.request-event__field-all-container-height-flexible {
  flex-direction: column !important;
  padding: 0px 15px !important;
}

.request-list__filters-wrapper.event-list__new-filters-container {
  margin-right: -60px;
  width: calc(100vw - 300px);
}

@import './left-main-menu.less';
@import './toast-overrides.less';
@import './table-overrides.less';
@import './main-tab.less';
@import './rte-overrides.less';
@import './event-types-view.less';
@import './event-comments-form.less';
@import './stepper.less';
@import './chat.less';
@import './choose-students-form.less';
@import './students-list.less';
@import './event-requests-tab.less';
@import './event-pause-period-list.less';
@import './events-request-wizard.less';
@import './user-import-view.less';
@import './perdiem.less';
@import './configurations-menu.less';
@import './event-form-merge.less';
@import './question-bank.less';
@import './tnaSkill.less';
@import './tna-new-questionbank.less';
@import './assessment-forms-list.less';
@import './choose-employees-form.less';
@import './choose-template-form.less';
@import './generic-dates-pickers.less';
@import './costs-component.less';
