.tna-development-plan-by-location__wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.tna-development-plan-by-location__wrapper .tna-development-plan-by-location__filters {
  display: flex;
  margin-bottom: 10px;
}
.tna-development-plan-by-location__wrapper .tna-development-plan-by-location__filters .custom-editor {
  margin-right: 12px;
  min-width: 150px !important;
  max-width: 150px !important;
  margin-bottom: 5px;
  min-height: 36px !important;
  height: 36px !important;
}
.tna-development-plan-by-location__wrapper .tna-development-plan-by-location__filters .custom-editor-search {
  margin-right: 12px;
  margin-bottom: 5px;
}
.tna-development-plan-by-location__wrapper .custom-table-main-actions.menu {
  display: none;
}
.tna-development-plan-by-location__wrapper .ui.table.custom-table thead tr:first-child > th:first-child {
  padding-left: 15px !important;
}
.tna-development-plan-by-location__wrapper .ui.table.custom-table tbody tr > td:first-child {
  padding-left: 15px !important;
}
