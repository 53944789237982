.ui.modal.reject-request-popup {
  background: #474b4f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  transform: translateY(35vh);
}

.ui.modal > .content.reject-request-popup__content {
  background: #474b4f 0% 0% no-repeat padding-box;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
}

.reject-request-popup__buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 16px 16px 0px;

  .form__cancel-btn {
    min-width: 70px;
    margin-left: 18px;
  }

  .reject-request-popup__cancel-btn {
    background-color: transparent;
    color: white;
    border: transparent !important;
  }
}

.ui.button.reject-request-popup__btn {
  border: 1px solid #ffffff;
  border-radius: 4px;
  opacity: 1;
  height: 32px;
  letter-spacing: 0.24px;
  padding: 0px 5px;
  margin-left: 13px;
  min-width: 70px;
}

.ui.button.reject-request-popup__pending-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #474b4f;
}

.ui.dropdown.reject-request-popup__reason-editor {
  max-width: 140px;
  margin-bottom: 6px;
}

.reject-request-popup__description {
  padding-left: 4px;

  > p {
    margin-bottom: 8px;
  }

  margin-bottom: 8px;
}
.reject-request-popup__selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.reject-request-popup__reason-description {
  min-height: 9rem;

  textarea {
    min-height: 9rem;
  }
}
