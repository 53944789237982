.support-details-container {
  font-size: 12px;
  position: relative;

  &.isform {
    .support-details__pre-approved {
      position: absolute;
      top: -60px;
      right: 0;
    }

    .support-details__main {
      margin-left: 0px;

      .support-details__add-position .cogs.large.icon {
        position: absolute;
        left: 0;
      }
    }
  }

  .support-details__pre-approved {
    display: flex;
    justify-content: flex-end;
    margin: 16px;

    h5 {
      white-space: nowrap;
      text-align: left;
    }

    .ui.checkbox {
      display: inline-flex;
      text-align: right;
    }
  }

  .support-details__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0%;

    input {
      width: 100%;
    }

    .support-details__add-position {
      .cogs.large.icon {
        position: absolute;
        left: 0;
      }

      width: 100%;
      margin: 16px 0;
      min-height: 32px;
      display: flex;
      align-items: center;

      .support-details__add-btn {
        display: flex;
        justify-content: space-between;
        font-size: 12px !important;
        padding: 8px;
        height: 32px;
        white-space: nowrap;
        width: 160px;
        border: 1px solid #474b4f;
        color: #474b4f;
        background-color: #ffffff;
        margin-right: 30px;

        i {
          margin: 4px;
          font-size: 14px;
        }
      }

      .costs {
        display: flex;
        flex-wrap: wrap;

        .costs-container {
          margin: 4px 12px 4px 0px;
        }
      }
    }

    .support-details__item {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 40px;

      &:nth-child(2) .support-details__item-collection {
        border-top: 1px solid #dedfdf;
      }

      .support-details__item-collection {
        width: 100%;
        border-bottom: 1px solid #dedfdf;

        .support-details__comment-input.ui.input {
          width: 250px;
          margin: 16px 0;
        }

        .new_support-details__item-main {
          white-space: nowrap;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin: -4px 0;
          padding: 20px 0px 20px 0;

          .supppor-details__item-main-segment {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 20px;
            margin-top: 10px;
            margin-bottom: 10px;

            .ui.right.labeled.input {
              input {
                padding-right: 4px;
                width: 40px;
              }

              .ui.basic.label.label {
                padding-left: 4px;
                border-left: none;
                line-height: 0.8em;
                font-weight: lighter;
              }
            }

            .support-details__segment-label {
              margin-right: 16px;
            }

            .fields {
              .field .ui.icon.input {
                i {
                  display: none;
                }

                width: 100px;
              }

              .field .from-date .ui.icon.input input {
                border-radius: 4px 0 0 4px;
                padding-right: 1em !important;
              }

              .field .to-date .ui.icon.input input {
                border-radius: 0 4px 4px 0;
                padding-right: 1em !important;
              }
            }

            .ui.input:not(.icon) {
              width: 80px;

              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              /* Firefox */
              input[type='number'] {
                -moz-appearance: textfield;
              }
            }

            .ui.inline.search.selection.dropdown.planit-user-selector__position-code {
              max-width: fit-content !important;
              width: fit-content !important;

              .visible.menu.transition {
                min-width: fit-content;

                .item {
                  white-space: nowrap;
                }
              }
            }
          }
        }

        .support-details__location-detail-item {
          margin: 12px 0;
          display: flex;

          .support-details__location-detail-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 16px;

            span {
              height: 14px;
            }
          }

          .ui.search.selection.dropdown.custom-editor {
            width: 170px !important;
            margin-right: 32px;
            height: 32px !important;
            min-height: unset;
            line-height: 0.8em;

            .icon {
              line-height: 0.8em;
            }

            .visible.menu.transition {
              min-width: fit-content;

              .item {
                white-space: nowrap;
              }
            }
          }
        }
      }

      i.red.remove.icon::before {
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}

.machine-selection-item {
  margin-top: 15px;
  //margin-bottom: -15px;
  display: flex;
  h4 {
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 0;
  }
  div {
    margin-left: 45px;
    margin-bottom: -25px;
  }
}

.support-details__add-position .cost-label {
  min-width: 110px;
}
.support-details__add-position .costs-container {
  margin-right: 20px !important;
}
.support-details__add-position .large.icon {
  display: none;
}

.vis-borde-01 {
  width: 100%;
  margin-bottom: 15px;
}
.vis-borde-01 .supppor-details__item-main-segment {
  flex-direction: row !important;
}

.vis-borde-02 {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.vis-borde-02 .flex-column {
  margin: 0px 20px 0px 0px !important;
}

.vis-borde-03 {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}
.vis-borde-03 .supppor-details__item-main-segment {
  align-items: start !important;
}
