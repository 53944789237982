.profile-def__all-wrapper {
  overflow: visible;
  width: 735px;
  margin: auto;
  min-height: fit-content;

  .edit-profile-def__numeric-edit-input {
    width: 80px !important;
    margin-right: 15px !important;
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .profile-def__label-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  label {
    margin-right: 12px !important;
    font-weight: 600;
  }

  .fields {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
  }

  .fields > .field {
    margin: 0px !important;
    padding: 0px !important;
    width: fit-content !important;
  }

  .profile-definition__inputs-container {
    width: 100%;

    .profile-def__label-input:first-child {
      margin-right: 24px !important;
    }
  }

  .profile-def__add-skill-row {
    margin-top: 12px !important;
    width: 100%;
    margin-bottom: 12.5px !important;

    .profile-def__label-input {
      justify-content: flex-start;
      min-width: 30% !important;

      label {
        text-align: start;
        width: 31.95px;
      }

      .planit-user-dropdown-custom {
        min-width: 182px !important;
        max-width: 182px !important;
        margin-right: 13px;
      }

      .profile-def__numeric-input {
        min-width: 80px !important;
        width: 80px !important;
      }

      .profile-def__numeric-phase-input {
        min-width: 75px !important;
        width: 55px !important;
        margin-right: 5px;
      }

      .profile-def__numeric-input:nth-child(1) {
        margin-right: 13px;
      }

      .profile-def__numeric-input:nth-child(2) {
        > input {
          padding-left: 11px !important;
        }
      }
    }

    .add-row-btn {
      width: 80px;
      height: 34px !important;
      margin-left: 18px !important;
      margin-right: 0px !important;
      justify-self: flex-end !important;
    }
  }

  .new-profile__new-row {
    font-weight: 600;

    .flex-center {
      border-top: 1.2px solid #b5b5b5;
      padding: 11.5px 0px;
      min-width: 370px;
      justify-content: space-between;
      margin-right: 20px;

      span:first-child {
        margin-right: 10px;
        width: 160px;
        padding-left: 10px;
      }

      span:nth-child(2) {
        margin-right: 85px;
      }

      span:nth-child(3) {
        margin-right: 60px;
      }
    }

    .iws-flex-center {
      border-top: 1.2px solid #b5b5b5;
      padding: 11.5px 0px;
      min-width: 570px;
      justify-content: space-between;
      margin-right: 20px;

      span:first-child {
        margin-right: 10px;
        width: 200px;
        padding-left: 10px;
        display: inline-flex;
      }

      span:nth-child(2) {
        display: inline-flex;
        width: 75px;
      }

      span:nth-child(3) {
        display: inline-flex;
        width: 85px;
      }
      span:nth-child(4) {
        display: inline-flex;
        width: 75px;
      }
      span:nth-child(5) {
        display: inline-flex;
        width: 75px;
      }
      span:nth-child(6) {
        display: inline-flex;
        width: 10px;
      }
    }

    .remove-icon {
      margin-left: 10px !important;
    }
  }

  .profile-def__textarea-label__wrapper {
    margin-top: 12px;
    width: 99%;
    padding-right: 30px !important;

    .profile-def__textarea {
      width: 472px !important;
      min-height: 64px;
    }

    label {
      margin-right: 18px !important;
      text-align: end !important;
    }
  }
}

.profile-definition__skills-group {
  max-height: 270px;
  overflow-y: scroll;
  width: 85%;
  margin: auto;

  .new-profile__new-row:last-child {
    .flex-center:last-of-type {
      border-bottom: 1.2px solid #b5b5b5;
    }
  }
}
