@inverted-color: #474b4f;
@hover-green: #00b2a9;
@default-grey: #dedfdf;
.form-main-tabs {
  .ui.segment {
    position: inherit;
    border: none;
    box-shadow: none;
    padding: 1rem 0;
  }
  .ui.pointing.secondary.menu {
    border-bottom: 16px solid #ccf0ee;
    margin-bottom: 0px;

    .item {
      text-transform: uppercase;
      font-weight: 500;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 14px;
      border: 1px solid #ccf0ee;
      color: #b0b0b0;
    }

    .active.item,
    .active.item:hover {
      background-color: #ccf0ee;
      color: #474b4f;
    }
  }
}
.form__all-wrapper {
  margin-bottom: 16px;
  padding: 0px 15px !important;
  .ui.divider {
    border-top-color: black !important;
    border-bottom: none;
  }
  .form__all-wrapper__main-content {
    height: 70vh;
    .ui.button {
      min-width: 80px;
      min-height: 32px;
      margin-right: 16px;
      &.active {
        color: white;
        background-color: @hover-green;

        &:hover {
          background-color: #018881;
        }
      }
    }
    .form-main-content {
      width: 100%;
      .form-header {
        font-family: inherit;
        border: 1px solid black;
        padding: 16px;
        display: flex;
        white-space: break-spaces;
        flex: 1 1 auto;
      }
    }
    .form-main-question {
      width: 100%;
      margin: 0;
      .form-main-question__label {
        padding: 10px;
        width: 100%;
        border-radius: 0;
        background-color: #f9f9f9;
        margin: 8px 0 16px 0;
      }
      .form-main-question__container {
        width: 100%;
        margin: 0;
        padding: 0 16px;

        img {
          margin-bottom: 16px;
        }
        iframe {
          margin-bottom: 10px;
        }
        .form-main-question__available-checkbox {
          margin-bottom: 12px;
          &.ui.checkbox label {
            padding-left: 32px;
            &::before {
              border-color: #707070;
              border-radius: 0;
            }
          }
        }
        .form-main-question__media-container {
          text-align: center;
        }
        .rating-answer__container {
          display: flex;
          margin: 0 !important;
          flex-direction: column;
          .answer-checkbox {
            padding: 8px 0;
          }
          .ui.checkbox label {
            padding-left: 32px;
            &::before {
              border-color: #707070;
            }
          }
          .ui.radio.checkbox input:checked ~ label:after {
            transition: border 0.1s ease, opacity 0.5s ease, transform 0.1s ease, box-shadow 0.1s ease;
            transform: scale(0.7);
            background-color: #018881;
          }
        }
      }
    }
  }
  .form__all-wrapper__footer {
    .ui.button {
      background-color: #474b4f;
      color: white;
      min-width: 150px;
      display: flex;
      white-space: nowrap;
      justify-content: space-between;
    }
    .spacer {
      flex: 1 1 auto;
    }
  }
}

.theoretical-summary__table {
  display: grid;
  grid-template-columns: 70px 100px 250px repeat(5, 1fr);
  width: 100%;

  .theoretical-summary__table-title {
    display: flex;
    align-items: center;
    background-color: #eeeeee;
    width: 100%;
    padding: 8px;
    margin-right: 0px;
    margin-bottom: 0;
  }

  .border-bottom-gray {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dedfdf;
    padding: 1em;
  }

  span {
    display: flex;
    align-items: center;
  }

  .text__transparent {
    color: transparent;
  }

  .icons {
    display: flex;
    align-items: center;
  }
}
