#feedback-question-bank-wrapper .tna__inputs-wrapper {
  width: 65%;
}
#feedback-question-bank-wrapper .tna__inputs-wrapper div.ui.input:nth-of-type(1) {
  height: 38px !important;
}
#feedback-question-bank-wrapper .tna__inputs-wrapper #feedback-questions-bank-list__filters__answerType {
  height: 38px;
}
#feedback-question-bank-wrapper .tna__inputs-wrapper #feedback-questions-bank-list__filters__langs {
  max-width: calc(100% - 510px) !important;
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper {
  overflow-y: scroll;
  max-height: 65vh !important;
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper .fields {
  margin: 0em -0.5em 1em !important;
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper .fields .first-label {
  width: 6.5em !important;
  text-align: right;
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper .fields .first-label.hidden {
  opacity: 0 !important;
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper .feedback-question-bank__label-textarea textarea {
  width: 406px;
  height: 64px;
  font-weight: 600;
  resize: none !important;
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper .feedback-question-bank__label-textarea div.ui.input {
  width: 406px;
  font-weight: 600;
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper .feedback-question-bank__translation-remove i {
  margin: 0 !important;
  transform: translateY(12px);
}
.feedback-question-bank__wrapper.feedback-question-bank__wrapper.feedback-question-bank__wrapper .feedback-question-bank__translation-lng .ui.dropdown {
  min-width: 100% !important;
  max-width: 100% !important;
}
