.role-editor__wrapper {
  width: 100%;
  display: flex;
}
.role-editor__wrapper .planit-users-roles-in-location-selector {
  width: 200px !important;
}
.role-editor__wrapper .planit-users-roles-in-location-selector.multiple {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.role-editor__wrapper.role-editor__wrapper__column-2.role-editor__wrapper__without-selector {
  margin-left: 39px;
}
.role-editor__wrapper.role-editor__wrapper__column-2.role-editor__wrapper__with-selector {
  margin-left: 40px;
}
.role-editor__wrapper.role-editor__wrapper__column-2 .role-editor__elements {
  width: 200px;
}
.role-editor__wrapper.role-editor__wrapper__column-1 {
  margin-left: 45px;
}
.role-editor__wrapper.role-editor__wrapper__column-1 .role-editor__elements {
  width: 150px;
}
.role-editor__elements__checkbox {
  margin-right: 15px;
  height: 100%;
}
.role-editor__elements__dropdown_language {
  padding-left: 70px;
}
@media all and (max-width: 770px) {
  .role-editor__wrapper {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 35px;
  }
}
