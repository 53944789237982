.theoretical-question-manager-container {
  .ui.form {
    width: 80%;
    margin: 1rem auto;

    .eight.wide.field {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &:first-of-type {
        justify-content: flex-start;

        label {
          min-width: 80px;
          display: flex;
          justify-content: flex-end;
        }
      }

      .selection.dropdown {
        width: 200px !important;
        min-width: 200px !important;
        max-width: 100% !important;
      }
    }

    .fields.media-box {
      .media-box-label {
        cursor: normal;
        display: flex;
        white-space: nowrap;
        justify-content: space-between;
        width: 130px !important;
        min-height: 1em;
        outline: none;
        border: none;
        vertical-align: baseline;
        background: #e0e1e2 none;
        color: rgba(0, 0, 0, 0.6);
        font-family: 12px;
        padding: 0.9em 1em 0.9em;
        text-transform: none;
        text-shadow: none;
        font-weight: bold;
        line-height: 1em;
        font-style: normal;
        text-decoration: none;
        border-radius: 0.33333333rem;
        -webkit-user-select: none;
        user-select: none;

        i.icon {
          margin: 0 !important;
        }
      }

      .four.wide.field {
        margin-left: 96px;
        padding-left: 0;

        .ui.button {
          display: flex;
          white-space: nowrap;
          justify-content: space-between;
          width: 130px;
        }
      }
    }

    .twelve.wide.field {
      display: flex;
      justify-content: flex-end;

      label {
        min-width: 80px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .five.wide.field {
      display: flex;
      align-items: center;

      label {
        min-width: 80px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .theoretical-options-box {
      display: flex;

      label {
        min-width: 80px;
        line-height: 2.5em;
        display: flex;
        justify-content: flex-end;
      }

      .options-group {
        margin-left: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .theoretical-question-option {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;

          label {
            min-width: unset;
            white-space: nowrap;
            margin-right: 8px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    .translate-accordion {
      margin-bottom: 16px;
      display: flex;

      i.remove.icon {
        color: #e03c31;
        cursor: pointer;
        font-size: 20px;
        padding: 0.3em;
        margin-left: 12px;
        margin-right: 0;
      }

      .accordion-wrapper {
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);

        .accordion__title {
          display: flex;
          justify-content: space-between;
          padding: 1em;
          border-radius: 4px 4px 0 0;
          background-color: #eeeeee;
        }

        .accordion__content {
          padding: 16px 0 !important;
          border-bottom: 1px solid #eeeeee;

          .ui.selection.dropdown {
            min-width: auto;
            width: 100%;
          }
        }
      }
    }

    .add-translation-btn {
      width: 100%;
    }
  }
}
