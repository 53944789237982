.requests-events-wizard___modal {
  min-width: 80%;

  & > div.content {
    min-height: 50vh;
  }

  .wizard__form__margin {
    margin-top: 39px;
  }

  .actions.wizard__action-buttons-wrapper {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    padding: 10px 32px 32px !important;

    .hidden {
      display: none;
    }

    .wizard__go-back-btn {
      min-width: 100px;
      color: #474b4f;
      background-color: #dedfdf;
      min-height: 32px;
      padding: 0px;

      .icon {
        background-color: #dedfdf !important;
        padding: 0px !important;
        font-weight: 600;
        font-size: 14px;
        color: #474b4f;
      }

      &.hidden {
        visibility: hidden !important;
      }

      &:hover {
        background-color: #dedfdf !important;
      }
    }

    .ui.positive.button {
      margin-left: 16px;
    }

    .form__align-right-btns {
      width: 100%;
    }

    .multi__wizard__dropdown.ui.inline.dropdown .dropdown.icon {
      margin: -10px 0px !important;
    }
  }

  .form__header__row-icon {
    color: transparent;
  }

  .wizard__basic-step__container {
    margin-top: 39px;
  }

  .wizard__step2__machine-related {
    h3 {
      font-size: 14px;
    }

    .wizard__step2__machine-related__content {
      .wizard__mr-table {
        .mr-table__column-title {
          background-color: transparent !important;
          border-bottom: 1px solid #dedfdf;
        }

        .event-details-tab__mr-table__remove {
          padding-left: 10px;
          padding-bottom: 17px;
        }

        .border-bottom-gray:nth-last-of-type(-n + 7) {
          border-bottom: 1px solid transparent !important;
        }
      }
    }
  }

  .wizard__students-step__container {
    padding: 0px 11%;

    .form__header__row {
      .form__header__row-icon {
        display: none;
      }
    }

    .students-tab__students-table {
      padding: 0px;
    }
  }

  .ui.icon.button.form__actions__button.wizard__save-draft-btn {
    background-color: white !important;
    color: #00b2a9 !important;
    border: 1px solid #00b2a9 !important;
    padding: 5px 20px 5px 12px !important;
    min-width: 128px;
    margin-left: 16px !important;
    text-align: left;
    font-weight: 500;

    .icon {
      color: #00b2a9;
      background-color: transparent !important;
      padding: 0px 0px 0px 6px !important;
      font-size: 16px;
    }
  }

  //Scroll styles
  .visible.menu.transition::-webkit-scrollbar {
    width: 4px;
  }

  .visible.menu.transition::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }

  .visible.menu.transition::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.356);
  }
}

.request-wizard__step5-instructors__container {
  margin-top: 39px;
}

.ui.input.request-title__input {
  width: 545px;
}

//Warnings

.wizard__action-warning {
  margin: 20px 0px 10px 20px !important;
}

.wizard__action-warning:hover {
  min-width: fit-content;
  width: 200px;
}
