.ui.modal.delete-user-import-popup {
  background: #474b4f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  max-width: 34rem;
  // transform: translateY(35vh);
}

.ui.modal > .content.delete-user-import-popup__content {
  background: #474b4f 0% 0% no-repeat padding-box;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
}

.delete-user-import-popup__buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 16px 16px 0px;
}

.ui.button.delete-user-import-popup__btn {
  border: 1px solid #ffffff;
  border-radius: 4px;
  opacity: 1;
  height: 32px;
  letter-spacing: 0.24px;
  padding: 0px 5px;
  margin-left: 13px;
  min-width: 70px;
}

.ui.button.delete-user-import-popup__pending-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #474b4f;
}

.ui.button.delete-user-import-popup__cancel-btn {
  background: #474b4f 0% 0% no-repeat padding-box;
  color: white;
}

.ui.dropdown.delete-user-import-popup__reason-editor {
  max-width: 140px;
  margin-bottom: 6px;
}

.delete-user-import-popup__description {
  padding-left: 4px;

  > p {
    margin-bottom: 8px;
  }

  margin-bottom: 8px;
}

.delete-user-import-popup__reason-description {
  min-height: 9rem;
}
