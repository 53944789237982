@import '../../../../../assets/less/variables.less';

.request-table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid #d8d9da;
  // transition: all 0.3s;
  ul {
    padding: 0;
    margin: 0;
  }

  &.hide {
    width: 0 !important;
  }

  .request-table-header {
    text-align: center;
    background: #f3f4f5;
    border-bottom: 1px solid #d8d9da;
    height: 65px;

    .request-table-title {
      overflow: hidden;
      font-size: 1.1em;
      text-transform: capitalize;
      font-weight: bold;
      padding: 0.45em 0;
      color: #222629;
      border-bottom: 1px solid #d8d9da;
    }

    .request-table-filter {
      display: flex;
      justify-content: flex-end;

      button {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: #474b4f;
        color: white;
        border: none;
        cursor: pointer;

        i {
          font-size: 14px;
        }
      }
    }
  }

  .request-table-list {
    background-color: #d8d9da;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    user-select: none;

    .request-table-list-item {
      border-left: solid 4px;
      border-radius: 4px;
      background-color: white;
      width: 100%;
      list-style: none;
      border-bottom: 1px solid #d8d9da;
      padding: 0.8rem;
      display: flex;

      &.draft {
        color: #b0b0b0 !important;
      }

      .ui.grid.request-component__detail-wrapper {
        margin: 0;
        > .row {
          padding: 0.2rem;
          align-items: center;
          flex-wrap: nowrap;
          &:first-of-type {
            font-size: 1.1em;
          }
          svg {
            margin: 0 0.5rem 0 0.3rem;
            &.fa-circle {
              margin-left: 0;
              font-size: 1.3rem;
            }
          }
        }
      }

      .request-component__options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .request-component__options-detail {
          text-align: center;
          padding: 8px;
          cursor: pointer;
          border-radius: 4px;
        }

        :hover {
          background: whitesmoke;
          box-shadow: 0px 0px 4px 2px #00000014;
          color: black;
        }

        .request-component__options-icon {
          align-self: flex-end;
        }
      }
    }
  }
}

.request-popup-filter {
  padding: 0px !important;
}

.filter-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 90vh;

  .filter-form-title {
    padding: 16px;
    font-weight: bold;
    border-bottom: 1px solid #d8d9da;
  }

  .filter-form-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;

    .filter-form__item {
      margin-bottom: 8px;

      .filter-form__item-input {
        width: 200px !important;
        min-height: 38px !important;
        border: 1px solid #474b4f !important;
        border-radius: 4px;
      }
    }

    .filter-form-actions {
      display: flex;
      padding-top: 16px;
      justify-content: space-between;

      .filter-form-actions__button {
        button {
          outline: none;
          cursor: pointer;
          background-color: #474b4f;
          height: 32px;
          min-width: 50px;
          border-radius: 4px;
          border: none;
          color: white;
          transition: all 0.2s;

          &:hover {
            background: black;
          }

          &:disabled {
            background-color: rgb(163, 163, 163);
          }
        }

        &.clear-filters-button button {
          background-color: white;
          color: #474b4f;
          font-weight: bolder;
        }

        &.ok-button button {
          min-width: 45px;
        }
      }
    }
  }
}

.dragged-request {
  background: @default-grey;
  color: @default-grey;
}
