.new-development-plan-modal.modal {
  display: flex !important;
  flex-direction: column !important;
  width: 75vw;
  transition: ease-in 0.8s;
  max-width: 1200px !important;

  .new-development-plan-modal__header {
    padding: 24px 32px 20px 32px !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }

  .new-development-plan-modal__content {
    padding: 24px 32px 12px 32px !important;
  }

  .new-development-plan-modal__actions {
    padding: 12px 32px 24px 32px !important;
  }

  .new-development-plan-modal__field {
    .employee-select-btn {
      border: 1px solid #474b4f;
      color: #474b4f !important;
      opacity: 1;
      line-height: 13px;
      box-shadow: none !important;

      > .user.plus.icon {
        font-size: 14px;
      }
    }

    label {
      font-weight: bold;
      min-width: 95px;
      margin-right: 40px;
    }

    .flex-column {
      margin-bottom: 5px;

      label {
        font-weight: normal;
        padding-bottom: 10px !important;
      }
    }

    &.new-development-plan-modal__machinery {
      > label {
        margin-top: 20px;
      }
    }

    .new-development-plan-modal__machinery__field {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }

    min-height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    margin-right: 8px;

    .custom-editor {
      margin-right: 12px;
      max-width: 165px !important;
      margin-bottom: 5px;
    }
  }
}

@media all and (min-width: 1920px) {
  .new-development-plan-modal {
    width: 70% !important;
  }
}
