.new-pattern-modal__name-btn-wrapper {
  > button {
    width: 108px;
    border: 1px solid #474b4f !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
    padding: 0px !important;

    > i {
      font-size: 9px !important;
    }

    .add.tiny.circular.icon {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      background-color: #474b4f !important;
    }
  }

  .button {
    margin-top: 21.5px;
    margin-left: 10px;
  }
}
.ui.table.custom-table.pattern-list__special-table thead tr th {
  min-width: unset !important;
}

.new-pattern__remove-icon {
  margin-right: 10px;

  .remove {
    width: fit-content;
    padding-top: 30px;
  }
}

.configuration__machine-related__machine-model-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(2) {
  min-width: 140px !important;
}

.configuration__machine-related__machine-model-table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(4) {
  min-width: 140px !important;
}

.pattern-list__special-table {
  > tbody > tr.custom-table-expanded-row > td:nth-child(n + 3) {
    padding: 0px !important;

    div.table__short-text {
      padding: 5px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      margin: 0px;
      height: 30px;
    }
    div.table__tooltip-top {
      white-space: break-spaces;
    }
  }
}
