.costs-container {
  display: flex;
  align-items: center;

  .cost-label {
    font-size: 12px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    span {
      height: 14px;
    }
  }
}

.cost-component {
  &.disabled input {
    background-color: inherit;
  }
  display: flex;
  justify-content: center;
  border: 1px solid #dedfdf;
  border-radius: 4px;
  width: 90px;
  height: 32px;
  min-width: 80px;
  background-color: white;
  input {
    padding-left: 8px;
    outline: none;
    line-height: 0.8em;
    width: 100%;
  }
  i {
    line-height: 1.5em;
    margin-top: 6px !important;
    margin-right: 6px !important;
    font-size: 12px !important;
  }
}
