.profile-def__title {
  text-transform: capitalize !important;
}

.profile-def__title + .planit-line-separator {
  margin-bottom: 0 !important;
}

.profile-definition__wrapper {
  padding: 0px 15px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between !important;

  .flex-between {
    width: 100%;
  }

  .custom-table-main-actions.menu {
    margin-right: 0px !important;
    width: fit-content !important;

    .fitted.item {
      margin-right: 0px !important;
    }

    .custom-table-add-btn {
      .icon {
        display: none;
      }
    }
  }

  .ui.right.floated.menu {
    position: absolute !important;
    right: 15px !important;
  }

  .table__flex-container {
    width: 45% !important;
    overflow: visible;
    margin-top: 48px;
    height: fit-content !important;
    border-bottom: 1px solid #eeeeee;

    > table > tbody > tr:hover {
      background-color: #eeeeee !important;
    }

    > table > thead > tr > th:nth-child(1) {
      min-width: 20px !important;
      width: 20px !important;
    }

    > table > thead > tr > th:nth-child(4) {
      min-width: 100px !important;
      width: 100px !important;
    }

    .row-selected {
      background-color: #eeeeee;
    }
  }

  .ui.secondary.menu.item {
    margin: 0px !important;
  }

  .profile-detail-wrapper {
    min-height: 70vh;
    height: fit-content;
    max-width: 55% !important;
    width: 55% !important;
    border: 1px solid #dedfdf;
    margin-top: 48px;
    font-size: 12px;

    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    padding: 18px 18px 60px;

    .profile-detail__header {
      display: flex;
      justify-content: space-around;
      width: 100%;

      p {
        margin: auto;
        width: 80%;
        font-weight: bold;
      }
    }

    .profile-detail__table {
      width: 90%;
      min-width: fit-content;
      border-spacing: 0 8px;
      justify-content: center;
      margin-top: 50px;

      td {
        min-height: 16px !important;
        max-height: 16px !important;
        height: 16px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        font-weight: 500;
      }

      th {
        width: 10%;
      }

      th:nth-of-type(1) {
        width: 20%;
        min-width: 20%;
      }

      .profile-detail__table-header {
        text-align: center;
      }

      .profile-detail__table-body-rows {
        text-align: center;
        min-height: 16px !important;
        max-height: 16px !important;
        height: 16px !important;

        :nth-child(2) {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }

        :last-child {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }

        .profile-skill {
          text-align: right;
          padding-right: 16px;
          font-weight: 600;
          max-width: fit-content;
        }

        .table__short-text {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }

        .table__tooltip .table__short-text {
          overflow: hidden;
          text-overflow: ellipsis !important;
          max-width: 210px;
          text-align: end;
          white-space: nowrap;

          .no-margin {
            margin: 0;
          }
        }
      }

      .profile-level {
        background-color: #00b2a933;
        height: 19.33px !important;
        padding: 0px;

        .item {
          border-radius: 50% !important;
          width: 18px;
          height: 18px;
          display: inline-block;
          line-height: 16.3px;
          user-select: none;
          cursor: pointer;
        }

        .substitute {
          color: #00b2a9;
          background: white;
          border: 1px solid #00b2a9;
        }

        .main {
          color: white;
          background-color: #00b2a9;
          border: 1px solid transparent;
        }
      }
    }
  }

  .empty-profile-definition-detail {
    text-align: center;
    font-weight: bold;
    min-width: fit-content;
    width: 55% !important;
    border: 1px solid #dedfdf;
    margin-top: 48px;
    height: fit-content;
    background-color: white;
    display: block;
    padding: 60px 18px;
  }
}

@media all and (max-width: 1211px) {
  .profile-definition__wrapper {
    padding: 0px 15px;
    .table__flex-container {
      width: 53% !important;
      overflow: visible;
    }
  }

  .profile-detail-wrapper,
  .empty-profile-definition-detail {
    max-width: 47% !important;
    width: 47% !important;
  }
}

@media all and (max-width: 1060px) {
  .profile-definition__wrapper {
    padding: 0px 15px;
    .table__flex-container {
      width: 55% !important;
      overflow: visible;
    }
  }

  .profile-detail-wrapper,
  .empty-profile-definition-detail {
    max-width: 45% !important;
    width: 47% !important;
  }
}

@media all and (min-width: 1280px) {
  .profile-detail-wrapper {
    padding: 18px 18px 60px;

    .profile-detail__table {
      border-spacing: 0 8px;

      td {
        min-height: 24px !important;
        max-height: 24px !important;
        height: 24px !important;
      }

      .profile-detail__table-body-rows {
        :nth-child(2) {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
        }

        :last-child {
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
        }
      }

      .profile-level {
        height: 24px !important;

        .item {
          width: 23.5px !important;
          height: 23.5px !important;
          line-height: 23px !important;
        }
      }
    }
  }
}
