#multiple-machine-model-selector-first-row {
  display: flex;
  flex-wrap: wrap;
}
#multiple-machine-model-selector-first-row .flex-column {
  margin: 0 20px;
}
#multiple-machine-model-selector-first-row .flex-column label {
  font-size: 12px;
  font-weight: 500;
}
#multiple-machine-model-selector-first-row .search-tna-template__btn {
  margin-top: 8px;
}
#multiple-machine-model-selector-first-row .hidden-label {
  visibility: hidden;
}
.machine-selection-item {
  margin-top: 15px;
  display: flex;
}
.machine-selection-item h4 {
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 0;
}
.machine-selection-item div {
  margin-left: 45px;
  margin-bottom: -25px;
}
