.question-bank__wrapper {
  padding: 0px !important;
  font-size: 1rem;

  .question-bank__language__div {
    display: flex;
  }

  .custom-table-main-actions {
    margin-left: 30px !important;

    .custom-table-add-btn {
      .icon {
        display: none;
      }
    }
  }

  .ui.table.custom-table {
    th:nth-child(1) {
      //min-width: 108px !important;
      min-width: fit-content !important;
      width: 25px !important;
    }

    th {
      min-width: fit-content !important;
    }

    th:nth-child(2) {
      min-width: fit-content !important;
      width: fit-content !important;
      max-width: 359px !important;
    }

    td:nth-child(2) > div {
      max-width: 359px !important;
      min-width: 260px !important;
      width: fit-content !important;
    }

    > tbody > tr > td:nth-child(5) > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .table-cell__more-width {
      min-width: 240px !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .question-bank__cell__tag {
    font-weight: 500;
    background-color: #eeeeee;
    margin: 5px;
    color: @inverted-color;
    min-width: fit-content !important;
    max-width: fit-content !important;
    height: 22px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content_transparent {
    height: 22px;
  }

  .popup {
    a {
      font-weight: bold !important;
    }
  }

  .custom-table-upload-btn.black-btn {
    background-color: white !important;
    color: @inverted-color !important;
    border: 1.3px solid @inverted-color !important;
  }

  .ui.mini.icon.button.custom-table-upload-btn.custom-table-upload-btn__up-down.transparent-btn {
    background-color: white !important;
    color: @inverted-color !important;
    border: 1.3px solid @inverted-color !important;
  }

  .custom-table-upload-btn:hover {
    color: @hover-green !important;
    border: 1px solid @hover-green !important;
  }

  .ui.mini.icon.button.custom-table-upload-btn.custom-table-upload-btn__up-down.transparent-btn:hover {
    color: @hover-green !important;
    border: 1px solid @hover-green !important;
  }

  //MEDIA QUERIES
  @media (max-height: 750px) {
    .table__flex-container {
      overflow: scroll;
      height: 54vh;
    }
  }

  @media (min-height: 750px) {
    .table__flex-container {
      overflow: scroll;
      height: 64vh;
    }
  }
}

.tna__inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  z-index: 10;

  .ui.search.selection.dropdown.custom-editor {
    min-width: 180px !important;
    width: 180px !important;
    height: 38px !important;
    margin-right: 12px;
    margin-top: 10px;
  }

  .ui.input:first-child {
    margin-right: 12px;
    width: 200px;
    margin-top: 10px;
    max-height: 38px;
  }

  .ui.inline.dropdown > .text {
    font-weight: 100 !important; //machine related dropdown
  }

  .planit-users-inputs.planit-user-dropdown.planit-user-selector {
    min-width: 160px !important;
    margin-right: 12px;
    margin-top: 10px;
    height: 38px;

    i {
      color: grey;
    }
  }

  .question-bank__multiple-dropdown {
    min-width: 180px;
    max-width: 460px;
    min-height: 38px;
    margin-top: 10px;
    margin-right: 12px;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;

    .icon {
      color: #474b4fbd;
    }

    a.ui.label {
      border: 1px solid #474b4f;
      border-radius: 11px;
      background-color: transparent;

      > .delete.icon {
        align-self: center !important;
        margin-bottom: 1.5px !important;
        color: white !important;
        background-color: #474b4f !important;
        opacity: 1 !important;
        border-radius: 100% !important;
      }
    }

    i.delete.icon::before {
      color: white !important;
      background-color: #474b4f !important;
      opacity: 1 !important;
      border-radius: 100% !important;
      font-size: 7px;
      padding: 4px !important;
      transition: opacity 0.1s ease;
      z-index: 3 !important;
      margin-bottom: 1.5px !important;
    }
  }
}

.ssa__inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 70%;

  .ui.search.selection.dropdown.custom-editor {
    min-width: 180px !important;
    width: 180px !important;
    height: 38px !important;
    margin-right: 12px;
    margin-top: 10px;
  }

  .ui.input:first-child {
    margin-right: 12px;
    width: 200px;
    margin-top: 10px;
    max-height: 38px;
  }

  .ui.inline.dropdown > .text {
    font-weight: 100 !important; //machine related dropdown
  }

  .planit-users-inputs.planit-user-dropdown.planit-user-selector {
    min-width: 160px !important;
    margin-right: 12px;
    margin-top: 10px;
    height: 38px;

    i {
      color: grey;
    }
  }

  .question-bank__multiple-dropdown {
    min-width: 180px;
    max-width: 460px;
    min-height: 38px;
    margin-top: 10px;
    margin-right: 12px;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;

    .icon {
      color: #474b4fbd;
    }

    a.ui.label {
      border: 1px solid #474b4f;
      border-radius: 11px;
      background-color: transparent;

      > .delete.icon {
        align-self: center !important;
        margin-bottom: 1.5px !important;
        color: white !important;
        background-color: #474b4f !important;
        opacity: 1 !important;
        border-radius: 100% !important;
      }
    }

    i.delete.icon::before {
      color: white !important;
      background-color: #474b4f !important;
      opacity: 1 !important;
      border-radius: 100% !important;
      font-size: 7px;
      padding: 4px !important;
      transition: opacity 0.1s ease;
      z-index: 3 !important;
      margin-bottom: 1.5px !important;
    }
  }
}
