.no-machine-related-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.no-machine-related-wrapper .no-machine-related-wrapper__element {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.no-machine-related-wrapper .no-machine-related-wrapper__element label {
  width: 120px;
}
.no-machine-related-wrapper .no-machine-related-wrapper__element .search {
  margin-right: 33px;
  min-width: 130px;
  width: 130px;
}
