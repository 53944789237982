.wizard__steps-container {
  padding: 0px 15px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper {
  margin-left: 0px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .fields {
  width: 100%;
  display: flex;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .fields .field.machinery__label-input-field {
  width: 175px;
  margin-right: 16px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .fields .field.machinery__label-input-field label {
  font-weight: bold;
  font-size: 12px;
}
.wizard__steps-container .wizard__step-two .custom-editor {
  min-width: 175px !important;
  max-width: 175px !important;
  margin-top: 8px;
  margin-bottom: 0 !important;
}
.wizard__steps-container .wizard__step-two .editor-38px .ui.search.selection.dropdown {
  min-height: 38px;
  height: unset !important;
  padding: 10px;
}
.wizard__steps-container .wizard__step-two .editor-38px .ui.search.selection.dropdown input {
  padding: 10px;
}
.wizard__steps-container .wizard__step-two .editor-38px .ui.search.selection.dropdown i.dropdown.icon {
  padding: 9px !important;
}
.wizard__steps-container .wizard__step-two .editor-38px .ui.search.selection.dropdown.loading.loading.loading.loading i.dropdown.icon {
  padding: 17px !important;
}
.wizard__steps-container .wizard__step-two .editor-38px .ui.search.selection.dropdown div.text {
  font-size: 12px !important;
  width: calc(100% - 20px) !important;
  top: 1px;
}
.wizard__steps-container .wizard__step-two .dropdown.machinery-step__multi-machine-model-editor.multiple.search.selection.ui {
  min-height: 38px !important;
  max-height: fit-content !important;
  height: max-content !important;
  margin-top: 8px;
  padding-right: 2em;
}
.wizard__steps-container .wizard__step-two .dropdown.machinery-step__multi-machine-model-editor.multiple.search.selection.ui .search {
  margin: 0px;
  min-height: 38px !important;
  height: fit-content !important;
  padding: 0px;
}
