@import './variables.less';

.main-tab {
  height: 100%;
  width: 100%;
  padding: 0px 15px;

  .ui.pointing.secondary.menu {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 3px solid @inverted-color;

    .item {
      font-weight: bold;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
      border-bottom: 3px solid @inverted-color;
      margin: 0em 0em -3px;
    }

    .active.item,
    .active.item:hover {
      margin: 0em 0em -3px;
      border-bottom-width: 3px;
      color: #00b2a9;
      border-bottom-color: #00b2a9;
    }

    .item:hover {
      margin: 0em 0em -3px;
      border-bottom-width: 3px;
      color: #00b2a9;
      border-bottom-color: #00b2a9;
    }
  }

  .ui.segment.active.tab {
    height: 99%;
    border: none;
    background-color: transparent;
    border-style: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.main-tab.sub-tab {
  .ui.pointing.secondary.menu {
    border-bottom: none !important;

    .active.item,
    .active.item:hover {
      color: #b0b0b0;
      border-bottom-color: #b0b0b0;
    }

    .item:hover {
      color: #b0b0b0;
      border-bottom-color: #b0b0b0;
    }
  }
}
