.development-plan-by-location__wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;

  .development-plan-by-location__filters {
    justify-content: start;
    display: inline-block;
    flex-wrap: wrap;
    margin-bottom: 5px !important;

    .custom-editor {
      margin-right: 12px;
      min-width: 150px !important;
      max-width: 150px !important;
      margin-bottom: 5px;
    }

    .custom-editor-search {
      margin-right: 12px;
      margin-bottom: 5px;
    }
  }

  .custom-table-main-actions.menu {
    display: none;
  }

  .ui.table.custom-table thead tr:first-child > th:first-child {
    padding-left: 15px !important;
  }

  .ui.table.custom-table tbody tr > td:first-child {
    padding-left: 15px !important;
  }
}

.users-per-locations__container {
  .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
    margin-top: -37px;
  }
}
