.planit-main-container #main-container-grid #content-container h3:first-child {
  text-transform: none;
}

.template-wizard-title {
  margin-top: 0 !important;
}

.template-wizard__step-title {
  font-size: 14px !important;
}

.iws-wizard__steps-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0px 15px;

  p {
    font-size: 12px;
  }

  //General styles
  .iws-wizard__step {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;

    .iws-wizard__label-title.flex-start {
      align-items: center !important;
      width: fit-content;
      margin-bottom: 32px;

      p {
        font-weight: bold;
      }

      .step__label-style {
        width: 38px;
        height: 38px;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 0px;
        margin-right: 24px;
        color: white;
        background-color: #00b2a9;
      }
    }

    .add-row-btn {
      height: 42px !important;
    }

    .inactive-step {
      p {
        color: #dedfdf;
      }

      .step__label-style {
        background-color: #dedfdf !important;
      }
    }
  }

  //Specific Styles by step content

  //step two styles
}

.iws-templates-wizard__btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  margin-top: 27px;
  margin-bottom: 34px;

  .button.button.button.button.button {
    &:first-child {
      margin-right: 16px;
      box-shadow: none !important;
      border: 1px solid #474b4f;
    }
  }
}

.iws-confirmation-modal {
  background-color: #474b4f !important;

  .iws-confirmation-modal__content {
    background-color: #474b4f !important;
    color: white;
  }

  .actions {
    .iws-confirmation-modal__btn {
      text-align: center;
      font-size: 12px !important;
      padding: 8px;
      height: 32px !important;
      min-width: 64px !important;
      width: fit-content !important;
      color: #474b4f;

      &.cancel {
        background-color: transparent;
        color: white;
      }
    }
  }
}
