.ui.modal.change-status-popup {
  background: #474b4f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  align-self: center !important;
}

.ui.modal > .content.change-status-popup__content {
  background: #474b4f 0% 0% no-repeat padding-box;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 24px;
  align-self: center !important;
}

.change-status-popup__buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 24px 16px 0px;
}

.ui.button.change-status-popup__btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 4px;
  opacity: 1;
  height: 32px;
  letter-spacing: 0.24px;
  color: #474b4f;
  padding: 0px 5px;
  min-width: 75px;
}
