.tna-question-bank__modal {
  width: 742px !important;

  .ui.form .fields {
    margin: 0px !important;
    width: 100% !important;
  }

  .image.content {
    padding-right: 30px !important;
  }

  .tna-squestion-bank__modal-header {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .tna-question-bank__wrapper {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    width: 98.3%;
    padding: 20px 0px 0px 0px !important;
    overflow-y: scroll;
    max-height: 65vh !important;

    .tna-question-bank__selctor-group {
      padding-right: 20px;
      width: 80%;
      display: flex !important;
      justify-content: space-between !important;

      .tna-question-bank__label-input {
        margin-bottom: 12px !important;
        display: flex !important;
        justify-content: flex-end;
        width: auto;

        label {
          margin-right: 16px;
          width: 100px;
          text-align: right;
          margin-top: 9px;
        }
      }
    }
  }

  .tna-question-bank__selctor-textarea {
    display: flex !important;
    justify-content: center !important;
    padding-left: 15px;

    .ui.button {
      margin: 0px 0px 0px 24px !important;
    }

    .tna-question-bank__label-textarea {
      display: flex !important;
      justify-content: flex-end !important;
      margin-bottom: 12px !important;

      width: 94%;

      label {
        width: 80px;
        text-align: end;
        padding-right: 16px;
        margin-top: 10px !important;
      }

      .tna-question-bank__custom-editorequest__textarea {
        width: 406px;
        height: 64px;
        font-weight: 600;
        resize: none !important;
      }
    }
  }

  .custom-table-add-row-btn {
    min-width: 100px;
    margin-left: 16px !important;
    max-height: 32px;
  }

  .tna-question-bank__new-rows {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-start !important;

    .tna-question-bank__label-textarea {
      display: flex !important;
      justify-content: flex-end !important;
      width: 70%;
    }

    .tna-question-bank__new-rows__textarea {
      width: 406px;
      height: 42px;
      margin-bottom: 12px !important;
      font-weight: 600;
      resize: none !important;
    }

    .tna-question-bank__new-rows__drop-remove {
      display: flex !important;
      justify-content: flex-start !important;
      width: 18.7%;
      margin-left: 16px !important;

      .search {
        min-width: 100px !important;
        width: 100px !important;
      }

      .new-pattern__remove-icon {
        width: 5px;
        align-self: center;
      }

      .red.remove.icon.clickable-icon.remove-icon {
        padding-top: 0px;
        margin-left: 10px !important;
      }
    }
  }

  .actions.new-tna-question-bank__btns {
    margin-right: 18px !important;
    margin-top: 15px !important;
    padding-bottom: 24px !important;
  }
}

// @media all and (max-width: 1280px) {
//   .tna-question-bank__modal {
//     width: 672px !important;
//     height: 403px !important;
//   }
// }
