.evaluation-search-container {
  top: 129px;
  left: 212px;
  width: 98%;
  min-height: 245px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eeeeee;
  opacity: 1;
  padding: 0px 15px !important;
  margin: auto;
}
.evaluation-search-container .ui.error.message {
  margin: 38px !important;
  width: 93%;
}
.evaluation-search-title {
  margin: 32px 32px 40px 32px !important;
}
.evaluation-search-iws-profile-skill-table-header {
  background: #ffffff !important;
  color: #000000 !important;
  text-align: center !important;
}
.evaluation-search__filters {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin-left: 31px !important;
  flex-wrap: wrap;
  width: 95%;
}
.evaluation-search__filters .custom-editor {
  margin-right: 12px;
  min-width: 160px !important;
  max-width: 160px !important;
  margin-bottom: 5px;
}
.evaluation-search__filters .custom-editor-search {
  margin-right: 12px;
  margin-bottom: 5px;
}
.evaluation-search__filters .planit-multi-dropdown__options {
  width: auto !important;
  margin: 0.6rem !important;
}
.evaluation-search__filters .planit-multi-dropdown__options .ui.fitted.checkbox {
  width: 3px !important;
}
.evaluation-search__filters .planit-multi-dropdown .planit-multi-dropdown__selected-option__number {
  margin-right: 1em !important;
}
.evaluation-search__filters .planit-multi-dropdown .planner-instructor__input-search {
  width: auto !important;
}
.evaluation-search__filters div:nth-child(3) > div > div.menu.visible.transition > div.planit-multi-dropdown__options > div > div {
  width: 3px;
}
.evaluation-search__checkbox-btn {
  width: 95%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 32px !important;
  padding-top: 15px;
}
.evaluation-search__checkbox-btn .inverted-color-btn {
  width: 160px !important;
}
#evaluation-search-footer {
  margin-top: 8em;
}
#evaluation-search-footer p {
  text-align: center;
  align-content: center;
  color: #474b4f;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
}
#evaluation-search-container_evaluation-result {
  padding-bottom: 1em;
}
#evaluation-result-filters {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin-left: 32px !important;
  flex-wrap: wrap;
  width: 95%;
  padding-top: 32px;
  min-height: calc(245px - 1em);
  margin-bottom: -34px;
}
#evaluation-result-filters #evaluation-result-filters_profile {
  width: 20%;
}
#evaluation-result-filters #evaluation-result-filters_profile .ui.label {
  border-radius: 0;
  background: #ccf0ee 0% 0% no-repeat padding-box !important;
  opacity: 1;
  color: #474b4f !important;
}
#evaluation-result-filters #evaluation-result-filters_profile-definition {
  max-width: 60%;
  min-width: 50%;
}
#evaluation-result-filters #evaluation-result-filters_profile-definition .grid .row:first-child {
  padding-bottom: 0 !important;
}
#evaluation-result-filters #evaluation-result-filters_profile-definition .grid .row:last-child {
  padding-top: 0 !important;
}
#evaluation-result-filters #evaluation-result-filters_profile-definition .grid .row:not(:first-child):not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#evaluation-result_modify-search-btn-container {
  text-align: right;
  padding-right: 3%;
  height: 34px;
  display: flex;
  justify-content: flex-end;
}
#evaluation-result_modify-search-btn-container #evaluation-result-filters_modify-search-btn {
  width: 160px;
}
#dynamic-evaluation-table {
  margin-top: 24px;
}
#dynamic-evaluation-table .custom-table-clear-sorts,
#dynamic-evaluation-table .custom-table-clear-filters,
#dynamic-evaluation-table .custom-table-upload-btn {
  height: 32px;
}
#dynamic-evaluation-table .min-column-score {
  color: #e03c31 !important;
}
#dynamic-evaluation-table .max-column-score {
  color: #4cd964 !important;
}
#dynamic-evaluation-table .evaluation_number-input {
  width: 40px;
  height: 22px;
  margin: 0 !important;
  text-align: center;
  border-radius: 4px;
}
#dynamic-evaluation-table .evaluation_number-input input {
  border: 1px solid #474b4f;
  padding: 1px !important;
  text-align: center;
}
#dynamic-evaluation-table tr.active.active.active.active {
  background: unset !important;
}
#dynamic-evaluation-table .evaluation_download-btn.evaluation_download-btn.evaluation_download-btn {
  width: 127px;
  height: 38px !important;
  padding: 5px !important;
  border: 1px solid #474b4f;
  border-radius: 4px;
  background-color: unset !important;
  color: #474b4f !important;
  display: -webkit-box;
  display: flex;
  box-shadow: none !important;
}
#dynamic-evaluation-table .evaluation_download-btn.evaluation_download-btn.evaluation_download-btn:hover {
  color: #00b2a9 !important;
  border: 1px solid #00b2a9 !important;
}
#dynamic-evaluation-table .evaluation_download-btn.evaluation_download-btn.evaluation_download-btn .button-text {
  width: 80%;
  height: 20px;
  padding-top: 4px;
  padding-left: 5px;
}
#dynamic-evaluation-table .evaluation_download-btn.evaluation_download-btn.evaluation_download-btn .button-icon {
  width: 20%;
}
#dynamic-evaluation-table .evaluation_download-btn.evaluation_download-btn.evaluation_download-btn .button-icon svg {
  font-size: 1.5em;
}
@media all and (max-width: 1280px) {
  .evaluation-search-title {
    margin: 24px !important;
  }
  .evaluation-search__filters {
    margin-left: 25px !important;
  }
  .evaluation-search__checkbox-btn {
    margin-left: 24px !important;
  }
  .evaluation-search-container {
    padding: 0px 15px !important;
    margin: auto;
  }
  .evaluation-search-container .ui.error.message {
    margin: 30px;
    width: 93%;
  }
  #evaluation-result-filters_profile-definition {
    max-width: 60%;
    min-width: 40%;
  }
}
