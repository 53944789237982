.profile-def__title {
  text-transform: capitalize !important;
}
.profile-def__title + .planit-line-separator {
  margin-bottom: 0 !important;
}
.profile-definition__wrapper {
  padding: 0px 15px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between !important;
}
.profile-definition__wrapper .flex-between {
  width: 100%;
}
.profile-definition__wrapper .custom-table-main-actions.menu {
  margin-right: 0px !important;
  width: fit-content !important;
}
.profile-definition__wrapper .custom-table-main-actions.menu .fitted.item {
  margin-right: 0px !important;
}
.profile-definition__wrapper .custom-table-main-actions.menu .custom-table-add-btn .icon {
  display: none;
}
.profile-definition__wrapper .ui.right.floated.menu {
  position: absolute !important;
  right: 15px !important;
}
.profile-definition__wrapper .table__flex-container {
  width: 45% !important;
  overflow: visible;
  margin-top: 48px;
  height: fit-content !important;
  border-bottom: 1px solid #eeeeee;
}
.profile-definition__wrapper .table__flex-container > table > tbody > tr:hover {
  background-color: #eeeeee !important;
}
.profile-definition__wrapper .table__flex-container > table > thead > tr > th:nth-child(1) {
  min-width: 20px !important;
  width: 20px !important;
}
.profile-definition__wrapper .table__flex-container > table > thead > tr > th:nth-child(4) {
  min-width: 100px !important;
  width: 100px !important;
}
.profile-definition__wrapper .table__flex-container .row-selected {
  background-color: #eeeeee;
}
.profile-definition__wrapper .ui.secondary.menu.item {
  margin: 0px !important;
}
.profile-definition__wrapper .profile-detail-wrapper {
  min-height: 70vh;
  height: fit-content;
  max-width: 55% !important;
  width: 55% !important;
  border: 1px solid #dedfdf;
  margin-top: 48px;
  font-size: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding: 18px 18px 60px;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__header {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__header p {
  margin: auto;
  width: 80%;
  font-weight: bold;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table {
  width: 90%;
  min-width: fit-content;
  border-spacing: 0 8px;
  justify-content: center;
  margin-top: 50px;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table td {
  min-height: 16px !important;
  max-height: 16px !important;
  height: 16px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-weight: 500;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table th {
  width: 10%;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table th:nth-of-type(1) {
  width: 20%;
  min-width: 20%;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-header {
  text-align: center;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows {
  text-align: center;
  min-height: 16px !important;
  max-height: 16px !important;
  height: 16px !important;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows :nth-child(2) {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows :last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows .profile-skill {
  text-align: right;
  padding-right: 16px;
  font-weight: 600;
  max-width: fit-content;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows .table__short-text {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows .table__tooltip .table__short-text {
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 210px;
  text-align: end;
  white-space: nowrap;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows .table__tooltip .table__short-text .no-margin {
  margin: 0;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-level {
  background-color: #00b2a933;
  height: 19.33px !important;
  padding: 0px;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-level .item {
  border-radius: 50% !important;
  width: 18px;
  height: 18px;
  display: inline-block;
  line-height: 16.3px;
  user-select: none;
  cursor: pointer;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-level .substitute {
  color: #00b2a9;
  background: white;
  border: 1px solid #00b2a9;
}
.profile-definition__wrapper .profile-detail-wrapper .profile-detail__table .profile-level .main {
  color: white;
  background-color: #00b2a9;
  border: 1px solid transparent;
}
.profile-definition__wrapper .empty-profile-definition-detail {
  text-align: center;
  font-weight: bold;
  min-width: fit-content;
  width: 55% !important;
  border: 1px solid #dedfdf;
  margin-top: 48px;
  height: fit-content;
  background-color: white;
  display: block;
  padding: 60px 18px;
}
@media all and (max-width: 1211px) {
  .profile-definition__wrapper {
    padding: 0px 15px;
  }
  .profile-definition__wrapper .table__flex-container {
    width: 53% !important;
    overflow: visible;
  }
  .profile-detail-wrapper,
  .empty-profile-definition-detail {
    max-width: 47% !important;
    width: 47% !important;
  }
}
@media all and (max-width: 1060px) {
  .profile-definition__wrapper {
    padding: 0px 15px;
  }
  .profile-definition__wrapper .table__flex-container {
    width: 55% !important;
    overflow: visible;
  }
  .profile-detail-wrapper,
  .empty-profile-definition-detail {
    max-width: 45% !important;
    width: 47% !important;
  }
}
@media all and (min-width: 1280px) {
  .profile-detail-wrapper {
    padding: 18px 18px 60px;
  }
  .profile-detail-wrapper .profile-detail__table {
    border-spacing: 0 8px;
  }
  .profile-detail-wrapper .profile-detail__table td {
    min-height: 24px !important;
    max-height: 24px !important;
    height: 24px !important;
  }
  .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows :nth-child(2) {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
  .profile-detail-wrapper .profile-detail__table .profile-detail__table-body-rows :last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .profile-detail-wrapper .profile-detail__table .profile-level {
    height: 24px !important;
  }
  .profile-detail-wrapper .profile-detail__table .profile-level .item {
    width: 23.5px !important;
    height: 23.5px !important;
    line-height: 23px !important;
  }
}
