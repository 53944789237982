.ssa-new-development-plan-modal {
  display: flex !important;
  flex-direction: column !important;
  width: 70vw;
  max-width: 1230px !important;
  transition: ease-in 0.8s;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__header {
  padding: 24px 32px 20px 32px !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__content {
  padding: 24px 32px 12px 32px !important;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__actions {
  padding: 12px 32px 24px 32px !important;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field {
  min-height: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  margin-right: 8px;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field .employee-select-btn {
  border: 1px solid #474b4f;
  color: #474b4f !important;
  opacity: 1;
  line-height: 13px;
  box-shadow: none !important;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field .employee-select-btn > .user.plus.icon {
  font-size: 14px;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field label {
  font-weight: bold;
  min-width: 95px;
  margin-right: 38px;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field .flex-column label {
  font-weight: normal;
  padding-bottom: 10px !important;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field.ssa-new-development-plan-modal__machinery > label {
  margin-top: 20px;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field .ssa-new-development-plan-modal__machinery__field {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field .custom-editor {
  margin-right: 12px;
  max-width: 165px !important;
  margin-bottom: 5px;
}
.ssa-new-development-plan-modal .ssa-new-development-plan-modal__field.flex-start label {
  padding-top: 10px;
}
@media all and (min-width: 1920px) {
  .ssa-new-development-plan-modal {
    width: 70% !important;
  }
}
