.modal-header-title {
  font-size: 16px !important;
}
.search__modal .search__modal__header.header {
  border-bottom: 1px solid rgba(181, 181, 181, 0.342) !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}
.fit-content {
  width: fit-content;
}
.text__center {
  text-align: center;
}
.add-row-btn {
  background-color: #eeeeee !important;
  border: 1px solid #eeeeee !important;
  padding: 2px !important;
}
.question-bank__cell__tag {
  background-color: #eeeeee;
  margin: 5px;
  color: #474b4f;
  font-weight: 500;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  border-radius: 10px;
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.text__regular {
  font-weight: 500;
}
.text__bold {
  font-weight: 700 !important;
}
.flex-baseline {
  display: flex;
  align-items: baseline;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.padding-margin-right-none {
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.text__grey {
  color: grey;
}
.margin-zero {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.padding-zero {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.form__box-content {
  border: 1px solid #c1c5c5 !important;
  background-color: white !important;
}
.form__header__row {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 100% !important;
  margin-bottom: 24px !important;
}
.form__header__row-icon {
  margin-right: 24px !important;
  font-size: 18px !important;
  margin-bottom: 5px !important;
  color: #474b4f !important;
  height: 18px !important;
  width: 18px !important;
}
.form__field-numeric {
  width: 70px !important;
  height: 32px !important;
  margin-right: 50px;
}
.form__planned-duration__numeric__wd {
  width: 90px !important;
}
.form__planned-duration__numeric__wd input {
  min-width: 90px;
}
.text__transparent {
  color: transparent !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #dedfdf !important;
}
.students-table__column-title {
  background-color: #eeeeee !important;
  width: 100% !important;
  padding: 8px !important;
  margin-right: 0px !important;
}
.form__tab__icon-element {
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}
.error-message__style {
  width: 95%;
  border: 1px solid #e03c31;
  background-color: white !important;
  color: #222629 !important;
  font-size: 11px;
  margin-bottom: 32px;
  box-shadow: none !important;
}
.error-message__style .list {
  margin: 0px;
}
.error-message__style li.content::before {
  display: none;
}
.error-message__style .icon {
  font-size: 16px !important;
  color: #e03c31;
  align-self: flex-start;
}
.error-message__style .header {
  padding-left: 14px !important;
  color: #e03c31 !important;
}
.ui.modal .actions.form__action-buttons-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #eeeeee !important;
  padding: 16px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__actions__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-right: 16px;
  font-weight: 500;
}
.ui.modal .actions.form__action-buttons-wrapper .form__align-right-btns {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
}
.ui.modal .actions.form__action-buttons-wrapper .form__white-btn {
  background-color: white;
  color: #474b4f;
  border: 1px solid #474b4f;
}
.ui.modal .actions.form__action-buttons-wrapper .form__white-btn .icon {
  color: #474b4f;
  background-color: transparent;
  padding: 0px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__request-changes-btn {
  padding: 10px;
  min-width: 120px;
}
.ui.modal .actions.form__action-buttons-wrapper .form__next-status-accept-btn {
  background-color: #00b2a9;
  color: white;
  font-weight: 600;
}
.ui.modal .actions.form__action-buttons-wrapper .request-form__reject-button {
  background-color: white;
  color: #e03c31;
  border: 1px solid #e03c31;
}
.form__cancel-btn {
  background-color: #ec8a83 !important;
  color: white !important;
  min-width: 110px;
  padding: 2px;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    width: 40px;
  }
  100% {
    opacity: 1;
    width: fit-content;
  }
}
.scroll {
  overflow-y: auto;
  scroll-padding: 5px !important;
  overflow-x: hidden;
  transition: 0.9s;
}
.scroll::-webkit-scrollbar {
  width: 4px;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #000000;
}
.scroll-not-visible {
  overflow-y: scroll;
  overflow-x: hidden;
}
.scroll-not-visible::-webkit-scrollbar {
  width: 0px;
}
.modal__action-warning {
  background-color: #f7cecb !important;
  color: #222629;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  width: 40px;
  border-radius: 4px;
  margin: 20px 0px 20px 0px;
  text-align: center !important;
  transition: all 0.8s linear;
}
.modal__action-warning .modal__action-warning__text {
  display: none;
  transition: all 0.8s linear;
}
.modal__action-warning .icon {
  color: #e03c31;
  font-size: 17px;
  margin-right: 0px !important;
  align-self: flex-start;
}
.modal__action-warning div {
  display: flex !important;
  flex-direction: column !important;
  transition: all 0.8s linear;
}
.modal__action-warning div p {
  width: fit-content;
}
.modal__action-warning:hover {
  width: fit-content;
  transition: all 0.8s linear;
}
.modal__action-warning:hover .modal__action-warning__text {
  display: flex;
  transition: all 0.8s linear;
}
.modal__action-warning:hover .icon {
  margin-right: 10px !important;
  transition: all 0.8s linear;
}
.year-editor {
  display: inline-block;
  height: 36px !important;
}
.year-editor .ui.icon.input,
.year-editor .remove.link.icon {
  height: 36px !important;
}
.b-drag-proxy {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
  background: white !important;
  border-radius: 4px;
  border-left-width: 10px;
  border-style: solid;
}
.b-drag-invalid {
  opacity: 0.6;
  color: #474b4f;
  font-weight: bolder;
  border-right-style: none;
  border-top-style: none;
  border-bottom-style: none;
  margin: auto;
}
.b-drag-proxy:not(.b-drag-invalid) {
  opacity: 1;
  word-break: break-all !important;
  color: #474b4f;
  font-weight: bolder;
  border-right-style: none;
  border-top-style: none;
  border-bottom-style: none;
  margin: auto;
}
.drag-element-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1560px) {
  .sch-locations__header .sch-locations__header-below {
    flex-wrap: nowrap !important;
  }
  .sch-locations__header .sch-locations__header-below .sch-locations__header-left {
    flex-wrap: wrap;
  }
  .sch-locations__header .sch-locations__header-below .sch-locations__header-left b {
    margin-left: 0 !important;
  }
  .sch-locations__header .sch-locations__header-below .sch-locations__header-left div.viewpreset {
    margin-right: 8px;
  }
  .sch-locations__header .sch-locations__header-below .sch-locations__header-right {
    margin-left: 16px;
    margin-bottom: 16px;
  }
  .sch-locations__header .sch-locations__header-below .sch-locations__header-right .sch-locations__header-right-filters {
    flex-wrap: wrap !important;
    align-self: center !important;
    margin-left: 0 4px;
  }
  .sch-locations__header .sch-locations__header-below .sch-locations__header-right .sch-locations__header-right-filters > div {
    margin: 4px;
  }
}
.b-mask {
  z-index: 4;
}
.sch-locations__inputs-hidden-wrapper {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.sch-locations__header-right-filters {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}
.sch-locations__container {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.sch-locations__container .sch-locations__scheduler-component {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.sch-locations__container .sch-locations__scheduler-component.grow {
  height: 90%;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container {
  width: calc(80% - 24px);
  max-height: 100%;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-grid-header-container {
  height: 64px;
  background-color: #f3f4f5 !important;
  margin: 0 !important;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-grid-header {
  padding: 0;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-grid-headers-locked .b-grid-header-text {
  text-transform: capitalize;
  text-align: center;
  font-size: 14px;
  padding: 0.5em 0;
  color: #222629 !important;
  font-weight: bold;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-filter-bar-field {
  margin: 0;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-filter-bar-field .b-field-inner {
  border: 1px solid #474b4f;
  border-radius: 4px;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-filter-bar-field .b-field-inner .b-icon-remove {
  padding-right: 0.8em;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-filter-bar-field .b-filter-bar-field-input {
  padding: 0.2em 0.1em 0.2em 1.5em;
  height: 32px;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-grid-headers-normal .b-sch-header-row {
  text-transform: capitalize;
  text-align: center;
  font-size: 14px;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-grid-headers-normal .b-sch-header-row .b-sch-header-timeaxis-cell {
  font-weight: bold !important;
  color: #222629 !important;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container .b-container {
  border-radius: 8px 0 0 8px;
  background: #f2f3f3;
}
.sch-locations__container .sch-locations__scheduler-component .b-react-scheduler-container.collapse {
  width: calc(100% - 24px);
}
.sch-locations__container .sch-locations__scheduler-component .request-table-container {
  display: flex;
  max-height: 100%;
  width: 20%;
}
.sch-locations__container .sch-locations__scheduler-component .request-table-container.collapse {
  width: 0;
}
div.sch-locations__inputs-hidden-wrapper > div {
  min-width: 140px !important;
  height: 38px;
  border: 1px solid #474b4f !important;
  border-radius: 4px;
  margin-right: 8px;
}
.sch-locations__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.sch-locations__header .sch-locations__header-above {
  display: flex;
}
.sch-locations__header .sch-locations__header-below {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sch-locations__header .sch-locations__header-left {
  display: flex;
  align-items: center;
}
.sch-locations__header .sch-locations__header-left b {
  margin-left: 8px;
  align-self: flex-start;
}
.sch-locations__header .sch-locations__header-right {
  display: flex;
  align-items: center;
}
.sch-locations__header .sch-locations__header-right .sch-locations__header-right-buttons {
  align-content: center;
  display: flex;
}
.sch-locations__header .sch-locations__header-right .sch-locations__header-right-buttons i {
  font-size: 16px;
}
.sch-locations__header .sch-locations__header-right .sch-locations__header-right-buttons button {
  margin-left: 8px;
}
.sch-locations__header .sch-locations__header-right .sch-locations__header-right-buttons .event-type-filter-icon-btn {
  width: 38px;
  height: 38px;
  background: #474b4f;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  border: none;
  outline: none;
}
.sch-locations__header .sch-locations__header-right .sch-locations__header-right-buttons .event-type-filter-icon-btn.active {
  background: #00b2a9 !important;
}
.sch-locations__header .sch-locations__header-right .sch-locations__header-right-buttons .event-type-filter-icon-btn .hide-filter {
  text-shadow: unset !important;
  color: white !important;
  opacity: 1;
  background-color: red;
  border-radius: 1em;
  font-size: 0.7rem;
  height: 12px;
  width: 12px;
  line-height: 11px;
  left: 44%;
  bottom: 10%;
}
.sch-locations__header .sch-locations__header-right .sch-locations__header-right-buttons .event-type-filter-icon-btn i.calendar-gears {
  margin: 0;
}
.sch-locations__header .planit-multi-dropdown {
  min-width: 140px !important;
  height: 38px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid #474b4f !important;
}
.inputs-visible-wrapper {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}
.b-grid-subgrid-normal {
  background: white;
}
.locations-component__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.b-sch-event {
  padding: 0;
  background-color: white !important;
  color: black;
  border-radius: 4px;
  border-width: 2px 2px 2px 10px;
  border-style: solid;
  border-image: initial;
}
.b-sch-event.draft {
  opacity: 0.6;
}
.b-sch-event.mini-event {
  min-width: 8px !important;
}
.b-sch-event.mini-event .event-template__state {
  color: white !important;
}
.b-sch-event.mini-event .event-template__state svg {
  display: inline;
  color: white !important;
}
.b-sch-event.requested-location {
  border-style: dashed dashed dashed solid;
  border-right-color: #dedfdf !important;
  border-top-color: #dedfdf !important;
  border-bottom-color: #dedfdf !important;
}
.b-sch-event.requested-location .event-template__wrapper {
  background-image: repeating-linear-gradient(45deg, #ccc, #ccc 2px, #fff 2px, #fff 10px);
}
.b-sch-event.requested-location .event-template__wrapper .event-template__state {
  color: #474b4f !important;
}
.b-sch-event .nested-pause-period {
  z-index: 0;
  position: absolute;
  border: none;
  background-image: repeating-linear-gradient(45deg, #ddd, #ddd 10px, #eee 10px, #eee 20px);
}
.b-sch-event:hover {
  background-color: #f7f7f7 !important;
}
.b-sch-event:hover .nested-pause-period {
  background-image: repeating-linear-gradient(45deg, #ccc, #ccc 10px, #ddd 10px, #ddd 20px) !important;
}
.b-sch-event:not(.b-milestone).b-sch-event-selected,
.b-sch-event:not(.b-milestone).b-sch-event-selected * {
  font-weight: bold !important;
  color: #222629 !important;
}
.event-template__wrapper {
  overflow: hidden;
  padding-left: 0.75em;
  width: 100%;
  height: 100%;
  color: #474b4f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.event-template__wrapper i.b-fa {
  font-size: 16px;
}
.event-template__wrapper .event-template__state {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}
.event-template__wrapper .event-template__state .event-template__state-section {
  align-self: center;
  padding-right: 16px;
}
.event-template__wrapper .event-template__state .event-template__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.event-template__wrapper .event-template__state .event-template__info .event-template__info-title {
  margin-bottom: 8px;
}
.event-template__wrapper .event-template__state .event-template__info .event-template__info-items {
  display: flex;
  justify-content: flex-start;
}
.event-template__wrapper .event-template__state .event-template__info .event-template__info-items div {
  margin-right: 8px;
}
.event-template__wrapper .event-template__conditionals {
  justify-self: self-end;
  color: #222629;
}
.event-template__wrapper .event-template__conditionals i.b-fa-circle {
  color: #e03c31 !important;
}
.grid-splitter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 8px 8px 0;
  background: #dedfdf;
  min-width: 24px;
  cursor: pointer;
}
.grid-splitter:hover {
  background: #d3d3d3;
}
.grid-splitter::before {
  font-size: 16px;
}
.event-vacation-template__wrapper {
  padding-left: 0.75em;
  width: 100%;
  height: 100%;
  color: #474b4f;
  background-color: #bdc4cb;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
}
.event-vacation-template__wrapper .event-vacation-template__info-title {
  font-size: 1.5rem;
}
.event-vacation-template__wrapper svg {
  margin-left: 2px;
  margin-right: 8px;
}
