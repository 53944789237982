.template-search__modal.choose-assessors .full-width.table__flex-container {
  overflow-x: scroll;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .ui.input {
  min-width: 200px;
  width: 200px;
  height: 36px !important;
  margin-bottom: 8px;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .ui.input.long-input {
  width: 250px;
  min-width: 250px;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .custom-editor.ui.inline.dropdown {
  min-width: 200px;
  width: 200px !important;
  margin-right: 12px;
  margin-top: 8px;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .custom-editor.ui.inline.dropdown .default.text {
  font-weight: 500 !important;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .custom-editor.ui.inline.dropdown .icon {
  color: #474b4f !important;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .custom-editor.ui.inline.dropdown .dropdown.icon {
  margin: -0.91666667em;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .search {
  font-weight: 500 !important;
}
.template-search__modal.choose-assessors .template-search__first-row__column-filters.choose-assessors .ui.inline.dropdown .dropdown.icon {
  margin: -10px 0px !important;
}
