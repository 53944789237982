.ui.modal.add-expertise-popup {
  //   background: #474b4f 0% 0% no-repeat padding-box;
  //   box-shadow: 0px 3px 6px #00000029;
  //   border-radius: 8px;
  //   opacity: 1;
  //   transform: translateY(35vh);

  background: #474b4f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

.ui.modal > .content.add-expertise-popup__content {
  background: #474b4f 0% 0% no-repeat padding-box;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 24px;
}

.add-expertise-popup__buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 24px 16px 24px;
}

.add-expertise-popup__buttons-left-container {
  display: flex;
  justify-content: flex-start;
}

.add-expertise-popup__buttons-container__right-container {
  display: flex;
  justify-content: flex-end;
}

.ui.button.add-expertise-popup__btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 4px;
  opacity: 1;
  height: 32px;
  letter-spacing: 0.24px;
  color: #474b4f;
  padding: 0px 10px;
  min-width: 75px;
}

.ui.button.add-expertise-popup__pending-btn {
  margin-left: 12px;
  width: 135px;
}

.ui.button.add-expertise-popup__back-btn {
  background-color: #565b60;
  color: #ffffff;
  border-color: #565b60;
}
