.wizard__numeric-input {
  width: 70px !important;
  height: 32px !important;
}
.wizard__step-dates-wrapper {
  min-height: 60%;
}
.wizard__step-dates-wrapper .wizard__numeric-input {
  width: 90px !important;
  height: 32px !important;
}
.wizard__step-dates-wrapper .date-picker__left,
.wizard__step-dates-wrapper .date-picker__right {
  width: 50% !important;
  display: inline-block;
  margin-bottom: 0 !important;
}
.wizard__step-dates-wrapper #date-step__date-input__left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0px !important;
}
.wizard__step-dates-wrapper #date-step__date-input__right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.wizard__step-dates-wrapper .second-row {
  padding-bottom: 0 !important;
}
.wizard__step-dates-wrapper .recalc-commands-row {
  padding-top: 0 !important;
}
.wizard__step-dates-wrapper .recalc-commands-row .recalc-dates-container {
  width: 50%;
  display: inline-block;
}
.wizard__step-dates-wrapper .recalc-commands-row .recalc-command {
  color: #00b2a9;
  cursor: pointer;
  font-size: 0.8em;
}
.wizard__step-dates-wrapper .relative-recalc-command-container {
  position: relative;
}
.wizard__step-dates-wrapper .relative-recalc-command-container #recalc-event-calculated-duration-command {
  position: absolute;
  top: 34px;
  left: 12px;
}
.wizard__step-dates-wrapper .padding-bottom-20px {
  padding-bottom: 20px !important;
}
.wizard__form__margin.wizard__step-dates-wrapper .period-input {
  display: flex;
  flex-wrap: nowrap;
}
.wizard__form__margin.wizard__step-dates-wrapper .period-input .date-picker__left {
  width: 120px !important;
  height: 32px;
  margin-right: -1px !important;
}
.wizard__form__margin.wizard__step-dates-wrapper .period-input .date-picker__left > div > input[type='text'] {
  width: 50% !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  text-align: center !important;
  padding: 4px 20px 4px 2px !important;
}
.wizard__form__margin.wizard__step-dates-wrapper .period-input .field.date-picker__right {
  height: 32px;
  min-width: 120px !important;
  width: 120px !important;
  max-width: 50% !important;
}
.wizard__form__margin.wizard__step-dates-wrapper .period-input .field.date-picker__right > div > input[type='text'] {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-align: center !important;
  padding: 4px 20px 4px 2px !important;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .ui.form .field {
  margin-bottom: 0px !important;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .wizard__step-dates-wrapper__desired.field.required {
  display: flex;
  align-items: center;
  margin: 0px !important;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .wizard__step-dates-wrapper__desired.field.required label {
  margin-right: 12px;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .generic-date-picker__wrapper.field.required.request-wizard__generic-date-picker__wrapper {
  display: flex;
  align-items: center !important;
  height: 32px;
  margin-left: 12px;
  margin-right: 60px;
  margin-bottom: 0px;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .generic-date-picker__wrapper.field.required.request-wizard__generic-date-picker__wrapper label {
  margin-right: 12px;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .generic-date-picker__wrapper.field.required.request-wizard__generic-date-picker__wrapper .date-picker__left {
  width: 120px !important;
  height: 32px;
  margin-right: -1px !important;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .generic-date-picker__wrapper.field.required.request-wizard__generic-date-picker__wrapper .date-picker__left > div > input[type='text'] {
  width: 50% !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  text-align: center !important;
  padding: 4px 20px 4px 2px !important;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .generic-date-picker__wrapper.field.required.request-wizard__generic-date-picker__wrapper .field.date-picker__right {
  height: 32px;
  min-width: 120px !important;
  width: 120px !important;
  max-width: 50% !important;
}
.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper .generic-date-picker__wrapper.field.required.request-wizard__generic-date-picker__wrapper .field.date-picker__right > div > input[type='text'] {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-align: center !important;
  padding: 4px 20px 4px 2px !important;
}
