.request-wizard__step6-comments__container {
  padding: 39px 20px 20px;

  .flex-start-center {
    justify-content: flex-start !important;
    margin-bottom: 24px;
    align-items: flex-start !important;

    label {
      min-width: 70px !important;
      margin-right: 16px;
      text-align: end;
      padding-top: 10px;
    }

    .request-title__input {
      margin-left: 0px;
    }
  }
}
