@import '../../../../assets/less/variables.less';
@import '../shared-scheduler-components/request-drag.style.less';

@media screen and (max-width: 1560px) {
  .sch-instructors__header {
    .sch-instructors__header-below {
      flex-wrap: nowrap !important;

      .sch-instructors__header-left {
        margin-top: -22px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 222px;

        b {
          margin-left: 0 !important;
          height: auto;
          margin-top: 20px;
        }

        div.viewpreset {
          margin-right: 8px;
          margin-top: 19px;
        }

        .sch-month-button {
          margin-top: 20px !important;
        }
      }

      .sch-instructors__header-right {
        margin-left: 16px;
        margin-bottom: 16px;
        display: flex;
      }
    }
  }
}

.b-mask {
  z-index: 4;
}

.inputs-hidden-wrapper {
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  // margin-bottom: 16px;
  flex-wrap: wrap;
}

.sch-instructors__header-right-filters {
  padding: 0px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  max-width: 80%;
  align-items: center;
}

.sch-instructors__container {
  display: flex;
  height: 100%;
  flex-direction: column;

  &.grow {
    height: 90%;
  }

  .sch-instructors__scheduler-component {
    display: flex;
    height: 100%;
    overflow: hidden;

    .b-grid-header-scroller {
      overflow: visible !important;

      &.b-grid-header-scroller-locked {
        .b-grid-headers {
          contain: none !important;
          overflow: visible !important;

          #InstructorsFilters {
            max-width: 200px;
            max-height: 42px;
            // overflow-x: scroll;
          }

          .ui.selection.dropdown {
            max-height: 40px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            padding: 4px 8px;

            input {
              min-width: 80%;
              max-width: 80%;
            }

            i.search.icon {
              height: 100%;
              padding: 6px;
              position: relative !important;
            }

            &.visible a.ui.label {
              display: none;
            }

            overflow-x: visible;

            &:not(.visible) {
              overflow-x: auto;
            }

            &::-webkit-scrollbar {
              cursor: pointer;
              width: 0;
              height: 4px;
            }
          }

          a.ui.label {
            min-width: max-content;
            background-color: @inverted-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 16px;
            color: white;
            font-size: inherit;
            padding: 2px 6px;
            margin: 0 4px;

            i.icon {
              width: 12px;
              height: 12px;
              padding: 1px 2px;
              background: white;
              color: @inverted-color;
              border-radius: 100%;
              opacity: 1;
            }
          }
        }
      }
    }

    .b-react-scheduler-container {
      width: calc(80% - 24px);
      max-height: 100%;

      .b-grid-header {
        padding: 0;
        overflow: visible !important;
      }

      .b-grid-headers-locked .b-grid-header-text {
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;
        padding: 0.5em 0;
        color: #222629 !important;
        font-weight: bold;
        overflow: visible !important;

        .b-grid-header-text-content {
          overflow: visible;
        }
      }

      .b-filter-bar-field {
        margin: 0;

        .b-field-inner {
          border: 1px solid @inverted-color;
          border-radius: 4px;

          .b-icon-remove {
            padding-right: 0.8em;
          }
        }

        .b-filter-bar-field-input {
          padding: 0.2em 0.1em 0.2em 1.5em;
          height: 32px;
        }
      }

      .b-grid-headers-normal .b-sch-header-row {
        text-transform: capitalize;
        text-align: center;
        font-size: 14px;

        .b-sch-header-timeaxis-cell {
          font-weight: bold !important;
          // padding: 0.2em 0;
          color: #222629 !important;
        }
      }

      .b-container {
        border-radius: 8px 0 0 8px;
        background: #f2f3f3;
      }

      .b-grid-header-container {
        background-color: #f3f4f5 !important;
        margin: 0 !important;
      }

      &.collapse {
        width: calc(100% - 24px);
      }

      &.full {
        width: 100%;

        .b-container {
          border-radius: 8px;
        }
      }
    }

    .request-table-container {
      display: flex;
      max-height: 100%;
      width: 20%;

      &.collapse {
        width: 0;
      }
    }
  }
}

div.sch-instructors__inputs-hidden-wrapper > div {
  min-width: 140px !important;
  height: 38px;
  border: 1px solid @inverted-color !important;
  border-radius: 4px;
  margin-right: 8px;
}

.sch-instructors__inputs-visible-wrapper {
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  // flex-wrap: wrap;
  margin-bottom: 16px;
}

.b-grid-subgrid-normal {
  background: white;
}

//InstructorsLocationComponent
.instructor-component__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-left: 8px;

  .instructor-location__wrapper {
    display: flex;
    flex-direction: column;

    .instructor-location__title {
      display: flex;
      font-weight: bold;
      margin-top: 8px;
    }
  }

  .instructor-location__icon {
    font-size: 16px;
    margin-left: 16px;
    margin-bottom: 4px;
    align-self: flex-end;
  }

  .instructor-component__star-wrapper {
    display: flex;
    justify-content: flex-end;

    .instructor-matches {
      color: #00b2a9;
    }
  }
}

.b-sch-event {
  padding: 0;
  background-color: white !important;
  color: black;
  border-radius: 4px;
  border-width: 2px 2px 2px 10px;
  border-style: solid;
  border-image: initial;

  &.draft {
    opacity: 0.6;
  }

  .nested-pause-period {
    height: 100%;
    z-index: 0;
    position: absolute;
    border: none;
    background-image: repeating-linear-gradient(45deg, #ddd, #ddd 10px, #eee 10px, #eee 20px);
  }

  &:hover {
    background-color: #f7f7f7 !important;

    .nested-pause-period {
      background-image: repeating-linear-gradient(45deg, #ccc, #ccc 10px, #ddd 10px, #ddd 20px) !important;
    }
  }
}

.b-sch-event:not(.b-milestone).b-sch-event-selected,
.b-sch-event:not(.b-milestone).b-sch-event-selected * {
  font-weight: bold !important;
}

.event-template__wrapper {
  overflow: hidden;
  padding-left: 0.75em;
  width: 100%;
  height: 100%;
  color: @inverted-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  i.b-fa {
    font-size: 16px;
  }

  .event-template__state {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .event-template__state-section {
      align-self: center;
      padding-right: 16px;
    }

    .event-template__info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .event-template__info-title {
        margin-bottom: 8px;
      }

      .event-template__info-items {
        display: flex;
        justify-content: flex-start;

        div {
          margin-right: 8px;
        }
      }
    }
  }

  .event-template__conditionals {
    justify-self: self-end;
    color: #222629;

    i:nth-child(2) {
      color: #e03c31 !important;
    }
  }
}

.grid-splitter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 8px 8px 0;
  background: @default-grey;
  min-width: 24px;

  &:hover {
    background: #d3d3d3;
  }

  cursor: pointer;

  &::before {
    font-size: 16px;
  }
}

.ui.dropdown.instructor-row-filter {
  width: 100%;

  a.ui.label {
    background-color: #474b4f;
    color: #f2f3f3;
    border-radius: 9px;
    font-weight: normal;

    i.delete.icon {
      // border-radius: 50%;
      // background-color: #ffffff !important;
      color: #ffffff;
      // height: 20px;
      // width: 20px;
      font-weight: normal;
    }
  }

  i.dropdown.icon {
    cursor: pointer;
    position: absolute;
    width: auto;
    height: auto;
    line-height: 1.21428571em;
    top: 0.91666667em;
    right: 1.16666667em;
    z-index: 3;
    margin: -0.91666667em;
    padding: 0.91666667em;
    opacity: 0.8;
    transition: opacity 0.1s ease;
    content: '\f002' !important;
  }
}

@media all and (min-width: 1750px) {
  .sch-instructors__header-right-filters {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 80%;
    align-items: flex-start;
  }
}
