.form__all-wrapper {
  //Footer
  .form__all-wrapper__footer {
    p {
      margin-bottom: 0px;
    }

    .ui.divider {
      border: 0.5px solid #474b4f;
      margin: 40.5px 0px 40.5px 0px;
    }
    &.tna-footer .ui.divider {
      margin: 1rem 0;
    }

    .tna-edit-form__footer__text-area {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }

    .template-section__comments {
      width: 100%;
      min-height: 60px !important;
      height: 60px !important;
    }

    .flex-between {
      margin-top: 16px;
      .flex-start {
        width: 50%;
      }
      .tna-edit-form__footer-considerations {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10px;
        width: 100%;
      }

      .tna-edit-form__footer__check {
        display: flex;
        flex: 1;
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .ui.fitted.checkbox {
          margin-right: 12px;

          label::before {
            border: 1px solid #474b4f;
          }
        }

        .table__tooltip {
          width: fit-content;
          max-width: fit-content;
          min-width: unset;
        }

        .table__tooltip:hover .table__tooltip-top {
          min-width: 360px;
          padding: 2px;
          font-size: 9px;
        }
      }

      .tna-edit-form__footer__btns {
        justify-self: flex-end;
        margin-left: 24px;
      }
    }

    .inverted-color-btn {
      font-weight: 500;
      margin-left: 16px;
    }

    .white-save-btn,
    .inverted-color-btn {
      width: 100px;
      height: 36px;
    }
  }
}
