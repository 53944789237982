.iws-form-general-data {
  max-width: 1002px;
}
.editor-38px.editor-38px.editor-38px.editor-38px .ui.search.selection.dropdown {
  min-height: 38px;
  height: unset !important;
  padding: 10px;
}
.editor-38px.editor-38px.editor-38px.editor-38px .ui.search.selection.dropdown input {
  padding: 10px;
}
.editor-38px.editor-38px.editor-38px.editor-38px .ui.search.selection.dropdown i.dropdown.icon {
  padding: 10px !important;
}
.editor-38px.editor-38px.editor-38px.editor-38px .ui.search.selection.dropdown.loading.loading.loading.loading i.dropdown.icon {
  padding: 18px !important;
}
.editor-38px.editor-38px.editor-38px.editor-38px .ui.search.selection.dropdown div.text {
  font-size: 12px !important;
  width: calc(100% - 20px) !important;
  top: 1px;
}
.iws-form-wizard__step-one__title-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
}
.iws-form-wizard__step-one__title-wrapper > div.ui.input {
  width: 100%;
  margin-right: 0px;
}
.iws-form-wizard__step-one__title-wrapper > label {
  min-width: fit-content;
}
.iws-form-wizard__step-one__location-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  max-width: 35%;
}
.iws-form-wizard__step-one__profile-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  max-width: 35%;
}
.iws-form-wizard__step-one__profile-wrapper div.ui.search.selection.dropdown.custom-editor,
.iws-form-wizard__step-one__profile-wrapper div.ui.input {
  max-height: 38px !important;
}
.iws-form-wizard__step-one__deadline-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  max-width: 50%;
}
.iws-form-wizard__step-one__deadline-wrapper div.field,
.iws-form-wizard__step-one__deadline-wrapper div.ui.icon.input {
  max-width: 160px;
  margin-right: 0px;
}
.wizard__step-one .iws-form-step-one__content {
  display: inline-block;
  width: 100%;
}
.wizard__step-one .iws-form-step-one__content .content_line {
  display: flex;
  margin: 5px;
  justify-content: center;
  align-items: center;
}
.wizard__step-one .iws-form-step-one__content .content_line .ui.search.selection.dropdown.custom-editor {
  width: max-content !important;
}
.wizard__step-one .iws-form-step-one__content .content_line .editor-38px {
  display: inline-flex;
  align-items: center;
}
.wizard__step-one .iws-form-step-one__content .content_line .editor-38px .custom-editor.w-xl {
  min-width: 50%;
  max-width: 80%;
  width: initial !important;
}
.wizard__step-one .iws-form-step-one__content .content_line .editor-38px.w-50 {
  width: 50%;
}
.wizard__step-one .iws-form-step-one__content .content_line .editor-38px.w-33 {
  width: 33%;
}
.wizard__step-one .iws-form-step-one__content .content_line .editor-38px.w-66 {
  width: 66%;
}
.wizard__step-one .iws-form-step-one__content .mt-20 {
  margin-top: 20px;
}
