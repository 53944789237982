.feedbacks-wizard__steps-container {
  padding: 0px 15px;
}
.feedbacks-wizard__steps-container .feedback-wizard__first-step {
  width: 100%;
}
.feedbacks-wizard__steps-container .feedback-wizard__first-step .first-step__content {
  display: flex;
  justify-content: flex-start;
  margin: 16px 0;
  flex-wrap: wrap;
}
.feedbacks-wizard__steps-container .feedback-wizard__first-step .first-step__content .title {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  font-weight: 500;
  width: 45%;
}
.feedbacks-wizard__steps-container .feedback-wizard__first-step .first-step__content .header {
  font-weight: 500;
  display: flex;
  width: 45%;
  flex-direction: column;
}
.feedbacks-wizard__steps-container .feedback-wizard__first-step .first-step__content .respondents {
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 32px 0;
}
.feedbacks-wizard__steps-container .feedback-wizard__first-step .first-step__content .feedback-wizard__textarea {
  max-width: 100%;
  width: 300px;
  min-width: 200px;
  height: 150px;
  min-height: 150px;
  max-height: 200px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #22242626;
  outline: none;
}
.feedbacks-wizard__steps-container .feedback-wizard__first-step .first-step__content .filter-respondents {
  min-width: 120px;
  width: 200px;
  margin-left: 16px;
  min-height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px 8px 8px;
  border: 1px solid #22242626;
  border-radius: 4px;
}
.feedbacks-wizard__steps-container .questions-container .ui.button {
  margin: 0 0 16px 0;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question {
  display: flex;
  align-items: center;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question:first-of-type .tna-wizard__assign-question__items {
  border-top: 1px solid #b0b0b0;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question:last-of-type {
  margin-bottom: 32px;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items {
  border-bottom: 1px solid #b0b0b0;
  padding: 8px;
  margin-right: 20px;
  width: 100%;
  align-items: center;
  display: flex;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__sort-icons-wrapper {
  margin-right: 4px;
  display: flex;
  align-items: center;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__sort-icons-wrapper i {
  font-size: 18px;
  cursor: pointer;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items :first-child {
  border-bottom: 0;
}
.feedbacks-wizard__steps-container .questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items p {
  margin: 0 16px !important;
  font-weight: 500;
}
.eventtype-selector {
  width: 200px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  min-width: 120px !important;
  padding: 8px 14px 8px 14px;
  border: 1px solid #22242626;
  min-height: 38px;
  height: fit-content !important;
  max-height: fit-content !important;
  border-radius: 4px;
}
.eventtype-selector input.search {
  padding: 8px 14px 8px 14px;
  height: 38px;
}
.eventtype-selector .menu.transition.visible .item {
  display: flex;
  align-items: center;
}
.eventtype-selector .menu.transition.visible .item .ui.checkbox {
  margin-right: 16px;
}
.ui.list.event-type-list {
  width: 50%;
}
.ui.list.event-type-list .item {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}
.ui.list.event-type-list .item:last-child span.event-type-list__item {
  border-bottom: 1px solid #b0b0b0;
}
.ui.list.event-type-list .item span.event-type-list__item {
  width: 100%;
  margin-right: 24px;
  border-top: 1px solid #b0b0b0;
  padding: 12px;
}
.ui.list.event-type-list .item i.icon {
  color: #e03c31;
}
.ui.list.event-type-list .item::after {
  display: none !important;
  content: unset !important;
}
.feedbacks-templates-wizard__btns {
  text-align: right;
  margin-bottom: 20px;
}
.feedbacks-templates-wizard__btns .ui.button.submit-btn {
  margin-left: 16px;
  color: white;
  background-color: #474b4f;
}
.feedbacks-templates-wizard__btns button.ui.inverted.secondary.button {
  margin-right: 16px;
  box-shadow: none !important;
  border: 1px solid #474b4f;
}
.ui.modal.feedbacks-confirmation-modal {
  background-color: #474b4f;
}
.ui.modal.feedbacks-confirmation-modal .feedbacks-confirmation-modal__content.content {
  background-color: #474b4f;
  color: white;
}
.ui.modal.feedbacks-confirmation-modal .actions .ui.small.button.feedbacks-confirmation-modal__btn {
  white-space: nowrap;
  min-width: fit-content;
}
