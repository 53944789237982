.feedbacks-wizard__steps-container {
  padding: 0px 15px;

  .feedback-wizard__first-step {
    width: 100%;

    .first-step__content {
      display: flex;
      justify-content: flex-start;
      margin: 16px 0;
      flex-wrap: wrap;

      .title {
        display: flex;
        flex-direction: column;
        margin-right: 32px;
        font-weight: 500;
        width: 45%;
      }

      .header {
        font-weight: 500;
        display: flex;
        width: 45%;
        flex-direction: column;
      }

      .respondents {
        font-weight: 500;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 32px 0;
      }

      .feedback-wizard__textarea {
        max-width: 100%;
        width: 300px;
        min-width: 200px;
        height: 150px;
        min-height: 150px;
        max-height: 200px;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #22242626;
        outline: none;
      }

      .filter-respondents {
        min-width: 120px;
        width: 200px;
        margin-left: 16px;
        min-height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 14px 8px 8px;
        border: 1px solid #22242626;
        border-radius: 4px;
      }
    }
  }

  .questions-container {
    .ui.button {
      margin: 0 0 16px 0;
    }

    .tna-wizard__assign-question {
      &:first-of-type .tna-wizard__assign-question__items {
        border-top: 1px solid #b0b0b0;
      }

      &:last-of-type {
        margin-bottom: 32px;
      }

      display: flex;
      align-items: center;

      .tna-wizard__assign-question__items {
        border-bottom: 1px solid #b0b0b0;
        padding: 8px;
        margin-right: 20px;
        width: 100%;
        align-items: center;
        display: flex;

        .wizard__sort-icons-wrapper {
          margin-right: 4px;
          display: flex;
          align-items: center;

          i {
            font-size: 18px;
            cursor: pointer;
          }
        }

        :first-child {
          border-bottom: 0;
        }

        p {
          margin: 0 16px !important;
          font-weight: 500;
        }
      }
    }
  }
}

.eventtype-selector {
  width: 200px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  min-width: 120px !important;
  padding: 8px 14px 8px 14px;
  border: 1px solid #22242626;
  min-height: 38px;
  height: fit-content !important;

  max-height: fit-content !important;
  border-radius: 4px;

  input.search {
    padding: 8px 14px 8px 14px;
    height: 38px;
  }

  .menu.transition.visible {
    .item {
      .ui.checkbox {
        margin-right: 16px;
      }

      display: flex;
      align-items: center;
    }
  }
}

.ui.list.event-type-list {
  width: 50%;

  .item {
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;

    &:last-child span.event-type-list__item {
      border-bottom: 1px solid #b0b0b0;
    }

    span.event-type-list__item {
      width: 100%;
      margin-right: 24px;
      border-top: 1px solid #b0b0b0;
      padding: 12px;
    }

    i.icon {
      color: #e03c31;
    }

    &::after {
      display: none !important;
      content: unset !important;
    }
  }
}

.feedbacks-templates-wizard__btns {
  text-align: right;
  margin-bottom: 20px;

  .ui.button.submit-btn {
    margin-left: 16px;
    color: white;
    background-color: #474b4f;
  }
  button.ui.inverted.secondary.button {
    margin-right: 16px;
    box-shadow: none !important;
    border: 1px solid #474b4f;
  }
}

.ui.modal.feedbacks-confirmation-modal {
  background-color: #474b4f;

  .feedbacks-confirmation-modal__content.content {
    background-color: #474b4f;
    color: white;
  }

  .actions {
    .ui.small.button.feedbacks-confirmation-modal__btn {
      white-space: nowrap;
      min-width: fit-content;
    }
  }
}
