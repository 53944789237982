.merge-event-form {
  .event-modal__first-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;

    p {
      width: fit-content;
      max-width: 160px;
      line-height: 15px;
      margin-right: 0px;
    }
  }

  .form__modal__left-container {
    width: 100%;
  }

  .event-modal__second-row {
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 10px;
    width: 100%;

    p {
      margin: 0px 5px 0px 0px !important;
      align-self: center;
      min-width: 90px;
      max-width: fit-content !important;
    }

    .event-form__inputs-dates-wrapper {
      .planit-eventform-dates.eventform-dates__first {
        width: 100px;

        > div > input[type='text'] {
          width: fit-content;
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          height: 35px !important;
        }
      }

      .planit-eventform-dates.eventform-dates__second {
        width: fit-content;

        > div > input[type='text'] {
          min-width: 115px !important;
          width: 115px !important;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          height: 35px !important;
        }
      }
    }
  }

  .event-form-merge__draft-third-row {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    display: grid;

    div {
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: flex-start;
    }

    p {
      margin: 0px !important;
      max-width: auto !important;
      min-width: fit-content !important;
      margin-right: 5px !important;
    }
  }

  .merge-event-body .event-modal__second-row {
    margin-bottom: 15px;
  }

  .event-form-merge__numeric-input {
    width: 70px;
    height: 32px;
    margin-right: 10px;
  }

  .event-details__all-config-container {
    display: flex !important;
    justify-content: space-around !important;
  }

  .eventsform__training__first-row__info__edit-mode {
    p {
      min-width: fit-content;
      padding-top: 12px;
    }
  }

  .eventsform__training__second-row__info {
    p {
      margin-right: 23px;
    }
  }

  .eventform__instructortab__container {
    grid-template-columns: auto auto auto auto auto auto 20px;
    grid-auto-rows: minmax(min-content, max-content);
    column-gap: 5px;
    row-gap: 5px;
    width: 100%;
    min-height: 75%;

    p {
      min-width: fit-content;
    }

    .ui.selection.dropdown.planit-eventform-inputs {
      width: 150px;
      height: 40px;
    }
  }

  ///Merge-Event-Form
  .merge-event-form {
    margin: 20px;
  }

  .merge-event-form .border-header {
    padding: 10px;
  }

  .merge-event-body {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .merge-event-body p {
    margin: 0em 0em 1em;
    line-height: 2.4285em;
  }

  .merge-event-body .first-row__event-type__circle {
    margin: 5px;
  }

  .merge-event-body .event-form-input-large,
  .merge-event-body .planit-eventform-dates {
    margin-left: 10px;
  }

  .merge-event-body .inline {
    display: inline-flex;
  }
}
