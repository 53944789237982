.ssa-wizard__step-second {
  .step-two__category-skills-questions__wrapper {
    margin-left: 62px;
    margin-bottom: 27px;

    .skill-label {
      background-color: #f9f9f9;
      color: #474b4f;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      margin-bottom: 18px;
      width: 100%;
      height: 38px;

      p {
        margin-bottom: 0px;
        margin-left: 12px;
      }

      .wizard__sort-icons-wrapper {
        margin-right: 12px;
        display: flex;
        align-items: center;

        i {
          font-size: 18px;
          cursor: pointer;
        }

        .tna-template-wizard__add-questions-btn {
          min-width: 100px;
          max-width: 100px;
          height: 38px;
          border-radius: 4px;
          padding: 0px !important;
          margin: 0px 120px 0px 0px;
          background-color: #f9f9f9 !important;
          border: 1px solid transparent !important;
          font-weight: 500;

          &:hover {
            background-color: #dedfdf !important;
            border: 1px solid #dedfdf !important;
            transition: ease-in 0.2s;
          }
        }
      }
    }

    .ssa-template-wizard__remove-btn {
      border: 1px solid #e03c31;
      color: #e03c31;
      width: 68px;
      margin-left: 24px;
      background-color: white;
      padding: 0px;
      height: 32px;

      &:hover {
        color: red;
        font-weight: bold;
      }

      &::after {
        background-color: white;
      }
    }
  }

  .ssa-templeate-wizard__checkpoint-container {
    width: 100%;
    margin-bottom: 12px;
  }

  .tna-arrow-left-margin {
    margin-right: 3px !important;
  }

  .remove {
    cursor: pointer;
  }

  .ssa-wizard__checkpoint-container {
    margin-bottom: 16px;
  }

  .ssa-wizard__add-question-btn {
    .ssa-wizard__add-questions-btn {
      background-color: #eeeeee;
    }
  }

  .tna-wizard__assign-question {
    display: flex;
    align-items: center;

    .tna-wizard__assign-question__items {
      display: flex;
      align-items: center;
      border-top: 1px solid #f9f9f9;
      padding: 14px 0px;
      width: 100%;
      margin-right: 32.5px;

      p {
        margin-bottom: 0px;
      }

      .wizard__sort-icons-wrapper {
        margin-right: 16.5px;

        .caret.square.up.outline.icon {
          cursor: pointer;
        }

        i {
          font-size: 16px;
          cursor: pointer;
        }
      }

      .wizard__question-item {
        &:first-of-type {
          margin-right: 24.5px;
        }
      }
    }
  }

  .tna-wizard__assign-question:last-child {
    .tna-wizard__assign-question__items {
      border-bottom: 1px solid #f9f9f9;
    }
  }
}

.ssa-wizard__add-link-button {
  border: 1px solid transparent !important;
  color: #474b4f !important;
  box-shadow: none !important;
  margin-right: 20px !important;

  .icon {
    background-color: transparent !important;
  }

  &:hover {
    background-color: #dedfdf !important;
    border: 1px solid #dedfdf !important;
    transition: ease-in 0.2s;
  }
}

.ui.input.ssa-wizard__checkpoint-input {
  min-width: 280px !important;
  max-width: calc(100% - 100px);
  margin-bottom: 12.5px;
}
