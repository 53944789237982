.question-bank__wrapper.feedbacks-template__wrapper {
  .tna__inputs-wrapper {
    align-items: flex-end;
  }

  .filter {
    margin-right: 16px;

    &.filter-template {
      height: 38px !important;
      min-width: 120px !important;
    }

    &.filter-eventtype {
      min-width: 120px !important;
    }

    &.active.visible {
      border-radius: 4px 4px 0 0;
    }

    &.filter-respondents {
      display: flex;
      max-width: 150px;
      height: 38px;
      justify-content: space-between;
      align-items: center;
      min-width: 120px !important;
      padding: 8px 12px 8px 8px;
      border: 1px solid #22242626;
      border-radius: 4px;

      .visible.menu.transition {
        left: -1px;
        border-top: none;
        border-radius: 0 0 4px 4px;
      }
    }
  }
}
