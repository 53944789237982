.feedbacks-wizard__steps-container.theoretical-form-wizard-container {
  .theoretical-template-wizard__questions-container {
    .ui.button {
      margin: 0 0 16px 0;
    }

    .tna-wizard__assign-question {
      &:first-of-type .tna-wizard__assign-question__items {
        border-top: 1px solid #b0b0b0;
      }

      &:last-of-type {
        margin-bottom: 32px;
      }

      display: flex;
      align-items: center;

      .tna-wizard__assign-question__items {
        border-bottom: 1px solid #b0b0b0;
        padding: 8px;
        margin-right: 20px;
        width: 100%;
        align-items: center;
        display: flex;

        .wizard__sort-icons-wrapper {
          margin-right: 4px;
          display: flex;
          align-items: center;

          i {
            font-size: 18px;
            cursor: pointer;
          }
        }

        :first-child {
          border-bottom: 0;
        }

        p {
          margin: 0 16px !important;
          font-weight: 500;
        }
      }
    }
  }
}
