.development-plan-by-location__wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.development-plan-by-location__wrapper .development-plan-by-location__filters {
  justify-content: start;
  display: inline-block;
  flex-wrap: wrap;
  margin-bottom: 5px !important;
}
.development-plan-by-location__wrapper .development-plan-by-location__filters .custom-editor {
  margin-right: 12px;
  min-width: 150px !important;
  max-width: 150px !important;
  margin-bottom: 5px;
}
.development-plan-by-location__wrapper .development-plan-by-location__filters .custom-editor-search {
  margin-right: 12px;
  margin-bottom: 5px;
}
.development-plan-by-location__wrapper .custom-table-main-actions.menu {
  display: none;
}
.development-plan-by-location__wrapper .ui.table.custom-table thead tr:first-child > th:first-child {
  padding-left: 15px !important;
}
.development-plan-by-location__wrapper .ui.table.custom-table tbody tr > td:first-child {
  padding-left: 15px !important;
}
.users-per-locations__container .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
  margin-top: -37px;
}
