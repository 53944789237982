.edit-form__title {
  font-size: 16px;
}
.form__all-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  font-size: 12px;
  max-height: 88vh;
  padding: 0px 15px !important;
}
.form__all-wrapper .scroll {
  scroll-padding: 5px !important;
}
.form__all-wrapper .scroll::-webkit-scrollbar {
  width: 6px;
}
.form__all-wrapper .scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #dfdfdf;
}
.form__all-wrapper .ui.secondary.menu .item {
  position: initial;
}
@media all and (max-height: 800px) {
  .form__all-wrapper {
    margin-top: 10px;
    padding: 0px 15px !important;
  }
  .form__all-wrapper .form__all-wrapper__header {
    margin-bottom: 20px;
  }
  .form__all-wrapper .form__all-wrapper__main-content {
    height: 50vh;
  }
  .form__all-wrapper .form__all-wrapper__footer .ui.divider {
    margin: 10px 0px 10px 0px;
  }
  .form__all-wrapper .form__all-wrapper__footer p {
    margin: 0px;
  }
  .form__all-wrapper .tna-edit-form__header__evaluation-criteria {
    margin-bottom: 5px;
  }
}
@media all and (max-width: 1300px) {
  .main-tab__skill-section__score {
    margin-right: 40px !important;
  }
}
