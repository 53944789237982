.search-tna-template__btn {
  margin-bottom: 20px !important;
}

.tna-form-tna-template {
  .step2__select-tna-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-left: 62px;
    flex-direction: column;

    .step2__select-tna-id-name {
      width: fit-content;
      display: flex;
      height: 26px;
      justify-content: flex-start;
      align-items: center;

      div {
        margin-left: 45px;
        margin-bottom: -15px;
      }
    }

    .step2__select-tna-wrapper__content {
      margin-top: 16px;
      margin-left: 10.5em;
      font-size: 12px !important;

      h4 {
        margin-bottom: 16px;
        font-size: 12px !important;
      }

      .machine-model__deactivated {
        color: #b0b0b0;
      }

      ul {
        padding-left: 0px;

        .step2__select-tna-wrapper__content__element {
          .step2__select-tna-content__machines {
            margin-bottom: 14px;

            label {
              margin-right: 24px;
              margin-left: 0.5em;
              font-weight: 400;
              font-size: 12px !important;
            }

            label::before {
              border: 1px solid #474b4f;
              border-radius: 2px;
            }

            &:last-child {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    p {
      color: #00b2a9;
      margin: 0px;
      font-weight: 600;
    }

    p:first-of-type {
      margin: 0px 32.5px 0px 14.5px;
    }

    .step2__select-tna__btn {
      width: 26px;
      height: 26px;
      background-color: #eeeeee !important;
      padding: 0px;
    }
  }

  button.ui.button {
    background-color: #eeeeee !important;
    color: #474b4f !important;
  }
}

@media all and (max-width: 1285px) {
  .tna-form-tna-template {
    .step2__select-tna-wrapper {
      .step2__select-tna-wrapper__content {
        margin-left: 10em;
      }
    }
  }
}
