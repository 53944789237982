.feedbacks-wizard__steps-container.theoretical-form-wizard-container {
  .template-wizard__machinery__training {
    margin-bottom: 32px;

    label {
      padding-bottom: 8px;
    }

    .ui.search.selection.dropdown.custom-editor {
      min-width: 175px;
      width: 175px !important;
    }
  }

  .template-wizard__machinery__wrapper {
    .flex-column {
      margin-right: 16px;
      margin-bottom: 50px;

      label {
        padding-bottom: 8px;
      }
    }
  }
}
