.ssa-individual-tab-pane-search {
  width: 100%;
  margin-bottom: 16px;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container {
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eeeeee;
  opacity: 1;
  padding: 32px 36px 22px 32px;
  margin-right: 32px;
  max-width: 1710px;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__title {
  margin-bottom: 32px !important;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper label {
  margin: 0px;
  padding-bottom: 8px;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper .fields.search-form__filters {
  margin-bottom: 0px !important;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper .search-form__filters {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 95%;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper .search-form__filters .field {
  margin-bottom: 0px !important;
  padding: 0px;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper .search-form__filters .filters-container-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 18px !important;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper .search-form__filters .custom-editor {
  margin-right: 12px;
  min-width: 160px !important;
  max-width: 160px !important;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__wrapper .search-form__filters .custom-editor-search {
  margin-right: 12px;
}
.ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ssa-individual-tab-pane-search__container__search-form .search-form__btn-wrapper {
  height: 100%;
  align-self: flex-end;
  padding-bottom: 18px;
}
@media all and (max-width: 1280px) {
  .ssa-individual-tab-pane-search .ssa-individual-tab-pane-search__container .ui.error.message {
    margin: 30px;
    width: 93%;
  }
}
