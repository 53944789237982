.new-machines-per-location__modal-container {
  label {
    min-width: 100px;
  }
}

.new-machines-per-location__location-field {
  width: 100% !important;
}
.machines-per-location .button {
  height: 38px;
}
