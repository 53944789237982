.user-modal {
  width: 950px !important;
  height: 95vh;
}
.user-modal__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 22px;
}
.user-modal__wrapper .ui.form .inline.field > label {
  color: #474b4f;
  line-height: 14px;
  font-weight: normal;
}
.user-modal__wrapper .ui.inline.dropdown > .text {
  font-weight: 500 !important;
}
.user-modal__wrapper .ui.form .planit-users-fields.field:last-child {
  margin-bottom: 1em;
}
.user-modal__wrapper .ui.checkbox:not(.user-modal__checkbox-toggle) label {
  color: #474b4f;
  line-height: 14px;
  font-weight: 420;
  padding-left: 29px;
  line-height: 17px !important;
  transform: translateY(50%);
}
.user-modal__wrapper .actions {
  margin-top: 20px;
  float: right;
  padding: 0 10px;
}
.new-user-modal__section-label {
  background-color: #00b2a933;
  padding: 7px 16px 7px 7px;
  display: flex;
  justify-content: flex-end;
  margin-left: -1.5rem;
  margin-top: 1.5rem;
  width: 200px;
  font-weight: bold;
}
.new-user-modal__inputs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  width: 100%;
}
.new-user-modal__inputs-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 70px;
}
.new-user-modal__roles-wrapper {
  margin-top: 26px;
  padding: 0 10px;
}
.location-editor__wrapper {
  width: 300px !important;
}
.planit-users-inputs.planit-users-inputs__date-time > div.ui.input {
  min-width: 300px;
  min-height: 32px;
}
.planit-users-fields.new-user-modal__language .planit-user-dropdown {
  width: 300px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
@media all and (max-width: 1000px) {
  .user-modal {
    width: 85% !important;
  }
  .new-user-modal__inputs-grid-container {
    grid-column-gap: 15px;
    grid-template-columns: repeat(2, 0.5fr);
    max-width: 700px;
  }
  .new-user-modal__roles-wrapper {
    margin-left: 97px;
    margin-top: 20px;
  }
  .planit-users-inputs {
    min-width: 200px !important;
  }
  .planit-users-inputs.planit-users-inputs__date-time > div.ui.input {
    min-width: 200px;
    min-height: 32px;
  }
  .location-editor__wrapper {
    width: 200px !important;
  }
  .planit-users-fields {
    min-width: 300px;
  }
}
@media all and (max-width: 900px) {
  .user-modal {
    width: 85% !important;
  }
  .new-user-modal__wrapper {
    padding-right: 0px;
  }
  .new-user-modal__inputs-container {
    width: 90%;
  }
  .new-user-modal__roles-wrapper {
    margin-left: 60px;
    margin-top: 30px;
  }
  .planit-users-inputs {
    min-width: 200px !important;
  }
  .planit-users-inputs.planit-users-inputs__date-time > div.ui.input {
    min-width: 200px;
    min-height: 32px;
  }
  .location-editor__wrapper {
    width: 200px !important;
  }
  .planit-users-fields {
    min-width: 300px;
  }
  .planit-users-roles-in-location-selector {
    width: 100px !important;
  }
}
@media all and (max-width: 770px) {
  body > div.ui.page.modals.dimmer.transition.visible.active > div > div.image.content > div > form > div.new-user-modal__roles-wrapper > div {
    flex-wrap: no-wrap !important;
  }
  .new-user-modal__inputs-container {
    width: 100%;
  }
  .new-user-modal__inputs-grid-container {
    display: flex;
    flex-direction: column;
  }
  .planit-users-inputs {
    margin-left: 40px;
  }
  .location-editor__wrapper {
    margin-left: 40px;
  }
  .new-user-modal__roles-wrapper {
    margin-left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .planit-users-roles-in-location-selector {
    min-width: 150px !important;
  }
  .multiple.search.selection.dropdown .ui.label {
    max-width: 100%;
  }
  .planit-user-dropdown,
  .planit-users-fields.new-user-modal__language .planit-user-dropdown,
  .planit-users-fields.new-user-modal__position-code .planit-user-selector__position-code {
    width: 200px !important;
  }
}
@media all and (min-width: 770px) and (max-width: 885px) {
  .new-user-modal__inputs-container {
    width: 100%;
  }
  .new-user-modal__roles-wrapper {
    margin-left: 80px;
  }
}
