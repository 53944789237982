.wizard__numeric-input {
  width: 70px !important;
  height: 32px !important;
}

.wizard__step-dates-wrapper {
  min-height: 60%;

  .wizard__numeric-input {
    width: 90px !important;
    height: 32px !important;
  }

  .date-picker__left,
  .date-picker__right {
    width: 50% !important;
    display: inline-block;
    margin-bottom: 0 !important;
  }

  #date-step__date-input__left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0px !important;
  }

  #date-step__date-input__right {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .second-row {
    padding-bottom: 0 !important;
  }

  .recalc-commands-row {
    padding-top: 0 !important;

    .recalc-dates-container {
      width: 50%;
      display: inline-block;
    }

    .recalc-command {
      color: #00b2a9;
      cursor: pointer;
      font-size: 0.8em;
    }
  }

  .relative-recalc-command-container {
    position: relative;

    #recalc-event-calculated-duration-command {
      position: absolute;
      top: 34px;
      left: 12px;
    }
  }

  .padding-bottom-20px {
    padding-bottom: 20px !important;
  }
}

.wizard__form__margin.wizard__step-dates-wrapper {
  .period-input {
    display: flex;
    flex-wrap: nowrap;
    .date-picker__left {
      width: 120px !important;
      height: 32px;
      margin-right: -1px !important;

      > div > input[type='text'] {
        width: 50% !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        text-align: center !important;
        padding: 4px 20px 4px 2px !important;
      }
    }

    .field.date-picker__right {
      height: 32px;
      min-width: 120px !important;
      width: 120px !important;
      max-width: 50% !important;

      > div > input[type='text'] {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        text-align: center !important;
        padding: 4px 20px 4px 2px !important;
      }
    }
  }
}

.wizard__step-dates-wrapper.request-wizard__step-dates-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .ui.form .field {
    margin-bottom: 0px !important;
  }

  .wizard__step-dates-wrapper__desired.field.required {
    display: flex;
    align-items: center;
    margin: 0px !important;

    label {
      margin-right: 12px;
    }
  }

  .generic-date-picker__wrapper.field.required.request-wizard__generic-date-picker__wrapper {
    display: flex;
    align-items: center !important;
    height: 32px;
    margin-left: 12px;
    margin-right: 60px;
    margin-bottom: 0px;

    label {
      margin-right: 12px;
    }

    .date-picker__left {
      width: 120px !important;
      height: 32px;
      margin-right: -1px !important;

      > div > input[type='text'] {
        width: 50% !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        text-align: center !important;
        padding: 4px 20px 4px 2px !important;
      }
    }

    .field.date-picker__right {
      height: 32px;
      min-width: 120px !important;
      width: 120px !important;
      max-width: 50% !important;

      > div > input[type='text'] {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        text-align: center !important;
        padding: 4px 20px 4px 2px !important;
      }
    }
  }
}
