.form__all-wrapper .form__all-wrapper__footer p {
  margin-bottom: 0px;
}
.form__all-wrapper .form__all-wrapper__footer .ui.divider {
  border: 0.5px solid #474b4f;
  margin: 40.5px 0px 40.5px 0px;
}
.form__all-wrapper .form__all-wrapper__footer.tna-footer .ui.divider {
  margin: 1rem 0;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between {
  margin-top: 16px;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .flex-start {
  width: 50%;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer-considerations {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  width: 100%;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__check {
  display: flex;
  flex: 1;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__check:not(:last-child) {
  margin-bottom: 16px;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__check .ui.fitted.checkbox {
  margin-right: 12px;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__check .ui.fitted.checkbox label::before {
  border: 1px solid #474b4f;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__check .table__tooltip {
  width: fit-content;
  max-width: fit-content;
  min-width: unset;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__check .table__tooltip:hover .table__tooltip-top {
  min-width: 360px;
  padding: 2px;
  font-size: 9px;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__text-area {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__text-area textarea {
  max-height: 100px;
  min-height: 69px;
}
.form__all-wrapper .form__all-wrapper__footer .flex-between .tna-edit-form__footer__btns {
  justify-self: flex-end;
  margin-left: 24px;
}
.form__all-wrapper .form__all-wrapper__footer .inverted-color-btn {
  font-weight: 500;
  margin-left: 16px;
}
.form__all-wrapper .form__all-wrapper__footer .white-save-btn,
.form__all-wrapper .form__all-wrapper__footer .inverted-color-btn {
  width: 100px;
  height: 36px;
}
