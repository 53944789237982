.wizard__steps-container {
  padding: 0px 15px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper {
  margin-bottom: 20px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .fields {
  width: 100%;
  display: flex;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .fields .field.machinery__label-input-field {
  width: 175px;
  margin-right: 16px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .fields .field.machinery__label-input-field label {
  font-weight: 500;
  font-size: 12px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .add-row-btn {
  width: 110px;
  height: 32px !important;
  align-self: center;
  margin-left: 16px;
}
.wizard__steps-container .wizard__step-two .custom-editor {
  min-width: 175px !important;
  max-width: 175px !important;
  margin-bottom: 24.5px;
  margin-top: 8px;
}
.wizard__steps-container .wizard__step-two .editor-32px .ui.search.selection.dropdown {
  min-height: 32px;
  height: unset !important;
  padding: 10px;
}
.wizard__steps-container .wizard__step-two .editor-32px .ui.search.selection.dropdown input {
  padding: 10px;
}
.wizard__steps-container .wizard__step-two .editor-32px .ui.search.selection.dropdown i.dropdown.icon {
  padding: 9px !important;
}
.wizard__steps-container .wizard__step-two .editor-32px .ui.search.selection.dropdown.loading.loading.loading.loading i.dropdown.icon {
  padding: 17px !important;
}
.wizard__steps-container .wizard__step-two .editor-32px .ui.search.selection.dropdown div.text {
  font-size: 12px !important;
  width: calc(100% - 20px) !important;
  top: 1px;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info {
  display: flex;
  margin-left: 62px;
  justify-content: flex-start;
  height: fit-content;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info:last-child {
  margin-bottom: 24px;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info:last-child .assigned-profile-row-machine__items {
  border-bottom: 1px solid #b0b0b0;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info .assigned-profile-row-machine__items {
  display: flex;
  border-top: 1px solid #b0b0b0;
  padding: 14px 0px;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info .assigned-profile-row-machine__items .machine-cell {
  width: 175px;
  margin: 0px 16px 0px 0px;
  min-width: 175px;
  max-width: 175px;
  font-size: 12px;
  font-weight: normal;
  align-self: center;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info .assigned-profile-row-machine__items .machine-cell:last-child {
  margin-right: 0px !important;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info .assigned-profile-row-machine__items .machine-cell-bold {
  width: 175px;
  margin: 0px 16px 0px 0px;
  min-width: 175px;
  max-width: 175px;
  font-size: 12px;
  font-weight: bold;
  align-self: center;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info .assigned-profile-row-machine__items .machine-cell-bold:last-child {
  margin-right: 0px !important;
}
.wizard__steps-container .wizard__step-two .assigned-profile-row-machine__info i {
  cursor: pointer;
  margin-left: 32px;
  width: fit-content;
}
.tna-w__button-export-excel__wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 4px;
}
.tna-w__button-export-excel__wrapper .custom-table-upload-btn.black-btn {
  margin-left: 10px;
  margin-top: 3px;
}
.wizard__steps-container .wizard__step-two .step-two__machinery-wrapper .tna-w__button-export-excel__wrapper .add-row-btn {
  align-self: flex-end;
}
