.ui.grid.event-pause-period-grid {
  > div.row {
    padding-top: 3px;
    padding-bottom: 3px;

    > div.column {
      padding-right: 4px;
      padding-left: 4px;
    }
  }

  div.ui.dropdown,
  div.ui.fields,
  div.inline.fields,
  input,
  div.planit-events-periods {
    height: 32px;
  }

  margin-left: 0;
  margin-right: 0;
}
