.user-import-view-grid {
  .user-import-view-action-row {
    padding: 0 !important;

    .form {
      width: 100%;

      .fields {
        .user-import-action-icon-btn {
          > i.icons {
            i.corner.hide-filter {
              opacity: 1;
            }

            i:not(.corner) {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  .user-import-view-error-row {
    .ui.error.message {
      width: 100%;
    }
  }

  .user-import-view-items-row {
    padding: 0 !important;
  }
}

.user-import-action-icon-btn {
  height: 38px;
  width: 38px;
}

div.row.user-import-view-items-row > div > div.ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
  .item {
    height: 0 !important;
  }
}

.user-import__matcher_btn {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1em;
}

.user-import__matcher_btn_icon {
  font-style: normal;
  font-size: xx-large;
  font-weight: bold;
}

div.right.aligned.row.user-import-view-action-row > form > div {
  justify-content: flex-end;
}

.user-import-view-items-row {
  font-weight: bold;
}

.user-import__table__sub-row-style {
  color: #b0b0b0;
}

.text__red {
  color: red !important;
}

.users__instructor-expertise-tab__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(3) > {
  min-width: 100px !important;
}

.users__instructor-expertise-tab__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(5) > {
  min-width: 130px !important;
}

.users__instructor-expertise-tab__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(9) > {
  min-width: 150px !important;
}
