//TOOLTIP

.table__tooltip {
  max-width: 20ch;
  min-width: 100%;
  position: relative;
}

.table__tooltip .table__tooltip-top {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.table__tooltip:hover .table__tooltip-top {
  position: absolute;
  min-width: 200px;
  top: -10px;
  left: 90%;
  transform: translate(-50%, -100%);
  padding: 10px 20px;
  color: #ffffff;
  background-color: #474b4f;
  font-weight: normal;
  font-size: 13px;
  border-radius: 4px;
  z-index: 4;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s;
  display: block;
  text-align: center;
}

.table__tooltip .table__short-text {
  overflow: hidden;
  text-overflow: ellipsis !important;

  .no-margin {
    margin: 0;
  }
}

.table__tooltip .table__tooltip-top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.table__tooltip .table__tooltip-top i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #474b4f;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.table__more-text-long {
  min-width: 250px;
  max-width: 250px;
}

.table__more-text-long-350 {
  min-width: 350px;
  max-width: 350px;
}
