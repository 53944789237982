@import './variables.less';

* {
  list-style: none;
}

.form__modal__right-container__chat {
  .ui.attached.tabular.menu {
    height: 53px;
  }
  .ui.dividing.header {
    margin-top: 0px !important;
  }

  > div:first-of-type {
    height: 100%;

    .tab.tab-panes-chat {
      height: calc(100% - 53px);

      .dimmed.dimmable {
        height: calc(100% - 95px);
        background-color: #eeeeee;
      }

      .ui.segment {
        margin-bottom: 0px !important;
      }

      .chat-div {
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #eeeeee;
        scroll-behavior: smooth;
        transition: 0.9s;
        border: none;
        border-style: none;
        max-height: 590px;
        min-height: 100%;
      }
    }
  }
}

.events-chat__write-section {
  background-color: #f9f9f9 !important;
  height: 95px;

  .field {
    height: 100%;

    .chat-input-div {
      width: 100%;
      height: 100%;

      .chat__textarea-message {
        padding: 20px;
        padding-right: 20%;
        resize: none;
        font-weight: bold;
        line-height: 14px;
        overflow-y: hidden;
        border: none;
        border-radius: 0px;
        background-color: transparent;
        height: 100%;
      }
    }
  }
}

.tab-panes-chat {
  background-color: #eeeeee !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: none !important;
  min-height: 95%;
}

.chat__textarea-container {
  padding-right: 0px;
  width: 100%;
  overflow-x: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  resize: none;
}

.chat__textarea-message {
  padding-right: 20% !important;
  font-weight: 600;
  background-color: #f9f9f9 !important;
  border: none !important;
}

.chat-button-div {
  width: 20%;
  padding-bottom: 5px;
  position: absolute;
  right: 16px;
  top: 14px;
  padding-left: 20px;

  .chat-button {
    align-content: right;
    align-items: right;

    height: 100%;
  }
}

.comment-own-color {
  background-color: #e2d5b1;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: left;
  margin-left: 3em;
  width: 85%;
  border-radius: 3px;

  .comment {
    padding-right: 5px !important;
  }
}

.comment .text {
  font-weight: bold !important;

  &.other-author {
    color: #59cba8 !important;
  }
}

.comment-basic-color {
  background-color: #ffffff;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 0.1em;
  text-align: left;
  width: 85%;
  border-radius: 3px;
}

.comment-system-color {
  background-color: #8cb2df;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 0.1em;
  text-align: left;
  width: 85%;
  border-radius: 3px;
}

.comment-metadata {
  text-align: right !important;
  padding-right: 10px;
  padding-bottom: 5px;
}

.chat-container-btn {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;

  border-radius: 0% !important;
}

.chat-div-request__users-icon-btn {
  background-color: #474b4f !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 100% !important;
  color: white;
}

.chat-div__users-icon-btn {
  background-color: #474b4f !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 100% !important;
  color: white;
  padding-top: 5px !important;
}

.chat-div__users-icon {
  color: white !important;
  font-size: 18px;
  vertical-align: middle !important;
}

.chat-div__users-icon-btn-students {
  background-color: #8031a7 !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 100% !important;
  color: white;

  padding-top: 5px !important;
}

.chat-div__users-icon-students {
  color: white !important;
  background: purple;
  font-size: 18px;
}

.chat-selected-tab {
  background-color: #eeeeee !important;
}

//Request chat differences
.form__request-chat-container {
  > div:first-of-type {
    height: 90%;
  }

  .ui.segment.dimmed.dimmable {
    margin-bottom: 0px !important;
    background-color: #eeeeee !important;
  }

  .chat-div.scroll {
    min-height: 100%;
  }
}

@media all and (min-height: 700px) {
  .form__modal__right-container__chat {
    .chat-div {
      max-height: 800px !important;
    }
  }
}
