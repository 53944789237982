.ui.container.employees-list-table {
  min-height: 40vh;
  margin-top: 10px;
}
.ui.container.employees-list-table > div > div.custom-table-main-actions {
  display: none;
}
.add-employees-button {
  background: #00b2a9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  min-width: 135px;
}
.template-search__modal.choose-employees .full-width.table__flex-container {
  overflow-x: scroll;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input {
  min-width: 200px;
  width: 200px;
  height: 36px !important;
  margin-bottom: 8px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.long-input {
  width: 250px;
  min-width: 250px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.feedback-form__long-input {
  width: fit-content !important;
  min-width: 210px !important;
  padding: 5px 25px 5px 5px !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  margin-bottom: 10px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.feedback-form__long-input > input {
  padding-right: 10px !important;
  padding-left: 5px !important;
  border: none;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .ui.input.feedback-form__short-input {
  min-width: 180px !important;
  width: 180px !important;
  margin-bottom: 10px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown {
  min-width: 200px;
  width: 200px !important;
  margin-right: 12px;
  min-height: 36px;
  max-height: 36px;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown .default.text {
  font-weight: 500 !important;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown .icon {
  color: #474b4f !important;
}
.template-search__modal.choose-employees .template-search__first-row__column-filters.choose-employees .custom-editor.ui.inline.dropdown .dropdown.icon {
  top: 0;
  right: 0;
}
.ui.custom-editor.dropdown.feedback-form__dropdown-input {
  min-width: 140px;
  width: 140px !important;
  margin-right: 12px;
  height: max-content;
}
.ui.custom-editor.dropdown.feedback-form__dropdown-input > div.default.text {
  font-weight: 500 !important;
}
.ui.custom-editor.dropdown.feedback-form__dropdown-input input.search {
  font-weight: 500 !important;
  min-height: fit-content !important;
  height: fit-content !important;
}
