.sch-instructors__header-right-buttons {
  justify-content: flex-end;
  margin: -8px 8px 0 -8px;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;

  i {
    font-size: 18px;
  }

  button,
  .button {
    margin-top: 8px;
    margin-left: 8px;
    width: 38px;
    height: 38px;
    background: #474b4f;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    border: none;
    outline: none;

    &.active {
      background: #00b2a9;
    }

    > i.icons {
      i.circular.icon {
        width: 0 !important;
        height: 0 !important;
      }

      i.plus.circle {
        color: #474b4f;
        padding: 0 !important;

        &::before {
          background-color: #fff !important;
          border-radius: 100%;
          border: 1px solid #fff;
        }
      }

      i.corner.hide-filter {
        text-shadow: unset;
        color: white;
        opacity: 1;
        background-color: red;
        border-radius: 1em;
        font-size: 0.7rem;
        height: 12px;
        width: 12px;
        line-height: 11px;
        left: 44%;
        bottom: 10%;
      }

      i:not(.corner) {
        color: white;
        opacity: 1;
        margin: 0 !important;
      }
    }

    &.event-type-filter-by-update-flag-btn {
      > i.icons > i {
        color: #e03c31;
      }

      &.active {
        > i.icons > i {
          color: white;
        }
      }
    }
  }
}
