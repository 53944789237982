.b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell:hover {
  background-color: inherit;
  cursor: auto;
}

.b-grid-header.b-depth-0 {
  cursor: auto;
}
.b-gridbase:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:hover,
.b-gridbase:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:focus {
  background-color: inherit;
}
