.user-profile-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr) 60px;
  grid-template-rows: repeat(auto, 1fr);
  width: 100%;
  margin-bottom: 30px;
}
.user-profile-list .user-profile-list__column-title {
  background-color: #eeeeee;
  width: 100%;
  padding: 8px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.user-profile-list .border-bottom-gray {
  border-bottom: 1px solid #dedfdf;
  padding: 11.5px;
}
.user-profile-list span {
  display: flex;
  align-items: center;
}
.user-profile-list .text__transparent {
  color: transparent;
}
.user-profile-list .icons {
  display: flex;
  align-items: center;
}
