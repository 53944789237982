.individual-tab-pane-search {
  width: 100%;
  margin-bottom: 16px;

  .individual-tab-pane-search__container {
    min-height: 245px;
    height: fit-content;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #eeeeee;
    opacity: 1;
    padding: 32px 36px 22px 32px;
    margin-right: 32px;

    .individual-tab-pane-search__container__title {
      margin-bottom: 32px !important;
    }

    .individual-tab-pane-search__container__search-form {
      display: flex !important;
      align-items: flex-end;
      justify-content: space-between;

      .field {
        margin-bottom: 0px !important;
      }

      .filters-container-field {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 18px !important;
      }

      .search-form__btn-wrapper {
        height: 100%;
        padding-bottom: 18px !important;
      }

      .search-form__wrapper {
        .search-form__filters {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          flex-wrap: wrap;
          width: 100%;

          .custom-editor {
            margin-right: 12px;
            min-width: 160px !important;
            max-width: 160px !important;
          }
        }
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }
}

@media all and (max-width: 1280px) {
  .individual-tab-pane-search .individual-tab-pane-search__container .ui.error.message {
    margin: 30px;
    width: 93%;
  }
}
