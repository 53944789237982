.events-form__check-list-container .field {
  margin-left: 74px;
}
.events-form__check-list-container .checkbox {
  margin-left: 74px;
}
.events-form__check-list-container .checkbox > label {
  padding-left: 30px;
}
.events-form__check-list-container .button {
  margin-left: 74px;
}
