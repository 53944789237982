.ssa-form-general-data {
  max-width: 1002px;
}

.editor-38px.editor-38px.editor-38px.editor-38px {
  .ui.search.selection.dropdown {
    min-height: 38px;
    height: unset !important;
    padding: 10px;

    input {
      padding: 10px;
    }

    i.dropdown.icon {
      padding: 10px !important;
    }

    &.loading.loading.loading.loading {
      i.dropdown.icon {
        padding: 18px !important;
      }
    }

    div.text {
      font-size: 12px !important;
      width: calc(100% - 20px) !important;
      top: 1px;
    }
  }
}

.ssa-form-wizard__step-one__title-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;

  > div.ui.input {
    width: 100%;
    margin-right: 0px;
  }

  > label {
    min-width: fit-content;
  }
}

.ssa-form-wizard__step-one__location-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  max-width: 35%;
}

.ssa-form-wizard__step-one__profile-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  max-width: 35%;

  div.ui.search.selection.dropdown.custom-editor,
  div.ui.input {
    max-height: 38px !important;
  }
}

.ssa-form-wizard__step-one__deadline-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  max-width: 30%;

  div.field,
  div.ui.icon.input {
    max-width: 160px;
    margin-right: 0px;
  }
}

.ssa-form-step-one__content {
  display: flex;
  justify-content: space-between;
}
