.tna-ssa-template__wrapper {
  .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
    display: flex;
    margin-right: -4px;

    .button.table__custom-white-outline-button {
      min-width: 155px;
      min-height: 38px;
      border-radius: 4px;
      background-color: white !important;
      color: #474b4f !important;
      justify-self: flex-end !important;
      border: 1px solid #474b4f;
      opacity: 1;
      padding: 9px 11px;
      font-size: 12px;
      letter-spacing: 0px;
      font-weight: 500;
      margin-right: 5px;
    }

    .icon.button.custom-table-add-btn {
      min-width: 151px !important;
      min-height: 38px !important;

      .icon {
        display: none !important;
      }
    }

    .button {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
    }
  }

  .tna__inputs-wrapper {
    justify-content: flex-start;
    width: 66vw;
    margin-bottom: 10px;
  }
}

@media all and (max-width: 1200px) {
  .tna-template__wrapper {
    .tna__inputs-wrapper {
      width: 65%;
    }
  }
}
