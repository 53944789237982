.request-details-tab__mr__pattern-row-btns {
  display: flex;
  align-items: center;
  margin-bottom: 39px !important;
}
.request-details-tab__mr__pattern-row-btns .ui.buttons {
  margin-bottom: 0px;
  margin-right: 16px;
}
.request-details-tab__mr__pattern-row-btns .ui.search.selection.dropdown.event-details__pattern-dropdown {
  width: 195px;
}
.form__header__request-reason__dropdown-style {
  max-width: 350px;
}
.form__header__request-reason__with_column {
  max-width: 420px !important;
}
@media all and (max-width: 2000px) {
  .form__event-details__container .no-machine-related-wrapper .flex-center {
    justify-content: flex-start !important;
  }
  .form__event-details__container .no-machine-related-wrapper .flex-center .no-machine-related-wrapper__element label {
    padding-right: 5px;
  }
}
