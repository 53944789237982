.practical-template-wizard__questions-container .template-step {
  margin-bottom: 32px !important;
}
.practical-template-wizard__questions-container .ui.button {
  margin: 0 0 16px 0;
}
.practical-template-wizard__questions-container .ssa-wizard__checkpoint-container .tna-wizard__assign-question:nth-child(2) .tna-wizard__assign-question__items {
  border-top: 1px solid #b0b0b0;
}
.practical-template-wizard__questions-container .ssa-wizard__checkpoint-container .tna-wizard__assign-question:last-child {
  margin-bottom: 32px;
}
.practical-template-wizard__questions-container .ssa-wizard__add-questions-btn {
  background-color: #eeeeee;
  color: #474b4f;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question {
  display: flex;
  align-items: center;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items {
  border-bottom: 1px solid #b0b0b0;
  padding: 3px 8px;
  margin-right: 20px;
  width: 100%;
  align-items: center;
  display: flex;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .practical-template-friendly-id {
  font-weight: bold;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .practical-template-tag-container {
  width: 39em;
  display: flex;
  align-items: center;
  min-height: 36px;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .practical-template-tag-container .sub-container {
  width: 13em;
  display: flex;
  justify-content: center;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .practical-template-tag-container .sub-container span {
  height: fit-content !important;
  min-height: 22px !important;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__sort-icons-wrapper {
  margin-right: 4px;
  display: flex;
  align-items: center;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__sort-icons-wrapper i {
  font-size: 18px;
  cursor: pointer;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items :first-child {
  border-bottom: 0;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items p {
  margin: 0 16px !important;
  font-weight: 500;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__question-item.question-bank__cell__tag {
  font-size: 12px;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__question-item.question-bank__cell__tag:not(:last-of-type) {
  margin-right: 5%;
}
.practical-template-wizard__questions-container .tna-wizard__assign-question i.red.large.remove.icon {
  cursor: pointer;
}
