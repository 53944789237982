@import '../../../../../src/assets/less/variables.less';

.instructor-expertise-table-wrapper {
  .full-width.table__flex-container {
    height: 69vh !important;
  }
}

.instructor-expertise__filters-wrapper {
  padding: 0px 15px !important;
  width: 80%;
  position: relative;
  z-index: 10;

  .flex-center-center {
    label {
      padding-right: 12px;
    }
  }
}

.fix-icon {
  i {
    top: 0 !important;
    right: 0 !important;
  }
}

.event-types-list-error-row {
  margin-bottom: 23px;
}

.ui.icon.button.instructor-expertise-filter-icon-btn {
  width: 38px;
  height: 38px;
  margin-top: 8px;
  margin-right: 16px;
  color: white !important;
  background-color: @inverted-color !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .icons {
    .icon:not(.remove) {
      padding-top: 10px;
    }

    .filter {
      font-size: 18px !important;
    }

    .remove {
      text-shadow: unset !important;
      color: white !important;
      opacity: 1;
      background-color: red;
      border-radius: 1em;
      font-size: 0.7rem;
      height: 12px;
      width: 12px;
      line-height: 11px;
      left: 75% !important;
    }
  }
}

.new-instructor-expertise__mr-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .field {
    margin-bottom: 5px !important;
  }
}
