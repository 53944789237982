.practical-question-manager-container .ui.form {
  width: 80%;
  margin: 1rem auto;
}
.practical-question-manager-container .ui.form .eight.wide.field {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.practical-question-manager-container .ui.form .eight.wide.field:first-of-type {
  justify-content: flex-start;
}
.practical-question-manager-container .ui.form .eight.wide.field:first-of-type label {
  min-width: 80px;
  display: flex;
  justify-content: flex-end;
}
.practical-question-manager-container .ui.form .eight.wide.field .selection.dropdown {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 100% !important;
}
.practical-question-manager-container .ui.form .ui.input.practical-minutes-input {
  width: 200px;
}
.practical-question-manager-container .ui.form .ui.input.practical-minutes-input input {
  padding-right: 4px;
  outline: none;
}
.practical-question-manager-container .ui.form .ui.input.practical-minutes-input input:focus {
  border-right: none;
  border-color: #22242626;
}
.practical-question-manager-container .ui.form .ui.input.practical-minutes-input div.ui.label.label {
  padding-left: 4px;
  background-color: transparent;
  border-top: 1px solid #22242626;
  border-bottom: 1px solid #22242626;
  border-right: 1px solid #22242626;
}
.practical-question-manager-container .ui.form .fields.media-box .media-box-label {
  cursor: normal;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  width: 130px !important;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: 12px;
  padding: 0.9em 1em 0.9em;
  text-transform: none;
  text-shadow: none;
  font-weight: bold;
  line-height: 1em;
  font-style: normal;
  text-decoration: none;
  border-radius: 0.33333333rem;
  -webkit-user-select: none;
  user-select: none;
}
.practical-question-manager-container .ui.form .fields.media-box .media-box-label i.icon {
  margin: 0 !important;
}
.practical-question-manager-container .ui.form .fields.media-box .four.wide.field {
  margin-left: 96px;
  padding-left: 0;
}
.practical-question-manager-container .ui.form .fields.media-box .four.wide.field .ui.button {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  width: 130px;
}
.practical-question-manager-container .ui.form .twelve.wide.field {
  display: flex;
  justify-content: flex-end;
}
.practical-question-manager-container .ui.form .twelve.wide.field label {
  min-width: 80px;
  display: flex;
  justify-content: flex-end;
}
.practical-question-manager-container .ui.form .five.wide.field {
  display: flex;
  align-items: center;
}
.practical-question-manager-container .ui.form .five.wide.field label {
  min-width: 80px;
  display: flex;
  justify-content: flex-end;
}
.practical-question-manager-container .ui.form .practical-options-box {
  display: flex;
}
.practical-question-manager-container .ui.form .practical-options-box label {
  min-width: 80px;
  line-height: 2.5em;
  display: flex;
  justify-content: flex-end;
}
.practical-question-manager-container .ui.form .practical-options-box .options-group {
  margin-left: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.practical-question-manager-container .ui.form .practical-options-box .options-group .practical-question-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.practical-question-manager-container .ui.form .practical-options-box .options-group .practical-question-option label {
  min-width: unset;
  white-space: nowrap;
  margin-right: 8px;
  display: flex;
  justify-content: flex-end;
}
.practical-question-manager-container .ui.form .translate-accordion {
  margin-bottom: 16px;
  display: flex;
}
.practical-question-manager-container .ui.form .translate-accordion i.remove.icon {
  color: #e03c31;
  cursor: pointer;
  font-size: 20px;
  padding: 0.3em;
  margin-left: 12px;
  margin-right: 0;
}
.practical-question-manager-container .ui.form .translate-accordion .accordion-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
}
.practical-question-manager-container .ui.form .translate-accordion .accordion-wrapper .accordion__title {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  border-radius: 4px 4px 0 0;
  background-color: #eeeeee;
}
.practical-question-manager-container .ui.form .translate-accordion .accordion-wrapper .accordion__content {
  padding: 16px 0 !important;
  border-bottom: 1px solid #eeeeee;
}
.practical-question-manager-container .ui.form .translate-accordion .accordion-wrapper .accordion__content .ui.selection.dropdown {
  min-width: auto;
  width: 100%;
}
.practical-question-manager-container .ui.form .add-translation-btn {
  width: 100%;
}
