.form__all-wrapper.tna-form .ui.accordion .title {
  padding: 0;
  background-color: #ccf0ee;
  color: #474b4f;
}
.form__all-wrapper.tna-form .ui.accordion .title i.dropdown.icon {
  vertical-align: middle;
  font-size: 2rem;
}
.form__all-wrapper .form__all-wrapper__header {
  position: sticky;
  margin-bottom: 10px;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language.flex-between {
  align-items: stretch !important;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .edit-form__header__general-info {
  margin-right: 1rem;
  width: 100%;
  display: flex;
  margin-top: -10px;
  flex-direction: row;
  justify-content: flex-start !important;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .edit-form__header__general-info:not(.ssa) {
  flex-wrap: wrap;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .edit-form__header__general-info .functional-experts {
  display: flex;
  flex-wrap: wrap;
  max-height: 210px;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .edit-form__header__general-info .edit-form__header__general-info__element {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 150px;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .edit-form__header__general-info .edit-form__header__general-info__element:not(:last-child) {
  margin-right: 50px;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .edit-form__header__general-info .edit-form__header__general-info__element h5 {
  margin-bottom: 8px;
  font-weight: 500;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .edit-form__header__general-info .edit-form__header__general-info__element p {
  font-weight: 600;
}
.form__all-wrapper .form__all-wrapper__header .edit-form__header__data-language .planit-user-dropdown-custom {
  min-width: 160px;
  max-height: 3em;
}
.form__all-wrapper .form__all-wrapper__header .header-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form__all-wrapper .form__all-wrapper__header .ui.divider {
  border: 0.5px solid #474b4f;
  margin: 10px 0px 10px 0px;
}
.ui.button.btn__check-questions {
  margin: 0.5em 0;
  align-self: flex-end;
}
.remaining-questions .remaining-questions__modal-content .item ::before {
  line-height: 1.9em;
}
.remaining-questions .remaining-questions__modal-content .remaining-item__box {
  text-align: right;
  background-color: #eee;
  padding: 4px;
  border-radius: 4px;
}
.remaining-questions .remaining-questions__modal-content .remaining-questions__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.remaining-questions .remaining-questions__modal-content .remaining-questions__all-list .remaining-questions__general-list {
  padding: 4px 0 4px 16px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  margin-bottom: 16px;
}
.remaining-questions .remaining-questions__modal-content .remaining-questions__all-list .remaining-questions__general-list .remaining-questions__remaining-item {
  padding: 8px;
}
.remaining-questions .remaining-questions__modal-content .remaining-questions__all-list .remaining-questions__machines-list > .item {
  padding: 8px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  margin-bottom: 16px;
}
.remaining-questions .remaining-questions__modal-content .remaining-questions__all-list .remaining-questions__machines-list > .item ::before {
  line-height: 1.8em;
}
