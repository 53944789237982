@import './variables.less';

.ui.fluid.selection.dropdown.custom-table-pagination-select {
  max-height: 35.28px;
  height: 100% !important;
  min-height: 2.5rem !important;
  padding: 11px;

  i {
    padding: 10px;
  }

  .text,
  .item {
    font-size: 12px;
  }
}

.custom-table-footer {
  width: 100% !important;
}

.custom-table-pagination {
  a.active.item {
    background-color: @inverted-color !important;
    color: white !important;
  }
}

.custom-table-pagination-select-column.custom-table-pagination-select-column {
  width: 125px !important;
  padding: 0 !important;
  height: 35.28px;
}

.custom-table-pagination-column.custom-table-pagination-column {
  width: calc(100% - 255px) !important;
}

.custom-table.ui.table thead tr th a {
  color: white;
}

.custom-table thead tr th {
  background-color: @inverted-color !important;
  min-width: 110px !important;

  &.small-icon {
    min-width: 3em !important;
  }
  &.text-long {
    min-width: 350px !important;
  }
}

.custom-table > thead > tr > th:last-child {
  max-width: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
}

.custom-table td span {
  text-overflow: ellipsis !important;
  white-space: nowrap;
  .table__short-textable {
    max-width: 135px;
  }
  display: block;
}

.table__cell-selected-background-no-check {
  background-color: #eeeeee !important;
}

.custom-table-clear-sorts,
.custom-table-clear-filters,
.custom-table-add-btn {
  height: 100%;
}

.custom-table-actions,
.custom-table-main-actions {
  .item {
    height: fit-content !important;
  }
}

tr.active.active.active.active.active {
  td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.custom-table-main-actions {
  margin-bottom: 16px !important;

  .fitted.item {
    .button:not(.custom-table-add-btn) {
      background-color: @inverted-color !important;
      color: white !important;
      width: 3.25em;
      height: 38px !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      font-weight: 500;

      .icons {
        i {
          color: white !important;
          opacity: 1 !important;
          text-shadow: none !important;

          &.corner {
            left: 80%;
            font-size: 0.6rem;
          }
        }
      }
    }
  }
}

.custom-table-add-btn {
  background: @inverted-color !important;
  color: white !important;
  font-size: 1rem !important;
  height: 38px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;

  i {
    color: @inverted-color !important;
    background-color: white !important;
  }

  .add.tiny.circular.icon {
    margin-left: 6px !important;
  }

  &:hover {
    border: 1px solid #00b2a9 !important;
  }
}

.custom-table-add-row-btn-inverted {
  background: white !important;
  border: 1px solid @inverted-color !important;
  color: @inverted-color !important;
  font-size: 1rem !important;
  height: 38px;

  i {
    background-color: @inverted-color !important;
    color: white !important;
  }
}

/*Expanded rows*/
.custom-table-expand-column {
  width: 3% !important;
}

.custom-table-expanded-row {
  td {
    border-top: 0 !important;
    padding-top: 0 !important;
  }
}

.custom-table-expanded-row-parent {
  td {
    padding-bottom: 0 !important;
  }
}

.row-disable-layout {
  color: #b0b0b0;

  .question-bank__cell__tag.question-bank__cell__tag {
    background-color: #eeeeee9d;
    color: #b0b0b0;
  }
}

.custom-table-expand-icon {
  cursor: pointer;
  font-size: 14px !important;
  color: @inverted-color;
}

.custom-table__checkbox-invisible {
  svg {
    display: none;
  }
}

/*Expanded rows*/

/*Extra inline actions*/
.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions.custom-table-extra-inline-actions {
  border: 0 !important;
  box-shadow: none !important;

  &:hover {
    box-shadow: none !important;
    border: 0 !important;
    background: none;
  }

  button {
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 0.5rem !important;

    i {
      font-size: 18px !important;
      color: @inverted-color;
    }

    &:hover,
    &:active {
      box-shadow: none !important;
      background: none !important;
    }

    // &:hover{
    //     i {
    //         color: @hover-green;
    //     }
    // }
  }

  .toggle.toggle.toggle.toggle {
    width: 2rem !important;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    input {
      width: 2rem !important;
    }

    label {
      padding: 0 !important;
    }
  }

  :last-child {
    &.button {
      padding-right: 0 !important;
    }

    &.toggle {
      margin-right: 0 !important;
    }
  }
}

.padding-right-zero {
  padding-right: 0 !important;
}

/*Extra inline actions*/

/* FILTERS*/
/*Semantic ui tables override*/
.ui.table thead tr th.selectable {
  padding: 0;
  cursor: pointer;

  .filter {
    margin-left: 4px;
  }
}

.ui.table thead tr th a {
  .filter {
    margin-left: 2px;
  }
}

.ui.table thead tr th {
  line-height: 14px;
}

.ui.table thead th {
  width: fit-content !important;

  b {
    margin-right: 5px;
    padding-right: 0px !important;
  }

  a {
    margin-right: 5px;
    padding-right: 0px !important;
  }
}

.ui.table thead tr th.selectable > a:not(.ui) {
  display: block;
  color: inherit;
  padding: 0.78571429em 0.78571429em;
}

.options-filter {
  min-width: 15rem;

  .select-field {
    padding-right: 0 !important;

    .selection.dropdown {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .filter-button-field {
    padding-left: 0 !important;

    button {
      height: 100%;
      border-radius: 0px 0.28571429rem 0.28571429rem 0px !important;
    }
  }
}

.dates-filter {
  .filter-button-field {
    button {
      border-radius: 0.28571429rem !important;
    }
  }

  form {
    width: 21rem !important;
  }

  .small.table * {
    font-size: 1.03em !important;
  }

  .small.table {
    min-width: 100% !important;
  }
}

.checkbox-filter {
  .filter-button-field {
    button {
      border-radius: 0.28571429rem !important;
    }
  }
}

.textbox-filter {
  input {
    font-size: 1.5em !important;
  }
}

/* FILTERS*/

/*toggle*/
table > tbody > tr > td {
  .ui.toggle.checkbox {
    min-height: 1.3rem !important;

    input {
      height: 1.3rem !important;
    }

    .box,
    label {
      min-height: 1.3rem !important;
      padding-left: 3rem !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 0 !important;
      width: 1rem;
    }

    .box:after,
    label:after {
      width: 1.3rem !important;
      height: 1.3rem !important;
      z-index: 1;
    }

    .box:before,
    label:before {
      width: 2rem !important;
      height: 1.3rem !important;
      border-radius: 1.3rem !important;
    }

    input:checked ~ .box:after,
    input:checked ~ label:after {
      left: 0.9rem !important;
    }
  }
}

/*toggle*/

/*full width table*/
.full-width {
  width: 100%;
}

/*full width table*/

.menu-item-download {
  padding: 0px !important;
}

.transparent-btn {
  background-color: transparent !important;
  border: solid 1px lightgray !important;
}

.black-btn {
  background-color: #474b4f !important;
  border: solid 1px lightgray !important;
  color: white !important;
}

.custom-table-upload-btn svg {
  font-size: 1.75rem;
}

.custom-table-hoverable-btn {
  box-shadow: none !important;
  border: none !important;
  height: 38px !important;

  &:hover {
    border: 1px solid #00b2a9 !important;
  }

  i {
    opacity: 1 !important;
    color: white !important;
  }
}

.custom-table-upload-btn {
  width: 38px;
  height: 38px;
  padding: 5px !important;
}

.custom-table-upload-btn__up-down {
  &:hover {
    color: #00b2a9 !important;
    border: 1px solid #00b2a9 !important;
  }
}

/*Semantic ui tables override*/

.bottom.right.popup.transition.visible {
  left: 9px !important;
}

.table__flex-container {
  overflow-x: scroll;
  height: 70vh;
  margin-bottom: 10px;
  scrollbar-width: thin;
}

.table__flex-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 3px;
  background-color: rgba(71, 75, 79, 0.63);
  transition: 0.9s;
}

.table__flex-container::-webkit-scrollbar-track {
  border-radius: 10px;
}

.full-width.table__flex-container {
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  :hover &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}

.table__flex-container > table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 2;
}

.table-general__pagination-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  .custom-table-pagination-select {
    width: 100px !important;
    margin-left: 20px;
  }

  .custom-table-pagination-total-rows {
    margin-left: 10px;
    padding: 11px;
  }
}

.custom-table-hide-column-btn {
  i.large.icon {
    line-height: 0.83333333em;
    height: 0.83333333em;
  }
}

.table__span-max-width-fit-content {
  max-width: fit-content !important;
}

.custom-table {
  tbody {
    tr:last-child td {
      border-bottom: 1px solid #2224261a;
    }
  }
}

.modal-content.user-imports .custom-table {
  thead tr th:first-of-type {
    min-width: 0 !important;
  }

  td span {
    white-space: pre-wrap;
  }
}

.filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 97%;
  margin-top: -8px;
  padding: 0px 15px !important;
  z-index: 10;

  .filter {
    margin-top: 8px;
    margin-right: 16px;
    min-height: 38px;
    height: 38px;
  }

  .filter-dates {
    .input {
      min-width: max-content;
    }
  }

  .planit-user-dropdown {
    div.text {
      font-weight: normal !important;
    }
  }
}

.table__filters-share-main-actions {
  margin-bottom: -36px !important;
  padding: 0px 15px !important;
}

.ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;

  div .fitted.item {
    .ui.mini.icon.button.custom-table-add-btn {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
}

//MEDIA QUERIES
@media all and (max-height: 750px) {
  .table__flex-container {
    overflow: scroll;
    height: 58vh;
  }
}

@media all and (min-width: 1300px) {
  .custom-table td span .table__short-text {
    max-width: 170px;
  }
}
