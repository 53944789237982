.form__event-details__container {
  display: flex;
  flex-direction: column;
}
.form__event-details__container p {
  margin-right: 16px;
  margin-bottom: 0px;
  width: fit-content;
}
.form__event-details__container h4 {
  font-size: 14px;
}
.form__event-details__container .form__header__dropdown-style {
  min-width: 138px;
}
.form__event-details__container .request-details-tab__mr__pattern-row-btns .events-form__btn__positive {
  width: 65px;
}
.form__event-details__container .request-details-tab__mr__pattern-row-btns .events-form__btn__negative {
  width: 65px;
}
.form__event-details__container .events-form__btn__positive {
  background-color: #474b4f;
  color: white;
  font-weight: 500;
  text-align: center;
}
.form__event-details__container .events-form__btn__positive:hover {
  background-color: #474b4f;
  color: white;
}
.form__event-details__container .events-form__btn__negative {
  color: #b0b0b0;
  text-align: center;
  background-color: white;
  font-weight: 500;
}
.form__event-details__container .events-form__btn__negative:hover {
  color: #b0b0b0;
  background-color: white;
}
.form__event-details__container .form__event-details-tab__element {
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}
.form__event-details__container .scroll {
  scroll-padding: 5px !important;
}
.form__event-details__container .scroll::-webkit-scrollbar {
  height: 4px;
}
.form__event-details__container .scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #dfdfdf;
}
@media all and (min-width: 1300px) {
  .form__event-details__container .form__event-details-tab__mr__pattern {
    padding-left: 46px;
  }
}
@media all and (max-width: 1210px) {
  .form__event-details__container .event-details-tab__mr-table {
    overflow-x: scroll !important;
    padding-bottom: 120px !important;
  }
}
