.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles {
  width: 100%;
  flex-direction: column;
  align-items: flex-start !important;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .flex-start {
  align-items: center !important;
  margin-bottom: 39px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles p {
  margin-right: 16px;
  margin-bottom: 0px;
  width: fit-content;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles h4 {
  font-size: 14px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .form__header__dropdown-style {
  min-width: 138px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .events-form__btn__positive {
  background-color: #474b4f;
  color: white;
  font-weight: 500;
  text-align: center;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .events-form__btn__positive:hover {
  background-color: #474b4f;
  color: white;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .events-form__btn__negative {
  text-align: center;
  color: #b0b0b0;
  background-color: white;
  font-weight: 500;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .events-form__btn__negative:hover {
  color: #b0b0b0;
  background-color: white;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .form__event-details-tab__element {
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .scroll {
  scroll-padding: 5px !important;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .scroll::-webkit-scrollbar {
  height: 4px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #dfdfdf;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(6, 1fr) 20px;
  grid-template-rows: repeat(auto, 1fr);
  margin-bottom: 20px;
  font-size: 11px;
  overflow: visible;
  padding-bottom: 10px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table div.field > label {
  font-weight: normal;
  font-size: 11px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .ui.selection.dropdown {
  min-width: 100px;
  width: 95%;
  margin-right: 5px !important;
  line-height: 12px;
  min-height: 100%;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .border-bottom-gray {
  padding-top: 9.5px;
  padding-bottom: 9.5px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown {
  margin-top: 8px;
  padding: 8px;
  display: flex !important;
  align-items: center !important;
  margin-right: 5px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown i {
  height: 100% !important;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown > div.text {
  display: flex !important;
  align-items: center !important;
  width: 70px !important;
  text-overflow: ellipsis !important;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown > div.text div {
  display: flex !important;
  align-items: center !important;
  width: 70px !important;
  text-overflow: ellipsis !important;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown__multi {
  margin-top: 8px;
  padding: 4px;
  margin-right: 5px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown__multi > div.default.text {
  font-weight: 11px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown__multi .ui.label {
  width: 66px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .event-details-tab__mr-table__remove {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__column-title {
  background-color: #eeeeee;
  width: 100%;
  padding: 8px;
  margin-right: 0px;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .text__transparent {
  color: transparent;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown.disabled {
  border: none !important;
  background-color: white !important;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .mr-table__dropdown.disabled i {
  display: none;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .ui.multiple.search.dropdown > input.search {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  min-height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  flex-direction: row !important;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .ui.multiple.search.dropdown > .text {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 80%;
  height: 100%;
  margin: 0px !important;
  padding: 5px !important;
  font-weight: 500;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .ui.search.dropdown > .text {
  width: 80%;
  height: 100%;
  margin: 0px !important;
}
.form__event-details-tab__mr__pattern.form__event-details-tab__element.mr-table-component__common-styles .event-details-tab__mr-table .ui.search.dropdown > .text > div {
  width: 100%;
  height: 100%;
}
