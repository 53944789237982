@import './variables.less';

.assessment-forms-grey-tag {
  background-color: #eeeeee;
  margin: 5px;
  color: #474b4f;
  font-weight: 500;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  border-radius: 10px;
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment-forms__table__tag {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 5px;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  line-height: 11;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  background-color: white;
}

.assessment-forms-employee-tag {
  color: #75a2d8;
  border: 1px #75a2d8 solid;
}

.assessment-forms-done-tag {
  color: #4bc982;
  border: 1px #4bc982 solid;
}

.assessment-forms-out-of-date-tag {
  color: #b0b0b0;
  border: 1px #b0b0b0 solid;
}

.assessment-forms-assessors-tag {
  color: #f2cc67;
  border: 1px #f2cc67 solid;
}

#assessment-forms-list-filters__title + .planit-line-separator {
  margin-bottom: 0 !important;
}

.specific-margin-bottom-equal-filters {
  margin-bottom: -49px;
}

.specific-margin-top-equal-filters {
  margin-top: 5px;
}

#assessment-forms-list-filters__admin-or-poc-container {
  display: inline-flex;
  width: calc(100% - 5px);

  #assessment-forms-list-filters__admin-or-poc-container__lef-container {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .field {
      margin: 0px 12px 12px 0px;

      .planit-multi-dropdown {
        margin-bottom: unset;
        margin-right: unset;
      }

      .input {
        height: 38px;
      }

      .ui.search.selection.dropdown,
      .ui.icon.input {
        height: 38px !important;
      }
    }

    .width-14em.width-14em.width-14em.width-14em {
      width: 14em !important;
    }
  }

  #assessment-forms-list-filters__admin-or-poc-container__right-container {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: -25px;

    .field {
      margin: 0.5em 0;
      height: 35.4px;
    }

    button {
      margin: 0 !important;
      height: 38px;
    }

    .orderby {
      text-align: end;
      width: fit-content;
      white-space: nowrap;
      padding-right: 55px;
      margin-top: 20px;
    }

    .include-users {
      padding-right: 100px !important;
    }
  }
}

#assessment-forms-list-filters__employee-but-no-assessor-container {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -41px !important;

  .field {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.5em;

    .input,
    .search,
    .custom-editor {
      height: 38px;
    }
  }

  .width-14em.width-14em.width-14em.width-14em {
    width: 14em !important;
  }
}

.assessment-forms-list-deactivate-button {
  font-weight: bold;
  color: red !important;
}

.tna-theoretical__wrapper {
  .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
    margin-top: -47px;
  }
}
