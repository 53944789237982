.vtc-section {
  margin: 8px 0;
}
.hzt-section {
  margin-right: 24px;
}
.main-separator {
  border-bottom: 1px solid #999;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.requests-events-wizard___modal .ui.input.ui.input.ui.input.ui.input.ui.input.ui.input.ui.input {
  height: 32px !important;
  min-height: 32px;
  line-height: 0.8em;
}
.new-events-support-details__container {
  font-size: 12px;
  padding: 0 50px;
  background-color: white;
}
.new-events-support-details__container .events-support-details__main-info {
  position: relative;
  display: flex;
  margin: 32px 0;
  align-items: center;
}
.new-events-support-details__container .events-support-details__main-info .cogs.icon {
  position: absolute;
  left: -45px;
  font-size: 17px;
}
.new-events-support-details__container .events-support-details__main-info .events-support-details__main-data {
  margin-right: 50px;
  display: flex;
  align-items: center;
}
.new-events-support-details__container .events-support-details__main-info .events-support-details__main-data span {
  margin-right: 12px;
}
.new-events-support-details__container .events-support-details__main-info .events-support-details__main-data > .ui.input {
  width: 90px;
}
.new-events-support-details__container .events-support-details__main-detail {
  display: flex;
  flex-direction: column;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 16px 0;
  width: 100%;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position > div {
  display: flex;
  margin-bottom: 20px;
  margin-right: 16px;
  align-items: center;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position .flex-column {
  flex-direction: row;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position label {
  margin-right: 16px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position .supppor-details__item-main-segment .ui.icon.input {
  width: 130px;
  margin-right: 0px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position > label {
  margin-right: 16px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position .ui.input {
  margin-right: 16px;
  min-width: 20px !important;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__info-position .ui.icon.button {
  background-color: white;
  color: #474b4f;
  border: 1px solid #474b4f;
  border-radius: 4px;
  width: 150px;
  height: 35px;
  line-height: 0.8em;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail {
  display: flex;
  padding: 20px 20px 20px 40px;
  background: #eee;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__comment {
  margin-bottom: 16px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .ui.right.labeled.input input {
  padding-right: 4px;
  width: 50px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .ui.right.labeled.input .ui.basic.label.label {
  padding-left: 4px;
  border-left: 0;
  line-height: 0.8;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .ui.right.labeled.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .ui.right.labeled.input.enlarged-input input {
  width: 75px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed span {
  margin-right: 16px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .fields .field .ui.icon.input {
  width: 100px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .fields .field .ui.icon.input i {
  display: none;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .fields .field .from-date .ui.icon.input input {
  border-radius: 4px 0 0 4px;
  padding-right: 1em !important;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info .info-support-details__employee-detailed .fields .field .to-date .ui.icon.input input {
  border-radius: 0 4px 4px 0;
  padding-right: 1em !important;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .events-support-details__detail-position .info-support-details__employee-info:last-child {
  margin-top: 0;
  width: 100%;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .edit.icon {
  cursor: pointer;
  align-self: center;
  font-size: 16px;
}
.new-events-support-details__container .events-support-details__main-detail .events-support-details__detail .remove.icon {
  margin-left: 25px;
  cursor: pointer;
  font-size: 16px;
  align-self: center;
}
