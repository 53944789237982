.edit-development-plan-modal {
  display: flex !important;
  flex-direction: column !important;
  width: 60%;

  .edit-development-plan-modal__header {
    padding: 24px 32px 20px 32px !important;
  }

  .edit-development-plan-modal__content {
    padding: 24px 32px 12px 32px !important;
  }

  .edit-development-plan-modal__actions {
    padding: 12px 32px 24px 32px !important;
  }

  .edit-development-plan-modal__field {
    label {
      font-weight: bold;
      min-width: 160px;
      margin-right: 14px;
    }

    .flex-column {
      label {
        font-weight: normal;
        padding-bottom: 10px !important;
      }
    }

    &.edit-development-plan-modal__machinery {
      > label {
        margin-top: 20px;
      }
    }

    .edit-development-plan-modal__machinery__field {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }

    min-height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    margin-right: 8px;

    .custom-editor {
      margin-right: 12px;
      max-width: 165px !important;
      margin-bottom: 5px;
    }
  }

  .confirm-changes-btn {
    width: fit-content !important;
  }
}

@media all and (min-width: 1920px) {
  .edit-development-plan-modal {
    width: 70% !important;
  }
}
