.planit-user-fields__location-select {
  width: 300px !important;
}
.planit-user-fields__position-code-select {
  width: 300px !important;
}
.planit-users__toggle-active-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 165px;
  margin-top: 8px;
}
.planit-users-fields__wrapper-element {
  width: 110px;
}
.planit-user-selector__position-code {
  width: 300px !important;
}
.ui.fitted.toggle.checkbox.edit-user-modal__checkbox-toggle {
  min-width: 40px;
  width: 40px;
}
.ui.fitted.toggle.checkbox.edit-user-modal__checkbox-toggle label::before {
  min-width: 30px;
  width: 30px;
}
.ui.fitted.toggle.checkbox.edit-user-modal__checkbox-toggle label::after {
  left: 0px;
}
.ui.checked.fitted.toggle.checkbox.edit-user-modal__checkbox-toggle {
  min-width: 40px;
  width: 40px;
}
.ui.checked.fitted.toggle.checkbox.edit-user-modal__checkbox-toggle label::before {
  min-width: 30px;
  width: 30px;
}
.ui.checked.fitted.toggle.checkbox.edit-user-modal__checkbox-toggle label::after {
  left: 15px;
}
.user-modal_checkbox-container {
  position: absolute;
  top: 0;
  right: 7px;
}
.user-modal_checkbox-field {
  display: inline-block;
  padding: 7px 0;
}
.user-modal_checkbox-field .ui.checkbox.user-modal__checkbox-toggle {
  min-height: 1.3rem !important;
  vertical-align: bottom;
}
.user-modal_checkbox-field input {
  height: 1.3rem !important;
}
.user-modal_checkbox-field label {
  min-height: 1.3rem !important;
  padding-left: 3rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  width: 1rem;
}
.user-modal_checkbox-field .box:before,
.user-modal_checkbox-field label:before {
  width: 2rem !important;
  height: 1.3rem !important;
  border-radius: 1.3rem !important;
  left: 0.9rem !important;
}
.user-modal_checkbox-field .box:after,
.user-modal_checkbox-field label:after {
  width: 1.3rem !important;
  height: 1.3rem !important;
  z-index: 1;
}
.user-modal_checkbox-field input:checked ~ .box:after,
.user-modal_checkbox-field input:checked ~ label:after {
  left: 1.9rem !important;
}
.user-modal_checkbox-field input:not(:checked) ~ .box:after,
.user-modal_checkbox-field input:not(:checked) ~ label:after {
  left: 0.9rem !important;
}
.field.user-modal_checkbox-field > label {
  margin-right: 0 !important;
}
@media all and (max-width: 770px) {
  .planit-user-fields__location-select,
  .planit-user-selector__position-code {
    width: 200px !important;
  }
  .field.planit-users-inputs.planit-users-inputs__date-time {
    margin-left: 40px;
  }
  .user-modal_checkbox-container {
    display: flex;
    position: unset;
    justify-content: flex-end;
  }
}
