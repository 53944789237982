.ssa-edit-development-plan-modal {
  display: flex !important;
  flex-direction: column !important;
  width: 60%;

  .ssa-edit-development-plan-modal__header {
    padding: 24px 32px 20px 32px !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }

  .ssa-edit-development-plan-modal__content {
    padding: 24px 32px 12px 32px !important;
  }

  .ssa-edit-development-plan-modal__actions {
    padding: 12px 32px 24px 32px !important;
  }

  .ssa-edit-development-plan-modal__field {
    label {
      font-weight: bold;
      min-width: 95px;
      margin-right: 38px;
    }

    .flex-column {
      label {
        font-weight: normal;
        padding-bottom: 10px !important;
      }
    }

    min-height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    margin-right: 8px;

    .custom-editor {
      margin-right: 12px;
      max-width: 165px !important;
      margin-bottom: 5px;
    }
  }

  .flex-start {
    label {
      padding-top: 10px;
    }
  }

  .confirm-changes-btn {
    width: fit-content !important;
  }
}

@media all and (min-width: 1920px) {
  .ssa-edit-development-plan-modal {
    width: 70% !important;
  }
}
