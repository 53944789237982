.wizard__steps-container {
  padding: 0px 15px;

  .wizard__step-two {
    .step-two__machinery-wrapper {
      margin-left: 0px;

      .fields {
        width: 100%;
        display: flex;

        .field.machinery__label-input-field {
          width: 175px;
          margin-right: 16px;

          label {
            font-weight: bold;
            font-size: 12px;
          }
        }
      }
    }

    .custom-editor {
      min-width: 175px !important;
      max-width: 175px !important;
      margin-top: 8px;
      margin-bottom: 0 !important;
    }

    .editor-38px {
      .ui.search.selection.dropdown {
        min-height: 38px;
        height: unset !important;
        padding: 10px;

        input {
          padding: 10px;
        }

        i.dropdown.icon {
          padding: 9px !important;
        }

        &.loading.loading.loading.loading {
          i.dropdown.icon {
            padding: 17px !important;
          }
        }

        div.text {
          font-size: 12px !important;
          width: calc(100% - 20px) !important;
          top: 1px;
        }
      }
    }

    .dropdown.machinery-step__multi-machine-model-editor.multiple.search.selection.ui {
      min-height: 38px !important;
      max-height: fit-content !important;
      height: max-content !important;
      margin-top: 8px;
      padding-right: 2em;

      .search {
        margin: 0px;
        min-height: 38px !important;
        height: fit-content !important;
        padding: 0px;
      }
    }
  }
}
