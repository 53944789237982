.position-code_all-content__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.position-code_all-content__wrapper.add-container {
  margin-left: 11px !important;
}
.position-code_all-content__wrapper .fields {
  margin: 0em 0em 1em !important;
}
.newPositionCode__tags-container {
  min-height: 95px;
  min-width: 650px;
  margin-left: 45px !important;
  width: 742px;
  border-radius: 4px;
  border: 1px solid black;
  margin-right: 44px;
}
.position-code-info__wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}
.keyword-input-button__wrapper {
  display: flex;
  align-items: flex-start;
}
.position-code__label-input__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
}
.position-code__label-input__wrapper label {
  min-width: fit-content;
  padding-right: 23px;
}
#position-code__input-description {
  min-width: 300px;
  max-width: 300px;
  height: 32px;
}
#position-code__input-code {
  height: 32px;
  width: 225px !important;
}
.footer-modal {
  padding-left: 103px;
  margin-top: 20px;
}
