@import './variables.less';

.stepper-container.stepper-container.stepper-container.stepper-container.stepper-container {
  background-color: @inverted-color;

  h2 {
    color: white;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }

  div:not(.stepper-back) {
    div {
      div {
        //step
        div {
          //circle
          a,
          span {
            line-height: 24px !important;
            font-weight: 300 !important;
            letter-spacing: 0.24px !important;
          }

          img {
            width: 100%;
            color: white;
            font-weight: 300 !important;
            letter-spacing: 0.24px !important;
          }
        }

        a,
        div {
          //title
          font-weight: 300 !important;
          letter-spacing: 0.24px !important;
        }

        div:empty:nth-last-child(1) {
          border-top: 2px solid rgb(176, 176, 176) !important;
        }

        div:empty:nth-last-child(2) {
          border-top: 2px solid rgb(176, 176, 176) !important;
        }
      }
    }
  }
}
