.rowheight-slider {
  background: white;
  width: 90px;
  height: 22px;
  padding: 16px 8px;
  border: 1px solid #949494;
  border-radius: 4px;
  outline: none;
  align-self: start;
}
.rowheight-slider input[type='range']:focus::-webkit-slider-runnable-track {
  background-color: black !important;
}
.rowheight-slider input[type='range'] {
  outline: none;
}
.rowheight-slider input[type='range']:focus {
  outline: none;
}
.rowheight-slider input[type='range']::-webkit-slider-thumb {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  background: white;
}
.rowheight-slider input[type='range']::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  background: white;
}
.rowheight-slider input[type='range']::-ms-thumb {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  background: white;
}
.rowheight-slider input[type='range']::-webkit-slider-runnable-track {
  height: 4px;
  background: black;
  border-radius: 8px;
  width: 65px;
}
.rowheight-slider input[type='range']::-moz-range-track {
  height: 4px;
  background: black;
  border-radius: 8px;
  width: 65px;
}
.rowheight-slider input[type='range']::-ms-track {
  height: 4px;
  background: black;
  border-radius: 8px;
  width: 65px;
}
