.ui.table.events-form-requests-list {
  > thead > tr > th {
    color: black;
    font-weight: normal;
    padding: 6px;
    padding-bottom: 8px !important;
  }

  > tbody > tr > td {
    border-top: none;
    padding: 6px;
  }

  > tbody > tr.bold-row {
    font-weight: bold;
  }

  > thead > tr > th:first-child {
    padding-left: 1em !important;
  }

  > tbody > tr > td:first-child {
    padding-left: 1em !important;
  }

  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
}
