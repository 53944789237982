.sch-instructors__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .employee-search-filter.input-search {
    align-self: flex-start;
    margin-right: 6px;
    margin-bottom: 10px;
    width: 150px;
    height: 38px !important;
    & > input {
      border: 1px solid #474b4f;
    }
  }

  .sch-instructors__header-above {
    display: flex;
  }

  .sch-instructors__header-below {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .sch-instructors__header-left {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    justify-content: flex-end;
    max-width: fit-content;
    width: 30%;

    .row {
      margin-top: -12px;
      position: relative;

      &.top-margin {
        margin-top: 8px;
      }
      button.button:not(:last-of-type) {
        margin-right: 8px;
      }
      display: flex;
      align-items: stretch;
      > div {
        display: flex;
        align-self: center;
        margin-top: 20px;
      }
    }
    .sch-month-button {
      margin-top: 20px !important;
    }

    b {
      margin-left: 8px;
      align-self: flex-start;
    }
  }

  .sch-instructors__header-right {
    display: flex;
    align-items: flex-start;
    width: 70%;
    justify-content: flex-end;
    flex-direction: row;
    padding: 0;
    position: relative;
    float: left;
    max-width: fit-content;
  }

  .planit-multi-dropdown {
    min-width: 150px !important;
    height: 38px;
    border-radius: 4px;
    margin-right: 8px;
    border: 1px solid #474b4f !important;
    margin-bottom: 6px;

    > div.menu.visible.transition,
    > div.visible.menu.transition {
      width: fit-content !important;
      border: 1px solid rgba(128, 128, 128, 0.541);
    }
  }

  .planit-multi-dropdown__options {
    overflow-x: hidden;
    text-overflow: ellipsis;

    > span > div {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > span > div > span {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.planit-multi-dropdown__options::-webkit-scrollbar {
  width: 4px;
}

.planit-multi-dropdown__options::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

.planit-multi-dropdown__options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0, 0, 0);
}

//MEDIA QUERIES
@media all and (max-width: 1200px) {
  .sch-instructors__header {
    .planit-multi-dropdown {
      // min-width: 130px !important;
      margin-bottom: 10px;

      > span {
        line-height: 12px !important;
      }
    }
  }
}
