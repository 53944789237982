.wizard__step-one .iws-wizard__label-title.flex-start {
  margin-bottom: 44px;
}
.wizard__step-one .step-one__content {
  margin-left: 62px;
}
.wizard__step-one .step-one__content .profile-editor .ui.search.selection.dropdown {
  min-height: 38px;
  height: unset !important;
  padding: 10px;
}
.wizard__step-one .step-one__content .profile-editor .ui.search.selection.dropdown input {
  padding: 10px;
}
.wizard__step-one .step-one__content .profile-editor .ui.search.selection.dropdown i.dropdown.icon {
  padding: 10px !important;
}
.wizard__step-one .step-one__content .profile-editor .ui.search.selection.dropdown.loading.loading.loading.loading i.dropdown.icon {
  padding: 18px !important;
}
.wizard__step-one .step-one__content .profile-editor .ui.search.selection.dropdown div.text {
  font-size: 12px !important;
  width: calc(100% - 20px) !important;
  top: 1px;
}
.wizard__step-one p,
.wizard__step-one label {
  margin-right: 16px;
  font-size: 12px;
  font-weight: 500;
}
.wizard__step-one .input {
  margin-right: 40px;
  min-width: 50%;
  height: 38px;
}
.wizard__step-one .ui.search.selection.dropdown.custom-editor {
  width: 175px !important;
  height: 32px !important;
}
.wizard__step-one .iws-wizard__textarea {
  width: 50%;
  height: 150px;
  border-radius: 4px;
  padding: 20px;
}
.wizard__step-one .step-one__content:nth-child(3) {
  margin-top: 31px;
}
