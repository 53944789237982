.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .ui.button {
  margin: 0 0 16px 0;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question {
  display: flex;
  align-items: center;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question:first-of-type .tna-wizard__assign-question__items {
  border-top: 1px solid #b0b0b0;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question:last-of-type {
  margin-bottom: 32px;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items {
  border-bottom: 1px solid #b0b0b0;
  padding: 8px;
  margin-right: 20px;
  width: 100%;
  align-items: center;
  display: flex;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__sort-icons-wrapper {
  margin-right: 4px;
  display: flex;
  align-items: center;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items .wizard__sort-icons-wrapper i {
  font-size: 18px;
  cursor: pointer;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items :first-child {
  border-bottom: 0;
}
.feedbacks-wizard__steps-container.theoretical-form-wizard-container .theoretical-template-wizard__questions-container .tna-wizard__assign-question .tna-wizard__assign-question__items p {
  margin: 0 16px !important;
  font-weight: 500;
}
