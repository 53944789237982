.icon-selector__input.icon-selector__input.icon-selector__input {
  min-width: 5em !important;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 36px;
  height: 36px;
}
.icon-selector__input.icon-selector__input.icon-selector__input .icon-selector__input__icon-container {
  font-size: 1.5em;
  line-height: 1.5em;
  min-height: 34px;
  height: 34px;
}
.icon-selector__input.icon-selector__input.icon-selector__input .icon-selector__input__icon-container i {
  min-height: 34px;
  line-height: 34px;
}
.icon-selector__input.icon-selector__input.icon-selector__input i.icon-selector__input__icon-dropdown,
.icon-selector__input.icon-selector__input.icon-selector__input i.icon-selector__input__icon-clear {
  right: 18px !important;
  padding: 0 !important;
  height: 34px !important;
  line-height: 34px !important;
}
.icon-selector__input.icon-selector__input.icon-selector__input i.icon-selector__input__icon-clear:before {
  content: '\f00d' !important;
}
.icon-selector__grid .icon-selector__grid__search-row {
  border-bottom: #e6e8e7 1px solid;
}
.icon-selector__grid .icon-selector__grid__search-row .input {
  width: 100%;
}
.icon-selector__grid .icon-selector__grid__icon-row .icon-selector__grid__icon-row__icon-column i {
  font-size: 1.5em;
}
.icon-selector__grid .icon-selector__grid__icon-row .icon-selector__grid__icon-row__icon-column i:hover,
.icon-selector__grid .icon-selector__grid__icon-row .icon-selector__grid__icon-row__icon-column i.icon-selector-selected {
  color: #00b2a9 !important;
}
.icon-selector__grid .icon-selector__grid__icon-row .icon-selector__grid__icon-row__icon-column i.icon-selector-null-choice:hover {
  background-color: #00b2a917;
}
.icon-selector__grid .icon-selector__grid__paginator-row {
  border-top: #e6e8e7 1px solid;
}
.icon-selector__grid .icon-selector__grid__paginator-row .icon-selector__grid__paginator-row__double-left-handle {
  font-size: 1.2em;
}
.icon-selector__grid .icon-selector__grid__paginator-row .icon-selector__grid__paginator-row__left-handle {
  font-size: 1.2em;
}
.icon-selector__grid .icon-selector__grid__paginator-row .icon-selector__grid__paginator-row__center-handle {
  pointer-events: none;
}
.icon-selector__grid .icon-selector__grid__paginator-row .icon-selector__grid__paginator-row__right-handle {
  font-size: 1.2em;
}
.icon-selector__grid .icon-selector__grid__paginator-row .icon-selector__grid__paginator-row__double-right-handle {
  font-size: 1.2em;
}
