.theoretical-templates__wrapper .theoretical-templates__filters {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin-top: -8px;
  z-index: 10;
}
.theoretical-templates__wrapper .theoretical-templates__filters .custom-editor {
  margin-top: 8px;
  margin-right: 16px;
  min-width: 150px !important;
  max-width: 150px !important;
  min-height: 38px !important;
  height: 38px !important;
}
.theoretical-templates__wrapper .theoretical-templates__filters .custom-editor-search {
  margin-top: 8px;
  margin-right: 16px;
}
.theoretical-templates__wrapper .ui.table.custom-table thead tr:first-child > th:first-child {
  padding-left: 15px !important;
}
.theoretical-templates__wrapper .ui.table.custom-table tbody tr > td:first-child {
  padding-left: 15px !important;
}
