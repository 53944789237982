.admin-report__counter-box__placeholder {
  width: 170px;
  height: 110px;
  padding: 10px 16px;
  margin-top: 0 !important;
  margin-right: 16px;
}
.admin-report__counter-box__placeholder .counter-placeholder {
  height: 50px !important;
  width: 60% !important;
}
.admin-report__counter-box__placeholder .title-placeholder {
  height: 20px !important;
  margin-top: 8px !important;
}
.admin-report__counter-box.admin-report__counter-box {
  min-width: 170px;
  height: 110px;
  padding: 10px 12px 10px 16px;
  margin-top: 0 !important;
  margin-right: 16px !important;
  margin-left: 0 !important;
  text-align: left !important;
  display: flex;
  justify-content: center;
}
.admin-report__counter-box.admin-report__counter-box .admin-report__counter-box__counter {
  font-weight: bold !important;
  font-size: 40px !important;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left !important;
}
.admin-report__counter-box.admin-report__counter-box .admin-report__counter-box__title {
  width: calc(170px - 16px - 12px);
  margin-top: 8px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left !important;
}
