.third-row__pause-periods__container-inputs {
  padding-bottom: 0px !important;
  input {
    margin-bottom: 2px !important;
  }
}

.remove-icon {
  margin-left: 23px !important;
  color: #474b4f !important;
}
