.wizard__step {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;

  .wizard__label-title.flex-start {
    align-items: center !important;
    width: fit-content;
    margin-bottom: 32px;

    p {
      font-weight: bold;
    }

    .step__label-style {
      width: 38px;
      height: 38px;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding: 0px;
      margin-right: 24px;
      color: white;
      background-color: #00b2a9;
    }
  }

  .add-row-btn {
    height: 42px !important;
  }

  .inactive-step {
    p {
      color: #dedfdf;
    }

    .step__label-style {
      background-color: #dedfdf !important;
    }
  }
}
