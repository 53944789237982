.search-iws-template__btn {
  margin-bottom: 20px !important;
}

.iws-form-iws-template {
  .step2__select-iws-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-left: 0px;
    flex-direction: column;
    margin-bottom: 0px;

    .step2__select-iws-id-name {
      width: fit-content;
      display: flex;
      height: 26px;
      justify-content: flex-start;
      align-items: center;

      .step2__select-iws-list {
        display: inline-flex;
      }
    }

    .step2__select-iws-wrapper__content {
      margin-top: 16px;
      margin-left: 10.5em;
      font-size: 12px !important;

      h4 {
        margin-bottom: 16px;
        font-size: 12px !important;
        display: flex;
        align-items: center;
      }

      div {
        margin-left: 5px;
        margin-bottom: -25px;
      }

      .machine-model__deactivated {
        color: #b0b0b0;
      }

      ul {
        padding-left: 0px;

        .step2__select-iws-wrapper__content__element {
          .step2__select-iws-content__machines {
            margin-bottom: 14px;

            label {
              margin-right: 24px;
              margin-left: 0.5em;
              font-weight: 400;
              font-size: 12px !important;
            }

            label::before {
              border: 1px solid #474b4f;
              border-radius: 2px;
            }

            &:last-child {
              margin-bottom: 20px;
            }
          }

          .wizard__sort-icons-wrapper {
            margin-right: 12px;
            display: flex;
            align-items: center;
            margin-bottom: unset;

            i {
              font-size: 18px;
              cursor: pointer;
            }

            .iws-template-wizard__add-questions-btn {
              min-width: 100px;
              max-width: 100px;
              height: 38px;
              border-radius: 4px;
              padding: 0px !important;
              margin: 0px 120px 0px 0px;
              background-color: #f9f9f9 !important;
              border: 1px solid transparent !important;
              font-weight: 500;

              &:hover {
                background-color: #dedfdf !important;
                border: 1px solid #dedfdf !important;
                transition: ease-in 0.2s;
              }
            }
          }
          .tna-arrow-left-margin {
            margin-right: 3px !important;
          }
        }
      }
    }

    p {
      color: #00b2a9;
      margin: 0px;
      font-weight: 600;
    }

    p:first-of-type {
      margin: 0px 32.5px 0px 14.5px;
    }

    .step2__select-iws__btn {
      width: 26px;
      height: 26px;
      background-color: #eeeeee !important;
      padding: 0px;
    }
  }

  button.ui.button {
    background-color: #eeeeee !important;
    color: #474b4f !important;
  }
}

@media all and (max-width: 1285px) {
  .iws-form-iws-template {
    .step2__select-iws-wrapper {
      .step2__select-iws-wrapper__content {
        margin-left: 10em;
      }
    }
  }
}
