.events-form-requests-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 40px;
  margin-bottom: 50px;
  width: 90%;
}
.events-form-requests-list p {
  padding: 8px;
}
.events-form-requests-list a {
  padding: 8px;
}
.events-form-requests-list .border-bottom-gray {
  display: flex;
  align-items: flex-start;
}
.events-form-requests-list .flex-center {
  align-items: center;
}
