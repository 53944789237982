.form__students-tab__container {
  display: flex;
  flex-wrap: wrap;

  p {
    margin-right: 16px;
    margin-bottom: 0px;
    width: fit-content;
  }

  h4 {
    font-size: 14px;
  }

  .form__event-details-tab__element {
    display: flex;
    align-items: center !important;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 24px;
  }

  .wizard__step-students-wrapper {
    min-height: 100%;
    align-items: center;
    display: flex;
    margin: 0px !important;

    label {
      margin: 0px !important;
      width: 120px;
    }
  }

  .students-tab__add-user-button {
    .button {
      border: 1px solid #474b4f;
      color: #474b4f !important;
      box-shadow: none;
    }

    .icon {
      background-color: transparent !important;
    }
  }

  .students-tab__students-table {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 60px 60px;
    grid-template-rows: repeat(auto, 1fr);
    width: 100%;
    padding-left: 48px;
    padding-right: 30px;
    margin-bottom: 50px;

    .students-table__column-title {
      background-color: #eeeeee;
      width: 100%;
      padding: 8px;
      margin-right: 0px;
    }

    .border-bottom-gray {
      border-bottom: 1px solid #dedfdf;
      padding: 11.5px;
    }

    span {
      display: flex;
      align-items: center;
    }

    .text__transparent {
      color: transparent;
    }

    .icons {
      display: flex;
      align-items: center;
    }
  }
}
