.ui.modal.transition.visible.active.planit-generic-form__modal {
  width: 97% !important;
  display: flex !important;
  justify-content: flex-start !important;
  overflow: hidden;
  min-height: 96%;

  .form__prev-status-btn {
    background-color: #dedfdf !important;
    color: #474b4f;
    min-width: 150px;
    padding: 2px 4px 2px 24px !important;
    margin: 0px;
    font-weight: bold;

    .icon {
      color: #474b4f;
      font-size: 16px;
      background-color: #dedfdf !important;
      padding: 10px !important;
      width: fit-content;
    }
  }

  .transition.visible.active > div {
    display: flex !important;
  }

  .form__modal__left-container {
    width: 75%;
    display: flex;
    padding: 24px 0px 0px 0px;

    .form__comment-textarea {
      width: 95% !important;
      margin-bottom: 50px;
    }

    .form__comment-textarea-request-form {
      width: 80% !important;
      margin-right: 30px;
    }

    .form__modal__close-menu-left-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      .form__header-top-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 20px;

        .form__header-title-status {
          display: flex;
          padding-right: 20px;
          padding-bottom: 8.5px;
          width: 90%;
          justify-content: space-between;
          margin-top: 10px;

          .flex-start-center {
            width: 80%;
          }

          p {
            font-size: 16px;
            margin-bottom: 0px;
            border-bottom: 1px solid #dedfdf;
            width: 80%;
            font-weight: 600;
          }

          .form__header__title-input {
            min-width: 70%;
            font-size: 16px;
            font-weight: 600;

            input {
              padding-left: 10px;
              text-overflow: ellipsis !important;
            }
          }

          .form__header__title-input__friendlyId {
            font-size: 16px;
            font-weight: 600;

            input {
              padding-right: 0px;
              width: 100px;
            }
          }

          input {
            border-radius: 0px;
            border-bottom: 1px solid #dedfdf;
            border-top: none !important;
            border-right: none !important;
            border-left: none !important;
          }

          .form__header__status-tag {
            min-width: 70px;
            width: fit-content;
            height: 22px;
            background-color: #474b4f;
            padding: 4px;
            color: white;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .form__modal__close-btn {
        background-color: transparent;
        color: #b0b0b0;
        font-size: 20px;
        width: 180px;
        text-align: start;
        height: fit-content !important;
        padding: 0px;
        margin-left: 24px;
        margin-top: 0px;
      }

      .tab-pane-with-header {
        height: 100%;
        margin-top: 24px;

        .tab-pane-with-header__lateral-menu {
          background-color: #f9f9f9;
          height: 100%;
        }

        .tab-pane-with-header__content {
          padding: 12px 0px 0px 32px;

          .form__modal__header-content {
            .ui.error.message {
              width: 95%;
              border: 1px solid #e03c31;
              background-color: white;
              color: #222629;
              box-shadow: none;
              font-size: 11px;
              margin-bottom: 32px;

              .header {
                display: none;
              }

              .list {
                margin: 0px;
              }

              li.content::before {
                display: none;
              }

              .icon {
                font-size: 16px;
                color: #e03c31;
              }
            }
          }

          .form__header__data {
            display: flex;
            flex-direction: column;
            width: 100% !important;

            .form__header__row-icon {
              margin-right: 24px;
              font-size: 18px;
              margin-bottom: 5px;
              color: #474b4f;
            }

            .form__header__text-disable {
              color: #b0b0b0;
            }

            p {
              margin-right: 16px;
              margin-bottom: 0px;
            }

            .form__header__dropdown-style {
              min-width: 138px;
              margin-right: 16px;
            }

            //first row styles
            .form__header__first-row__event-type {
              margin-bottom: 24px;

              .first-row__event-type__circle {
                width: 18px;
                height: 18px;
                margin-right: 24px;
              }
            }

            .field.planit-eventform-dates-period {
              width: 100px;

              &.from-date .ui.icon.input input {
                text-align: center;
                border-radius: 4px 0 0 4px;
                width: 100px;
                padding-right: 10px !important;
              }

              &.to-date .ui.icon.input input {
                text-align: center;
                border-radius: 0 4px 4px 0;
                width: 100px;
                border-left: none;
                padding-right: 10px !important;
              }
            }

            //second row styles
            .form__header__row {
              display: flex;
              align-items: center !important;
              justify-content: flex-start;
              width: 100%;
              margin-bottom: 24px;

              .pause-period__btn {
                margin-right: 16px;

                // margin-left: 24px;
                .ui.button {
                  background-color: white;
                  border-radius: 4px;
                  border: 1px solid #474b4f;
                  height: 32px;
                  color: #474b4f;
                  line-height: 0.8em;
                  white-space: nowrap;

                  &:hover {
                    color: black;
                  }
                }
              }

              .pause-period__items {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: -16px;

                .pause-period__dates-row {
                  display: flex;
                  margin-bottom: 16px;
                  margin-right: 16px;
                  align-items: center;

                  .icon.remove-icon {
                    margin-left: 10px !important;
                  }
                }
              }

              .flex-wrap {
                justify-content: flex-start !important;
                align-items: center;

                .form__header__second-row__requested-date-slot {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;
                  margin-right: 50px;
                  height: 32px;
                  align-self: center;
                }

                .form__header__second-row__event-dates {
                  margin-bottom: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start !important;
                  height: 32px;
                }
              }

              .form__header__third-row__durations__element {
                margin-bottom: 5px;
                display: flex;
                align-items: center;

                .form__duration-field-numeric {
                  width: 70px;
                  height: 32px;
                  margin-right: 50px;
                }

                .form__duration-field-numeric__wd {
                  width: 44px;

                  input {
                    padding: 4px 4px 4px 8px;
                  }

                  .ui.basic.label.label {
                    border-left: none;
                    padding: 9px 9px 9px 2px;
                    color: #474b4f;
                    font-weight: 500;
                  }
                }

                .form__duration-field-numeric__wd-number {
                  font-weight: 500;
                  width: 65px;

                  input {
                    padding-right: 4px;
                  }

                  .ui.basic.label {
                    border-left: none;
                    color: #474b4f;
                    padding: 9px 9px 9px 1px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal__action-warning {
      margin: 20px 0px 20px 0px !important;
    }
  }

  .form__modal__right-container__chat {
    min-width: 25%;
    min-height: 100% !important;
    max-height: 100% !important;
  }

  .ui.error.message {
    width: 95%;
    border: 1px solid #e03c31;
    background-color: white;
    color: #222629 !important;
    box-shadow: none;
    font-size: 11px;
    margin-bottom: 32px;

    .list {
      margin: 0px;
    }

    li.content::before {
      display: none;
    }

    .icon {
      font-size: 16px !important;
      color: #e03c31;
    }

    .header {
      color: #222629 !important;
    }
  }

  .visible.menu.transition::-webkit-scrollbar {
    width: 4px;
  }

  .visible.menu.transition::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }

  .visible.menu.transition::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.356);
  }

  .change-status-popup {
    transform: translateY(35vh) !important;
  }
}

//MEDIA QUERIES

@media all and (min-width: 1700px) {
  .tab-pane-with-header__content {
    width: 90% !important;
  }
}

@media all and (max-width: 1150px) {
  .tab-pane-with-header__content {
    width: 78% !important;
  }
}

.event-duration-warnings-container.event-duration-warnings-container.event-duration-warnings-container.event-duration-warnings-container.event-duration-warnings-container {
  max-width: 47vw !important;
  margin: 10px 0 24px 42px !important;

  .label.label.label.label {
    margin: 0 !important;

    p {
      text-align: left !important;
    }
  }
}

.recalc-command {
  color: #00b2a9;
  cursor: pointer;
  font-size: 0.8em;
}

#recalc-start-date {
  position: absolute;
  top: 36px;
  right: 170px;
}

#recalc-end-date {
  position: absolute;
  top: 36px;
  right: 60px;
}

#recalc-event-duration {
  position: absolute;
  top: 38px;
  right: 40px;
}

#recalc-calculated-duration {
  position: absolute;
  top: 36px;
  right: 43px;
}

.recalc-container {
  position: relative;
}

.form__header__third-row__durations.fix-margin.fix-margin.fix-margin.fix-margin.fix-margin.fix-margin.fix-margin {
  margin-bottom: 0 !important;
}

#events__step3__event-duration__input {
  min-width: 110px;
}
