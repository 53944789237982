.rowheight-slider {
  background: white;
  width: 90px;
  height: 22px;
  padding: 16px 8px;
  border: 1px solid #949494;
  border-radius: 4px;
  outline: none;
  align-self: start;

  input[type='range']:focus::-webkit-slider-runnable-track {
    background-color: black !important;
  }

  input[type='range'] {
    outline: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-thumb {
    // margin-top: -9px;
    width: 15px;
    height: 15px;
    border: 1px solid black;
    background: white;
  }

  input[type='range']::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 1px solid black;
    background: white;
  }

  input[type='range']::-ms-thumb {
    width: 15px;
    height: 15px;
    border: 1px solid black;
    background: white;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 4px;
    background: black;
    border-radius: 8px;
    width: 65px;
  }

  input[type='range']::-moz-range-track {
    height: 4px;
    background: black;
    border-radius: 8px;
    width: 65px;
  }

  input[type='range']::-ms-track {
    height: 4px;
    background: black;
    border-radius: 8px;
    width: 65px;
  }
}
