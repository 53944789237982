.admin-report__counters-container {
  padding: 0px 16px;
  margin-top: 70px;
  background-color: #f9f9f9;
  overflow-x: auto;

  .admin-report__counters-container__row {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 110px;
  }
}

.admin-report__counters__graph-container {
  margin-top: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  .admin-report__counters__graph-title.admin-report__counters__graph-title {
    color: #00b2a9 !important;
    font-weight: bold;
    letter-spacing: 0px;
    opacity: 1;
    margin-bottom: 0 !important;
    font-size: 16px !important;
    text-align: center;
  }
}

.admin-reports__audit-logs-container {
  margin-top: 60px;
  padding: 0px 15px !important;

  .admin-reports__audit-logs-container__title {
    font-size: 14px !important;
    margin-bottom: 24px !important;
  }

  .filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 97%;
    margin-top: -8px;

    .filter {
      margin-top: 8px;
      margin-right: 16px;
      min-height: 38px;
    }

    .filter-dates {
      .input {
        min-width: max-content;
        min-height: 38px;
      }
    }

    .planit-user-dropdown {
      div.text {
        font-weight: normal !important;
      }
    }
  }
}
