@import '../../../../../assets/less/variables.less';

.assigment-employees-profiles__filters {
  justify-content: start;
  display: inline-block;
  flex-wrap: wrap;

  .custom-editor {
    margin-right: 12px;
    min-width: 150px !important;
    max-width: 150px !important;
    margin-bottom: 5px;
  }

  .custom-editor-search {
    margin-right: 12px;
    margin-bottom: 5px;
  }
}

.assigment-profile__label__inactive {
  color: #b0b0b0;
}

#assigment-employees-profiles-edit__title + .planit-line-separator {
  margin-bottom: 0 !important;
}

.assigment-employees-profiles-edit__wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;

  p {
    font-weight: bold;
    font-size: 14px;
  }

  .edit__select-profiles__wrapper {
    margin: 0 5% 100px 15%;
    min-width: calc(120px * 6);
    max-width: calc(160px * 6);

    label {
      font-weight: bold;
    }

    label::before {
      border: 1px solid #474b4f;
    }

    label::after {
      border: 1px solid #474b4f;
      border-radius: 0.25rem;
    }

    .edit__select-profiles__wrapper__row {
      margin-bottom: 10px !important;
      height: 32px;
      display: flex;
      align-items: center;

      .ui.label {
        line-height: unset;
      }

      .profile-label {
        background-color: #f9f9f9;
        user-select: none;
        border-radius: 0;
        margin-left: 8px;

        &.expandable {
          cursor: pointer;

          &:hover {
            background-color: #ebebeb;
          }
        }

        &.inactive {
          color: #b0b0b0 !important;
        }
      }
    }

    .edit__select-profiles__display-none {
      display: none;
    }

    .edit__select-profiles__edit-wrapper {
      margin-left: 20px;
      display: flex !important;
      margin-bottom: 20px;

      .fields {
        display: flex !important;
        max-width: 100%;
        flex-wrap: wrap;

        .field {
          font-size: 12px;
        }
      }

      .edit__select-profiles__label-input {
        max-width: 160px;
        min-width: 100px;
      }

      .search {
        margin-top: 8px !important;
        max-width: 145px !important;
      }

      .visible.menu.transition {
        width: fit-content;

        .item {
          white-space: nowrap;
        }
      }

      .dropdown:last-child {
        margin-top: 8px;
        min-width: 145px !important;
        max-width: 145px !important;
      }

      .add-row-btn {
        width: 110px;
        height: 42px !important;
        align-self: flex-start;
        margin-top: 28px;
        margin-left: 24px;
      }
    }

    .assigned-profile-row-machine__info {
      display: flex;
      // margin-left: 20px;
      justify-content: flex-start;
      height: fit-content;

      &:last-child {
        margin-bottom: 24px;

        .assigned-profile-row-machine__items {
          border-bottom: 1px solid #b0b0b0;
        }
      }

      .assigned-profile-row-machine__items {
        display: flex;
        border-top: 1px solid #b0b0b0;
        padding: 14px 0;

        .machine-cell {
          padding-left: 16px;
          width: 170px;
          margin: 0px;
          min-width: 160px;
          max-width: 160px;
          font-size: 12px;
          font-weight: normal;
          align-self: center;

          &:last-child {
            min-width: 145px;
            max-width: 145px;
          }
        }
      }

      i {
        cursor: pointer;
        margin-left: 32px;
      }
    }

    .profile_assignment__btns {
      text-align: right;
      margin-top: 10%;

      .button {
        min-width: 100px;
      }

      .secondary {
        margin-right: 8px;
      }
    }
  }

  .new-tna-skill__btns {
    display: flex !important;
    justify-content: flex-end !important;

    .button {
      margin-right: 14px;
    }
  }
}
