#new-tna-theoretical-test__template-selection {
  margin-left: 62px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  #new-tna-theoretical-test__template-selection__column1 {
    button.step2__select-tna__btn {
      height: 26px;
      width: 26px;
      background-color: #eeeeee;
      padding: 0 !important;

      i {
        color: #b5b5b5;
      }
    }

    button.search-tna-template__btn {
      height: 38px;
      background-color: #eeeeee;
      color: #474b4f;
    }

    padding-right: 14.5px;
  }

  #new-tna-theoretical-test__template-selection__column2 {
    font-size: 12px;
    color: #474b4f;
    opacity: 1;
    padding-right: 18.5px;
  }

  #new-tna-theoretical-test__template-selection__column3 {
    font-size: 12px;
    color: #474b4f;
    opacity: 1;
  }
}
