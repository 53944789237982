.invidual-tab-pane__list {
  .ui.table.custom-table thead tr:first-child > th:first-child {
    padding-left: 15px !important;
  }

  .ui.table.custom-table tbody tr > td:first-child {
    padding-left: 15px !important;
  }
  .custom-table-main-actions.menu {
    display: none;
  }
}
