@import '../../../../../src/assets/less/variables.less';

.instructor-expertise-wrapper {
  padding: 0px 15px !important;
}

.instructor-expertise-table-wrapper {
  padding: 0px !important;
  .full-width.table__flex-container {
    height: 69vh !important;
  }
}

.instructors-expertise-list-filter-second-row {
  form {
    margin-bottom: 23px !important;
  }
}

.expertise-request-tag__Pending {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 0;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  line-height: 11;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  background-color: white;
  color: #75a2d8;
  border: 1px #75a2d8 solid;
}

.expertise-request-tag__Approved {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 0;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  line-height: 11;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  background-color: white;
  color: #4bc982;
  border: 1px #4bc982 solid;
}

.expertise-request-tag__Rejected {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 0;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 22px;
  line-height: 11;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  background-color: white;
  color: #e03c31;
  border: 1px #e03c31 solid;
}

.expertise-request-wrapper.expertise-request-wrapper.expertise-request-wrapper.expertise-request-wrapper {
  height: 95%;
  border: none;
  background-color: transparent;
  border-style: none;
  box-shadow: none;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.fix-icon {
  i {
    top: 0 !important;
    right: 0 !important;
  }
}

.instructors-expertise-table-view-row {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.new-instructor-height-plc {
  max-height: 37px !important;
}

.filter-button-group.green {
  button.disabled.disabled.disabled {
    opacity: 1 !important;
  }
}

.event-types-list-error-row {
  &.fix-margin-top {
    margin-top: -23px;
  }

  margin-bottom: 23px;
}

.request-form__reject-button.request-form__reject-button.request-form__reject-button {
  background-color: white;
  color: #e03c31;
  border: 1px solid #e03c31;
}

.view-expertise-modal-tag {
  margin-left: 0 !important;
}

.instructors-expertise-list-grid {
  margin-left: 0 !important;
  margin-right: 0 !important;

  .instructors-expertise-list-filter-first-row {
    padding: 0 !important;

    .form {
      width: 100%;

      .fields {
        -webkit-box-orient: horizontal !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
        margin: 0 !important;

        input,
        div.ui.dropdown {
          border: 1px solid @inverted-color;
        }

        .field {
          &:first-child {
            padding-right: 0 !important;
          }

          div.default.text,
          input::-webkit-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input::-ms-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input::placeholder {
            color: #b0b0b0 !important;
          }

          div.input > input {
            width: 175px;
          }

          div.ui.dropdown {
            min-width: 150px;
          }
        }

        div.ui.dropdown {
          max-height: 2.96428571em;
        }
      }
    }
  }

  .instructors-expertise-list__filter-dropdown {
    margin-bottom: 10px;
    display: flex !important;
    align-items: center !important;
    align-content: center !important;
    min-width: 150px !important;
    max-width: 150px !important;
    height: 38px !important;
    min-height: 38px !important;
    padding: 5px 5px 5px 6px !important;

    &:not(button) {
      margin-right: 10px;
    }

    &.button {
      margin: 0 !important;

      .icon {
        font-size: 20px;
      }
    }

    .text {
      align-content: center !important;
    }
  }

  .instructors-expertise-list-filter-second-row {
    padding: 0 !important;

    .form {
      width: 100%;

      .fields {
        -webkit-box-orient: horizontal !important;
        margin: 0 !important;

        label {
          line-height: 36px;
        }

        input,
        div.ui.dropdown {
          border: 1px solid @inverted-color;
        }

        .field {
          div.default.text,
          input::-webkit-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input:-ms-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input::-ms-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input::placeholder {
            color: #b0b0b0 !important;
          }

          div.input > input {
            width: 175px;
          }

          div.ui.dropdown {
            min-width: 150px;
          }
        }

        div.ui.dropdown {
          max-height: 2.96428571em;
        }
      }
    }
  }
}
