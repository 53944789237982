@import './variables.less';

.new-event-type__color-picker-wrap {
  max-width: 60px;
}

.ui.mini.vertical.menu.new-event-type__custom-menu {
  width: 100%;
  margin: 0 !important;
}

#left-column
  > div:nth-child(1)
  > div.right.aligned.middle.aligned.five.wide.computer.four.wide.widescreen.column.field-title.required.field {
  max-width: 88px !important;
  line-height: 18px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ui.buttons.filter-button-group {
  width: 150px;

  &.three-btns {
    width: 245px;
  }

  &.fix-height {
    .button {
      height: 2.9em;
    }
  }

  &.green {
    .button.active {
      color: white !important;
      font-weight: 700 !important;
      letter-spacing: 0.24px;
      background-color: @hover-green !important;
      border: 1px solid @hover-green !important;

      &.gray-border {
        border: 1px solid @hover-green !important;
      }
    }
  }

  .button:not(.active) {
    color: #b0b0b0 !important;
    font-weight: 700 !important;
    letter-spacing: 0.24px;
    background-color: white !important;

    &.gray-border {
      color: @inverted-color !important;
    }
  }

  .button.active {
    background-color: @inverted-color !important;
    color: white !important;
    font-weight: 700 !important;
    letter-spacing: 0.24px;
    border: 1px solid @inverted-color;

    &.gray-border {
      border: 1px solid @inverted-color !important;
    }
  }

  .button:first-child {
    border-right: 1px solid @inverted-color;
    border-top: 1px solid @inverted-color;
    border-bottom: 1px solid @inverted-color;
    border-left: 1px solid @inverted-color;
    width: 52.01px !important;

    &.gray-border {
      border-right: 1px solid #dedfdf;
      border-top: 1px solid #dedfdf;
      border-bottom: 1px solid #dedfdf;
      border-left: 1px solid #dedfdf;
    }
  }

  .button.middle {
    border-top: 1px solid @inverted-color;
    border-right: 1px solid @inverted-color;
    border-bottom: 1px solid @inverted-color;
    width: 75px !important;
    padding: 11px !important;

    &.gray-border {
      border-top: 1px solid #dedfdf;
      border-right: 1px solid #dedfdf;
      border-bottom: 1px solid #dedfdf;
    }
  }

  .button:last-child {
    border-right: 1px solid @inverted-color;
    border-top: 1px solid @inverted-color;
    border-bottom: 1px solid @inverted-color;
    border-left: 0 solid;
    width: 52.01px !important;

    &.gray-border {
      border-right: 1px solid #dedfdf;
      border-top: 1px solid #dedfdf;
      border-bottom: 1px solid #dedfdf;
    }
  }
}

.event-wrapper {
  padding: 0px 15px !important;
}

.event-types-list-wrapper {
  padding: 0px 0px 0px 15px !important;
}

.event-types-options-list-wrapper {
  padding: 0px 15px 0px 0px !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.event-types-list-grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0px !important;

  .event-types-list-filter-row {
    padding: 0 !important;

    .form {
      width: 100%;

      .fields {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;

        .field {
          div.default.text,
          input::-webkit-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input:-ms-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input::-ms-input-placeholder {
            color: #b0b0b0 !important;
          }

          div.default.text,
          input::placeholder {
            color: #b0b0b0 !important;
          }

          div.input > input {
            width: 175px;
          }

          div.ui.dropdown {
            min-width: 150px;
          }
        }

        div.ui.dropdown {
          max-height: 2.96428571em;
        }

        #event-type-search-input {
          div.ui.icon.input {
            height: 2.964em !important;
          }
        }

        .event-type-filter-icon-btn {
          height: 38px !important;
        }

        #add-event-type {
          button {
            height: 38px !important;
            padding: 7.5% 18px;

            i {
              margin: 0;
              margin-left: 5px;
              color: white;
              opacity: 1;
              font-size: 1.2rem;
            }
          }

          height: 48px !important;
        }

        .new-event-btn-form {
          button {
            min-width: 125px !important;
            max-width: 125px !important;
          }
        }

        .event-type-hide-filters-btn {
          padding-top: 0.7rem;
          padding-bottom: 0.65rem;
          padding-left: 0.65rem;
          padding-right: 0.65rem;
          line-height: 1;

          > i.icons {
            i.corner.hide-filter {
              text-shadow: unset !important;
              color: white !important;
              opacity: 1;
              background-color: red;
              border-radius: 1em;
              font-size: 0.7rem;
              height: 12px;
              width: 12px;
              line-height: 11px;
              left: 44%;
              bottom: 10%;
            }

            i:not(.corner) {
              font-size: 1.6rem;
              color: white;
              opacity: 1;
              margin: 0 !important;
            }
          }
        }

        .event-type-filter-by-update-flag-btn {
          padding-top: 0.7rem;
          padding-bottom: 0.65rem;
          padding-left: 0.65rem;
          padding-right: 0.65rem;
          line-height: 1;

          > i.icons {
            i.corner.hide-filter {
              text-shadow: unset !important;
              color: white !important;
              opacity: 1;
              background-color: red;
              border-radius: 1em;
              font-size: 0.7rem;
              height: 12px;
              width: 12px;
              line-height: 11px;
              left: 44%;
              bottom: 10%;
            }

            i:not(.corner) {
              font-size: 1.6rem;
              color: white;
              opacity: 1;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  .event-types-list-error-row {
    .ui.error.message {
      width: 100%;
    }
  }

  .events-home-page__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(5) {
    min-width: 130px !important;
  }

  .events-home-page__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(9) {
    min-width: 130px !important;
  }

  .events-home-page__table > div.full-width.table__flex-container > table > thead > tr > th:nth-child(11) {
    min-width: 160px !important;
  }

  .events-home-page__table {
    .request-event__filter-buttons {
      margin-right: 6px;
    }

    .ui.secondary.stackable.right.floated.custom-table-main-actions.menu {
      .fitted.item {
        align-self: flex-start !important;
        margin: 6px 0px 0px 16px;
      }
    }
  }

  .event-types-list-items-row {
    min-height: calc(100vh - 16em);
    max-height: calc(100vh - 16em);
    // overflow-y: auto;
    padding: 0 !important;

    .event-types-list-item {
      &.inactive-event-type__item {
        opacity: 0.4;
      }

      min-height: 145px !important;
      max-height: fit-content !important;
      // height: 145px !important;
      background-color: #eeeeee;
      margin: 1em 0;
      border-radius: 8px;
      padding: 4px 4px 4px 20px;
      display: block !important;

      .row-0 {
        h3 {
          margin: 0 0 0 10px;
          font-size: 16px;
          text-transform: none !important;
        }

        padding-bottom: 10 !important;
        height: 30% !important;

        .label {
          max-height: 2em !important;
        }
      }

      @media only screen and (min-width: 1600px) {
        .column-1.column-1.column-1 {
          width: 18% !important;
        }

        .central-column.central-column.central-column {
          width: 37% !important;

          .column-2 {
            width: 25% !important;
          }

          .column-3 {
            width: 22% !important;
          }

          .column-4 {
            width: 16% !important;
          }

          .column-5 {
            width: 25% !important;
          }
        }

        .column-6.column-6.column-6 {
          width: 33% !important;
        }
      }

      @media only screen and (min-width: 1300px) and (max-width: 1600px) {
        .column-1.column-1.column-1 {
          width: 18% !important;
        }

        .central-column.central-column.central-column {
          width: 40% !important;

          .column-2 {
            width: 25% !important;
          }

          .column-3 {
            width: 27% !important;
          }

          .column-4 {
            width: 25% !important;
          }

          .column-5 {
            width: 23% !important;
          }
        }

        .column-6.column-6.column-6 {
          width: 30% !important;
        }
      }

      @media only screen and (min-width: 992px) and (max-width: 1300px) {
        .column-1.column-1.column-1 {
          width: 18% !important;
        }

        .central-column.central-column.central-column {
          width: 40% !important;

          .column-2 {
            width: 25% !important;
          }

          .column-3 {
            width: 25% !important;
          }

          .column-4 {
            width: 25% !important;
          }

          .column-5 {
            width: 25% !important;
          }
        }

        .column-6.column-6.column-6 {
          width: 30% !important;
        }
      }

      .row-1 {
        height: 70% !important;
        padding-top: 0 !important;
      }

      .column-1 {
        width: 20% !important;
        padding-left: 25px !important;
      }

      .central-column {
        width: 35% !important;
        padding: 0 10px !important;

        .column-2 {
          width: 26% !important;
          padding: 12px 6px;

          h5 {
            margin-bottom: 0.4em;
            font-size: 14px;
          }
        }

        .column-3 {
          width: 23% !important;
          padding: 12px 6px;

          h5 {
            margin-bottom: 0.4em;
            font-size: 14px;
          }
        }

        .column-4 {
          width: 17% !important;
          padding: 12px 6px;

          h5 {
            margin-bottom: 0.4em;
            font-size: 14px;
          }
        }

        .column-5 {
          width: 30% !important;
          padding: 12px 6px;

          h5 {
            margin-bottom: 0.4em;
            font-size: 14px;
          }
        }
      }

      .column-6 {
        width: 33% !important;

        h5 {
          // margin-top: 2.5em;
          margin-bottom: 0.4em;
          font-size: 14px;
        }
      }

      .column-7 {
        width: 10% !important;

        .ui.toggle.checkbox {
          min-height: 1.3rem !important;

          input {
            height: 1.3rem !important;
          }

          .box,
          label {
            min-height: 1.3rem !important;
            padding-left: 3rem !important;
            width: 1rem;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-right: 0 !important;
          }

          .box:after,
          label:after {
            width: 1.3rem !important;
            height: 1.3rem !important;
          }

          .box:before,
          label:before {
            width: 2rem !important;
            height: 1.3rem !important;
            border-radius: 1.3rem !important;
          }

          input:checked ~ .box:after,
          input:checked ~ label:after {
            left: 0.9rem !important;
          }
        }

        i.ellipsis {
          margin-left: 8%;
        }
      }

      .event-type-label {
        margin: 2px;
        line-height: 18px;
        padding: 0.2em 0.8em !important;

        &.label-1 {
          background-color: white;
          border: 1px solid #00b2a9;
          color: #00b2a9;
        }

        &.label-2 {
          color: white;
          border: 1px solid #00b2a9;
          background-color: #00b2a9;
        }

        &.label-3 {
          color: #222629;
          border: 1px solid #222629;
          background-color: transparent;
        }

        &.label-4 {
          color: #474b4f;
          border: 1px solid #dedfdf;
          background-color: #dedfdf;
        }
      }
    }
  }
}

.event-type-pop-up {
  padding: 5px !important;

  .menu.event-type-pop-menu {
    .item {
      font-size: 14px;
      margin: 0 !important;
      padding: 7px !important;
    }
  }
}

#new-event-modal {
  background: linear-gradient(to left, #ffffff 56.25%, #00b2a9 43.75%);
  width: 80vw !important;
  height: 90vh;

  .error.message {
    margin: 10px 10px -18px 10px;
    z-index: 99;
  }

  .image.content {
    min-height: 70vh;
    padding: 0 !important;
    border-radius: 8px;

    .container {
      margin: 0 !important;
      width: 100% !important;

      #new-event-form {
        height: 90vh;

        #new-event-form-grid {
          margin: 0;
          height: 100%;

          .fields {
            margin-bottom: 0;
          }

          #left-column {
            background-color: @hover-green;
            border-radius: 8px 0 0 8px;
            padding: 20px 16px;

            .icon-selector-wrapper {
              height: 36px !important;
            }

            .fit-content-column {
              height: fit-content;
            }

            .editing-title {
              background-color: @hover-green !important;
              color: white;
              font-weight: 700;
              font-size: 14px;
              border: 1px white solid;
              border-radius: 4px;
              padding: 9px;
            }

            .field-title {
              font-size: 14px;
              color: white;
              letter-spacing: 0.24px;
              line-height: 2.5em;
            }

            .required.field::after {
              margin: -0.2em 0em 0em 0.2em;
              content: ' *';
              color: #db2828;
            }

            .group-title {
              font-size: 16px;
              color: white;
              text-transform: uppercase;
              font-weight: bold;
              letter-spacing: 0.24px;
              padding-left: 13px;
            }

            .field {
              margin: 0 !important;

              div.dropdown {
                min-width: 50px;
                max-width: 50px;
              }

              .dropdown.full-width {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
              }
            }

            .row.no-padding {
              padding: 0;
              margin-bottom: 1em;

              .required.field::after {
                display: contents;
              }
            }

            .row.no-padding.first {
              padding-top: 1rem;
            }

            .row > div.dropdown {
              min-width: 100% !important;
              max-width: 100% !important;
            }

            .field-group {
              .row {
                margin-bottom: 1em;
              }
            }

            .field-group-grid {
              &:not(:last-child):not(:first-child) {
                margin: 15px 0;
              }

              &:last-child {
                margin-top: 15px;
                margin-left: 0;
                margin-right: 0;
              }

              &:first-child {
                margin-bottom: 15px;
                margin-left: 0;
                margin-right: 0;
              }
            }

            .inverted-chckbx {
              span {
                position: relative;
                bottom: 4px;
              }

              .ui.checkbox {
                margin-right: 12px;
              }
            }
          }

          #right-column {
            padding: 20px 16px;
            background-color: white;
            border-radius: 0 8px 8px 0;

            .green-group-title {
              color: #00b2a9;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.24px;
              margin-bottom: 0.5em;
            }

            .field-group-grid {
              &.error.error.error.error.error.error {
                * {
                  color: #9f3a38 !important;
                }

                .status-label {
                  border: 1px solid #9f3a38 !important;
                }

                i.add-status-role.add-status-role.add-status-role {
                  color: #9f3a38 !important;
                }

                i.add-config-role.add-config-role.add-config-role {
                  background-color: #9f3a38 !important;
                  color: whitesmoke !important;
                  border: 1px solid #9f3a38;
                }
              }

              &.inactive:not(.error) {
                * {
                  color: #b0b0b0 !important;
                }

                .status-label {
                  border: 1px solid #b0b0b0 !important;
                }

                i.add-status-role.add-status-role.add-status-role {
                  color: #b0b0b0 !important;
                }

                i.add-config-role.add-config-role.add-config-role {
                  background-color: grey !important;
                  color: #b0b0b0 !important;
                  border: 1px solid grey;
                }
              }

              &.extra-margin-bottom {
                margin-bottom: 30px;
              }

              border-bottom: 1px solid #eeeeee;
              margin-left: 0;
              margin-right: 0;
              padding: 0.5em;

              .status-row-1 {
                .status-row-1-p {
                  span.status-name {
                    font-size: 14px;
                    color: #222629;
                    font-weight: bold;
                    letter-spacing: 0.24px;
                    margin-right: 1em;
                    text-transform: uppercase;
                  }

                  i.add-status-role {
                    margin-left: 1em;
                    font-size: 19px !important;
                    color: #222629 !important;
                  }

                  .status-label {
                    border: 1px solid #222629;
                    background-color: transparent;
                    color: #222629;
                    letter-spacing: 0.24px;
                    font-weight: 700;
                    margin: 1px !important;

                    i {
                      margin: 0 !important;
                      color: #222629;
                    }
                  }
                }

                .ui.toggle.checkbox {
                  min-height: 1.3rem !important;

                  input {
                    height: 1.3rem !important;
                  }

                  .box,
                  label {
                    min-height: 1.3rem !important;
                    padding-left: 3rem !important;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    padding-right: 0 !important;
                    width: 1rem;
                  }

                  .box:after,
                  label:after {
                    width: 1.3rem !important;
                    height: 1.3rem !important;
                  }

                  .box:before,
                  label:before {
                    width: 2rem !important;
                    height: 1.3rem !important;
                    border-radius: 1.3rem !important;
                  }

                  input:checked ~ .box:after,
                  input:checked ~ label:after {
                    left: 0.9rem !important;
                  }
                }
              }

              .status-row-2 {
                .status-row-2-p {
                  span.status-row-2-ptitle {
                    font-size: 12px;
                    color: #222629;
                    font-weight: 700;
                    letter-spacing: 0.21px;
                  }

                  i.add-config-role {
                    margin-left: 1em;
                    font-size: 16px !important;
                    background-color: #474b4f !important;
                    border-radius: 4px !important;
                    border: 1px solid #474b4f;

                    &.active {
                      color: #00b2a9 !important;
                      border: 1px solid #00b2a9;
                      background-color: white !important;
                    }
                  }
                }
              }
            }

            .event-types-modal-actions {
              position: absolute;
              bottom: 32px;
              right: 16px;
            }
          }
        }

        .main-tab .ui.pointing.secondary.menu {
          border: 0;
        }

        .main-tab .ui.pointing.secondary.menu .item {
          color: white;
          text-transform: uppercase;
          font-size: 14px;
        }

        .main-tab .ui.pointing.secondary.menu .active.item,
        .main-tab .ui.pointing.secondary.menu .item:hover {
          border-bottom-color: @jti-yellow;
        }
      }
    }
  }
}

.field-group-description-popup {
  width: 175px;
}

.status-actors-popup {
  padding: 4px !important;

  .menu {
    a.item.item.item {
      margin: 0 !important;
      z-index: 4 !important;
      border-radius: 0 !important;
      font-size: 14px !important;
      color: #222629 !important;
      font-weight: 700;
      letter-spacing: 0.24px;

      &:hover {
        background-color: #474b4f !important;
        color: white !important;
      }
    }
  }
}

.status-config-popup {
  padding: 12px !important;
  width: max-content !important;
  min-width: 190px !important;
  max-width: 25em !important;

  .checkbox {
    label {
      font-size: 14px !important;
      color: #222629 !important;
      font-weight: 700;
      letter-spacing: 0.24px;
    }
  }

  i.popup-icon {
    color: #b0b0b0;
    font-size: 14px;
    cursor: pointer;

    &.active {
      color: #00b2a9;
    }
  }

  .content:not(.floated) {
    margin-right: 5em !important;
  }
}

.event-workflow__item__icon {
  padding-bottom: -20px !important;
  position: absolute;
  left: 10px;
}

#event-type-request-creators {
  a.ui.label[value='FactoryLead'] {
    pointer-events: none !important;
    height: 22px !important;

    i {
      visibility: hidden;
      width: 0 !important;
    }

    i:before {
      content: '';
    }
  }
}

//MEDIA QUERIES

@media all and (max-width: 1700px) {
  .event-types-list-grid {
    padding: 0px !important;
    .event-types-list-filter-row {
      .form {
        width: 100%;

        .fields {
          .field {
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  #new-event-modal#new-event-modal#new-event-modal {
    width: 95vw !important;
  }
}
