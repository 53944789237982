.ui.celled.grid.students-list-grid {
  min-width: fit-content;
  margin-top: 0px;
  margin-left: 5px;
  box-shadow: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  div.row:first-child {
    border-radius: 4px 4px 0px 0px !important;
    border-top: 1px solid #dedfdf !important;
  }

  div.row:last-child {
    border-bottom: 1px solid #dedfdf !important;
    border-bottom-right-radius: 4px;
  }

  div.row {
    box-shadow: none;
    border-bottom: 0.5px solid #dedfdf !important;
    border-top: 0.5px solid #dedfdf !important;
    border-left: 1px solid #dedfdf !important;
    border-right: 1px solid #dedfdf !important;

    > .column {
      padding: 6px;
      box-shadow: none;
    }

    > div.center.aligned.one.wide.column {
      width: 23px !important;
    }
  }
}
