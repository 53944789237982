.header__status-not-done {
  justify-content: space-between;
  border-bottom: 4px solid #00b2a9;
}

.ssa-edit-form__all-wrapper {
  .form__all-wrapper__main-content {
    height: 70vh;
  }

  .ssa-form__poc-admin-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    p {
      margin-bottom: 0px;
      font-weight: 600;
      border: 1px solid #dedfdf;
      padding: 10px;
    }

    .ssa-form__table__comment {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
    }

    .ssa-form__table-not-border {
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;
    }
  }
}

.ssa-form__skills-section-wrapper {
  margin-top: 36px;
}

.ssa-form__skill-section {
  width: 100%;
  background-color: #ccf0ee;
  display: flex;
  align-items: center;
  margin: 20px 0px;
  padding: 18px 0px 17px 16px !important;
  font-weight: 600;
}

.ssa-form-skill__description-container {
  width: 100%;
  margin-bottom: 38px;

  p {
    width: 80%;
    margin-right: 96px;
  }

  .open-btn {
    color: #00b2a9;
    border: 1px solid #00b2a9;
    background-color: white;
    width: 127px;
    padding: 10px 5px;

    &:hover,
    &:focus,
    &:active {
      background-color: white !important;
    }
  }
}

.ssa-form__scores-wrappper {
  display: flex;
  width: 100%;
}

.skill-section__comments-area {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;

  .flex-column {
    margin-right: 27px;
    margin-top: 38px;

    .skill-section__comments {
      min-width: 340px;
      width: 100%;
    }
  }
}

.ssa-form__checkpoint-container {
  h4 {
    color: #eaaa00 !important;
    font-weight: 600;
    font-size: 14px;
  }

  .ssa-form__checkpoint-right {
    display: flex;

    p {
      padding-right: 30px;
      margin: 0px;
    }
  }
}

.ssa-form-grades.main-tab__skill-section__score-items {
  display: flex;
  justify-content: center;
  flex-direction: row;

  .main-tab__skill-section__score {
    width: fit-content;
    min-width: 95px;
    margin-bottom: 21px;
    margin-right: 70px;
    min-height: fit-content;
    border: 1px solid #dedfdf;
    font-size: 11px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    &.active {
      border-top: 2px solid #eaaa00;
      border: 1px solid #eaaa00;

      .skill-section__score__number {
        background-color: #eaaa00;
        color: white;
      }
    }

    p {
      width: 100%;
      margin: 0px;
    }

    p:nth-child(2) {
      padding: 3px 5px;
    }

    .skill-section__score__number {
      border-bottom: 1px solid #dedfdf;
      height: 23.5px;
      padding-top: 5px;
    }
  }
}

.ssa-form__check-accordion__title {
  padding-left: 20px !important;
  background-color: #f9f9f9;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.ssa-form__check-accordion__content {
  p {
    padding-left: 20px;
  }
}

//Footer
.ssa-form-buttons-container {
  width: 100%;

  .ui.button.ssa-form-return-btn {
    display: inline !important;
    padding: 5px 0px;
    min-width: 128px;
  }
}

.ssa-form-returned-modal {
  .modal-content {
    .returned-item__textarea {
      min-height: 50px;
      max-height: 150px;
      max-width: 250px;
      min-width: 100px;
      padding: 8px;
      outline: none;
    }
  }

  .ssa-form__skills-section-wrapper {
    margin-top: 36px;
  }

  .ssa-form__skill-section {
    width: 100%;
    background-color: #ccf0ee;
    display: flex;
    align-items: center;
    margin: 20px 0px;
    padding: 18px 0px 17px 16px;
    font-weight: 600;
  }
}

.assessment-forms-review-tag {
  color: #407ec9;
  border: 1px solid #407ec9;
}

.assessment-ssa-forms-done-tag {
  color: #00b24d;
  border: 1px solid #00b24d;
}

.assessment-forms-returned-tag {
  color: #8031a7;
  border: 1px solid #8031a7;
}

.assessment-forms-assessors-tag {
  color: #eaaa00;
  border: 1px solid #eaaa00;
}

//MEDIA QUERIES
@media all and (max-height: 800px) {
  .ssa-edit-form__all-wrapper {
    .form__all-wrapper__main-content {
      height: 70vh;
    }
  }
}

@media all and (min-width: 1800px) {
  .skill-section__comments-area {
    .flex-column {
      .skill-section__comments {
        min-width: 650px;
      }
    }
  }
}
