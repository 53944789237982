.events-form__validation-tab-container {
  margin-bottom: 50px;
}
.events-form__validation-tab__row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 42px;
  margin-bottom: 17px;
}
.events-form__validation-tab__row label {
  width: 70%;
}
.events-form__validation-tab__row .button {
  width: 68px;
  height: 24px;
  padding: 5px;
  font-weight: 500;
}
.events-form__validation-tab__row .ui.button:disabled {
  opacity: 1 !important;
}
.events-form__validation-tab__row .validation-tab__pending-btn {
  background-color: #ec8a83;
  color: white;
}
.events-form__validation-tab__row .validation-tab__validated-btn {
  background-color: #eeeeee;
  color: #b0b0b0;
}
