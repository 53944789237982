.notifications__bell-alert {
  min-width: 14px !important;
  min-height: 14px !important;
  top: -5px !important;
  transition: 0.8s;
  z-index: 99 !important;
}

.notifications-max-container {
  top: -6px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  right: 0px;
  justify-self: flex-end;
  position: absolute;
  transition: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: 0.8s;
  z-index: 1001;

  > div > div > div.main-notification-tab > div.ui.segment.active.tab {
    min-height: 761px;
    height: fit-content;
  }
}

.notifications__pop-up {
  animation: slide-up 1.2s ease;
  animation-fill-mode: backwards;
  width: 400px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  transition: all 0.8s linear;
  margin-bottom: 20px;

  a {
    text-decoration: none;
    color: #222629;
  }
}

.notifications__pop-up::before,
.notifications__pop-up::after {
  animation: slide-up 1s ease;
}

.main-notification-tab {
  width: 100%;

  .ui.pointing.secondary.menu {
    border-bottom: none;
    margin-left: 16px;

    .item {
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
    }

    .active.item {
      transition: 0.8s;
    }

    .active.item,
    .active.item:hover {
      margin: 0em 0em -3px;
      border-bottom-width: 3px;
      color: #474b4f;
      border-bottom-color: #00b2a9;
    }

    .item:hover {
      transition: 0.4s;
      margin: 0em 0em -3px;
      border-bottom-width: 3px;
      color: #00b2a9;
      border-bottom-color: #00b2a9;
    }
  }

  .ui.segment.active.tab {
    border: none;
    background-color: transparent;
    border-style: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.notif__header__buttons-container {
  margin: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notif__header__buttons-container__icons-wrapper {
  display: flex;
  justify-content: flex-start;
}

.notif__header__buttons-container__mark-all-wrapper {
  .notif__mark-all-btn {
    border: none;
    font-weight: bold;
    background-color: #eeeeee !important;
    padding: 0px;

    &:hover {
      color: rgb(0, 0, 0);
      transition: 0.8s;
    }
  }
}

.notification__filter-btn {
  border: 1px solid #474b4f !important;
  background-color: transparent !important;
  height: 24px;
  width: 24px;
  padding: 0px !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: 10px !important;
  margin-right: 8px !important;

  .icon {
    color: #474b4f;
    font-size: 14px;
  }
}

.notification__filter-btn-is-active {
  background-color: #00b2a9 !important;
  border: 1px solid #00b2a9 !important;

  .icon {
    color: white !important;
  }
}

.notification__filter-btn:hover {
  transition: 0.8s;
  background-color: #474b4f !important;
  background-image: none;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset !important;
  border: 1px solid #474b4f !important;

  .icon {
    color: white;
  }
}

.notifications__container {
  border-top: 1px solid white !important;
  transition: 0.8s;
}

.notif__header__remove-box {
  display: flex;
  justify-content: flex-end;
  margin: 16px 16px 0px;
}

.notification__remove-btn {
  background-color: transparent !important;
  color: #d1d2d3 !important;
  justify-self: flex-end;
  padding: 0px !important;
  font-size: 20px !important;

  &:hover {
    color: rgba(0, 0, 0, 0.8) !important;
    transition: 0.8s;
  }

  .icon {
    margin: 0px !important;
  }
}

.notification__message-box {
  padding: 8px 16px !important;
  border-bottom: 1px solid white !important;
  transition: 0.8s;

  .icon {
    font-size: 14px;
  }

  .notification__icon-event__wrapper {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    height: fit-content;

    .notification__message-title {
      text-align: left !important;
      font-weight: bold;
      width: fit-content !important;
    }
  }

  .notification__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    color: #00b2a9;
    background-color: #ccf0ee;
    margin-right: 12px;

    i.icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 0px;
    }
  }

  .notification__pinned-go-to__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    cursor: pointer;

    .icon {
      margin-left: 5px;
      font-size: 14px;
      cursor: pointer;
      z-index: 5;
    }

    .pin__active {
      color: #474b4f;
      transition: 0.8s;
    }

    .pin__not-active {
      color: #c9c9c9;
      transition: 0.8s;
    }
  }
}

.notification__is-not-read {
  background-color: #e3e4e5;

  &:hover {
    background-color: rgba(201, 201, 201, 0.383) !important;
    transition: 0.8s;
  }
}

.notification-was-read {
  background-color: #eeeeee !important;

  &:hover {
    background-color: rgba(201, 201, 201, 0.281) !important;
    transition: 0.7s;
  }

  .notification__icon {
    background-color: #d1d2d3;

    .icon {
      color: #b0b0b0;
    }
  }
}

.notification__date-hour {
  color: #b0b0b0;
  font-weight: bold;
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
}

.notification__pagination-wrapper {
  margin: 0px 16px;
  width: 93%;
}

.pagination__display-flex-elements {
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.notif__pag__link {
  text-decoration-style: none;
  display: flex;
  cursor: pointer;

  a {
    color: #b0b0b0;
  }

  .icon {
    margin: 0px 10px;
  }
}

.notif__pag__link:hover {
  color: black;
  transition: 0.9s;
}

.notif__pag__previous {
  color: #b0b0b0;
}

.notifications-not-found__wrapper {
  text-align: left;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;

  p {
    text-align: center !important;
    margin-top: 16px;
    font-weight: bold;
    color: #b5b5b5;
  }

  .notif__empty__image {
    margin-top: 50px;
  }
}

.hidden {
  display: none;
}

// TO DO LIST STYLES

.to-dolist__header {
  display: flex;
  justify-content: flex-end;
  margin: 0px 16px;
  align-items: center;

  .to-do-list__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    background-color: #474b4f !important;
    border: none;
  }

  .checkbox {
    label::before {
      transition: 0.8s;
      border: 1px solid #00b2a9;
      background-color: transparent !important;
    }

    label::after {
      transition: 0.8s;
      background-color: transparent !important;
      border-radius: 3px;
      color: white !important;
      font-weight: 100 !important;
    }
  }

  span {
    margin-right: 8px;
    font-weight: bold;
  }
}

.to-do__message-box {
  padding: 8px 16px !important;
  border-bottom: 1px solid white !important;
  background-color: #e3e4e5;

  .notification__date-hour {
    color: #b0b0b0;
    font-weight: bold;
    font-size: 11px;
    display: flex;
    justify-content: flex-end;
  }
}

.to-do__checkbox-event__wrapper {
  display: flex;
  align-items: center;

  .checkbox {
    margin-right: 12px;

    label::before {
      transition: 0.8s;
      border: 1px solid #00b2a9;
      background-color: transparent !important;
    }

    label::after {
      transition: 0.8s;
      background-color: transparent !important;
      border: 1px solid #b0b0b0;
      border-radius: 3px;
      color: #b0b0b0 !important;
    }
  }

  p {
    font-weight: bold;
    width: fit-content !important;
  }
}

.todo__reason {
  margin-top: 12px;
  text-align: left !important;
  width: fit-content !important;
  margin-left: 28px;
  padding-bottom: 8px;
  cursor: pointer;
}

.to-do-list__checkbox {
  margin-bottom: 8px;
  transition: 0.8s;
}

.to-do__pagination {
  padding-top: 10px;
}

//MEDIA QUERIES

@media all and (max-height: 800px) {
  .notifications-max-container {
    margin-top: 0px;
  }
}

@media all and (max-height: 700px) {
  .notifications-max-container {
    > div > div > div.main-notification-tab > div.ui.segment.active.tab {
      height: 600px;
    }
  }

  .notifications__container {
    overflow: scroll;
    overflow-x: hidden;
    width: 330px;
    height: 62vh;
  }

  .notifications__pop-up {
    overflow: hidden;
    overflow-x: hidden;
    width: 330px;
    height: 91vh;
  }

  .notifications__container::-webkit-scrollbar {
    width: 0px;
  }

  .notifications__container:hover::-webkit-scrollbar {
    width: 4px;
  }

  .notifications__container:hover::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }

  .notifications__container:hover::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(0, 0, 0);
  }

  .pagination__display-flex-elements {
    padding-top: 0px !important;
  }

  //to do list media query
  .to-do-list__container {
    overflow: scroll;
    overflow-x: hidden;
    width: 330px;
    scroll-padding: 5px !important;
    max-height: 67vh;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
