.generic-date-picker__wrapper {
  display: flex;
  align-items: center !important;
  height: 32px;

  .date-picker__left {
    width: 120px !important;
    height: 32px;
    margin-right: -1px !important;

    input {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      text-align: center !important;
      padding: 4px 20px 4px 2px !important;
    }
  }

  .date-picker__right {
    height: 32px;
    width: 120px;

    input {
      text-align: center !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      margin-left: -1px !important;
      padding: 4px 20px 4px 2px !important;
    }
  }
}

.generic-date-picker-modificable__wrapper {
  display: flex;
  align-items: center !important;
  height: 32px;

  .eventform-dates__first {
    > div {
      width: 110px;
      height: 32px;
      margin-right: -1px !important;
    }

    > div > i {
      display: none;
    }

    > div > input[type='text'] {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      text-align: center;
      padding: 9px 22px 9px 22px !important;
    }
  }

  .eventform-dates__second {
    > div {
      width: 110px;
      height: 32px;
    }

    > div > i {
      display: none;
    }

    > div > input[type='text'] {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      text-align: center;
      padding: 9px 22px 9px 22px !important;
    }
  }
}
