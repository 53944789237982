.viewpreset {
  width: 90px;

  .b-field-inner {
    background: #474b4f;
    border-radius: 4px;
    user-select: none;
    color: white;

    .b-icon {
      color: white;
      margin-right: 4px;
    }

    .b-field-updated {
      animation-name: none;
      animation-duration: unset;
    }
  }
}

.style-list.b-widget.b-list.b-floating {
  border-radius: 4px;
  border: 1px solid #707070;
}

.style-list .b-list-item {
  min-width: 80px;
  max-height: 38px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  color: #474b4f;
  border-bottom: 1px solid #707070;

  i {
    right: 8px !Important;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #474b4f;
    color: white;
  }

  &.b-active {
    color: white;
    background: #474b4f;
  }
}

.style-list .b-list-item .b-sch-event-wrap {
  height: 80px;
  // border: 1px dashed black;
  // width: calc(100% - 1.5em);
}

.style-list .b-list-item .b-sch-event:not(.b-sch-style-colored) {
  border: 1px dashed black;
  color: #fff !important;
}
