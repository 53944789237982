.vtc-section {
  margin: 8px 0;
}

.hzt-section {
  margin-right: 24px;
}

.main-separator {
  border-bottom: 1px solid #999;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.requests-events-wizard___modal {
  .ui.input.ui.input.ui.input.ui.input.ui.input.ui.input.ui.input {
    height: 32px !important;
    min-height: 32px;
    line-height: 0.8em;
  }
}

.new-events-support-details__container {
  font-size: 12px;
  padding: 0 50px;
  background-color: white;

  .events-support-details__main-info {
    position: relative;
    display: flex;
    margin: 32px 0;
    align-items: center;

    .cogs.icon {
      position: absolute;
      left: -45px;
      font-size: 17px;
    }

    .events-support-details__main-data {
      margin-right: 50px;
      display: flex;
      align-items: center;

      span {
        margin-right: 12px;
      }

      & > .ui.input {
        width: 90px;
      }
    }
  }

  .events-support-details__main-detail {
    display: flex;
    flex-direction: column;

    .events-support-details__info-position {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 16px 0;
      width: 100%;
      //border-bottom: 1px solid #999;

      > div {
        display: flex;
        margin-bottom: 20px;
        margin-right: 16px;
        align-items: center;
      }

      .flex-column {
        flex-direction: row;
      }

      label {
        margin-right: 16px;
      }

      .supppor-details__item-main-segment .ui.icon.input {
        width: 130px;
        margin-right: 0px;
      }

      & > label {
        margin-right: 16px;
      }

      .ui.input {
        margin-right: 16px;
        min-width: 20px !important;
      }

      .ui.icon.button {
        background-color: white;
        color: #474b4f;
        border: 1px solid #474b4f;
        border-radius: 4px;
        width: 150px;
        height: 35px;
        line-height: 0.8em;
      }
    }

    .events-support-details__detail {
      display: flex;
      padding: 20px 20px 20px 40px;
      background: #eee;

      .events-support-details__detail-position {
        padding: 16px 0;
        display: flex;
        flex-direction: column;

        .info-support-details__comment {
          margin-bottom: 16px;
        }

        .info-support-details__employee-info {
          display: flex;
          flex-wrap: wrap;
          margin-top: -16px;

          .info-support-details__employee-detailed {
            display: flex;
            align-items: center;
            margin-top: 16px;

            .ui.right.labeled.input {
              input {
                padding-right: 4px;
                width: 50px;
              }

              .ui.basic.label.label {
                padding-left: 4px;
                border-left: 0;
                line-height: 0.8;
              }

              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
            }

            .ui.right.labeled.input.enlarged-input {
              input {
                width: 75px;
              }
            }

            span {
              margin-right: 16px;
            }

            .fields {
              .field .ui.icon.input {
                i {
                  display: none;
                }

                width: 100px;
              }

              .field .from-date .ui.icon.input input {
                border-radius: 4px 0 0 4px;
                padding-right: 1em !important;
              }

              .field .to-date .ui.icon.input input {
                border-radius: 0 4px 4px 0;
                padding-right: 1em !important;
              }
            }
          }

          &:last-child {
            margin-top: 0;
            width: 100%;
          }
        }
      }

      .edit.icon {
        cursor: pointer;
        align-self: center;
        font-size: 16px;
      }

      .remove.icon {
        margin-left: 25px;
        cursor: pointer;
        font-size: 16px;
        align-self: center;
      }
    }
  }
}
