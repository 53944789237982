.tna__skills__wrapper {
  .custom-table .table__short-text {
    white-space: break-spaces;
  }

  .ui.table.custom-table {
    th:nth-child(3) {
      min-width: fit-content !important;
      width: fit-content !important;
      max-width: 359px !important;
    }

    td:nth-child(3) > div {
      max-width: 359px !important;
      min-width: fit-content !important;
      width: fit-content !important;
    }
  }

  .tna__inputs-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 72%;

    .ui.input {
      margin-right: 12px;
      width: 204px;
      margin-top: 10px;
    }

    .ui.inline.dropdown > .text {
      font-weight: 100 !important; //machine related dropdown
      min-width: 144px;
      width: 144px;
    }

    .planit-users-inputs.planit-user-dropdown.planit-user-selector {
      min-width: 160px !important;
      margin-right: 12px;
      margin-top: 10px;

      i {
        color: grey;
      }
    }
  }

  .question-bank__cell__tag {
    font-weight: 500;
    background-color: #eeeeee;
    margin: 5px;
    color: #474b4f;
    min-width: fit-content !important;
    max-width: fit-content !important;
    height: 22px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-table-main-actions {
    margin-left: 30px !important;

    .custom-table-add-btn {
      .icon {
        display: none;
      }
    }
  }

  .popup {
    a {
      font-weight: bold !important;
    }
  }

  .planit-user-dropdown {
    width: 180px !important;
    min-height: 32px !important;
    word-break: break-all !important;
    height: fit-content;
    justify-content: center !important;
  }

  .planit-user-dropdown-custom {
    width: 180px !important;
    min-height: 32px !important;
    height: fit-content;
    justify-content: center !important;
  }
}

.tna-skill__label-input {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  width: fit-content !important;
  padding: 0px;

  label {
    margin-right: 20px !important;
    min-width: fit-content;
    width: 108px;
    text-align: end;
  }
}

.tna-skill__selctor-group {
  display: flex;
  justify-content: flex-end !important;
  width: fit-content;
}

.tna-skill__label-textarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: fit-content;

  label {
    margin-right: 20px !important;
    margin-top: 10px !important;
  }
}

.tna-skill__modal {
  border-radius: 8px;
  width: 772px !important;
  min-height: 353px !important;
  height: fit-content !important;
}

.tna-skill__custom-editorequest__textarea {
  height: 46px;
  width: 500px !important;
  resize: none !important;
}

.tna-skill__custom-editorequest__textarea.longArea {
  height: 67px;
}

.tna-skill__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 24px;
}

.tna-skill__modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.new-tna-skill__btns {
  margin-right: 24px;
}

//MEDIA QUERIES

@media all and (min-width: 1285px) {
  .tna-skill__modal {
    width: 842px !important;
  }
}

@media all and (min-width: 1285px) {
  .tna-skill__wrapper {
    margin-right: 104px;
  }
}

@media all and (min-width: 1285px) {
  .new-tna-skill__btns {
    margin-right: 32px;
  }
}
