.support-details-container {
  font-size: 12px;
  position: relative;
}
.support-details-container.isform .support-details__pre-approved {
  position: absolute;
  top: -60px;
  right: 0;
}
.support-details-container.isform .support-details__main {
  margin-left: 42px;
}
.support-details-container.isform .support-details__main .support-details__add-position .cogs.large.icon {
  position: absolute;
  left: 0;
}
.support-details-container .support-details__pre-approved {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
}
.support-details-container .support-details__pre-approved h5 {
  white-space: nowrap;
  text-align: left;
}
.support-details-container .support-details__pre-approved .ui.checkbox {
  display: inline-flex;
  text-align: right;
}
.support-details-container .support-details__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5%;
}
.support-details-container .support-details__main input {
  width: 100%;
}
.support-details-container .support-details__main .support-details__add-position {
  width: 100%;
  margin: 16px 0;
  min-height: 32px;
  display: flex;
  align-items: center;
}
.support-details-container .support-details__main .support-details__add-position .cogs.large.icon {
  position: absolute;
  left: 0;
}
.support-details-container .support-details__main .support-details__add-position .support-details__add-btn {
  display: flex;
  justify-content: space-between;
  font-size: 12px !important;
  padding: 8px;
  height: 32px;
  white-space: nowrap;
  width: 160px;
  border: 1px solid #474b4f;
  color: #474b4f;
  background-color: #ffffff;
  margin-right: 50px;
}
.support-details-container .support-details__main .support-details__add-position .support-details__add-btn i {
  margin: 4px;
  font-size: 14px;
}
.support-details-container .support-details__main .support-details__add-position .costs {
  display: flex;
  flex-wrap: wrap;
}
.support-details-container .support-details__main .support-details__add-position .costs .costs-container {
  margin: 4px 12px;
}
.support-details-container .support-details__main .support-details__item {
  display: flex;
  align-items: center;
  width: 100%;
}
.support-details-container .support-details__main .support-details__item:nth-child(2) .support-details__item-collection {
  border-top: 1px solid #dedfdf;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection {
  width: 100%;
  border-bottom: 1px solid #dedfdf;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__comment-input.ui.input {
  width: 250px;
  margin: 16px 0;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main {
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: -4px 0;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment {
  display: flex;
  align-items: center;
  margin-right: 32px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.right.labeled.input input {
  padding-right: 4px;
  width: 40px;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.right.labeled.input .ui.basic.label.label {
  padding-left: 4px;
  border-left: none;
  line-height: 0.8em;
  font-weight: lighter;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .support-details__segment-label {
  margin-right: 16px;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .fields .field .ui.icon.input {
  width: 100px;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .fields .field .ui.icon.input i {
  display: none;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .fields .field .from-date .ui.icon.input input {
  border-radius: 4px 0 0 4px;
  padding-right: 1em !important;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .fields .field .to-date .ui.icon.input input {
  border-radius: 0 4px 4px 0;
  padding-right: 1em !important;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.input:not(.icon) {
  width: 70px;
  /* Firefox */
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.input:not(.icon) input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.input:not(.icon) input[type='number'] {
  -moz-appearance: textfield;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.inline.search.selection.dropdown.planit-user-selector__position-code {
  max-width: fit-content !important;
  width: fit-content !important;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.inline.search.selection.dropdown.planit-user-selector__position-code .visible.menu.transition {
  min-width: fit-content;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__item-main .supppor-details__item-main-segment .ui.inline.search.selection.dropdown.planit-user-selector__position-code .visible.menu.transition .item {
  white-space: nowrap;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__location-detail-item {
  margin: 12px 0;
  display: flex;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__location-detail-item .support-details__location-detail-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__location-detail-item .support-details__location-detail-label span {
  height: 14px;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__location-detail-item .ui.search.selection.dropdown.custom-editor {
  width: 170px !important;
  margin-right: 32px;
  height: 32px !important;
  min-height: unset;
  line-height: 0.8em;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__location-detail-item .ui.search.selection.dropdown.custom-editor .icon {
  line-height: 0.8em;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__location-detail-item .ui.search.selection.dropdown.custom-editor .visible.menu.transition {
  min-width: fit-content;
}
.support-details-container .support-details__main .support-details__item .support-details__item-collection .support-details__location-detail-item .ui.search.selection.dropdown.custom-editor .visible.menu.transition .item {
  white-space: nowrap;
}
.support-details-container .support-details__main .support-details__item i.red.remove.icon::before {
  font-size: 18px;
  cursor: pointer;
}
