.feedbacks-wizard__steps-container.theoretical-form-wizard-container {
  .ui.dropdown > .clear.dropdown.icon::before {
    color: red !important;
  }

  .theoretical-template-wizard__first-step {
    width: 100%;

    .first-step__content {
      display: flex;
      justify-content: space-between;
      margin: 16px 0px 50px 0px;
      flex-wrap: wrap;

      label {
        padding-bottom: 8px;
      }

      .title {
        display: flex;
        flex-direction: column;
        margin-right: 32px;
        font-weight: 500;
        width: 45%;
      }

      .header {
        font-weight: 500;
        display: flex;
        min-width: 52%;
        flex-direction: column;
        justify-self: flex-end !important;
        margin-top: 10px;
      }

      .theoretical-wizard__textarea {
        max-width: 100% !important;
        min-width: 200px !important;
        height: 150px;
        min-height: 150px;
        max-height: 200px;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid #22242626;
        outline: none;
        resize: none;
      }

      .first-step__filters-container {
        margin-top: 32px;
        flex-wrap: wrap;
        width: 100%;

        .flex-start-center {
          margin-right: 5%;
          margin-bottom: 10px;

          label {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

.template-search__modal {
  .template-search__first-row__column-filters {
    .ui.search.selection.dropdown.planit-user-dropdown-custom {
      margin-right: 12px !important;
    }
  }
}

.eventtype-selector {
  width: 200px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  min-width: 120px !important;
  padding: 8px 14px 8px 14px;
  border: 1px solid #22242626;
  min-height: 38px;
  height: 38px;
  max-height: 38px;
  border-radius: 4px;

  input.search {
    padding: 8px 14px 8px 14px;
    height: 38px;
  }

  .menu.transition.visible {
    .item {
      .ui.checkbox {
        margin-right: 16px;
      }

      display: flex;
      align-items: center;
    }
  }
}

.ui.list.event-type-list {
  width: 50%;

  .item {
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;

    &:last-child span.event-type-list__item {
      border-bottom: 1px solid #b0b0b0;
    }

    span.event-type-list__item {
      width: 100%;
      margin-right: 24px;
      border-top: 1px solid #b0b0b0;
      padding: 12px;
    }

    i.icon {
      color: #e03c31;
    }

    &::after {
      display: none !important;
      content: unset !important;
    }
  }
}

.ui.modal.feedbacks-confirmation-modal {
  background-color: #474b4f;

  .feedbacks-confirmation-modal__content.content {
    background-color: #474b4f;
    color: white;
  }

  .actions {
    .ui.small.button.feedbacks-confirmation-modal__btn {
      white-space: nowrap;
      min-width: fit-content;
    }
  }
}

.theoretical-templates-wizard__btns {
  margin: 48.5px 0px 20px 0px;
  text-align: right;

  .button:first-of-type {
    margin-right: 16px;
  }

  .ui.inverted.secondary.button {
    box-shadow: 0px 0px 0px 1px #545454 inset !important;
  }
}

.template-list-table.theoretical-questions-list {
  .full-width.table__flex-container {
    overflow-x: scroll !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.wizard__theoretical-question-text {
  width: 50%;
}

.theoretical__questions-modal.template-search__modal {
  .template-search__first-row__column-filters {
    .ui.search.selection.dropdown {
      margin-right: 12px !important;
      margin-bottom: 5px;
      font-weight: 500 !important;
    }

    .ui.inline.search.selection.dropdown.template-wizard__machine-unit {
      font-weight: 500 !important;

      .default.text {
        font-weight: 500 !important;
      }

      i.dropdown.icon {
        margin: 0px !important;
        padding: 0px !important;

        &::before {
          margin: 0px !important;
          padding: 0px !important;
        }
      }
    }
  }
}
