.events-wizard__instructors-step__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 11%;
  min-height: 250px;

  .form__instructors-tab__buttons-row {
    width: 100%;
    display: flex;

    .button {
      min-width: 133px;
    }

    .ui[class*='right labeled'].icon.button {
      padding: 3px 16px 3px 4px !important;
    }
  }

  .wizard__inputs-dates-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px;

    .fields {
      margin-bottom: 0px;
    }

    .planit-eventform-dates-period {
      width: 110px;

      > div > i {
        font-size: 16px;
      }

      div > input[type='text'] {
        padding: 10px 15px 10px 8px !important;
        display: flex;
        justify-content: center;
        text-align: start;
      }
    }

    .from-date {
      div > input[type='text'] {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .to-date {
      div > input[type='text'] {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: -1px;
      }
    }
  }
}

.events-wizard__instructors-step__table {
  align-items: flex-start;
  display: grid;
  grid-template-columns: auto auto auto auto 20px 20px;
  grid-auto-rows: minmax(min-content, max-content);
  column-gap: 20px;
  row-gap: 10px;
  width: 100%;
  z-index: 10;
  height: fit-content !important;

  .ui.selection.dropdown.planit-eventform-inputs {
    min-width: 120px !important;
    min-height: 100%;
  }

  .instructors__edit-icon__container,
  .instructors__delete-icon__container {
    padding-top: 10px;
  }

  .instructors__edit-icon__container {
    color: #474b4f;
    font-size: 10px;
  }
}
