.events-form__documentation-container {
  display: flex;
  flex-direction: column;
}
.events-form__documentation-container .form__tab__icon-element {
  align-items: flex-start !important;
}
.events-form__documentation-container .form__tab__icon-element p {
  margin-bottom: 0px;
  margin-right: 16px;
}
.events-form__documentation-container .form__tab__icon-element .button {
  border: 1px solid #474b4f;
  color: #474b4f !important;
}
.events-form__documentation-container .form__tab__icon-element .button .icon {
  background-color: transparent !important;
}
.events-form__documentation-container .documentation__links-list {
  display: flex !important;
  flex-direction: column !important;
}
.events-form__documentation-container .documentation__links-container {
  margin-left: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 11px;
}
.events-form__documentation-container .documentation__links-container .icon {
  margin-left: 24px;
}
.event-form-documentation-popup-buttons {
  text-align: end;
}
.event-form-documentation-popup-buttons > button.ui.basic.button.cancel-button {
  margin-right: 16px;
}
.event-form-documentation-popup {
  min-width: 398px !important;
  min-height: 120px;
  text-align: start;
  padding: 16px !important;
}
.event-form-documentation-popup .event-form-documentation-popup-input {
  margin-bottom: 5px;
  text-align: left;
}
.event-form-documentation-popup .event-form-documentation-popup-input > div.ui.input > input {
  width: 200px;
  height: 32px;
}
.event-form-documentation-popup .popup-url-input {
  display: flex;
  justify-content: space-between;
  margin-right: 4px !important;
}
.event-form-documentation-popup .popup-url-input input {
  min-width: 319px;
}
