.wizard__event-details__3step {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 39px;
  margin-right: 0px;
  padding: 0px 10%;

  .ui.search.selection.dropdown.custom-editor {
    min-width: 180px !important;
    width: 180px !important;
  }

  .event-details__3step__row__element {
    width: 650px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
      margin-right: 16px !important;
      width: 140px;
      font-weight: 600;
      text-align: end;
    }
  }

  .event-details-row__wrapper {
    width: 650px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;

    .event-details__row__element {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      label {
        padding-right: 16px !important;
        font-weight: 600;
        text-align: end;
        width: 130px;
      }
    }
  }
}

@media all and (min-width: 1300px) {
  .wizard__event-details__3step {
    padding: 0px 20%;
  }
}
