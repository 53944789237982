.choose-instructors__modal {
  .choose-instructors__modal__dropdown {
    width: 120px;
    margin-bottom: 10px;
    margin-top: 0px;
    margin-right: 12px;
  }

  .input.icon.choose-instructors__modal__dropdown {
    width: 200px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
}
